import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-similac-whychoose',
  templateUrl: './similac-whychoose.component.html',
  styleUrls: ['./similac-whychoose.component.scss']
})
export class SimilacWhychooseComponent implements OnInit {
  hideInChinese: boolean = false;
  constructor(public translate: TranslateService, private apiService: ApiService) {
    this.apiService.chineseLang.subscribe(res=>{
      this.hideInChinese = res;
     })
   }
   ValueSelected:any;
   selectedVal:any;
  ngOnInit(): void {
    window.scrollTo({top: 0})
    this.apiService.trackLang.subscribe(res=>{
      this.ValueSelected = res;
      if(this.ValueSelected != ''){
        this.selectedVal = this.ValueSelected;
      }
     // console.log(this.ValueSelected ,this.selectedVal )
     
    })
  }

  // selectedLanguage(lang_val){
  //   if(lang_val == 'en_US'){
  //     this.hideInChinese = false;
  //     this.translate.use(lang_val)
  //   }
  //   if(lang_val == 'bh'){
  //     this.hideInChinese = false;
  //     this.translate.use(lang_val)
  //   }
  //   if(lang_val == 'ch'){
  //     this.hideInChinese = true;
  //     this.translate.use(lang_val)
  //   }
  // }

}
