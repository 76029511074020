<nav class="headerMenu headerSecondary pb-0 ">
    <div class="custom-container">
        <div class="row align-items-center m-0 topHeader">
            <div class="col-6">
                <div class="row m-0 align-items-center">
                    <div class="logo col-8 pr-0 pl-0">
                        <img src="../../../assets/images/logo-new.png" alt="logo" />
                    </div>
                    <div class="shareCareWord text-right col-4 pr-0">
                        <img class="max-width-100" src="../../../assets/images/headerlogosharecare.png"
                            alt="share care world" />
                    </div>
                </div>
    
            </div>
            <div class="col-6">
                <div class="row m-0 align-items-center justify-content-between">
                    <div class="offset-7 col-2 pr-0 pl-0 headerAccount">
                        <a href="javascript:;" class="link" routerLink="/profile">
                            <img class="max-width-100" src="../../../assets/images/acconunt.png" alt="Account" />
                        </a>
                    </div>
                    <div class="text-right col-2 pr-0 pl-0">
                        <img 
                         class="max-width-85" src="../../../assets/images/close.png" alt="Close" 
                         *ngIf="showCloseIcon" (click)=" onClickCloseIcon()" />
                        <img class="max-width-100" src="../../../assets/images/hamburger.png" alt="Menu" 
                        *ngIf="showHamburgerIcon" (click)="onclickHamburger()" />
                    </div>
                </div>
            </div>
        </div>
        <div class="menuOuter" *ngIf="showMenuItems">
            <div class="menu-items text-center">
                <div class="menu-link">
                    <a href="javascript:;" class="link" routerLink="/carepoints">CarePoints</a>
                </div>
                <div class="menu-link">
                    <a href="javascript:;" class="link" routerLink="/redeem-rewards">REDEMPTION CATALOGUE</a>
                </div>
                <div class="menu-link">
                    <a href="javascript:;" class="link" routerLink="/products">PRODUCTS</a>
                </div>
                <div class="menu-link">
                    <a href="http://pediasuregrowthdiary.com.my/" class="link">PEDIASURE GROWTH DIARY</a>
                </div>
                <!-- <div class="menu-link refer_link">
                    <a href="javascript:;" class="link" routerLink="/refer-friend">REFER A FRIEND</a>
                </div> -->
            </div>
        </div>
    </div>
</nav>