import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ensuresharecare',
  templateUrl: './ensuresharecare.component.html',
  styleUrls: ['./ensuresharecare.component.scss']
})
export class EnsuresharecareComponent implements OnInit {

  productInfo:any;
  content:any;
  expiryDate:any;

  constructor() {
    this.productInfo = JSON.parse(sessionStorage.getItem('iSprintData'));
    console.log(this.productInfo, 'productInfo')
    }
 
    parseFunction(value) {
     return JSON.parse(value);
   }
 
   ngOnInit(): void {
    window.scrollTo({top:0})
    //  this.content = this.productInfo.product.txt;
    //  this.content.forEach((item: any) => {
    //   if (item.orders == 5) {
    //     console.log('expiry date')
    //     if(this.parseFunction(item.value).en_US == undefined){
    //       let parsedPrimaryValue = this.parseFunction(item.value).value;
    //       console.log(parsedPrimaryValue, 'parsedPrimaryValue')
    //       console.log(this.parseFunction(item.value), 'his.parseFunction(item.value)')
    //       this.expiryDate = parsedPrimaryValue;
    //     }else{
    //       let parsedPrimaryValue = this.parseFunction(item.value).en_US;
    //       console.log(parsedPrimaryValue, 'parsedPrimaryValue')
    //       console.log(this.parseFunction(item.value), 'his.parseFunction(item.value)')
    //       this.expiryDate = parsedPrimaryValue;
    //     }
    //   }
      
    //  })
   }
}
