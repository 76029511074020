<section class="desktopOnly">
  <div class="desktopOnlyMessage">
    <div class="row header-new">
      <div class="logo col-6">
        <img src="../../../assets/images/logo-new.png" alt="logo" />
      </div>
      <div class="text-right col-6">
        <img
          class="max-width-100"
          src="../../../assets/images/headerlogosharecare.png"
          alt="share care world"
        />
      </div>
    </div>

    <div class="background-img p-5">
      <div class="d-flex justify-content-center">
        <div class="txt-col white-background">
          <div class="text-center mb-2">
            <img
              src="../../../assets/images/Close-icon.png"
              width="80"
              height="80"
            />
          </div>
          <p class="fs48 fW600 text-center mb-5">SORRY</p>
          <p class="fW600 text-center site-content">
            THIS SITE CAN ONLY BE VIEWED <br />ON MOBILE DEVICES
          </p>
        </div>
      </div>
      <!-- <div class="mt-5 text-center fs10 txt-col fW600">
        <p>
          COPYRIGHT 2022 ABBOTT LABORATORIES (MALAYSIA) SDN. BHD.
          MAL.2022.24852.ENS -PDS -SIM.1)
        </p>
      </div> -->
    </div>
    <div class="footer-fixed">
      <div class="footer-new">
        <div class="">
          <a routerLink="/aboutus" class="about-link">ABOUT ASCW</a>
        </div>
        <div class="fs14">
          <p class="mb-0">
            <sup>©</sup>Copyright 2022 Abbott Laboratories Sdn Bhd (163560-X)
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<ngx-spinner> </ngx-spinner>
<div class="mobile-only-pages">
  <router-outlet></router-outlet>
</div>
