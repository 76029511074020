<app-header-primary></app-header-primary>
<section class="blueBg _h100vh positon-relative pb100">
    <div class="codeBottom right text-right">
        <p>MAL.2021.19812.CORP.1 (v1.0)</p>
    </div>
    <div class="whiteBg mt100 h420px">
        <div class="row h-100 align-items-center textBlockOut">
            <div class="col-12 text-center">
                <img class="img-fluid successImg" src="../../../assets/images/suceessiconlughtblue.png">
                <h3 class="mt60">SUCCESS</h3>
                <p class="boldContent mt50 mb-0 text-uppercase">Your redemption is successful!<br />
                    Kindly allow 4 weeks for your delivery.</p>
                
            </div>
        </div>
    </div>
    <div class="-mx-20">
        <app-footer></app-footer>
    </div>
</section>
<app-bottom-nav></app-bottom-nav>

