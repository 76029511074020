import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss']
})
export class ProductInfoComponent implements OnInit {
  productInfo:any;
  productImage:any;
  content:any;
  productCode:any;
  productName:any;
  productName1:any;
  productName2:any;
  batchNumber:any;
  sourceofIn:any;
  manufacturingDate:any;
  expiryDate:any;
  arrivalDate:any;
  manufactorIn:any;
  customClearanceDate:any;
  
  constructor() {
   this.productInfo = JSON.parse(sessionStorage.getItem('iSprintData'));
   console.log(this.productInfo, 'productInfo')
   }

   parseFunction(value) {
    return JSON.parse(value);
  }

  ngOnInit(): void {
    window.scrollTo({top:0})
    this.productImage = this.productInfo.product_image;
    this.content = this.productInfo.product.txt;
    this.content.forEach((item: any) => {
      // product code 
      this.productCode = this.productInfo.product.productCode;
      if (item.code == 'name') {
        if(this.parseFunction(item.value).en_US == undefined){
          let parsedPrimaryValue = this.parseFunction(item.value).value;
          this.productName = parsedPrimaryValue;
        }else{
          let parsedPrimaryValue = this.parseFunction(item.value).en_US;
          this.productName = parsedPrimaryValue;
        }
        // let splitWords = this.productName.split(' ');
        // let slice = splitWords.slice(2);
        // let slice2 = splitWords[0] + ' ' + splitWords[1];
        // let join = slice.join(' ');
        // this.productName1 = slice2
        // this.productName2 = join
      }
      if (item.code == 'Batch') {
        if(this.parseFunction(item.value).en_US == undefined){
          let parsedPrimaryValue = this.parseFunction(item.value).value;
          this.batchNumber = parsedPrimaryValue;
        }else{
          let parsedPrimaryValue = this.parseFunction(item.value).en_US;
          this.batchNumber = parsedPrimaryValue;
        }
      }
      if (item.code == 'SourceOfMilk') {
        if(this.parseFunction(item.value).en_US == undefined){
          let parsedPrimaryValue = this.parseFunction(item.value).value;
          this.sourceofIn = parsedPrimaryValue;
        }else{
          let parsedPrimaryValue = this.parseFunction(item.value).en_US;
          this.sourceofIn = parsedPrimaryValue;
        }
        
      }
      if (item.code == 'ManufacturingDate') {
        console.log('manu facturing date')
        if(this.parseFunction(item.value).en_US == undefined){
        let parsedPrimaryValue = this.parseFunction(item.value).value;
        this.manufacturingDate = parsedPrimaryValue;
        }else{
          let parsedPrimaryValue = this.parseFunction(item.value).en_US;
          this.manufacturingDate = parsedPrimaryValue;
        }
      }
      if (item.code == 'ManufacturesIn') {
        if(this.parseFunction(item.value).en_US == undefined){
          let parsedPrimaryValue = this.parseFunction(item.value).value;
          this.manufactorIn = parsedPrimaryValue;
        }else{
          let parsedPrimaryValue = this.parseFunction(item.value).en_US;
          this.manufactorIn = parsedPrimaryValue;
        }
      }
      if (item.code == 'ExpirationDate') {
        console.log('expiry date')
        if(this.parseFunction(item.value).en_US == undefined){
          let parsedPrimaryValue = this.parseFunction(item.value).value;
          console.log(parsedPrimaryValue, 'parsedPrimaryValue')
          console.log(this.parseFunction(item.value), 'his.parseFunction(item.value)')
          this.expiryDate = parsedPrimaryValue;
        }else{
          let parsedPrimaryValue = this.parseFunction(item.value).en_US;
          console.log(parsedPrimaryValue, 'parsedPrimaryValue')
          console.log(this.parseFunction(item.value), 'his.parseFunction(item.value)')
          this.expiryDate = parsedPrimaryValue;
        }
      }
      if (item.code == 'ArrivalDate') {
        if(this.parseFunction(item.value).en_US == undefined){
          let parsedPrimaryValue = this.parseFunction(item.value).value;
          this.arrivalDate = parsedPrimaryValue;
        }else{
          let parsedPrimaryValue = this.parseFunction(item.value).en_US;
          this.arrivalDate = parsedPrimaryValue;
        }
       
      }
      if (item.code == 'CustomClearanceDate') {
        if(this.parseFunction(item.value).en_US == undefined){
          let parsedPrimaryValue = this.parseFunction(item.value).value;
          this.customClearanceDate = parsedPrimaryValue;
        }else{
          let parsedPrimaryValue = this.parseFunction(item.value).en_US;
          this.customClearanceDate = parsedPrimaryValue;
        }
       
      }
    })
  }


}
