import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-primary',
  templateUrl: './header-primary.component.html',
  styleUrls: ['./header-primary.component.scss']
})
export class HeaderPrimaryComponent implements OnInit {
   showMenuItems = false;
  showCloseIcon = false;
  showHamburgerIcon = true;
  constructor() { }

  ngOnInit(): void {
  }
   onclickHamburger() {
    this.showMenuItems = true;
    this.showHamburgerIcon = false;
    this.showCloseIcon = true;
  }

  onClickCloseIcon() {
    this.showMenuItems = false;
    this.showHamburgerIcon = true;
    this.showCloseIcon = false;
  }

}
