import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-traceability-ensure',
  templateUrl: './traceability-ensure.component.html',
  styleUrls: ['./traceability-ensure.component.scss'],
})
export class TraceabilityEnsureComponent implements OnInit {
  productInfo: any;
  productImage: any;
  content: any;
  productCode: any;
  productName: any;
  productName1: any;
  productName2: any;
  batchNumber: any;
  sourceofIn: any;
  manufacturingDate: any;
  expiryDate: any;
  arrivalDate: any;
  manufactorIn: any;
  customClearanceDate: any;
  prodImage: any;
  sku: any;
  isIsprint: any;
  flavor: any;

  constructor(
    private apiService: ApiService, 
    private bsService: BugsnagService
    ) {
    this.productInfo = JSON.parse(sessionStorage.getItem('iSprintData'));
    console.log(this.productInfo, 'productInfo');
  }

  parseFunction(value) {
    return JSON.parse(value);
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    this.getTraceabilityContent();
    this.apiService.getGpasProductImage(this.sku).subscribe((res: any) => {
      console.log(res.data, 'getGpasProductImage');
      this.prodImage = res.data[0]?.productimage;
      console.log(this.prodImage);
    }, err => {
      this.bsService.notifyError(err, {sku: this.sku});
    });
    // this.productImage = this.productInfo.product_image;
    // this.content = this.productInfo.product.txt;
    // this.content.forEach((item: any) => {
    //   // product code
    //   this.productCode = this.productInfo.product.productCode;
    //   if (item.orders == 1) {
    //     if(this.parseFunction(item.value).en_US == undefined){
    //       let parsedPrimaryValue = this.parseFunction(item.value).value;
    //       this.productName = parsedPrimaryValue;
    //     }else{
    //       let parsedPrimaryValue = this.parseFunction(item.value).en_US;
    //       this.productName = parsedPrimaryValue;
    //     }
    //     let splitWords = this.productName.split(' ');
    //     let slice = splitWords.slice(2);
    //     let slice2 = splitWords[0] + ' ' + splitWords[1];
    //     let join = slice.join(' ');
    //     this.productName1 = slice2
    //     this.productName2 = join
    //   }
    //   if (item.orders == 8) {
    //     if(this.parseFunction(item.value).en_US == undefined){
    //       let parsedPrimaryValue = this.parseFunction(item.value).value;
    //       this.batchNumber = parsedPrimaryValue;
    //     }else{
    //       let parsedPrimaryValue = this.parseFunction(item.value).en_US;
    //       this.batchNumber = parsedPrimaryValue;
    //     }
    //   }
    //   if (item.orders == 2) {
    //     if(this.parseFunction(item.value).en_US == undefined){
    //       let parsedPrimaryValue = this.parseFunction(item.value).value;
    //       this.sourceofIn = parsedPrimaryValue;
    //     }else{
    //       let parsedPrimaryValue = this.parseFunction(item.value).en_US;
    //       this.sourceofIn = parsedPrimaryValue;
    //     }

    //   }
    //   if (item.orders == 3) {
    //     console.log('manu facturing date')
    //     if(this.parseFunction(item.value).en_US == undefined){
    //     let parsedPrimaryValue = this.parseFunction(item.value).value;
    //     this.manufacturingDate = parsedPrimaryValue;
    //     }else{
    //       let parsedPrimaryValue = this.parseFunction(item.value).en_US;
    //       this.manufacturingDate = parsedPrimaryValue;
    //     }
    //   }
    //   if (item.orders == 4) {
    //     if(this.parseFunction(item.value).en_US == undefined){
    //       let parsedPrimaryValue = this.parseFunction(item.value).value;
    //       this.manufactorIn = parsedPrimaryValue;
    //     }else{
    //       let parsedPrimaryValue = this.parseFunction(item.value).en_US;
    //       this.manufactorIn = parsedPrimaryValue;
    //     }
    //   }
    //   if (item.orders == 5) {
    //     console.log('expiry date')
    //     if(this.parseFunction(item.value).en_US == undefined){
    //       let parsedPrimaryValue = this.parseFunction(item.value).value;
    //       console.log(parsedPrimaryValue, 'parsedPrimaryValue')
    //       console.log(this.parseFunction(item.value), 'his.parseFunction(item.value)')
    //       this.expiryDate = parsedPrimaryValue;
    //     }else{
    //       let parsedPrimaryValue = this.parseFunction(item.value).en_US;
    //       console.log(parsedPrimaryValue, 'parsedPrimaryValue')
    //       console.log(this.parseFunction(item.value), 'his.parseFunction(item.value)')
    //       this.expiryDate = parsedPrimaryValue;
    //     }
    //   }
    //   if (item.orders == 7) {
    //     if(this.parseFunction(item.value).en_US == undefined){
    //       let parsedPrimaryValue = this.parseFunction(item.value).value;
    //       this.arrivalDate = parsedPrimaryValue;
    //     }else{
    //       let parsedPrimaryValue = this.parseFunction(item.value).en_US;
    //       this.arrivalDate = parsedPrimaryValue;
    //     }

    //   }
    //   if (item.orders == 6) {
    //     if(this.parseFunction(item.value).en_US == undefined){
    //       let parsedPrimaryValue = this.parseFunction(item.value).value;
    //       this.customClearanceDate = parsedPrimaryValue;
    //     }else{
    //       let parsedPrimaryValue = this.parseFunction(item.value).en_US;
    //       this.customClearanceDate = parsedPrimaryValue;
    //     }

    //   }
    // })
  }
  getTraceabilityContent() {
    this.productName = this.productInfo.product_name;
    this.productImage = this.productInfo.product_image;

    if (this.productInfo.is_isprint == true) {
      let parsedProductName = this.parseFunction(this.productInfo.product_name);
      this.productName = parsedProductName.ms_MY;

      this.batchNumber = this.productInfo.batch_number.value;
      this.manufactorIn = this.productInfo.country_of_origin.en_US;
      this.manufacturingDate = this.productInfo.manufacture_date.value;
      this.expiryDate = this.productInfo.expiry_date.value;
      this.customClearanceDate = this.productInfo.custom_clearance_date.value;
      this.arrivalDate = this.productInfo.arrival_date.value;
    } else {
      this.productName = this.productInfo.product_name;
      this.batchNumber = this.productInfo.batch_number;
      this.manufactorIn = this.productInfo.country;
      this.manufacturingDate = this.productInfo.manufacture_date;
      this.expiryDate = this.productInfo.expiry_date;
      this.customClearanceDate = this.productInfo.custom_clearance_date;
      this.arrivalDate = this.productInfo.arrival_date;
      this.flavor = this.productInfo.flavor;
    }
    this.sku = this.productInfo.sku;
    this.isIsprint = this.productInfo.is_isprint;
  }
}
