<app-header-primary></app-header-primary>
<div class="blueBg pb100">
    <div class="whiteBg p0 mb0">
        <div class="similacDetailsOut">
            <h2 class="text-uppercase text-center">WHY CHOOSE <span>SIMILAC GAIN PLUS GOLD</span></h2>
            <div class="inner">
                <p>As a parent, your child’s growth and safety are certainly your biggest priority.</p>
                <p>With SIMILAC Gain Plus Gold’s essential nutritional ingredients, you will never have to worry. Apart from the very beneficial 2’‑FL and Nucleotides, there are 10 other key nutrients that fall under the Abbott’s patented Eye-Q System.</p>
                <div class="mt20 mb40">
                    <img src="../../../assets/images/sdi1.png" alt="nutrition" />
                </div>
                <p class="content1 text-center">After years of scientific development, 2’-FL is finally here! A formulation that supports your child’s body defense and fast learning.</p>
            </div>
            <div class="yellowBg">
                <div class="row">
                    <div class="col-4">
                        <img src="../../../assets/images/sdi2.png" alt="illness" />
                    </div>
                    <div class="col-4">
                        <img src="../../../assets/images/sdi3.png" alt="illness" />
                    </div>
                    <div class="col-4">
                        <img src="../../../assets/images/sdi4.png" alt="illness" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-8">
                        <p class="content1 mt5 text-left">
                            2'-FL is a prebiotic that is able to 'trick' bacteria, reducing chances of them binding to the body.<sup>1</sup>
                            <span>Adapted from Bode L. Glycobiology. 2012.</span>
                        </p>
                    </div>
                    <div class="col-4">
                        <p class="content1 mt5">Feeds good bacteria in the gut.</p>
                    </div>
                </div>
                <h4 class="mt40">NOURISH YOUR CHILD’S BODY DEFENSE WITH DUAL SYSTEM OF 2’-FL & NUCLEOTIDES</h4>
                <img src="../../../assets/images/similacboyimagenew.png" alt="nourish" />
            </div>
            <div class="yellowBg">
                <h1>WHAT IS EYE-Q® NUTRITION SYSTEM?</h1>
                <p>EYE-Q® NUTRITION SYSTEM is a unique blend of Lutein, Natural Vitamin E & DHA for overall brain development to support faster learning.</p>
                <img src="../../../assets/images/sdi6.png" alt="iq" />
            </div>
            <div class="inner">
                <h4 class="mb20">SIMILAC GAIN PLUS® GOLD & GAIN KID® GOLD. THE WORLD’S FIRST FORMULA WITH 2’-FL, NUCLEOTIDES & EYE-Q® NUTRITION SYSTEM.</h4>
                <small>Strengthen your child’s body defense and brain development with <b>Similac Gain Plus® Gold and Similac Gain Kid® Gold</b> because</small>
                <img src="../../../assets/images/sdi7.png" alt="strong" />
            </div>
        </div>
    </div>
    <div class="similacDetailsFooter">
        <h6>References</h6>
        <ul>
            <li>Bode L. Human milk oligosaccharides: every baby needs a sugar mama. Glycobiology 2012;22(9):1147-62.
            <li>Goehring KC, et al. J Nutr. 2016 Dec; 146(12):2559-2566. Epub 2016.</li>
            <li>Pickering LK, et al. Pediatrics. 1998; 101(2):242-249</li>
        </ul>
    </div>
    <p class="code pb-3 mt-4">MAL.2021.19812.CORP.1 (v1.0)</p>
    <div class="-mx-20">
        <app-footer></app-footer>
    </div>
</div>
<app-bottom-nav></app-bottom-nav>