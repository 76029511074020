<app-header homeLogo="../../../assets/images/homeicon.svg" link="/traceability-pediasure"></app-header>
<section class="pediaSureGlobalContainer">
    <div class="pediaSureInnerBlock">
        <div class="card mt30">
            <div class="px30">
                <img class="img-fluid topImgPrimary w-100" src="../../../../assets/images/pediamanufacturinggold.png">
            </div>
            <div class="px25">
                <div class="topDateBlock text-center mt40 mb40">
                    <p class="batchNumber">{{ 'PEDIA_MANUFACTURING.BATCH_NUMBER' | translate }} {{batchNumber}}</p>
                    <div class="manufacturingBlock text-left">
                        <div class="row no-gutters purpleBorderBottom">
                            <div class="col-7 pl-3 pt-3 purpleBorderRight">
                                <p>{{ 'PEDIA_MANUFACTURING.MANUFACTURING_IN' | translate }}</p>
                            </div>
                            <div class="col-5 pl-3 pt-3 fontGBook">
                                <p>{{manufactorIn}}</p>
                            </div>
                        </div>
                        <div class="row no-gutters">
                            <div class="col-7 pl-3 pt-3 purpleBorderRight">
                                <p>{{ 'PEDIA_MANUFACTURING.MANUFACTURED_DATE' | translate }}</p>
                            </div>
                            <div class="col-5 pl-3 pt-3 fontGBook">
                                <p>{{manufacturingDate}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="w-100 mt-3" src="../../../../assets/images/abbotfactorytopviewimg.png" />
                <p class="fontGBMediumSmall mt-4">{{ 'PEDIA_MANUFACTURING.FACTORY_CONTENT_PLANT' | translate }}</p>
                <img class="w-100" src="../../../../assets/images/manufactutingInidefactorytype2.png" />
                <p class="fontGBMediumSmall mt-3" >{{ 'PEDIA_MANUFACTURING.BOTTOM_DESC_PURPOSE' | translate }}</p>
            </div>
        </div>
    </div>
    <div class="pediaBottomImageBlock">
        <div class="learnMoreBlock">
        <button class="learnMoreBtn text-uppercase" routerLink="/pediasure-naturalvitamin">Learn more</button>
        </div>  
        <img class="img-fluid" src="../../../../assets/images/pediachildnutrient.png">
    </div>
    <app-footer></app-footer>
</section>
<app-pediasure-bottomnav></app-pediasure-bottomnav>
