<app-header homeLogo="../../../assets/images/homeicon.svg" link="/traceability-pediasure"></app-header>
<section class="pediaSureGlobalContainer">
    <div class="pediaSureInnerBlock">
        <div class="card mt30">
            <div class="px30">
                <img class="img-fluid topImgPrimary w-100" src="../../../../assets/images/pedianaturalvitamingold.png">

                <img class="img-fluid w-100 mt-4" src="../../../../assets/images/pediavitaminboy.png">

                <img class="img-fluid w-100 mt-4 mb-2" src="../../../../assets/images/borderlineimage.png">
            </div>
            <img class="img-fluid w-100 mt-4" src="../../../../assets/images/pediargininenew.png">
            <div class="px30">
                <p class="vitaminContent mt-3"><span>{{ 'PEDIA_NATURALVITAMIN.ARGININE' | translate }}</span> {{ 'PEDIA_NATURALVITAMIN.TRIGGERS' | translate }} <span>{{ 'PEDIA_NATURALVITAMIN.LONGER' | translate }} </span> {{ 'PEDIA_NATURALVITAMIN.BONE1' | translate }}<sup>{{ 'PEDIA_NATURALVITAMIN.1' | translate }}</sup></p>
                <img class="img-fluid w-100 mt-3" src="../../../../assets/images/pediagrowthdirection.png" >
            </div>
            <img class="img-fluid w-100 mt-4" src="../../../../assets/images/pedianaturalvitamink2new.png">
            <div class="px30">
                <p class="vitaminContent mt-3"><span>{{ 'PEDIA_NATURALVITAMIN.NATURAL' | translate }}</span> {{ 'PEDIA_NATURALVITAMIN.PROMOTES' | translate }}<span> {{ 'PEDIA_NATURALVITAMIN.STRONG' | translate }}</span>{{ 'PEDIA_NATURALVITAMIN.BONE1' | translate }}<sup>{{ 'PEDIA_NATURALVITAMIN.1' | translate }}</sup></p>
                <img class="img-fluid w-100 mt-3" src="../../../../assets/images//vitaminsufficientk2new.png" >
            </div>
        </div>
    </div>
    <div class="pediaBottomImageBlock">
        <div class="learnMoreBlock">
        <button class="learnMoreBtn text-uppercase"><a href="https://www.Pediasure.com.my">Learn more</a></button>
        </div>  
        <img class="img-fluid" src="../../../../assets/images/pediachildnutrient.png">
    </div>
    <app-footer></app-footer>
</section>
<app-pediasure-bottomnav></app-pediasure-bottomnav>
