<app-header homeLogo="../../../assets/images/homeicon.svg" link="/traceability-ensure"></app-header>
<section class="ensureGlobalContainer">
    <div class="bannerBlockTop">
        <img class="img-fluid w-100 -mt5" src="../../../../assets/images/ensuredarkbluebannerwithproduct.png">
        <img class="titleImage whyEnsureImg" src="../../../../assets/images/whyensuretextimag.png">
    </div>
    <div class="ensureInnerBlock bgWhite pb30 px35 pt60">
        <p class="contentMediumBold">{{ 'WHY_ENSURE.DESC1' | translate }} <sup>{{ 'WHY_ENSURE.STAR' | translate }}</sup></p>
        <p class="contentMediumBold mt-4">{{ 'WHY_ENSURE.DESC2' | translate }} </p>
        <div class="smallContentBlock">
            <p class="mb-0">{{ 'WHY_ENSURE.DESC3' | translate }}</p>
            <p class="mb-0">{{ 'WHY_ENSURE.DESC4' | translate }}</p>
            <p class="mb-0">{{ 'WHY_ENSURE.DESC5' | translate }}</p>
            <p class="mb-0">{{ 'WHY_ENSURE.DESC6' | translate }}</p>
        </div>
        <img class="img-fluid mt-2 mb-5" src="../../../../assets/images/ensuregoldwithmilkbottleimg.png">
        <div class="whyChooseImgBlockOut text-center">
            <img class="numberImage" src="../../../../assets/images/whychoosenuberoneimg.png">
            <img class="img-fluid customImg" src="../../../../assets/images/whychoosemostrecommended.png" />
        </div>
        <div class="whyChooseImgBlockOut text-center mt-4">
            <img class="numberImage" src="../../../../assets/images/whychoosenumbetwoimage.png">
            <img class="img-fluid customImg" src="../../../../assets/images/scientificevidencesnew (1).png" />
        </div>
        <div class="whyChooseImgBlockOut text-center mt-4">
            <img class="numberImage" src="../../../../assets/images/whychoosenumberthree.png">
            <img class="img-fluid customImg" src="../../../../assets/images/whychooseage.png" />
        </div>
        <div class="whyChooseImgBlockOut text-center mt-4">
            <img class="numberImage" src="../../../../assets/images/whychoosenumberfour.png">
            <img class="img-fluid customImg" src="../../../../assets/images/whychooseprefferedtaste.png" />
        </div>
    </div>
    <app-footer></app-footer>
</section>
<app-ensure-bottomnav></app-ensure-bottomnav>
