import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMyDpOptions } from 'mydatepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { environment as env } from '../../../environments/environment';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-redeem-rewards',
  templateUrl: './redeem-rewards.component.html',
  styleUrls: ['./redeem-rewards.component.scss'],
})
export class RedeemRewardsComponent implements OnInit {
  rewardList: any;
  childEdu: any;
  healthandFit: any;
  houseHold: any;
  voucherEvoucher: any;
  abbottProducts: any;

  constructor(
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService, 
    private bsService: BugsnagService
  ) {}

  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    this.getCustomer();
  
  }

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    this.spinner.show();
    let getcustresp;
    this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
      this.spinner.hide();
      getcustresp = res;
      if (res['status'].code == 200) {
        this.getRewardList();
        // Block the user
        let block = res['customers']['customer'][0].custom_fields.field;
        let blockUser = block.filter((filt) => {
          return filt.name == 'block_count';
        });
        console.log(blockUser, 'blockUser===');
        if (blockUser[0]?.value == '1') {
          this.spinner.hide();
          sessionStorage.clear();
          this.router.navigate(['/block-notice']);
        }
        sessionStorage.setItem(
          'abbottUserId',
          res['customers']['customer'][0].user_id
        );
      }
    },err=>{
      this.bsService.notifyError(err);
      this.spinner.hide()
      this.apiService.showErrorMsg('Something went wrong, please try again')
      console.log(err, '=== get customer error =======')
      console.log(mobile, '=== get customer mobile =======');
      console.log(err?.error?.message, '=== get customer error message =======');
      console.log(err?.error?.code, '=== get customer error code =======');
    });
  }

  getRewardList() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    this.spinner.show();
    let getreward;
    this.apiService
      .getMarvalRewardDetails(mobile, token)
      .pipe(first())
      .subscribe((reward: any) => {
        getreward = reward;

        this.spinner.hide();
        console.log(reward.rewardList, 'reward list');
        this.rewardList = reward.rewardList;

        this.childEdu = this.rewardList.filter((child) => {
          return child.label == 'child education';
        });
        console.log(this.childEdu, 'this.childEdu');

        this.healthandFit = this.rewardList.filter((child) => {
          return child.label == 'health & fitness';
        });
        console.log(this.healthandFit, 'health & fitness');

        this.houseHold = this.rewardList.filter((child) => {
          return child.label == 'household';
        });
        console.log(this.houseHold, 'household');

        this.voucherEvoucher = this.rewardList.filter((child) => {
          return child.label == 'voucher/ e-voucher';
        });
        console.log(this.voucherEvoucher, 'voucher / e-voucher');

        this.abbottProducts = this.rewardList.filter((child) => {
          return child.label == 'abbott products';
        });
        console.log(this.abbottProducts, 'abbott products');
      },err=>{
        
        this.bsService.notifyError(err);
            this.spinner.hide()
            this.apiService.showErrorMsg('Something went wrong, please try again')
            console.log(err, '=== get customer error =======')
      });
  }

  reedemReward(id) {
    this.router.navigate(['/redeem-reward-details', id]);
  }
}
