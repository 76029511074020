<app-header-secondary></app-header-secondary> 
<section class="similacGlobalContainer">
    <section class="similacTraceabilityContainer">
        <div class="topBanner">
            <div class="container">
                <div class="row">
                    <div class="col-7 pr-0">
                        <figure class="mb-0 text-center">
                            <!-- <img class="productImageTop" [src]="productImage" /> -->
                            <img
                            *ngIf="isIsprint == false"
                            class="prodImg"
                            [src]="prodImage"
                            alt="ensure"
                            />
                            <img
                            *ngIf="isIsprint == true"
                            class="prodImg w-100"
                            [src]="productImage"
                            alt="ensure"
                            />
                        </figure>
                        <div class="shadowCard">
                            <img class="img-fluid assuredImg" src="../../../../assets/images/restassuredyellow.png" />
                            <p class="mb-0 titleMain -mt15"> {{ productName }}</p>
                            <p class="mb-0 descSmall"> {{ 'TRACEABILITY_SIMILAC.BATCH_NUMBER' | translate }} {{ batchNumber }}</p>
                        </div>
                    </div>
                    <div class="col-4 pl-0">
                        <img class="mt-3 authenTicImg" src="../../../assets/images/authenticbadge.png">

                        <figure>
                            <img class="arrowRight" src="../../../assets/images/orangearrowright.png" />
                        </figure>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-7 pl-0">
                        <figure>
                            <img class="arrowLeft" src="../../../assets/images/orangearrowleft.png" />
                        </figure>
                    </div>
                    <div class="col-4 pr-0">
                        <figure class="mb-0 text-center">
                            <img class="milkBottleImg"  routerLink="/similac-milksource" src="../../../../assets/images/similacmilkbottlewithcap.png">
                        </figure>
                        <div class="shadowCard pt-4">
                            <p class="mb-0 descSmall"> {{ 'TRACEABILITY_SIMILAC.MILK_SOURCE' | translate }} </p>
                        </div>
                    </div>
                </div>
                <div class="row mt50">
                    <div class="col-8 pr-0">
                        <figure class="mb-0">
                            <img class="milkBottleImg" routerLink="/similac-manufacturing" src="../../../../assets/images/similactreacebailitymanufacturing.png">
                        </figure>
                        <div class="shadowCard pt-4">
                            <p class="mb-0 descSmall">{{ 'TRACEABILITY_SIMILAC.BATCH_NUMBER' | translate }} {{ batchNumber }}</p>
                            <p class="mb-0 descSmall">{{ 'TRACEABILITY_SIMILAC.MANUFACTURED' | translate }} {{ manufactorIn }}</p>
                            <p class="mb-0 descSmall">{{ 'TRACEABILITY_SIMILAC.MANUFACTURED_DATE' | translate }} {{ manufacturingDate }}</p>
                        </div>
                    </div>
                    <div class="col-4 pl-0">
                        <figure>
                            <img class="arrowRight" src="../../../assets/images/orangearrowright.png" />
                        </figure>
                    </div>
                </div>
                <div class="row mt10 justify-content-end">
                    <div class="col-7">
                        <figure class="mb-0 text-center">
                            <img class="milkBottleImg" routerLink="/similac-qualitycontrol" src="../../../../assets/images/similactracebailityaulaitycontrol.png">
                        </figure>
                        <div class="shadowCard pt-4">
                            <p class="mb-0 descSmall">{{ 'TRACEABILITY_SIMILAC.QUALITY_CONTROL' | translate }}</p>
                            <p class="mb-0 descSmall">{{ 'TRACEABILITY_SIMILAC.EXPIRY_DATE' | translate }} {{ expiryDate }}
                            </p>
                        </div>
                        <figure class="mb-0">
                            <img class="" src="../../../assets/images/arrowleftcurvy.png" />
                        </figure>
                    </div>
                </div>
                <div class="row justify-content-start -mt20">
                    <div class="col-9 pr-0">
                        <figure class="mb-0">
                            <img class="milkBottleImg" routerLink="/similac-delivery" src="../../../../assets/images/similactraceabilitydelivery.png">
                        </figure>
                        <div class="shadowCard pt30">
                            <p class="mb-0 descSmall">{{ 'TRACEABILITY_SIMILAC.CUSTOM_CLEARANCE' | translate }} {{ customClearanceDate }}</p>
                            <p class="mb-0 descSmall">{{ 'TRACEABILITY_SIMILAC.ARRIVED' | translate }} {{ arrivalDate }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pediaTricSampleBlock mt50 pt20 pb30">
                <p class="mb-0 mt-4 titleBottom"> {{ 'TRACEABILITY_SIMILAC.GET_FREE' | translate }}</p>
                <p class="mb-0 titleBottom">{{ 'TRACEABILITY_SIMILAC.GAIN_PLUS' | translate }}</p>
                <p class="mb-0 titleBottom">{{ 'TRACEABILITY_SIMILAC.SAMPLE_TODAY' | translate }}</p>
                <p class="mt-3 smallContent">{{ 'TRACEABILITY_SIMILAC.TASTE_IT' | translate }}</p>
                <button class="btn getNowBtn text-uppercase"><a href=" https://abbottnutrition.com.my/similacgainplus/aboutsimilac">{{ 'TRACEABILITY_SIMILAC.GET_IT_NOW' | translate
                    }}</a></button>
            </div>
            <div class="pb100"></div>
        </div>
    </section>
    <app-footer></app-footer>
</section>
<app-similac-bottomnav></app-similac-bottomnav>