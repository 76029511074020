import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pediasure-qualitycontrol',
  templateUrl: './pediasure-qualitycontrol.component.html',
  styleUrls: ['./pediasure-qualitycontrol.component.scss'],
})
export class PediasureQualitycontrolComponent implements OnInit {
  productInfo: any;

  expiryDate: any;

  constructor() {
    this.productInfo = JSON.parse(sessionStorage.getItem('iSprintData'));
    console.log(this.productInfo, 'productInfo');
  }

  parseFunction(value) {
    return JSON.parse(value);
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    // this.productImage = this.productInfo.product_image;
    this.getTraceabilityContent();
  }

  getTraceabilityContent() {
    this.expiryDate = this.productInfo.expiry_date.value;
  }
}
