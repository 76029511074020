<app-header homeLogo="../../../assets/images/homeicon.svg" link="/traceability-pediasure"></app-header>
<section class="pediaSureGlobalContainer">
    <div class="pediaSureInnerBlock">
        <div class="card mt30 pb40">
            <div class="px30">
                <img class="img-fluid topImgPrimary w-100" src="../../../../assets/images/pediaqualitycontrolgold.png">
            </div>
            <div class="px25">
                <div class="expiryDateBlock text-center mt40">
                    <p class="mb-0 mb20">{{ 'PEDIA_QUALITYCONTROL.EXPIRY_DATE' | translate }} {{expiryDate}}</p>
                </div>
                <p class="mt-3 fontGBMediumSmall">{{ 'PEDIA_QUALITYCONTROL.DESC_MANUFACTURING1' | translate }}</p>
                <img class="w-100" src="../../../../assets/images/labqualitytestimage.png">
                <p class="mt-3 fontGBMediumSmall">{{ 'PEDIA_QUALITYCONTROL.DESC_MANUFACTURING2' | translate }}</p>

                <div class="makeSwitchContent text-center mt60">
                    <p class="mb-0">{{ 'PEDIA_QUALITYCONTROL.MAKE_SWITCH' | translate }}</p>
                    <p class="mb-0">{{ 'PEDIA_QUALITYCONTROL.HEIGHT' | translate }}</p>
                    <p class="mb-0"> {{ 'PEDIA_QUALITYCONTROL.CHILD_GROWTH' | translate }}</p>
                </div>
                <img class="img-fluid w-100 mt-2" src="../../../../assets/images/pediawhyensure.png" routerLink="/pediasure-naturalvitamin">
            </div>

        </div>
    </div>
    <app-footer></app-footer>
</section>
<app-pediasure-bottomnav></app-pediasure-bottomnav>
