<app-header-secondary homeLogo="../../../assets/images/homeicon.svg" link="/traceability-similac"></app-header-secondary>
<section class="similacGlobalContainer">
    <div class="topBannerBlock"></div>
    <div class="similacInnerBlock pb50">
        <div class="card">
            <img class="img-fluid topImg" *ngIf="ValueSelected == '' || ValueSelected == 'undefined' || ValueSelected == 'en_US'" src="../../../../assets/images/simialcmanufacuturingnew.png">
            <img class="img-fluid topImg" *ngIf="ValueSelected == 'bh' " src="../../../../assets/images/bm/header_manufacturing.png">
            <img class="img-fluid topImg" *ngIf="ValueSelected == 'ch' " src="../../../../assets/images/cn/header_manufacturing.png">
       
            <div class="topDateBlock text-center mt-2">
                <div class="batchNumber p-2">
                    <p class=" mb-0">{{ 'SIMILAC_MANUFACTURING.BATCH_NUMBER'| translate }}<span>{{ batchNumber }}</span>
                    </p>
                </div>
                <div class="row align-items-center bg-white mx-0">
                    <div class="col-6 text-center px-0 pt-3 pb-3 greyBorderRight">
                        <p class="contentMediumBold mb-0">{{ 'SIMILAC_MANUFACTURING.MANUFACTURING_IN'| translate }}</p>
                        <p class="contentMediumSmall mb-0">{{ manufactorIn }}</p>
                    </div>
                    <div class="col-6 px-0 pt-3 pb-3">
                        <p class="contentMediumBold mb-0">{{ 'SIMILAC_MANUFACTURING.MANUFACTURED_DATE'| translate }}</p>
                        <p class="contentMediumSmall mb-0">{{ manufacturingDate }}</p>
                    </div>
                </div>
            </div>
            <p class="contentMediumSmall mt-3">{{ 'SIMILAC_MANUFACTURING.DESC1'| translate }}</p>
            <img class="img-fluid w-100" src="../../../../assets/images/abbotfactorytopviewimg.png" />
            <p class="contentMediumSmall mt-2">{{ 'SIMILAC_MANUFACTURING.DESC2'| translate }}</p>
            <img class="img-fluid w-100" src="../../../../assets/images/insidefactoryimagetwo.JPG" />
        </div>

        <!-- <div class="latestBreakThroughBlock text-center mt40">
            <p class="mb-0">{{ 'LATEST_BREAKTHROUGH.INTRO'| translate }}<span class="orangeText">{{
                    'LATEST_BREAKTHROUGH.2FL'| translate }}</span> {{ 'LATEST_BREAKTHROUGH.LATEST'| translate }}</p>
            <p class="mb-0">{{ 'LATEST_BREAKTHROUGH.BREAKTHROUGH'| translate }}</p>

            <img class="img-fluid mt-4 mb-4 d-block" src="../../../../assets/images/Similac Gain-Plus-Gold.png">
            <button class="btn buttonOutlineBlue" routerLink="/similac-nucleotides">{{ 'LATEST_BREAKTHROUGH.LEARN_MORE'|
                translate }}</button>
        </div> -->

        <div class="latestBreakThroughBlock text-center mt40">
            <p class="mb-0">{{ 'LATEST_BREAKTHROUGH.INTRO'| translate }}<span class="orangeText">{{
                    'LATEST_BREAKTHROUGH.2FL'| translate }}</span> {{ 'LATEST_BREAKTHROUGH.LATEST'| translate }}</p>
            <p class="mb-0" [hidden]="hideInChinese || hideInBahasa">{{ 'LATEST_BREAKTHROUGH.BREAKTHROUGH'| translate }}</p>

            <img class="img-fluid mt-4 mb-4 d-block" *ngIf="ValueSelected == '' || ValueSelected == 'undefined' || ValueSelected == 'en_US'" src="../../../../assets/images/Similac Gain-Plus-Gold.png">
            <img class="img-fluid w-100" *ngIf="ValueSelected == 'bh' " src="../../../../assets/images/bm/2fl_2.png">
            <img class="img-fluid w-100" *ngIf="ValueSelected == 'ch' " src="../../../../assets/images/cn/2fl.png">
            
             <button class="btn buttonOutlineBlue" routerLink="/similac-nucleotides">{{ 'LATEST_BREAKTHROUGH.LEARN_MORE'|
                translate }}</button>
        </div>
    </div>
    <a href="https://loyalty.abbottmalaysia.com.my/">
        <img class="img-fluid w-100" *ngIf="ValueSelected == '' || ValueSelected == 'undefined' || ValueSelected == 'en_US'" src="../../../../assets/images/beaparty.png">
        <img class="img-fluid w-100" *ngIf="ValueSelected == 'bh' " src="../../../../assets/images/bm/ascw.png">
        <img class="img-fluid w-100" *ngIf="ValueSelected == 'ch' " src="../../../../assets/images/cn/ascw.png">
   
    </a>
    <app-footer></app-footer>
</section>
<app-similac-bottomnav></app-similac-bottomnav>