<app-header-primary></app-header-primary>
<div class="blueBg px-2">
    <div class="text-left">
        <p class="-mt20" (click)="onClickBack()"><i class="mdi mdi-arrow-left backIcon" ></i> Go back</p>
    </div>
    <div class="redemptionHistory">
        <div class="headerImg">
            <img src="../../../assets/images/myredemptionhistory.png" alt="my collected care ponits" />
        </div>
        <div class="updatesOut">
        </div>
        <div class="pointsList">
            <div class="container px-0">
                <ng-container>
                    <div class="row mb20 no-gutters" *ngFor="let history of voucherList; let i = index">
                        <div class="col col-3">
                            <i class="img"><img [src]="history?.rewardDetails?.imageUrl"
                                    alt="pediasure vanila 850g" /></i>
                        </div>
                        <div class="col col-5 pt-3">
                            <p class="text-uppercase mb-0">{{history?.rewardDetails?.name}}</p>
                            <!-- <p class="carePoints"  *ngFor="let point of pointsList; let j = index;">
                                <span [hidden]="i != j">{{point?.points_redeemed}} CarePoints</span>
                            </p> -->
                            <p class="carePoints">
                                <span>{{history?.total_points}} CarePoints</span>
                            </p>
                        </div>
                        <div class="col col-4 pt-3 pl-0 pr-0">
                            <p class="text-uppercase reddemedDate">{{history?.quantity}}x Redeemed on {{history?.displayDate | date: 'dd MMM yyyy'}}</p>
                        </div>
                    </div>
                </ng-container>
                <!-- <h5 > No Transactions are Available </h5> -->
            </div>
        </div>
    </div>
    <app-version></app-version>
    <div class="-mx-20">
        <app-footer></app-footer>
    </div>
</div>
<app-bottom-nav></app-bottom-nav>