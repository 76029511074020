import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMyDpOptions } from 'mydatepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { environment as env } from '../../../environments/environment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.scss'],
})
export class ScanComponent implements OnInit {
  // onClickHereToScan: boolean = false;
  @ViewChild(' scanQrcodeGuideModal', { static: true })
  scanQrcodeGuideModal: ModalDirective;

  profileForm: FormGroup;
  submitted = false;
  myDate = new Date();
  cityList: any;
  stateList: any;
  user: any;
  registerForm: any;
  newNumber: any;
  public myDatePickerOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'mm.dd.yyyy',
  };
  changeMobNo: boolean = true;
  curr_date = new Date();
  childMinDate: any;
  childMaxDate: any;
  parentMinDate: any;
  parentMaxDate: any;
  mobLength: any;
  @ViewChild('profileUpdated', { static: true }) profileUpdated: ModalDirective;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router, 
    private bsService: BugsnagService
  ) {}

  ngOnInit(): void {
    this.getCustomer();
  }
  onClickToScan() {
    // this.onClickHereToScan = true;
    this.scanQrcodeGuideModal.show();
  }

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    this.spinner.show();
    this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
      this.spinner.hide();
      console.log(res, 'res---');
      if (res['status'].code == 200) {
        // Block the user
        let block = res['customers']['customer'][0].custom_fields.field;
        let blockUser = block.filter((filt) => {
          return filt.name == 'block_count';
        });
        console.log(blockUser, 'blockUser===');
        if (blockUser[0]?.value == '1') {
          sessionStorage.clear();
          this.router.navigate(['/block-notice']);
        }
        console.log(res['customers']['customer'][0].user_id, 'used id---');
        sessionStorage.setItem(
          'abbottUserId',
          res['customers']['customer'][0].user_id
        );
        this.user = res['customers']['customer'][0];
        console.log(this.user, 'this.user');
      }
    }, err => {
      this.bsService.notifyError(err);
      console.log(mobile, '=== get customer mobile =======');
      console.log(err?.error?.message, '=== get customer error message =======');
      console.log(err?.error?.code, '=== get customer error code =======');
    });
  }
}
