import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manufacturing',
  templateUrl: './manufacturing.component.html',
  styleUrls: ['./manufacturing.component.scss'],
})
export class ManufacturingComponent implements OnInit {
  productInfo: any;
  batchNumber: any;
  manufacturingDate: any;
  manufactorIn: any;

  constructor() {
    this.productInfo = JSON.parse(sessionStorage.getItem('iSprintData'));
    console.log(this.productInfo, 'productInfo');
  }

  parseFunction(value) {
    return JSON.parse(value);
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    this.getTraceabilityContent();
  }

  getTraceabilityContent() {
    if (this.productInfo.is_isprint == true) {
      this.batchNumber = this.productInfo.batch_number.value;
      this.manufactorIn = this.productInfo.country_of_origin.en_US;
      this.manufacturingDate = this.productInfo.manufacture_date.value;
    } else {
      this.manufactorIn = this.productInfo.country;
      this.manufacturingDate = this.productInfo.manufacture_date;
    }
  }
}
