<app-header></app-header>
<section class="ensureGlobalContainer">
  <section class="ensureTraceabilityContainer pb-3">
    <img class="img-fluid" src="../../../assets/images/enusurebanner.png" />
    <div class="container">
      <div class="row justify-content-end mx-0">
        <div class="col-3">
          <figure class="mb-0">
            <img
              class="arrowLeft"
              src="../../../assets/images/ensurelarrowleft.png"
            />
          </figure>
        </div>
        <div class="col-8 text-center">
          <figure class="mb-0">
            <!-- <img class="prodImg" [src]="productImage" /> -->
            <img
              *ngIf="isIsprint == false"
              class="prodImg"
              [src]="prodImage"
              alt="ensure"
            />
            <img
              *ngIf="isIsprint == true"
              class="prodImg w-100"
              [src]="productImage"
              alt="ensure"
            />
          </figure>
          <h5 class="mb-0 titleTop">{{ productName1 }}</h5>
          <h5 class="mb-0 fontGtmRBook colorDarkBlue-xxx">
            {{ productName2 }}
          </h5>
          <p class="smallContent fontGtmRBook">
            {{ "TRACEABILITY_ENSURE.BATCH_NUMBER" | translate }}
            {{ batchNumber }}
          </p>
        </div>
      </div>
      <div class="row justify-content-start mx-0">
        <div class="col-7 text-left">
          <figure class="mb-0 mt-2">
            <img
              class="sourceImg"
              src="../../../assets/images/source.png"
              routerLink="/ensure-ingredients"
            />
          </figure>
          <figure class="mb-0">
            <img
              *ngIf="productInfo.is_isprint == true"
              class="sourceOfCode"
              src="../../../assets/images/sourceofingredientsnew.png"
            />
            <!-- TODO: Replace the below <p> tag when asset is available ----hint:asset similar to above image tag -->
            <p *ngIf="productInfo.is_isprint == false" class="flavor mb-0">
              FLAVOUR
            </p>
          </figure>
          <p *ngIf="productInfo.is_isprint == true" class="mb-0 smallContent">
            {{ sourceofIn }}
          </p>
          <p *ngIf="productInfo.is_isprint == false" class="mb-0 smallContent">
            {{ flavor }}
          </p>
          <!-- <p class="mb-0 smallContent">{{ 'TRACEABILITY_ENSURE.NETHER_LAND_USA' | translate }}</p> -->
        </div>
        <div class="col-4">
          <img
            class="arrowRight"
            src="../../../assets/images/ensurearrowright.png"
          />
        </div>
      </div>
      <div class="row justify-content-end mx-0 mt-3">
        <div class="col-4">
          <figure class="mb-0">
            <img
              class="arrowLeftOne ml-4"
              src="../../../assets/images/ensurelarrowleft.png"
            />
          </figure>
        </div>
        <div class="col-8 text-right">
          <figure class="mb-0 text-right">
            <img
              class="sourceImg"
              src="../../../assets/images/manufactuing.png"
              routerLink="/ensure-manufacturing"
            />
          </figure>
          <figure class="mb-0">
            <img
              class="sourceOfCode _w200"
              src="../../../assets/images/Manufacturing@2x.png"
            />
          </figure>
          <p class="mb-0 smallContent">
            {{ "TRACEABILITY_ENSURE.BATCH_NUMBER" | translate }}
            {{ batchNumber }}
          </p>
          <p class="mb-0 smallContent">
            {{ "TRACEABILITY_ENSURE.MANUFACTURED" | translate }}
            {{ manufactorIn }}
          </p>
          <p class="mb-0 smallContent">
            {{ "TRACEABILITY_ENSURE.MANUFACTURED_DATE" | translate }}
            {{ manufacturingDate }}
          </p>
        </div>
      </div>
      <div class="row justify-content-start mx-0 mt-2">
        <div class="col-7 text-left">
          <figure class="mb-0">
            <img
              class="sourceImg"
              src="../../../assets/images/quality.png"
              routerLink="/ensure-qualitycontrol"
            />
          </figure>
          <figure class="mb-0">
            <img
              class="sourceOfCode _w200"
              src="../../../assets/images/Quality control@2x.png"
            />
          </figure>
          <p class="mb-0 smallContent">
            {{ "TRACEABILITY_ENSURE.EXPIRY_DATE" | translate }} {{ expiryDate }}
          </p>
        </div>
        <div *ngIf="productInfo.is_isprint == true" class="col-4">
          <img
            class="arrowRightOne"
            src="../../../assets/images/ensurearrowright.png"
          />
        </div>
      </div>
      <div
        *ngIf="productInfo.is_isprint == true"
        class="row justify-content-end mx-0"
      >
        <div class="col-8 text-right">
          <figure class="mb-0 text-right">
            <img
              class="sourceImg"
              src="../../../assets/images/delivery.png"
              routerLink="/ensure-delivery"
            />
          </figure>
          <figure class="mb-0">
            <img
              class="sourceOfCode"
              src="../../../assets/images/DeliveryNew.png"
            />
          </figure>
          <p class="mb-0 deliveryContent">
            {{ "TRACEABILITY_ENSURE.CUSTOM_CLEARANCE" | translate }}
            <span class="mediumFont">{{ customClearanceDate }}</span>
          </p>
          <p class="mb-0 deliveryContent">
            {{ "TRACEABILITY_ENSURE.ARRIVED" | translate }}
            <span class="mediumFont">{{ arrivalDate }}</span>
          </p>
        </div>
      </div>
      <div class="ensureGoldSampleBlockOut mt-3 mx10">
        <div class="ensureGoldSampleBlock text-center pt-3">
          <p class="smallContentBottom">
            {{ "TRACEABILITY_ENSURE.SMALL_CODE" | translate }}
          </p>
          <p class="mb-0 contentPrimaryWhite">
            {{ "TRACEABILITY_ENSURE.GET_FREE" | translate }}
          </p>
          <p class="mb-0 contentPrimaryWhite">
            {{ "TRACEABILITY_ENSURE.SAMPLE_TODAY" | translate }}
          </p>
          <p class="contentSecondaryWhite mt-3">
            {{ "TRACEABILITY_ENSURE.TASTE_IT" | translate }}
          </p>
          <button class="btn getNowBtn text-uppercase">
            <a href="https://abbottnutrition.com.my/products/ensure-gold">
              {{ "TRACEABILITY_ENSURE.GET_IT_NOW" | translate }}
            </a>
          </button>
        </div>
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</section>
<app-ensure-bottomnav></app-ensure-bottomnav>
