<app-header homeLogo="../../../assets/images/homeicon.svg" link="/traceability-ensure"></app-header>
<section class="ensureGlobalContainer">
    <div class="bannerBlockTop">
        <img class="img-fluid w-100 -mt5" src="../../../../assets/images/ensurrehmbdarkbluebanner.png">
        <img class="titleImage hmbImg" src="../../../../assets/images/ensurehmbtripletextimage.png">
    </div>
    <div class="ensureInnerBlock bgWhite pb30 px35 pt80">
        <div class="hmbInnerWrapperBlock">
            <img class="img-fluid w-100" src="../../../../assets/images/whatishmbimage.png">
            <img class="img-fluid w-100 mt-3 mb-3" src="../../../../assets/images/ensurequickfacts.png">
            <p class="mb-0 contentSmall">{{ 'ENSURE_HMB.DESC1' | translate }}</p>
            <p class="mb-0 contentSmall">{{ 'ENSURE_HMB.DESC2' | translate }}</p>
            <p class="mb-0 contentSmall">{{ 'ENSURE_HMB.DESC3' | translate }}</p>
            <p class="mb-0 contentSmall mt-2">{{ 'ENSURE_HMB.DESC4' | translate }}</p>
            <p class="mb-0 contentSmall">{{ 'ENSURE_HMB.DESC5' | translate }}</p>
        </div>
        <div class="hmbInnerWrapperBlock">
            <p class="mb-0 contentBold mt-3">{{ 'ENSURE_HMB.DESC6' | translate }}</p>
            <p class="mb-0 contentBold">{{ 'ENSURE_HMB.DESC7' | translate }}</p>
            <p class="achieveContent mb-0">{{ 'ENSURE_HMB.DESC8' | translate }}</p>
            <p class="achieveContent">{{ 'ENSURE_HMB.DESC9' | translate }}<sup>{{ 'ENSURE_HMB.DESC_SUB' | translate }}</sup><span class="contentBold">.</span></p>

            <img class="img-fluid toGetImg" src="../../../../assets/images/hmbtogetimage.png">
            <img class="w-100 mt-2" src="../../../assets/images/edi3.png">
            <p class="mb-0 contentSmall mt-2">{{ 'ENSURE_HMB.DESC10' | translate }}</p>
        </div>
        <img class="w-100 mt-3" src="../../../../assets/images/ensurehmbtripleprotienblend.png">
        <div class="text-center">
            <img class="wheyImg mt-2" src="../../../../assets/images/ensurehmbcassinesoywheyimage.png">
        </div>
        <img class="w-100 mt-2" src="../../../../assets/images/ensureanimoacidimage.png">
        <ul class="hmbListBlock">
            <li><p class="mb-0">{{ 'ENSURE_HMB.DESC11' | translate }}</p></li>
            <li><p class="mb-0">{{ 'ENSURE_HMB.DESC12' | translate }}</p></li>
            <li><p class="mb-0">{{ 'ENSURE_HMB.DESC13' | translate }}</p></li>
        </ul>
        <p class="bottomContentSmallBold mt30 ml-2">{{ 'ENSURE_HMB.DESC14' | translate }}</p>
    </div>
    <app-footer></app-footer>
</section>
<app-ensure-bottomnav></app-ensure-bottomnav>
