<app-header-primary></app-header-primary>
<section class="aboutUsContainer pb100">
  <img
    class="img-fluid"
    src="../../../assets/images/sharecareworlssunlightbanner.png"
  />
  <!-- <img class="img-fluid" src="../../../assets/images/bluwithwhitebgimg.png"> -->
  <div class="memberBlock">
    <div class="row justify-content-center mx-0">
      <div class="col-9 text-center">
        <img
          class="img-fluid shareCareLogo"
          src="../../../assets/images/aboutsharecareworld.png"
        />
        <p class="memberContent mt-1">
          AS A MEMBER OF ABBOTT SHARE & CARE WORLD, YOU WILL RECEIVE THE
          FOLLOWING BENEFITS:
        </p>
      </div>
    </div>
  </div>
  <div class="px30">
    <img
      class="img-fluid mt-3"
      src="../../../assets/images/collectcarepointsimg.png"
    />
    <img
      class="img-fluid mt-3"
      src="../../../assets/images/recieveupdate.png"
    />
    <img
      class="img-fluid mt-3"
      src="../../../assets/images/seeknutritionimg.png"
    />
    <!-- <div class="iconBlock text-center">
            <i class="mdi mdi-export-variant"></i>
            <i class="mdi mdi-facebook"></i>
            <i class="mdi mdi-twitter"></i>
            <i class="mdi mdi-instagram"></i>
            <i class="mdi mdi-whatsapp"></i>
            <i class="mdi mdi-wechat"></i>
            <i class="mdi mdi-linkedin"></i>
        </div> -->
  </div>
  <div class="howItworksBlock mt-4">
    <img class="img-fluid" src="../../../assets/images/how itworksimage.png" />
  </div>
  <div class="howToCollectBlock">
    <img
      class="img-fluid howToImg mt-3"
      src="../../../assets/images/howtocollectimg.png"
    />
    <div class="row mx-0 justify-content-center no-gutters mt-3">
      <div class="col-2">
        <img
          class="img-fluid phoneListImg"
          src="../../../assets/images/phonelistnewone.png"
        />
      </div>
      <div class="col-10">
        <p class="howToListContent">Open URL code Scanner on your phone</p>
      </div>
    </div>
    <div class="row mx-0 justify-content-center no-gutters mt-3">
      <div class="col-2">
        <img
          class="img-fluid phoneListImg"
          src="../../../assets/images/phonelistnewtwo.png"
        />
      </div>
      <div class="col-10">
        <p class="howToListContent">
          Scan the code behind the flap on your BiB product
        </p>
      </div>
    </div>
    <div class="row mx-0 justify-content-center no-gutters mt-3">
      <div class="col-2">
        <img
          class="img-fluid phoneListImg"
          src="../../../assets/images/phonelistnewthree.png"
        />
      </div>
      <div class="col-10">
        <p class="howToListContent">Register and login to your account</p>
      </div>
    </div>
    <div class="row mx-0 justify-content-center no-gutters mt-3">
      <div class="col-2">
        <img
          class="img-fluid phoneListImg"
          src="../../../assets/images/phonelistnewfour.png"
        />
      </div>
      <div class="col-10">
        <p class="howToListContent">
          Collect CarePoints and redeem your favourite products
        </p>
      </div>
    </div>
  </div>

  <div class="accordionBlock px20 mt-3 mb-2">
    <accordion>
      <accordion-group
        (isOpenChange)="OnChange($event, 'eligible')"
        [isDisabled]="isFirstDisabled"
      >
        <button
          class="btn btn-link btn-block clearfix"
          accordion-heading
          type="button"
        >
          <div class="pull-left float-left">
            ELIGIBLE ITEMS CAREPOINTS CHART (WITH CRM QR CODE)
          </div>
        </button>
      </accordion-group>
    </accordion>
  </div>
  <div class="productsBlockOut">
    <div class="bg-white">
    <div class="whiteCardBg text-center">
      <!-- <p class="brand">
        PEDIASURE <span class="flavours">Vanilla / Chocolate / Plain</span>
      </p>
      <ul>
        <li>
          <div class="text-center">
            <p class="grams">600 g</p>
            <img
              class="img-fluid"
              src="../../../assets/images/pediasure600g.png"
            />
          </div>
          <div class="mt-2">
            <img class="img-fluid" src="../../../assets/images/collect6.png" />
          </div>
        </li>
        <li>
          <div class="text-center">
            <p class="grams">1.2kg</p>
            <img
              class="img-fluid"
              src="../../../assets/images/pediasure600g.png"
            />
          </div>
          <div class="mt-2">
            <img class="img-fluid" src="../../../assets/images/collect12.png" />
          </div>
        </li>
        <li>
          <div class="text-center">
            <p class="grams">1.8kg</p>
            <img
              class="img-fluid"
              src="../../../assets/images/pediasure600g.png"
            />
          </div>
          <div class="mt-2">
            <img class="img-fluid" src="../../../assets/images/collect18.png" />
          </div>
        </li>
        <li>
          <div class="text-center">
            <p class="grams">3.0kg</p>
            <img
              class="img-fluid"
              src="../../../assets/images/pediasure3kg.png"
            />
          </div>
          <div class="mt-2">
            <img class="img-fluid" src="../../../assets/images/collect30.png" />
          </div>
        </li>
      </ul> -->

      <!-- ======= table data started ========== -->

      
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th class="align-middle">Brand</th>
                <th class="align-middle">SKU</th>
                <th class="align-middle">Points Awarded</th>
              </tr>
            </thead>
  
            <tbody>
              <tr class="tr-blue">
                <td rowspan="4" class="align-middle">
                  PediaSure OptiHEIGHT Bag in Box
                </td>
                <td>600g</td>
                <td>6</td>
              </tr>
              <tr class="tr-blue">
                <td>1.2kg</td>
                <td>12</td>
              </tr>
              <tr class="tr-blue">
                <td>1.8kg</td>
                <td>18</td>
              </tr>
              <tr class="tr-blue">
                <td>3kg</td>
                <td>30</td>
              </tr>
  
              <tr class="tr-light-blue">
                <td rowspan="3" class="align-middle">PediaSure OptiHEIGHT Can</td>
                <td>400g</td>
                <td>4</td>
              </tr>
              <tr class="tr-light-blue">
                <td>850g</td>
                <td>8</td>
              </tr>
              <tr class="tr-light-blue">
                <td>1.6kg</td>
                <td>16</td>
              </tr>
  
              <tr class="tr-blue">
                <td rowspan="4" class="align-middle">
                  PediaSure Peptigro Bag in Box
                </td>
                <td>600g</td>
                <td>6</td>
              </tr>
              <tr class="tr-blue">
                <td>1.2kg</td>
                <td>12</td>
              </tr>
              <tr class="tr-blue">
                <td>1.8kg</td>
                <td>18</td>
              </tr>
              <tr class="tr-blue">
                <td>3kg</td>
                <td>30</td>
              </tr>
  
              <tr class="tr-light-blue">
                <td class="align-middle">PediaSure 10+ Can</td>
                <td>850g</td>
                <td>8</td>
              </tr>
  
              <tr class="tr-blue">
                <td rowspan="4" class="align-middle">
                  Ensure Gold HMB Bag in Box
                </td>
                <td>400g</td>
                <td>4</td>
              </tr>
              <tr class="tr-blue">
                <td>1.2kg</td>
                <td>12</td>
              </tr>
              <tr class="tr-blue">
                <td>1.6kg</td>
                <td>16</td>
              </tr>
              <tr class="tr-blue">
                <td>2.4kg</td>
                <td>24</td>
              </tr>
  
              <tr class="tr-light-blue">
                <td rowspan="3" class="align-middle">Ensure Gold HMB Can</td>
              </tr>
              <tr class="tr-light-blue">
                <td>400g</td>
                <td>4</td>
              </tr>
              <tr class="tr-light-blue">
                <td>850g</td>
                <td>8</td>
              </tr>
  
              <tr class="tr-blue">
                <td rowspan="4" class="align-middle">
                  SIMILAC GAIN PLUS GOLD Bag in Box
                </td>
                <td>600g</td>
                <td>6</td>
              </tr>
              <tr class="tr-blue">
                <td>1.2kg</td>
                <td>12</td>
              </tr>
              <tr class="tr-blue">
                <td>1.8kg</td>
                <td>18</td>
              </tr>
              <tr class="tr-blue">
                <td>3kg</td>
                <td>30</td>
              </tr>
  
              <tr class="tr-light-blue">
                <td rowspan="4" class="align-middle">
                  SIMILAC GAIN KID GOLD Bag in Box
                </td>
                <td>600g</td>
                <td>6</td>
              </tr>
              <tr class="tr-light-blue">
                <td>1.2kg</td>
                <td>12</td>
              </tr>
              <tr class="tr-light-blue">
                <td>1.8kg</td>
                <td>18</td>
              </tr>
              <tr class="tr-light-blue">
                <td>3kg</td>
                <td>30</td>
              </tr>
  
              <tr class="tr-blue">
                <td rowspan="3" class="align-middle">
                  Similac Intelli-Pro S3 Bag in Box
                </td>
              </tr>
              <tr class="tr-blue">
                <td>600g</td>
                <td>6</td>
              </tr>
              <tr class="tr-blue">
                <td>1.2kg</td>
                <td>12</td>
              </tr>
  
              <tr class="tr-light-blue">
                <td rowspan="3" class="align-middle">
                  Similac Intelli-Pro S4 Bag in Box
                </td>
              </tr>
              <tr class="tr-light-blue">
                <td>600g</td>
                <td>6</td>
              </tr>
              <tr class="tr-light-blue">
                <td>1.2kg</td>
                <td>12</td>
              </tr>
  
              <tr class="tr-blue">
                <td class="align-middle">Similac Mom Can</td>
                <td>900g</td>
                <td>9</td>
              </tr>
  
              <tr class="tr-light-blue">
                <td rowspan="3" class="align-middle">Glucerna Bag in Box</td>
              </tr>
              <tr class="tr-light-blue">
                <td>1.6kg</td>
                <td>16</td>
              </tr>
              <tr class="tr-light-blue">
                <td>2.4kg</td>
                <td>24</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- <div class="whiteCardBg text-center mt-3">
      <p class="brand">
        SIMILAC <span class="flavours">Gain Plus / Kid Gold</span>
      </p>
      <ul class="similacCard">
        <li>
          <div class="text-center">
            <p class="grams">600 g</p>
            <img
              class="img-fluid productImg"
              src="../../../assets/images/similac600gblue.png"
            />
            <img
              class="img-fluid productImg"
              src="../../../assets/images/similac600gorange.png"
            />
          </div>
          <div class="mt-2">
            <img class="img-fluid" src="../../../assets/images/collect6.png" />
          </div>
        </li>
        <li>
          <div class="text-center">
            <p class="grams">1.2kg</p>
            <img
              class="img-fluid productImg"
              src="../../../assets/images/similac600gblue.png"
            />
            <img
              class="img-fluid productImg"
              src="../../../assets/images/similac600gorange.png"
            />
          </div>
          <div class="mt-2">
            <img class="img-fluid" src="../../../assets/images/collect12.png" />
          </div>
        </li>
        <li>
          <div class="text-center">
            <p class="grams">1.8kg</p>
            <img
              class="img-fluid productImg"
              src="../../../assets/images/similac600gblue.png"
            />
            <img
              class="img-fluid productImg"
              src="../../../assets/images/similac600gorange.png"
            />
          </div>
          <div class="mt-2">
            <img class="img-fluid" src="../../../assets/images/collect18.png" />
          </div>
        </li>
        <li>
          <div class="text-center">
            <p class="grams">3.0kg</p>
            <img
              class="img-fluid"
              src="../../../assets/images/similac3kg.png"
            />
          </div>
          <div class="mt-2">
            <img class="img-fluid" src="../../../assets/images/collect30.png" />
          </div>
        </li>
      </ul>
    </div>
    <div class="whiteCardBg text-center mt-3">
      <p class="brand">ENSURE <span class="flavours">Vanilla / Wheat</span></p>
      <ul>
        <li>
          <div class="text-center">
            <p class="grams">400 g</p>
            <img
              class="img-fluid"
              src="../../../assets/images/ensure400g.png"
            />
          </div>
          <div class="mt-2">
            <img class="img-fluid" src="../../../assets/images/collect4.png" />
          </div>
        </li>
        <li>
          <div class="text-center">
            <p class="grams">1.2kg</p>
            <img
              class="img-fluid"
              src="../../../assets/images/ensure1.2kg.png"
            />
          </div>
          <div class="mt-2">
            <img class="img-fluid" src="../../../assets/images/collect12.png" />
          </div>
        </li>
        <li>
          <div class="text-center">
            <p class="grams">1.6kg</p>
            <img
              class="img-fluid"
              src="../../../assets/images/ensure1.6kg.png"
            />
          </div>
          <div class="mt-2">
            <img class="img-fluid" src="../../../assets/images/collect16.png" />
          </div>
        </li>
        <li>
          <div class="text-center">
            <p class="grams">2.4kg</p>
            <img
              class="img-fluid"
              src="../../../assets/images/ensure1.6kg.png"
            />
          </div>
          <div class="mt-2">
            <img class="img-fluid" src="../../../assets/images/collect24.png" />
          </div>
        </li>
      </ul>
    </div> -->
  </div>

  <div class="px20 mt-3">
    <p class="mb-0 smallContent">
      For products without CRM QR codes, kindly submit product scoops.
    </p>
    <p class="mb-0 smallContent">Each scoop is 10 points.</p>
  </div>

  <div class="mt-4">
    <img
      class="img-fluid w-100"
      src="../../../assets/images/bluwithwhitebgimg.png"
    />
  </div>
  <div class="accordionBlock redemptionBlock px20">
    <img
      class="redemptionImg"
      src="../../../assets/images/redemptionitemimg.png"
    />
    <accordion [isAnimated]="true">
      <accordion-group (isOpenChange)="OnChange($event, 'abbotproducts')">
        <button
          class="btn btn-link btn-block clearfix"
          accordion-heading
          type="button"
        >
          <div class="pull-left float-left">ABBOTT PRODUCTS</div>
          <span class="float-right pull-right" *ngIf="!showProductsCloseIcon">
            <i class="mdi mdi-plus"></i
          ></span>
          <span class="float-right pull-right" *ngIf="showProductsCloseIcon">
            <i class="mdi mdi-minus"></i
          ></span>
        </button>
        <div class="row mx-0 mt-4 pb-5 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/similacmom900g.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/150points.png"
                />
              </div>
              <div class="col-8 pl-2">
                <p class="productName mb-0">Similac Mom®</p>
                <p class="productName mb-0">900g</p>
                <p class="productCode mb-0">ABB220001</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>Supporting nutritional needs for family beginnings</p>
                  </li>
                  <li>
                    <p>
                      Formulated with Eye-Q nutrition system, it now comes with
                      DHA, Natural Vitamin E and Lutein to support brain
                      development for your child
                    </p>
                  </li>
                  <li>
                    <p>
                      Low fat maternal milk formulated with 23 vitamins and
                      minerals for pregnant and breastfeeding moms to help
                      bridge key nutritional needs to your next generation
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 mt-4 pb-5 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/pediasureplain850g.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/400points.png"
                />
              </div>
              <div class="col-8 pl-2">
                <p class="productName mb-0">PediaSure® (Plain)</p>
                <p class="productName mb-0">850g</p>
                <p class="productCode mb-0">ABB200002</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>O% sucrose</p>
                  </li>
                  <li>
                    <p>
                      Complete and balanced nutrition for children 1-10 years
                      old, scientifically proven for catch up growth and less
                      sick days
                    </p>
                  </li>
                  <li>
                    <p>
                      Now with Arginine, Natural Vitamin K2 and Calcium to
                      support bone growth
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 mt-4 pb-5 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/pediasure850vannilanew.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/400points.png"
                />
              </div>
              <div class="col-8 pl-2">
                <p class="productName mb-0">PediaSure® (Vanilla)</p>
                <p class="productName mb-0">850g</p>
                <p class="productCode mb-0">ABB200003</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>
                      Complete and balanced nutrition for children 1-10 years
                      old, scientifically proven for catch up growth and less
                      sick days
                    </p>
                  </li>
                  <li>
                    <p>
                      Now with Arginine, Natural Vitamin K2 and Calcium to
                      support bone growth
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 mt-4 pb-5 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/pediasurechocalte850g.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/400points.png"
                />
              </div>
              <div class="col-8 pl-2">
                <p class="productName mb-0">PediaSure® (Chocolate)</p>
                <p class="productName mb-0">850g</p>
                <p class="productCode mb-0">ABB200004</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>
                      Complete and balanced nutrition for children 1-10 years
                      old, scientifically proven for catch up growth and less
                      sick days
                    </p>
                  </li>
                  <li>
                    <p>
                      Now with Arginine, Natural Vitamin K2 and Calcium to
                      support bone growth
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="row mx-0 mt-4 pb-5 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/pediasure10plusvanilla.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/400points.png"
                />
              </div>
              <div class="col-8 pl-2">
                <p class="productName mb-0">PediaSure 10+ (Vanilla)</p>
                <p class="productName mb-0">850g</p>
                <p class="productCode mb-0">ABB220005</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>
                      Complete & balanced nutrition supplement, scientifically
                      designed for 10-15 years to Catch-Up Growth
                    </p>
                  </li>
                  <li>
                    <p>
                      Contains high quality protein and is enriched with 38 key
                      nutrients including Arginine, Natural Vitamin K2 & Calcium
                      for bone growth
                    </p>
                  </li>
                  <li>
                    <p>
                      Formulated with unique Triple Growth System supporting
                      increased height, energy release and immune function
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 mt-4 pb-5 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/AXDhaK@3x.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/400points.png"
                />
              </div>
              <div class="col-8 pl-2">
                <p class="productName mb-0">PediaSure® 10+(Chocolate)</p>
                <p class="productName mb-0">850g</p>
                <p class="productCode mb-0">ABB220006</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>
                      Complete & balanced nutrition supplement, scientifically
                      designed for 10-15 years to Catch-Up Growth
                    </p>
                  </li>
                  <li>
                    <p>
                      Contains high quality protein and is enriched with 38 key
                      nutrients including Arginine, Natural Vitamin K2 & Calcium
                      for bone growth
                    </p>
                  </li>
                  <li>
                    <p>
                      Formulated with unique Triple Growth System supporting
                      increased height, energy release and immune function
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="row mx-0 mt-4 pb-5 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/similacgainplusgold900g.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/300points.png"
                />
              </div>
              <div class="col-8 pl-2">
                <p class="productName mb-0">Similac Gain Plus® Gold</p>
                <p class="productName mb-0">900g</p>
                <p class="productCode mb-0">ABB220007</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>Formulated milk powder for children 1–3 years old</p>
                  </li>
                  <li>
                    <p>
                      The world’s first formula with 2’-FL & nucleotides and
                      Eye-Q® system to enable your child to grow stronger with
                      good defense system for fast learning
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 mt-4 pb-5 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/similacgainkidgold1.8kg.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/520points.png"
                />
              </div>
              <div class="col-8 pl-2">
                <p class="productName mb-0">Similac Gain Kid® Gold</p>
                <p class="productName mb-0">1.8kg</p>
                <p class="productCode mb-0">ABB220008</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>Formulated milk powder for children 4–9 years old</p>
                  </li>
                  <li>
                    <p>
                      The world’s first formula with 2’-FL & nucleotides and
                      Eye-Q® system to enable your child to grow stronger with
                      good defense system for fast learning
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="row mx-0 mt-4 pb-5 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/iUCnGe@3x.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/320points.png"
                />
              </div>
              <div class="col-6 pl-2 position-relative">
                <img
                  class="img-fluid iqImg pl-2"
                  src="../../../assets/images/IQ.png"
                />
                <p class="productName mb-0">Similac Isomil Plus</p>
                <p class="productName mb-0">850g</p>
                <p class="productCode mb-0">ABB200011</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>
                      For children age 1-10 with cow's milk protein allergy,
                      lactose intolerance, galactosemia, or those on vegetarian
                      diet
                    </p>
                  </li>
                  <li>
                    <p>
                      #1 soy-based formula in Malaysia, trusted by moms for over
                      50 years
                    </p>
                  </li>
                  <li>
                    <p>Supported by more than 70 clinical studies</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="row mx-0 mt-4 pb-5 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/similacIntelliProStep3-1.2kg.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/240points.png"
                />
              </div>
              <div class="col-8 pl-2">
                <p class="productName mb-0">Similac Intelli-Pro Step 3</p>
                <p class="productName mb-0">1.2kg</p>
                <p class="productCode mb-0">ABB200009</p>
              </div>
              <div class="col-12 mt-2">
                <p class="nosDiscContent">
                  Growing up milk for 1-3 years old with DHA +7 nutrients to
                  provide optimum nutrient absorptions for your child’s learning
                  development and optimum growth.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 mt-4 pb-5 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/similacIntelliProStep4-1.2kg.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/230points.png"
                />
              </div>
              <div class="col-8 pl-2">
                <p class="productName mb-0">Similac Intelli-Pro Step 4</p>
                <p class="productName mb-0">1.2kg</p>
                <p class="productCode mb-0">ABB220010</p>
              </div>
              <div class="col-12 mt-2">
                <p class="nosDiscContent">
                  Growing up milk for 4-9 years old with DHA +7 nutrients to
                  provide optimum nutrient absorptions for your child’s learning
                  development and optimum growth.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row mx-0 mt-4 pb-5 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/ennsuregoldvannila850g.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/410points.png"
                />
              </div>
              <div class="col-8 pl-2">
                <p class="productName mb-0">Ensure® GOLD (Vanilla)</p>
                <p class="productName mb-0">850g</p>
                <p class="productCode mb-0">ABB200012</p>
              </div>
              <div class="col-12 mt-2">
                <p class="nosDiscContent">
                  Ensure® Gold is scientifically formulated to provide complete
                  and balanced nutrition to live life strong.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row mx-0 mt-4 pb-5">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/glucernavennila850g.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/480points.png"
                />
              </div>
              <div class="col-8 pl-2">
                <p class="productName mb-0">Glucerna® (Vanilla)</p>
                <p class="productName mb-0">850g</p>
                <p class="productCode mb-0">ABB200013</p>
              </div>
              <div class="col-12 mt-2">
                <p class="nosDiscContent">
                  A scientifically formulated complete and balanced formula for
                  diabetics with steady energy release to help support blood
                  glucose control.
                </p>
              </div>
            </div>
          </div>
        </div>
      </accordion-group>

      <accordion-group class="mt-2" (isOpenChange)="OnChange($event, 'child')">
        <button
          class="btn btn-link btn-block clearfix"
          accordion-heading
          type="button"
        >
          <div class="pull-left float-left">CHILD EDUCATION</div>
          <span class="float-right pull-right" *ngIf="!showChildCloseIcon">
            <i class="mdi mdi-plus"></i
          ></span>
          <span class="float-right pull-right" *ngIf="showChildCloseIcon">
            <i class="mdi mdi-minus"></i
          ></span>
        </button>
        <div class="row mx-0 mt-4 pb-5 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/youngScientists.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/560points.png"
                />
              </div>
              <div class="col-8 pl-2">
                <p class="productName mb-0">The Young Scientists</p>
                <p class="productName mb-0">Level 1 Year 2020</p>
                <p class="productName mb-0">Collectors’ Set (10books)</p>
                <p class="productCode mb-0">ABB200014</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>A best-selling science comic for children aged 7-8</p>
                  </li>
                  <li>
                    <p>
                      Reveals the wonders of science by creating stories in
                      comic format to assist children to better understand the
                      science contents
                    </p>
                  </li>
                  <li>
                    <p>
                      Opens up the world of Science to curious minds and
                      prepares them for a fascinating journey into the studies
                      of various sciences in the years to come
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 mt-4 pb-5 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/happyDragon.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/270points.png"
                />
              </div>
              <div class="col-8 pl-2">
                <p class="productName mb-0">Happy Dragon 100,000</p>
                <p class="productName mb-0">Whys (English) (Vol 41-45)</p>
                <p class="productCode mb-0">ABB200015</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>Suitable for children age 7 and above</p>
                  </li>
                  <li>
                    <p>
                      Happy Dragon is a best-selling children's science comic
                    </p>
                  </li>
                  <li>
                    <p>
                      It is a collection of scientific mysteries that are asked
                      by children
                    </p>
                  </li>
                  <li>
                    <p>
                      Each mystery is presented in simple language with funny
                      and interesting comic illustrations relating to the
                      scientific fact concerned
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 mt-4 pb-5 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/faberCastellEarlyAge.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/120points.png"
                />
              </div>
              <div class="col-8 pl-2">
                <p class="productName mb-0">Faber-Castell Early Age</p>
                <p class="productName mb-0">Grasp Crayons (Set of 4+6 pcs)</p>
                <p class="productCode mb-0">ABB200016</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>Ideal for children age 4 and above</p>
                  </li>
                  <li>
                    <p>
                      Perfect for transition from first grip to correct grip
                    </p>
                  </li>
                  <li>
                    <p>
                      Thick, easy to grasp bulb shape ideal for little hands,
                      less tiring for small hands
                    </p>
                  </li>
                  <li>
                    <p>
                      Improve hand-eye coordination and enhance motor skill
                      development
                    </p>
                  </li>
                  <li>
                    <p>
                      Smooth, non flaky, high break resistant long lasting
                      crayons
                    </p>
                  </li>
                  <li>
                    <p>
                      Vibrant and intense colours - clean, safe and washable
                      from most clothing and surfaces
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 mt-4 pb-5 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/faberCastellPosterColors.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/140points.png"
                />
              </div>
              <div class="col-8 pl-2">
                <p class="productName mb-0">Faber-Castell Poster</p>
                <p class="productName mb-0">Colours of 12 Colours +</p>
                <p class="productName mb-0">Soft Touch Brush Set of 4</p>
                <p class="productCode mb-0">ABB200017</p>
              </div>
              <div class="col-12 mt-2">
                <p class="nosDiscContent">Poster Colour</p>
                <ul class="listContentBlock">
                  <li>
                    <p>Rich and vibrant colour Brush Set</p>
                  </li>
                  <li>
                    <p>Wash colour mixing and blending</p>
                  </li>
                  <li>
                    <p>Quick drying</p>
                  </li>
                  <li>
                    <p>Shake well before use</p>
                  </li>
                  <li>
                    <p>Store in cool dry place aways from sunlight</p>
                  </li>
                </ul>
                <p class="nosDiscContent">Brush Set</p>
                <ul class="listContentBlock">
                  <li>
                    <p>Brush size : #4, #8, #10 & #12</p>
                  </li>
                  <li>
                    <p>
                      Brush with soft-touch grip area for a good hold while
                      painting
                    </p>
                  </li>
                  <li>
                    <p>Sturdy, sofy synthetic hair</p>
                  </li>
                  <li>
                    <p>Ideal set for school and leisure time</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 mt-4 pb-5 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/leapFrogLearningLight.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/360points.png"
                />
              </div>
              <div class="col-8 pl-2">
                <p class="productName mb-0">LeapFrog Learning</p>
                <p class="productName mb-0">Light Remote Deluxe</p>
                <p class="productCode mb-0">ABB200018</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>Suitable for children age 12-36 months</p>
                  </li>

                  <li>
                    <p>
                      Explore and learn more than 100 phrases, sounds and music
                      while building fine motor skills
                    </p>
                  </li>
                  <li>
                    <p>
                      Flip between modes for role-play excitement or learning
                      activities that introduce numbers and letters
                    </p>
                  </li>
                  <li>
                    <p>
                      Silly pretend channels inspire imaginative play. Switch to
                      the Spanish channel to hear common Spanish phrases
                    </p>
                  </li>
                  <li>
                    <p>
                      Listen and dance to three different music styles on the
                      music channel
                    </p>
                  </li>
                  <li>
                    <p>
                      Hear rain, snow and wind sound effects on the weather
                      channel.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 mt-4 pb-5 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/leapFrogLevelUp.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/430points.png"
                />
              </div>
              <div class="col-8 pl-2">
                <p class="productName mb-0">LeapFrog Level Up</p>
                <p class="productName mb-0">& Learn Controller</p>
                <p class="productCode mb-0">ABB200019</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>Suitable for children age 12-36 months</p>
                  </li>
                  <li>
                    <p>
                      A controller with a 6 touched-screen learning activities
                      that allows you to play and join the animals in exploring
                      counting, colours, rhyming, Spanish phrases and more
                    </p>
                  </li>
                  <li>
                    <p>
                      The four colourful shape buttons introduce shapes and
                      encourage exploring numbers
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 mt-4 pb-5 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/animalJigsawParty.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/120points.png"
                />
              </div>
              <div class="col-8 pl-2">
                <p class="productName mb-0">Animal Jigsaw Party</p>
                <p class="productName mb-0">Alphabet Puzzle Game</p>
                <p class="productCode mb-0">ABB200020</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>Suitable for children age 3 and above</p>
                  </li>
                  <li>
                    <p>
                      Learn about cute animals, words and colours with this
                      customised jigsaw puzzle game
                    </p>
                  </li>
                  <li>
                    <p>
                      Help improve children's recognition ability and literacy
                      skills
                    </p>
                  </li>
                  <li>
                    <p>Comes with 54 cards and game guide</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 mt-4 pb-5 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/4mThinkingKits.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/310points.png"
                />
              </div>
              <div class="col-8 pl-2">
                <p class="productName mb-0">4M ThinkingKits</p>
                <p class="productName mb-0">- Dough Circuit Piano</p>
                <p class="productCode mb-0">ABB200021</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>Suitable for children age 4 and above</p>
                  </li>
                  <li>
                    <p>
                      Create your dough circuit piano in all sorts of crazy, fun
                      designs using the dough provided
                    </p>
                  </li>
                  <li>
                    <p>
                      When you touch both the ground pin and the dough strips
                      attached to the piano module, the piano module sends out
                      safe signals and completes the circuit that triggers the
                      unique sound. It is so much fun!
                    </p>
                  </li>
                  <li>
                    <p>
                      Amaze your friends and family by learning to play well-
                      known tunes using the musical cards provided!
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="row mx-0 mt-4 pb-5">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/4mKidzLabs.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/240points.png"
                />
              </div>
              <div class="col-8 pl-2">
                <p class="productName mb-0">4M KidzLabs</p>
                <p class="productName mb-0">- Flashing Emergency Light</p>
                <p class="productCode mb-0">ABB200022</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>Suitable for children age 5 and above</p>
                  </li>
                  <li>
                    <p>
                      Build this cool emergency light, learn about basic
                      circuits and how the motorised reflector makes the light
                      flash
                    </p>
                  </li>
                  <li>
                    <p>
                      It’s also a great decoration for your bedroom or for
                      camping
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </accordion-group>

      <accordion-group
        class="mt-2"
        (isOpenChange)="OnChange($event, 'voucher')"
      >
        <button
          class="btn btn-link btn-block clearfix"
          accordion-heading
          type="button"
        >
          <div class="pull-left float-left">VOUCHER / E-VOUCHER</div>
          <span class="float-right pull-right" *ngIf="!showVoucherCloseIcon">
            <i class="mdi mdi-plus"></i
          ></span>
          <span class="float-right pull-right" *ngIf="showVoucherCloseIcon">
            <i class="mdi mdi-minus"></i
          ></span>
        </button>
        <div class="row mx-0 pb-5 mt-4 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/Group 272.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/400points.png"
                />
              </div>
              <div class="col-6 pl-2">
                <p class="productName mb-0">Touch 'n Go</p>
                <p class="productName mb-0">eWallet Reload PIN RM100</p>
                <p class="productCode mb-0">ABB200023</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>
                      A unique PIN code valid for 1 year and for 1 time use /
                      redemption only
                    </p>
                  </li>
                  <li>
                    <p>
                      One-time use code will be sent to recipient via email;
                      please provide your correct and valid email address to
                      facilitate fulfllment
                    </p>
                  </li>
                  <li>
                    <p>To reload, key in unique code into the TNG mobile app</p>
                  </li>
                  <li>
                    <p>
                      After successfully reload, value will be entered into the
                      TNG e-wallet
                    </p>
                  </li>
                  <li>
                    <p>
                      Value is redeemable at TNG participating merchants only
                    </p>
                  </li>
                  <li>
                    <p>Not valid for exchange in cash</p>
                  </li>
                  <li>
                    <p>Subject to Issuer's full terms and conditions</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 pb-5 mt-4 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/grabimage.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/400points.png"
                />
              </div>
              <div class="col-6 pl-2">
                <p class="productName mb-0">Grab Food</p>
                <p class="productName mb-0">RM100 e-voucher</p>
                <p class="productCode mb-0">ABB200024</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>
                      e-Voucher valid for 5 months and for 1 time use /
                      redemption only
                    </p>
                  </li>
                  <li>
                    <p>
                      One-time use code will be sent to recipient via email;
                      please provide your correct and valid email address to
                      facilitate fulfllment
                    </p>
                  </li>
                  <li>
                    <p>
                      Grab e-voucher is valid for GRAB Mobile app only and for
                      GrabFood only
                    </p>
                  </li>
                  <li>
                    <p>Not valid for exchange for cash</p>
                  </li>
                  <li>
                    <p>Subject to Issuer's full terms and conditions</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 pb-5 mt-4 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/lazardaimg.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/400points.png"
                />
              </div>
              <div class="col-6 pl-2">
                <p class="productName mb-0">Lazada</p>
                <p class="productName mb-0">RM100 e-Voucher</p>
                <p class="productCode mb-0">ABB200025</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>
                      e-Voucher valid for 5 months and for 1 time use /
                      redemption only
                    </p>
                  </li>
                  <li>
                    <p>
                      One-time use code will be sent to recipient via email;
                      please provide your correct and valid email address to
                      facilitate fulfllment
                    </p>
                  </li>
                  <li>
                    <p>
                      Lazada e-voucher is valid for Lazada Online shopping only
                    </p>
                  </li>
                  <li>
                    <p>Not valid for exchange in cash</p>
                  </li>
                  <li>
                    <p>Subject to Issuer's full terms and conditions</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="row mx-0 pb-5 mt-4 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/uwSKmh.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/400points.png"
                />
              </div>
              <div class="col-6 pl-2">
                <p class="productName mb-0">CARiNG Pharmacy</p>
                <p class="productName mb-0">RM100 e-voucher</p>
                <p class="productCode mb-0">ABB200026</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>
                      e-Voucher valid for 5 months and for 1 time use /
                      redemption only
                    </p>
                  </li>
                  <li>
                    <p>
                      One-time use code will be sent to recipient via email;
                      please provide your correct and valid email address to
                      facilitate fulfllment
                    </p>
                  </li>
                  <li>
                    <p>
                      Redeemable at CARiNG Pharmacy outlets only and not valid
                      on CARiNG e-store
                    </p>
                  </li>
                  <li>
                    <p>Not valid for exchange in cash</p>
                  </li>
                  <li>
                    <p>Subject to Issuer's full terms and conditions</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 pb-5 mt-4 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/BMS Logo_-01.psd.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/400points.png"
                />
              </div>
              <div class="col-6 pl-2">
                <p class="productName mb-0">BMS Organics</p>
                <p class="productName mb-0">RM100 Gift Voucher</p>
                <p class="productCode mb-0">ABB200027</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>
                      Redemption of this voucher must be made via ASCW
                      redemption program only and not directly from any BMS
                      Organics stores
                    </p>
                  </li>
                  <li>
                    <p>
                      Gift voucher can be used for purchase at branches of BMS
                      Organics physical stores only. Not applicable for online
                      store use
                    </p>
                  </li>
                  <li>
                    <p>
                      Gift voucher is valid for 1 year from date of Issue. Any
                      remaining balance will not be refunded on the expiry of
                      the validity period
                    </p>
                  </li>
                  <li>
                    <p>
                      Not exchangeable for cash and use for one(1) transaction
                      only. Any unused balance will not be refundable
                    </p>
                  </li>
                  <li>
                    <p>
                      Usage is subject to Issuer's full terms and conditions
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 pb-5 mt-4 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/Group 268.jpg"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/400points.png"
                />
              </div>
              <div class="col-6 pl-2">
                <p class="productName mb-0">Popular</p>
                <p class="productName mb-0">RM100 Gift Voucher</p>
                <p class="productCode mb-0">ABB200028</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>
                      Redemption of this voucher must be made via ASCW
                      redemption program only and not directly from any POPULAR
                      book stores
                    </p>
                  </li>
                  <li>
                    <p>
                      Valid to be used at all Popular Book, CD-Rama and Harris
                      outlets in Malaysia only
                    </p>
                  </li>
                  <li>
                    <p>
                      This voucher is not valid to be used for magazines and
                      concessionaire counter products in all outlets
                    </p>
                  </li>
                  <li>
                    <p>
                      This voucher is not valid to be used in all Popular's Book
                      Fests, Mega Bookfairs and Bookfairs
                    </p>
                  </li>
                  <li>
                    <p>
                      Voucher is valid for 12 months from date of issue. Please
                      refer to physical voucher for validity
                    </p>
                  </li>
                  <li>
                    <p>
                      Not exchangeable for cash and use for one(1) transaction
                      only. Any unused balance will not be refundable
                    </p>
                  </li>
                  <li>
                    <p>
                      Usage is subject to Issuer's full terms and conditions
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 pb-5 mt-4 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/Group 250.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/400points.png"
                />
              </div>
              <div class="col-6 pl-2">
                <p class="productName mb-0">Lotus's</p>
                <p class="productName mb-0">RM 100 Gift Voucher</p>
                <p class="productCode mb-0">ABB200029</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>
                      Redemption of this voucher must be made via ASCW
                      redemption program only and not directly from Lotus's
                      stores
                    </p>
                  </li>
                  <li>
                    <p>
                      Voucher validity is 12 months; please refer to physical
                      voucher for validity
                    </p>
                  </li>
                  <li>
                    <p>Not applicable for Lotus's online shopping</p>
                  </li>
                  <li>
                    <p>
                      Not valid for exchangeable in cash and can only be used
                      for one (1) transaction; any unused balance will not be
                      refundable
                    </p>
                  </li>
                  <li>
                    <p>
                      Usage is subject to Issuer's full terms and conditions
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 pb-5 mt-4">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/Group 256.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/400points.png"
                />
              </div>
              <div class="col-6 pl-2">
                <p class="productName mb-0">AEON</p>
                <p class="productName mb-0">RM100 Gift Voucher</p>
                <p class="productCode mb-0">ABB200030</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>
                      Redemption of this voucher must be made via ASCW
                      redemption program only and not directly from any AEON
                      stores
                    </p>
                  </li>
                  <li>
                    <p>
                      This voucher allows the holder/bearer to redeem goods at
                      any of the Aeon Co. (U) Bhd. (126926-H) (Formerly known as
                      Jaya Jusco Stores Bhd) outlet in Malaysia only
                    </p>
                  </li>
                  <li>
                    <p>
                      It is only valid in exchange for goods at any AEON outlets
                      and it is not exchangeable for cash
                    </p>
                  </li>
                  <li>
                    <p>
                      Voucher is valid for 2 years from date of issue; please
                      refer to physical voucher for voucher validity
                    </p>
                  </li>
                  <li>
                    <p>
                      Not valid for exchangeable in cash and can only be used
                      for one (1) transaction; any unused balance will not be
                      refundable
                    </p>
                  </li>
                  <li>
                    <p>
                      Usage is subject to Issuer's full terms and conditions
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </accordion-group>

      <accordion-group
        class="mt-2"
        (isOpenChange)="OnChange($event, 'household')"
      >
        <button
          class="btn btn-link btn-block clearfix"
          accordion-heading
          type="button"
        >
          <div class="pull-left float-left">HOUSEHOLD</div>
          <span class="float-right pull-right" *ngIf="!showHouseCloseIcon">
            <i class="mdi mdi-plus"></i
          ></span>
          <span class="float-right pull-right" *ngIf="showHouseCloseIcon">
            <i class="mdi mdi-minus"></i
          ></span>
        </button>
        <div class="row mx-0 mt-4 pb-5 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/gourmetrectangularcontainer.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/100points.png"
                />
              </div>
              <div class="col-6 pl-2">
                <p class="productName mb-0">LA Gourmet Pureglas 0.32L</p>
                <p class="productName mb-0">Square Container</p>
                <!-- <p class="productName mb-0">
                            Rectangular Container
                        </p> -->
                <p class="productCode mb-0">ABB220031</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>Capacity: 0.32 Litre</p>
                  </li>
                  <li>
                    <p>Material: 100% Borosilicate Glass</p>
                  </li>
                  <li>
                    <p>Body Heat Resistant Up to 400°C</p>
                  </li>
                  <li>
                    <p>Food Safe PP Lid / Tritan Lid</p>
                  </li>
                  <li>
                    <p>Temperature Range within 120°C</p>
                  </li>
                  <li>
                    <p>
                      Specially designed silicone ring and clips make it
                      airtight
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 mt-4 pb-5 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/tupperwareontouchpink.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/120points.png"
                />
              </div>
              <div class="col-6 pl-2">
                <p class="productName mb-0">Tupperware One Touch</p>
                <p class="productName mb-0">Canister Junior</p>

                <p class="productCode mb-0">ABB220032</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>Capacity: 1.25 Litre</p>
                  </li>
                  <li>
                    <p>BPA-Free materials</p>
                  </li>
                  <li>
                    <p>
                      Airtight container helps to retain the quality and
                      freshness of foods
                    </p>
                  </li>
                  <li>
                    <p>
                      Designed ideally to store snack, prawn crackers, milk
                      powder and etc.
                    </p>
                  </li>
                </ul>
                <p class="nosDiscContent">* Colour is subject to change</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 mt-4 pb-5 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/LmAMsF.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/320points.png"
                />
              </div>
              <div class="col-6 pl-2">
                <p class="productName mb-0">Holabebe Airtight Milk</p>
                <p class="productName mb-0">Powder Container 2.3L</p>

                <p class="productCode mb-0">ABB220033</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>Capacity: 2.3 Litre / 1kg milk powder</p>
                  </li>
                  <li>
                    <p>Material: ABS, AS, Silica gel material</p>
                  </li>
                  <li>
                    <p>Dimensions: 16cm (L) x 13cm (W) x 22cm (H)</p>
                  </li>
                  <li>
                    <p>
                      One-Touch Button: Can be opened quickly and closed with
                      just a light press
                    </p>
                  </li>
                  <li>
                    <p>Strong Seal: Ensure perfect airtightness</p>
                  </li>
                  <li>
                    <p>
                      Space Saving: Bottom and cover fit perfectly for stacking
                    </p>
                  </li>
                  <li>
                    <p>Detachable lid: Easy for dismantling and cleaning</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 pb-5 mt-4 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/6a2th7.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/Group 714.png"
                />
              </div>
              <div class="col-6 pl-2">
                <p class="productName mb-0">Thermos 400ml Straw</p>
                <p class="productName mb-0">Bottle with Pouch</p>
                <p class="productCode mb-0">ABB220034</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>
                      Double-wall vacuum insulation for maximum temperature
                      retention •
                    </p>
                  </li>
                  <li>
                    <p>Hygienic Push-Button Lid</p>
                  </li>
                  <li>
                    <p>Snaps close for leak-proof travel</p>
                  </li>
                  <li>
                    <p>Keeps content cool and fresh at minimum of 12 hours</p>
                  </li>
                  <li>
                    <p>Private pouch provided for easy-to-hold</p>
                  </li>
                </ul>
                <p class="nosDiscContent">
                  * Colour is subject to changeHygienic Push-Button Lid
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 pb-5 mt-4">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/DJkjdH.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/Group 715.png"
                />
              </div>
              <div class="col-6 pl-2">
                <p class="productName mb-0">Thermos 500ml</p>
                <p class="productName mb-0">Insulated Soup Jar</p>
                <p class="productCode mb-0">ABB220035</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>
                      Double wall vacuum insulation for maximum temperature
                      retention
                    </p>
                  </li>
                  <li>
                    <p>Extra wide mouth is easy to fill, serve and clean</p>
                  </li>
                  <li>
                    <p>Unbreakable stainless steel interior and exterior</p>
                  </li>
                  <li>
                    <p>
                      Cool to the touch with hot liquids, sweat-proof with cold
                      liquids
                    </p>
                  </li>
                </ul>

                <p class="nosDiscContent">* Colour is subject to change</p>
              </div>
            </div>
          </div>
        </div>
      </accordion-group>

      <accordion-group class="mt-2" (isOpenChange)="OnChange($event, 'health')">
        <button
          class="btn btn-link btn-block clearfix"
          accordion-heading
          type="button"
        >
          <div class="pull-left float-left">HEALTH & FITNESS</div>
          <span class="float-right pull-right" *ngIf="!showHealthCloseIcon">
            <i class="mdi mdi-plus"></i
          ></span>
          <span class="float-right pull-right" *ngIf="showHealthCloseIcon">
            <i class="mdi mdi-minus"></i
          ></span>
        </button>
        <div class="row mx-0 pb-5 mt-4 borderBottom">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/8YgTDD.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/Group 706.png"
                />
              </div>
              <div class="col-6 pl-2">
                <p class="productName mb-0">Sit-up Device</p>
                <!-- <p class="productName mb-0">
                            Shaker Bottle
                        </p> -->
                <p class="productCode mb-0">ABB220036</p>
              </div>
              <div class="col-12 mt-2">
                <ul class="listContentBlock">
                  <li>
                    <p>Product size: 27cm (L) x 18cm (H)</p>
                  </li>
                  <li>
                    <p>Weight: 0.8 kg</p>
                  </li>
                  <li>
                    <p>Material: iron + elastic foam</p>
                  </li>
                  <li>
                    <p>Colour: red (colour subject to availability)</p>
                  </li>
                  <li>
                    <p>
                      Home/office fitness equipment; can be used to train
                      abdomen, slim waist, and reduce belly fat
                    </p>
                  </li>
                  <li>
                    <p>
                      Adjustable and compact in size, occupying only small area
                      and easy to use everywhere
                    </p>
                  </li>
                  <li>
                    <p>Strong suction to floor and non slippery</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0 pb-5 mt-4">
          <div class="col-4">
            <img
              class="tabBrandImgLeft"
              src="../../../assets/images/ySlmj0.png"
            />
          </div>
          <div class="col-8 px-0">
            <div class="row no-gutters mx-0">
              <div class="col-4">
                <img
                  class="img-fluid Img340"
                  src="../../../assets/images/120points.png"
                />
              </div>
              <div class="col-6 pl-2">
                <p class="productName mb-0">Hypergear Dry</p>
                <p class="productName mb-0">Bag Lite 10L</p>
                <!-- <p class="productName mb-0">
                            Non-slip
                        </p> -->
                <p class="productCode mb-0">ABB220037</p>
              </div>
              <div class="col-12">
                <ul class="listContentBlock">
                  <li>
                    <p>Dimensions: Closed Height: 36cm</p>
                    <p>Open Height: 51cm) x Width: 7.5cm</p>
                  </li>
                  <li>
                    <p>Storage pouch size: 16cm</p>
                  </li>
                  <li>
                    <p>Weight: 100 gram</p>
                  </li>
                  <li>
                    <p>Material: light weight nylon material</p>
                  </li>
                  <li>
                    <p>Color: Black (colour subject to change)</p>
                  </li>
                  <li>
                    <p>
                      Can be stored in a very compact size, and expanded to a
                      full-size dry bag whenever in use. Use it as a dry bag for
                      water sports activity or as an inner compartment for any
                      bag
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </accordion-group>

      <accordion-group class="mt-2" (isOpenChange)="OnChange($event, 'terms')">
        <button
          class="btn btn-link btn-block clearfix"
          accordion-heading
          type="button"
        >
          <div class="pull-left float-left">Terms and Conditions</div>
          <span class="float-right pull-right" *ngIf="!showTermsCloseIcon">
            <i class="mdi mdi-plus"></i
          ></span>
          <span class="float-right pull-right" *ngIf="showTermsCloseIcon">
            <i class="mdi mdi-minus"></i
          ></span>
        </button>

        <div
          class="termsContentBlock"
          *ngIf="terms"
          [innerHTML]="terms | safe: 'html'"
        ></div>
      </accordion-group>

      <!-- <accordion-group class="mt-2" (isOpenChange)="OnChange($event, 'faq')">
        <button
          class="btn btn-link btn-block clearfix"
          accordion-heading
          type="button"
        >
          <div class="pull-left float-left">FAQ</div>
          <span class="float-right pull-right" *ngIf="!showFaqCloseIcon">
            <i class="mdi mdi-plus"></i
          ></span>
          <span class="float-right pull-right" *ngIf="showFaqCloseIcon">
            <i class="mdi mdi-minus"></i
          ></span>
        </button>
        <div *ngFor="let data of faqcontent">
          <h5 class="faqQuestions">{{ data.faqtitle }}</h5>
          <p
            class="faqDescriptions"
            [innerHTML]="data.faqdescription | safe: 'html'"
          ></p>
        </div>
      </accordion-group> -->
    </accordion>
  </div>
</section>

<app-bottom-nav></app-bottom-nav>
<app-footer></app-footer>
<!-- (isOpenChange)="OnChange($event)" -->
