<app-header-primary></app-header-primary>
<section class="blueBgContainer pt60 pb70">
    <div class="productBlockOut text-center">
        <img class="" src="../../../assets/images/productpedisure.png" routerLink="/pediasure-details">
        <p class="productName mt30">{{ 'PRODUCTS.PEDIASURE' | translate }}</p>
        <div class="row justify-content-center mx-0">
            <div class="col-8 text-center">
                <p class="productDescription">{{ 'PRODUCTS.PEDIA_DESCRIPTION' | translate }}</p>
            </div>
        </div>
    </div>
    <div class="productBlockOut text-center">
        <img class="" src="../../../assets/images/proudctsimilacgainplusgold.png" routerLink="/similac-details">
        <p class="productName mb-0">{{ 'PRODUCTS.SIMILAC' | translate }}</p>
        <p class="productName">{{ 'PRODUCTS.SIMILAC1' | translate }}</p>

        <div class="row justify-content-center mx-0">
            <div class="col-8 text-center">
                <p class="productDescription"> {{ 'PRODUCTS.ENSURE_DESCRIPTION' | translate }}</p>
            </div>
        </div>
    </div>
    <div class="productBlockOut text-center">
        <img class="" src="../../../assets/images/productensuregold.png" routerLink="/ensure-details">
        <p class="productName mb-0 mt-4">{{ 'PRODUCTS.ENSURE' | translate }}</p>
        <div class="row justify-content-center mx-0 mt-3">
            <div class="col-8 text-center">
                <p class="productDescription">{{ 'PRODUCTS.PEDIA_DESCRIPTION' | translate }}</p>
            </div>
        </div>
    </div>
    <p class="bottomCode text-right pb-3 mt-4 mr-4">{{ 'PRODUCTS.BOTTOM_CODE' | translate }}</p>
    <app-footer></app-footer>
</section>
<app-bottom-nav></app-bottom-nav>