<app-header homeLogo="../../../assets/images/homeicon.svg" link="/traceability-ensure"></app-header>
<section class="ensureGlobalContainer">
    <div class="bannerBlockTop">
        <img class="img-fluid" src="../../../../assets/images/ensuredarkbluebannerwithproduct.png">
        <img class="titleImage manufactureImg" src="../../../../assets/images/ensuremanufacturing.png">
    </div>
    <div class="ensureInnerBlock bgDarkBlue pb30">
        <div class="card secondaryCard pt50 mt50">
            <div class="topDateBlock">
                <p class="text-center mt-3 batchNumber text-uppercase">{{ 'ENSURE_MANUFACTURING.BATCH_NUMBER' | translate }} {{batchNumber}}
                </p>
                <div class="row justify-content-center no-gutters">
                    <div class="col-7 text-left">
                        <p class="dateContent fontGRBold">{{ 'ENSURE_MANUFACTURING.MANUFACTURING_IN' | translate }}</p>
                    </div>
                    <div class="col-3 text-left fontGRMedium">
                        <p class="dateContent">{{manufactorIn}}</p>
                    </div>
                </div>
                <div class="row justify-content-center no-gutters">
                    <div class="col-7 text-left">
                        <p class="dateContent fontGRBold">{{ 'ENSURE_MANUFACTURING.MANUFACTURED_DATE' | translate }}</p>
                    </div>
                    <div class="col-3 text-left fontGRMedium">
                        <p class="dateContent">{{manufacturingDate }}</p>
                    </div>
                </div>
            </div>
            <img class="w-100 mt-3" src="../../../../assets/images/abbotfactorytopviewimg.png" />
            <p class="contentMediumSmall mt-4">{{ 'ENSURE_MANUFACTURING.FACTORY_CONTENT_PLANT' | translate }}</p>
            <img class="w-100" src="../../../../assets/images/manufacturingInsideFactory.png" />
            <p class="cardBottomSmallContentManufacture mt-3 ml-1">{{ 'ENSURE_MANUFACTURING.BOTTOM_DESC_PURPOSE' | translate }}</p>
        </div>
    </div>
    <app-footer></app-footer>
</section>
<app-ensure-bottomnav></app-ensure-bottomnav>
