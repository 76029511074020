import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss'],
})
export class DeliveryComponent implements OnInit {
  productInfo: any;
  expiryDate: any;
  arrivalDate: any;
  customClearanceDate: any;

  constructor() {
    this.productInfo = JSON.parse(sessionStorage.getItem('iSprintData'));
    console.log(this.productInfo, 'productInfo');
  }

  parseFunction(value) {
    return JSON.parse(value);
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    // this.productImage = this.productInfo.product_image;
    this.getTraceabilityContent();
  }

  getTraceabilityContent() {
    if (this.productInfo.is_isprint == true) {
      this.expiryDate = this.productInfo.expiry_date.value;
      this.customClearanceDate = this.productInfo.custom_clearance_date.value;
      this.arrivalDate = this.productInfo.arrival_date.value;
    } else {
      this.expiryDate = this.productInfo.expiry_date;
      this.customClearanceDate = this.productInfo.custom_clearance_date;
      this.arrivalDate = this.productInfo.arrival_date;
    }
  }
}
