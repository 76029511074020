<section class="pediasureNavMenu">
    <div class="card">
        <ul>
            <li>
                <a href="javascript:;" routerLinkActive="active" routerLink="/pediasure-manufacturing">
                    <img src="../../../assets/images/manufacturewhiteiconsmall.png" *ngIf="!showManufacturing"   alt="home" />
                    <img src="../../../assets/images/manufacturewhiteactive.png" *ngIf="showManufacturing"   alt="home" />

                    <span>{{ 'PEDIASURE_BOTTOM_NAV.MANUFACTURING' | translate }}</span>
                </a>
            </li>
            <li >
                <a href="javascript:;" routerLink="//pediasure-qualitycontrol">
                    <img class="rewardsImg" src="../../../assets/images/qualitycontrolwhiteiconsmall.png" *ngIf="!showQualityControl" alt="" />
                    <img src="../../../assets/images/qualitycontrolwhiteactive.png" *ngIf="showQualityControl"   alt="home" />

                    <span>{{ 'PEDIASURE_BOTTOM_NAV.QUALITY_CONTROL' | translate }}</span>
                </a>
            </li>
            <li>
                <a href="javascript:;" routerLink="/pediasure-delivery" >
                    <img class="deliveryImg" src="../../../assets/images/deliverwhiteiconsmall.png" *ngIf="!showDelivery" alt="" />
                    <img class="deliveryImg" src="../../../assets/images/deliverywhiteactive.png" *ngIf="showDelivery" alt="" />

                    <span>{{ 'PEDIASURE_BOTTOM_NAV.CLEARANCE' | translate }}<br> {{ 'PEDIASURE_BOTTOM_NAV.DELIVERY' | translate }}</span>
                </a>
            </li>
            <li>
                <a href="javascript:;" routerLink="/pediasure-naturalvitamin">
                    <img class="vitaminImg" src="../../../assets/images/vitaminwhiteiconsmall.png" *ngIf="!showVitamin" alt="trace" />
                    <img class="vitaminImg" src="../../../assets/images/vitaminwhiteactive.png" *ngIf="showVitamin" alt="" />

                    <span>{{ 'PEDIASURE_BOTTOM_NAV.ARGININE' | translate }}<br> {{ 'PEDIASURE_BOTTOM_NAV.NATURAL_VITAMIN' | translate }}</span>
                </a>
            </li>
            <li >
                <a href="javascript:;" routerLink="/pediasure-sharecare">
                    <img class="sharCareImg" src="../../../assets/images/sharecarewhiteiconsmall.png" *ngIf="!showShareCare"  alt="" />
                    <img class="deliveryImg" src="../../../assets/images/sharecarewhteactive.png" *ngIf="showShareCare" alt="" />

                    <span>{{ 'PEDIASURE_BOTTOM_NAV.SHARE' | translate }}<br>{{ 'PEDIASURE_BOTTOM_NAV.CARE' | translate }}</span>
                </a>
            </li>
        </ul>
    </div>
</section>
