<app-header-secondary homeLogo="../../../assets/images/homeicon.svg" link="/traceability-similac"></app-header-secondary>
<section class="similacGlobalContainer">
    <div class="topBannerBlock"></div>
    <div class="similacInnerBlock pb50">
        <div class="card">
            <img class="img-fluid topImg" *ngIf="ValueSelected == '' || ValueSelected == 'undefined' || ValueSelected == 'en_US'" src="../../../../assets/images/similacdelivery.png">
            <img class="img-fluid topImg" *ngIf="ValueSelected == 'bh' " src="../../../../assets/images/bm/header_delivery.png">
            <img class="img-fluid topImg" *ngIf="ValueSelected == 'ch' " src="../../../../assets/images/cn/header_delivery.png">

            <p class="contentMediumSmall mt-2">{{ 'SIMILAC_DELIVERY.DESC1' | translate }}</p>
            <div class="px30 text-center">
                <p class="arrivedAndClearance">{{ 'SIMILAC_DELIVERY.CUSTOM' | translate }}</p>
                <div class="dateBlockArriveandClearance">
                    <p class="mb-0">{{ customClearanceDate }}</p>
                </div>
                <p class="arrivedAndClearance mt-4">{{ 'SIMILAC_DELIVERY.ARRIVE' | translate }} </p>
                <div class="dateBlockArriveandClearance">
                    <p class="mb-0">{{ arrivalDate }}</p>
                </div>
            </div>
        </div>
        <div class="latestBreakThroughBlock bgBlue text-center mt40">
            <p class="mb-0">{{ 'LATEST_BREAKTHROUGH.INTRO'| translate }}<span class="orangeText">{{ 'LATEST_BREAKTHROUGH.2FL'| translate }}</span> {{ 'LATEST_BREAKTHROUGH.LATEST'| translate }}</p>
            <p class="mb-0" [hidden]="hideInChinese || hideInBahasa">{{ 'LATEST_BREAKTHROUGH.BREAKTHROUGH'| translate }}</p>
            <img class="img-fluid mt-4 mb-4 d-block" *ngIf="ValueSelected == '' || ValueSelected == 'undefined' || ValueSelected == 'en_US'" src="../../../../assets/images/world1st_2FL_icon.png">
            <img class="img-fluid mt-4 mb-4 d-block" *ngIf="ValueSelected == 'bh' " style ="width:100%" src="../../../../assets/images/bm/2fl.png">
            <img class="img-fluid mt-4 mb-4 d-block" *ngIf="ValueSelected == 'ch' " style ="width:100%" src="../../../../assets/images/cn/2fl.png">
            <button class="btn buttonOutlineBlue" routerLink="/similac-nucleotides">{{ 'LATEST_BREAKTHROUGH.LEARN_MORE'| translate }}</button>
        </div>
    </div>
    <a href="https://loyalty.abbottmalaysia.com.my/">
        <img class="img-fluid w-100" *ngIf="ValueSelected == '' || ValueSelected == 'undefined' || ValueSelected == 'en_US'" src="../../../../assets/images/beaparty.png">
        <img class="img-fluid w-100" *ngIf="ValueSelected == 'bh' " src="../../../../assets/images/bm/ascw.png">
        <img class="img-fluid w-100" *ngIf="ValueSelected == 'ch' " src="../../../../assets/images/cn/ascw.png">
    </a>
    <app-footer></app-footer>
</section>
<app-similac-bottomnav></app-similac-bottomnav>
