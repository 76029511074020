<div class="ml-6">
    <label class="text-white">
      Language
    </label><br>
      <select #langSelect (change)="selectedLanguage(langSelect.value)" [(ngModel)]="selectedVal">
        <option value="en_US">English</option>
        <option value="bh">Bahasa</option>
        <option value="ch">简体中文</option>
      </select>
</div>
