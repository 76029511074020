import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-points-status',
  templateUrl: './points-status.component.html',
  styleUrls: ['./points-status.component.scss'],
})
export class PointsStatusComponent implements OnInit {
  status = 'pending';
  customerData: any;
  customerName: string;
  customerNamePresent: any = false;
  loyaltyPoints: any;
  pointsList: any;
  totalLength: any;
  productList = [];
  userPoints: any;
  user: any;
  findDay: any;
  rewardList: any;
  nearestListReward: any;
  lessPointvoucher: any;
  lastTranasction: any;
  measurementData: any;
  hideCont: boolean = false;
  hideCont1: boolean = false;
  noData: any;
  webquloPointData: any;
  promotionalPointsList: any;
  finalFilteredobj: any;
  referralList: any;
  findGrothdairyflag: any;

  lastTransactionDate: any = '';
  rejectReason: any;
  @ViewChild('reasonModal', { static: true }) reasonModal: ModalDirective;
  @ViewChild('reUploadImageModal', { static: true })
  reUploadImageModal: ModalDirective;
  selectedId: any;
  tabId: any = 'allStatus';
  setBtnToBlue: boolean = false;
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private location: Location, 
    private bsService: BugsnagService
  ) {}

  ngOnInit(): void {
    // this.status = 'pending';
    this.getCustomer();
    this.getAllPoints();
    this.getWebquloPointDetail();
    this.getWebquloMeasurement();
  }
  onClickBack() {
    this.location.back();
  }

  onClickPill(id) {
    this.tabId = id;
  }

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    this.spinner.show();
    this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
      this.spinner.hide();
      if (res['status'].code == 200) {
        // Block the user
        let block = res['customers']['customer'][0].custom_fields.field;
        let blockUser = block.filter((filt) => {
          return filt.name == 'block_count';
        });
        console.log(blockUser, 'blockUser===');
        if (blockUser[0]?.value == '1') {
          this.spinner.hide();
          sessionStorage.clear();
          this.router.navigate(['/block-notice']);
        }
        let findgroth = res['customers']['customer'][0].custom_fields?.field;
        console.log(findgroth, 'custom fields');
        findgroth.filter((dataa) => {
          //console.log(dataa?.name == 'growthdiaryflag', 'growthdiaryflag===')
          if (dataa?.name == 'growthdiaryflag')
            this.findGrothdairyflag = dataa?.name == 'growthdiaryflag';
          console.log(this.findGrothdairyflag, 'growthdiaryflag===');
        });
        //  this.findGrothdairyflag =  findgroth.filter(dataa => console.log(dataa[0]?.name == 'growthdiaryflag'))
        //  console.log(this.findGrothdairyflag, 'this.findGrothdairyflag---')
        console.log(res['customers']['customer'][0].user_id, 'used id---');
        sessionStorage.setItem(
          'abbottUserId',
          res['customers']['customer'][0].user_id
        );
        this.user = res['customers']['customer'][0];
        this.userPoints = res['customers']['customer'][0]?.loyalty_points
          ? res['customers']['customer'][0]?.loyalty_points
          : '0';

        let promotionalPoints =
          res['customers']['customer'][0].promotion_points?.customer?.item;
        this.promotionalPointsList = promotionalPoints;
        console.log(promotionalPoints?.length, 'promotionalPoints ==');
        console.log('Referral result', res);
        //console.log("Promotional type", promotionalPoints)
        let referPointsArray = [];
        promotionalPoints.forEach((item) => {
          var referString = 'REFERRAL_CAMPAIGN';
          //var dobSrting = "Profile_DOB"
          var tempString1 = item.promotion_name.split('_');
          var tempString2 = tempString1.splice(0, 2);
          var referOutput = tempString2.join('_');
          //console.log("c",referString3)
          if (referOutput == referString) {
            let pointObject = {
              points: item.points,
              date: item.issued_on,
            };
            referPointsArray.push(pointObject);
          }
        });
        //console.log("referPointsArray", referPointsArray);
        this.referralList = referPointsArray;
        console.log(this.referralList, 'this.referralList');
      }
    }, err => {
      this.bsService.notifyError(err);
      console.log(mobile, '=== get customer mobile =======');
      console.log(err?.error?.message, '=== get customer error message =======');
      console.log(err?.error?.code, '=== get customer error code =======');
    });
  }

  getAllPoints() {
    console.log(this.productList, 'productssssss');

    let mobile = sessionStorage.getItem('mobileNo');
    this.spinner.show();

    this.apiService
      .getProductsList(mobile)
      .pipe(first())
      .subscribe((res) => {
        console.log(res, 'result');

        this.spinner.hide();
        this.pointsList = res['result'];
        this.totalLength = this.pointsList.length;

        this.pointsList.forEach((product) => {
          let productItem = {
            id: product?.id,
            name: product.product_name,
            image: product.product_image,
            status: product.receipt_status,
            productCode: product.id,
            submissionDate: moment(product.submission_date).format(),
            points: product.points,
            color: '',
            reason: product.reason,
          };
          // productItem.status = 'awaiting'

          if (productItem.status == 'approved') {
            productItem.status = 'approved';
            productItem.color = '#30C878';
            if (productItem.points == null) {
              productItem.points = '';
            } else {
              productItem.points = '+' + productItem.points + ' ' + 'pts';
            }
          } else if (productItem.status == 'pending') {
            productItem.status = 'pending';
            productItem.points = '';
            productItem.color = '#8b8f91';
          } else if (productItem.status == 'rejected') {
            productItem.status = 'rejected';
            productItem.points = '';

            productItem.color = '#fe2121';
          } else if (productItem.status == 'awaiting') {
            productItem.status = 'awaiting';
            productItem.color = '#EFBE35';
            productItem.points = '';
          }

          this.productList.push(productItem);
        });
        console.log(this.productList);
        let productListLength = this.productList?.length;
        if (productListLength > 0) {
          this.lastTransactionDate = this.productList[0].submissionDate;
          console.log(moment(this.lastTransactionDate).format('DD-MM-YYYY'));
        } else {
          this.lastTransactionDate = '';
        }
      }),
      (err) => {
        console.log(err);
        this.spinner.hide();
        this.bsService.notifyError(err);
      };
  }

  getWebquloMeasurement() {
    this.spinner.show();
    let abbott_user_id = sessionStorage.getItem('abbottUserId');
    let getMobile = sessionStorage.getItem('mobileNo');
    let formData = {
      'user_id': abbott_user_id,
    };
    this.apiService.getWebqloMeasurement(formData).subscribe((res: any) => {
      console.log(res, 'webQulo data');
      this.spinner.hide();
      if (res.result == 'User not registered in growth diary.') {
        this.noData = 'User not registered in growth diary.';
      } else if (res.result == 'Invalid user ID.') {
        this.noData = 'Invalid user ID.';
      } else if (res.result == '') {
        this.noData = 'No Measuredata';
      }
      this.measurementData = res.result;
      console.log(this.measurementData[0]?.name, 'length');

      console.log(this.measurementData?.length, 'this.measurementData');
      if (this.measurementData.length == 0) {
        this.hideCont1 = true;
        console.log(this.hideCont1, 'this.hideCont');
      }
    }, err => {
      this.bsService.notifyError(err, formData);
    });
  }

  getWebquloPointDetail() {
    this.spinner.show();
    let abbott_user_id = sessionStorage.getItem('abbottUserId');
    let getMobile = sessionStorage.getItem('mobileNo');
    let formData = {
      'user_id': abbott_user_id,
    };
    this.apiService.getWebqloPointdetail(formData).subscribe((res: any) => {
      console.log(res, 'webQulo Point data ====');
      this.spinner.hide();
      this.webquloPointData = res.result;
      console.log(this.webquloPointData.length, 'this.webquloPointData.length');
      if (this.webquloPointData.length == 0) {
        this.hideCont = true;
        console.log(this.hideCont, 'this.hideCont');
      }
    }, err => {
      this.bsService.notifyError(err), formData;
    });
  }

  prductCode: any;
  onclickUploadReceipt(productCode) {
    this.prductCode = productCode;
    console.log(this.prductCode, 'this.prductCode ');
    let data = {
      data: this.prductCode,
    };
    sessionStorage.setItem('reUpload', 'Uploaded');

    this.router.navigate(['/validate-receipt', data]);
  }
  closeReasonModal() {
    this.reasonModal.hide();
  }
  onClickViewDetails(reason) {
    this.rejectReason = reason;
    console.log(this.rejectReason);
    this.reasonModal.show();
  }
  openReUploadImageModal(id) {
    this.reUploadImageModal.show();
    console.log(id);
    this.selectedId = id;
  }
  closeReUploadImageModal() {
    this.reUploadImageModal.hide();
  }
  onclickReUploadImageReceipt() {
    // this.prductCode = productCode;
    // console.log(this.prductCode, 'this.prductCode ');
    let data = {
      data: this.selectedId,
    };
    this.router.navigate(['/validate-receipt', data]);
  }
}
