<nav class="headerMenu headerSecondary pb-0">
    <div class="custom-container">
        <div class="row align-items-center m-0 topHeader">
            <div class="col-6">
                <div class="row m-0 align-items-center">
                    <div class="logo col-8 pr-0 pl-0">
                        <img src="../../../assets/images/logo-new.png" alt="logo" />
                    </div>
                </div>

            </div>
            <div class="col-6 d-flex justify-content-end">
                <div class="shareCareWord text-right col-4 pr-0">
                    <img class="max-width-100" src="../../../assets/images/headerlogosharecare.png"
                        alt="share care world" />
                </div>
            </div>
        </div>
    </div>
</nav>