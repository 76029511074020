<app-header homeLogo="../../../assets/images/homeicon.svg" link="/traceability-ensure"></app-header>
<section class="ensureGlobalContainer">
    <div class="bannerBlockTop">
        <img class="img-fluid" src="../../../../assets/images/ensuredarkbluebannerwithproduct.png">
        <img class="titleImage" src="../../../../assets/images/ensuredelivery.png">
    </div>
    <div class="ensureInnerBlock bgDarkBlue pb30 _h68vh">
        <div class="card goldenStripBorder text-center pb50">
            <p class="contentMediumSmall mb-0 mt20">{{ 'DELIVERY.AUTHENTIC_LINE1' |  translate }}</p>
            <p class="contentMediumSmall mb-0">{{ 'DELIVERY.AUTHENTIC_LINE2' |  translate }}</p>
            <p class="mt40 arrivedAndClearance mb-0">{{ 'DELIVERY.CUSTOM_CLEARANCE' |  translate }}</p>
            <div class="dateBlock">
                <p class="mb-0">{{customClearanceDate}}</p>
            </div>
            <p class="mt40 arrivedAndClearance mb-0">{{ 'DELIVERY.ARRIVED' |  translate }} </p>
            <div class="dateBlock">
                <p class="mb-0">{{arrivalDate}}</p>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</section>

<app-ensure-bottomnav></app-ensure-bottomnav>
