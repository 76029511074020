<app-header-primary></app-header-primary>
<section class="blueBg _h100vh">
    <div class="whiteBg mt100">
        <div class="formOut">
            <h4 class="formTitle text-center text-uppercase">change password</h4>
            <form class="mt40" [formGroup]="changePasswordForm" (ngSubmit)="onSubmitChangePasswordForm()" *ngIf="showChangePasswordForm">
                <div class="form-group">
                    <label>current password*</label>
                    <input type="password" class="form-control" formControlName="currentPassword" (keydown)="passwordError($event)"
                        [ngClass]="{ 'is-invalid': submittedChangePasswordForm && changePasswordFval.currentPassword.errors }" />
                    <div *ngIf=" submittedChangePasswordForm && changePasswordFval.currentPassword.errors" class="invalid-feedback">
                        <div *ngIf="changePasswordFval.currentPassword.errors.required">Enter current password </div>
                    </div>
                    <div class="text-danger" *ngIf="submittedChangePasswordForm && passworderror">
                        Your old password is incorrect.
                    </div>
                </div>
                <div class="form-group mt30 mb-0">
                    <label>enter new password* </label>
                    <input type="password" class="form-control" formControlName="newPassword"
                        [ngClass]="{ 'is-invalid': submittedChangePasswordForm && changePasswordFval.newPassword.errors }" />
                    <div *ngIf=" submittedChangePasswordForm && changePasswordFval.newPassword.errors" class="invalid-feedback">
                        <div *ngIf="changePasswordFval.newPassword.errors.required">Enter new password </div>
                    </div>
                </div>
                <p class="contentDarkBlue ml-3">Password must have minimum 8 characters, contain at least 
                    1 numeric character and 1 special character.</p>
                <div class="form-group mt30">
                    <label>confirm new password*</label>
                    <input type="password" class="form-control" formControlName="confirmNewPassword" (keydown)="confirmPasswordError($event)"
                        [ngClass]="{ 'is-invalid': submittedChangePasswordForm && changePasswordFval.confirmNewPassword.errors }" />
                    <div *ngIf=" submittedChangePasswordForm && changePasswordFval.confirmNewPassword.errors" class="invalid-feedback">
                        <div *ngIf="changePasswordFval.confirmNewPassword.errors.required">Enter Confirm new password </div>
                        <div *ngIf="changePasswordFval.confirmNewPassword.errors.mustMatch">Password confirmation does not match
                        </div>
                    </div>
                    <div class="text-danger" *ngIf="submittedChangePasswordForm && oldPassworderror">
                        The new password and the old password must not be the same.
                    </div>
                </div>
                <div class="text-center mt30">
                    <button type="submit" class="blueBtn mb-3"
                        [ngClass]="{'isValidSubmit': changePasswordForm.valid }">Reset my password</button>
                </div>
            </form>

            <form class="mt40" [formGroup]="changePasswordOtpForm" (ngSubmit)="onSubmitChangePasswordOtpForm()"  *ngIf="showChangePasswordOtpForm">
               
                <div class="form-group mt30 text-center">
                    <!-- <label>Enter a 6 digit otp</label>
                    <input type="password" class="form-control" formControlName="otp" (keydown)="otpInput($event)"
                        [ngClass]="{ 'is-invalid':submittedChangePasswordOtpForm && changePasswordOtpFval.otp.errors }" />
                    <div *ngIf="submittedChangePasswordOtpForm && changePasswordOtpFval.otp.errors" class="invalid-feedback">
                        <div *ngIf="changePasswordOtpFval.otp.errors.required">Enter otp </div>
                        <div class="text-left" *ngIf="changePasswordOtpFval.otp.errors.pattern ||  changePasswordOtpFval.otp.errors.pattern">
                            Invalid Otp
                        </div>
                        <div *ngIf="changePasswordOtpFval.otp.errors.minlength">otp should be 6 characters</div>
                        <div *ngIf="changePasswordOtpFval.otp.errors.maxlength">otp should be 6 characters</div>
                    </div>
                    <div *ngIf="invalidOtp" class="invalidText">
                        <div>Invalid Otp</div>
                    </div> -->
                    <p class="mb-0 contentSmall">OTP has been sent to your mobile number</p>
                    <p class="mb-0 contentSmall">Please enter it below:</p>
                    <div class="text-center mt-3" *ngIf="!showResendOtp">
                        (<span class="contentSmall" *ngIf="beginTimer">
                            Resend Otp in
                            <countdown #cd [config]="{leftTime: 60,format: 'mm:ss',notify: [ 2, 5 ]}"
                                (event)="handleEvent($event)"></countdown>
                        </span>)
                        <br>
                    </div>
                    <ng-otp-input (onInputChange)="onOtpChange($event)"
                        [config]="{length:6,placeholder:'0',allowNumbersOnly: true}"
                        [ngClass]="{'is-invalid' : submittedChangePasswordOtpForm && (otp == undefined || otp.length < 6) || invalidOtp }"
                        #ngOtpInput>
                    </ng-otp-input>
                    <div *ngIf="submittedChangePasswordOtpForm && changePasswordOtpFval.otp.errors" class="text-danger pl-0">
                        <div *ngIf="!activeClassButton">Please Enter OTP</div>
                    </div>
                    <div *ngIf="invalidOtp" class="text-danger pl-0">
                        <div>The entered OTP is incorrect</div>
                    </div>
                </div>

                <div class="text-center" *ngIf="showResendOtp">
                    <button type="submit" class="blueBtn text-white" (click)="resendOTP()">Resend</button>
                </div>

                <div class="text-center mt-4">
                    <button type="submit"
                    [ngClass]="!activeClassButton ? 'lightBlueBtn mb-3 mt-3' : 'blueBtn mb-3 mt-3'">submit</button>
                </div>
                <div class="text-center mt-4">
                    <p class="contentSmall mb-2">Didn't receive otp?</p>
                    <button *ngIf="!showResendOtp" class="lightBlueBtn" type="button">Resend otp</button>
                    <button *ngIf="showResendOtp" class="blueBtn" type="button" (click)="resendOTP()">Resend otp</button>
                </div>
                <div class="text-center mt-4">
                    <button class="blueBtn text-uppercase" type="button" (click)="onClickChangeNumber()">Change Number</button>
                </div>
            </form>
        </div>
    </div>
</section>