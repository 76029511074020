<app-header-secondary homeLogo="../../../assets/images/homeicon.svg" link="/traceability-similac"></app-header-secondary>
<section class="similacGlobalContainer">
    <div class="topBannerBlock"></div>
    <div class="similacInnerBlock pb50">
        <div class="card">
            <img class="img-fluid topImg" *ngIf="ValueSelected == '' || ValueSelected == 'undefined' || ValueSelected == 'en_US'" src="../../../../assets/images/milksourcrlogonew.png">
            <img class="img-fluid topImg" *ngIf="ValueSelected == 'bh' " src="../../../../assets/images/bm/header_milk.png">
            <img class="img-fluid topImg" *ngIf="ValueSelected == 'ch' " src="../../../../assets/images/cn/header_milk1.jpg">
   
            <p class="contentMediumSmall mt-2">{{ 'SIMILAC_MILKSOURCE.DESC1' | translate }}</p>
            <img class="img-fluid w-100" src="../../../../assets/images/cowlooksimage.png"/>
            <p class="contentMediumSmall mt-3">{{ 'SIMILAC_MILKSOURCE.DESC2' | translate }}</p>
            <img class="img-fluid w-100" src="../../../../assets/images/cowgroundimage.png">
            <p class="contentMediumSmall mt-3">{{ 'SIMILAC_MILKSOURCE.DESC3' | translate }}</p>
        </div>
        <div class="keepChildBlock text-center mt60">
            <p class="mb-0">{{ 'SIMILAC_MILKSOURCE.KEEP_CHILD' | translate }}</p>
            <p class="mb-0">{{ 'SIMILAC_MILKSOURCE.FASTER' | translate }}</p>

            <img class="mt-3 d-block" src="../../../../assets/images/usewithshieldIcon.png">

            <button class="buttonOutlineBlue mt-4" routerLink="/similac-whychoose"> {{ 'SIMILAC_MILKSOURCE.WHY_SIMILAC' | translate }}</button>
        </div>
    </div>
    <a href="https://loyalty.abbottmalaysia.com.my/">
        <img class="img-fluid w-100" *ngIf="ValueSelected == '' || ValueSelected == 'undefined' || ValueSelected == 'en_US'" src="../../../../assets/images/beaparty.png">
        <img class="img-fluid w-100" *ngIf="ValueSelected == 'bh' " src="../../../../assets/images/bm/ascw.png">
        <img class="img-fluid w-100" *ngIf="ValueSelected == 'ch' " src="../../../../assets/images/cn/ascw.png">
   
    </a>
    <app-footer></app-footer>
</section>
<app-similac-bottomnav></app-similac-bottomnav>
