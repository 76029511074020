import { HTTP_INTERCEPTORS, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { environment } from 'src/environments/environment';

const TOKEN_HEADER_KEY = "cap_authorization";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(
    private apiService: ApiService
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    let reqClone = req.clone();
    if(req.url.includes(environment.API_BASE_URL)){
    //if (!req.url.includes('/token/generate') && !req.url.includes('/password/validate') && !req.url.includes('/token/regenerate') && !reqClone.url.includes('/otp/generate')){
      const authToken = sessionStorage.getItem('authToken') ? sessionStorage.getItem('authToken') : "";
      reqClone = req.clone({ headers: req.headers.set("cap_authorization", authToken)});
    }


    return next.handle(reqClone).pipe(map(evt => {
      if (evt instanceof HttpResponse) {
          if(evt.body.status && evt.body.status.success === false){
            if (req.url.includes(environment.API_BASE_URL) && evt.body.status.code === 401) {
              throw new HttpErrorResponse({
                error: 'your error',
                headers: evt.headers,
                status: 401,
                statusText: 'Warning',
                url: evt.url
            });
          }
        }
      }
      return evt;
    }), catchError(error => {

      const authKey = sessionStorage.getItem('authKey');
      if (error instanceof HttpErrorResponse && req.url.includes(environment.API_BASE_URL) && error.status === 401 && authKey) {
        return this.handle401Error(reqClone, next);
      }
      return throwError(error);
    }));
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      let tokenReGeneratePayload = {
        mobile: sessionStorage.getItem('mobileNo'),
        deviceId: environment.DEVICE_ID,
        brand: environment.BRAND,
        key: sessionStorage.getItem('authKey')
      };
      this.refreshTokenSubject.next(null);
      return this.apiService.tokenReGenerate(tokenReGeneratePayload).pipe(
        switchMap((resp: any) => {
          this.refreshTokenSubject.next(resp.auth.token);
          return next.handle(this.addTokenHeader(request, resp.auth.token));
        }),
        catchError((err) => {
          this.isRefreshing = false;
          return throwError(err);
        })
      );
    }
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => {
        sessionStorage.setItem("authToken", token);
        return next.handle(this.addTokenHeader(request, token))
      })
    );
  }
  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, token) });
  }
}