// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  AUTH_BASE_URL: 'https://apac-s3wrapper.capillarytech.com',
  WRAPPER_API: 'https://abbott-ph-api-dev.sttarter.com/api/v1',
  API_BASE_URL: 'https://apac-apigateway.capillarytech.com/mobile/v2/api',
  SALESFORCE_BASE_URL: ' https://abbott-ph-api-dev.sttarter.com/api/v1',
  CMS_URL:
    'https://api.sttarter.com/contentsystem/d3eaf7a8067373ea08c47134748d7abf',
  API_URl_TRACEBILITY: 'https://abbott-ph-api-dev.sttarter.com/api',
  IMAGE_UPLOAD: 'https://apac-apigateway.capillarytech.com/mobile/v2/',
  EMAIL_CHECK_API: 'https://abbott-ph-api-dev.sttarter.com/api/v1',
  BRAND: 'ABBOTTALLDEMO',
  DEVICE_ID: '123454321',
  IDENTIFIER_TYPE: 'MOBILE',
  // TILL_ID:"abbottphtemppage",
  TILL_ID: 'abbottdemomalaysia',

  NUTRITION_CUST_ADD_URL: 'https://cap-intouch-in.sttarter.com',
  NUTRITION_TILL_ID: 'nutrition',
  STTARTER_TERMS_FAQ:
    'https://api.sttarter.com/contentsystem/62b8b17263017ed475b3bfe42ec6a2b2',

  auth_key: 'WLQ7eRcvLNSb0Z3hSAYIswPuf',
  auth_secret: 'FfuXJFW5nW7zovCVVnDCmLF2TV7sUd',
  webqloURL: 'https://pediasure-growth-staging.uidhosting.com',
  ENV:'DEMO',
  LICENSEKEY: "ATZykTPLETn2O0a3pAfvP1Yy9nr4HtjPqEkcHFVX984EOyg4gVr757Zeu5MQZVv+/lEBfk5OJ1ikRqg1u3utPPhwle1DdKmKMjW5r5tTLUqIVbGt0XjGdU5QuTXzKwzN0W8bR+pwc7cubzMMlBfLkyNFa7VpaOZ651RR9mR2rW7dTGN81hE2elwXPAyRIVBcXAOsAgzmQVVduhNeGyMksIqNSaUzQSZh8/aWiOa4L2vcqhOX7lq3LkdqyQqqU63Jz2rN6D250yWGesCp/1nfO1vu51o+a8WagJXiw90aNJe82aWJk/w1SIdvaUE69gGzh9R9MtIBbL9f/MzG8ylLChpZ8FUrcDdWkO9md846udPI4P1hi4SW4MQ6doh3ksYAv7NLv9ECAUFQeGErihnsiDkymrKSH5PnNLRLdCiWd94RmE6xpoY3wobcJETVZh/u7QMvL+I5TT7K9dVvEJi0SUQ7hfPjTTaeFLem6nS46M83+Jcd9i5g3jnUNJJlWBSLweCUmbopd/XkuFm0W6em4tcnI/cz7cPC2NKlwIMXs3R54Z7gKc0w0cmDRZGDXIBTj/lliHODUjP8hUk4y9ISdozZWE+5KhmB17Fb1Fv8TIph4GNy2YdYhhFzBSZcY5SzzgG4n+KPSOifR0gcqFyZU6+6DzjlSQmA7Ja2FDvepPVNDxKx7RgwAxT25V64OIaR6OYjILo1ShQpT631LvHC9D/DNTKgZbr86rmvxs6oDCkLtM9M7AILHcb+yEhU3z0ODVoB0f41fsG/hDNyJ9yy7Mq2SHmbOI11DXOUr6PuVieV5kAM3jMLmVrqehs8+fVx1/n56ZXyxK7N1Pek99Sa4IzSeaaGJzsur4iczUL58He0Hlck",
  webQlo_api_key: 'kFhq5xcXzsHJrqMoptDdqsfNt',
  webQlo_api_secret: 'gkKcn642fEWggagJBjulbHjmjz3VED18RSYUDgnD',
  COUNTRY_CODE: 'MY',
  LANGUAGE: 'en_US',
  REWARD_ID1: '1707',
  REWARD_ID2: '1706',
  SITE_KEY: "6LcV2mQbAAAAALcsfVCxf7zChESp1v-1WOT3JOFB",
  bugsnag_version: '23092022',
  MOBILE_APP_KEY: "85a4447a3b438fbb6e33e5c0428460d3"
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
