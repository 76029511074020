<app-header homeLogo="../../../assets/images/homeicon.svg" link="/traceability-pediasure"></app-header>
<section class="pediaSureGlobalContainer">
    <div class="pediaSureInnerBlock">
        <div class="card mt30">
            <div class="px30 text-center pb90">
                <img class="img-fluid topImgPrimary" src="../../../../assets/images/pediadeliverygold.png">
                <p class="fontGBMediumSmall mb-0 mt40">{{ 'PEDIA_DELIVERY.AUTHENTIC_LINE1' | translate }}</p>
                <p class="fontGBMediumSmall mb-0">{{ 'PEDIA_DELIVERY.AUTHENTIC_LINE2' | translate }}</p>
                <p class="mt40 arrivedAndClearance mb-0">{{ 'PEDIA_DELIVERY.CUSTOM_CLEARANCE' | translate }}</p>
                <div class="dateBlock">
                    <p class="mb-0">{{customClearanceDate}}</p>
                </div>
                <p class="mt40 arrivedAndClearance mb-0">{{ 'PEDIA_DELIVERY.ARRIVED' | translate }} </p>
                <div class="dateBlock">
                    <p class="mb-0">{{arrivalDate}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="pediaBottomImageBlock">
        <div class="learnMoreBlock">
        <button class="learnMoreBtn text-uppercase" routerLink="/pediasure-naturalvitamin">{{ 'PEDIA_DELIVERY.LEARN_MORE' | translate }}</button>
        </div>  
        <img class="img-fluid" src="../../../../assets/images/pediachildnutrient.png">
    </div>
    <app-footer></app-footer>
</section>
<app-pediasure-bottomnav></app-pediasure-bottomnav>
