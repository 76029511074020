<app-header-secondary homeLogo="../../../assets/images/homeicon.svg" link="/traceability-similac"></app-header-secondary>
<section class="similacGlobalContainer">
    <div class="topBannerBlock"></div>
    <div class="similacInnerBlock pb50">
        <div class="card">
            <img class="img-fluid topImg" *ngIf="ValueSelected == '' || ValueSelected == 'undefined' || ValueSelected == 'en_US'" src="../../../../assets/images/similacqualitycontrol.png">
            <img class="img-fluid topImg" *ngIf="ValueSelected == 'bh' " src="../../../../assets/images/bm/header_quality.png">
            <img class="img-fluid topImg" *ngIf="ValueSelected == 'ch' " src="../../../../assets/images/cn/header_quality.png">
   
            <div class="dateBlock text-center mt-2">
                <p class="mb-0">{{ 'SIMILAC_QUALITYCONTROL.EXPIRY_DATE' | translate }} {{ expiryDate }}</p>
            </div>
            <p class="contentMediumSmall mt-3">{{ 'SIMILAC_QUALITYCONTROL.DESC1' | translate }}</p>
            <img class="img-fluid w-100" src="../../../../assets/images/labqualitytestimage.png">
            <p class="contentMediumSmall mt-2">{{ 'SIMILAC_QUALITYCONTROL.DESC2' | translate }}</p>
        </div>
        <div class="bottomContentBlock text-center mt-4">
            <p class="mb-0">{{ 'SIMILAC_QUALITYCONTROL.SWITCH' | translate }}</p>
            <p class="mb-0" [hidden]="hideInChinese">{{ 'SIMILAC_QUALITYCONTROL.BETTER' | translate }}</p>
            <img class="mt-2" *ngIf="ValueSelected == '' || ValueSelected == 'undefined' || ValueSelected == 'en_US'" src="../../../../assets/images/switchroundedimage.png">
            <img class="mt-2" *ngIf="ValueSelected == 'bh'" style="width:175px;" src="../../../../assets/images/bm/switch.png">
            <img class="mt-2" *ngIf="ValueSelected == 'ch'" style="width:175px;" src="../../../../assets/images/cn/switch.png">
            <div>
               <button class="buttonOutlineBlue mt-3" routerLink="/similac-whychoose">{{ 'SIMILAC_QUALITYCONTROL.WHY_SIMILAC' | translate }}</button>
            </div>
        </div>
    </div>
    <a href="https://loyalty.abbottmalaysia.com.my/">
        <img class="img-fluid w-100" *ngIf="ValueSelected == '' || ValueSelected == 'undefined' || ValueSelected == 'en_US'" src="../../../../assets/images/beaparty.png">
        <img class="img-fluid w-100" *ngIf="ValueSelected == 'bh' " src="../../../../assets/images/bm/ascw.png">
        <img class="img-fluid w-100" *ngIf="ValueSelected == 'ch' " src="../../../../assets/images/cn/ascw.png">
   
    </a>
    <app-footer></app-footer>
</section>
<app-similac-bottomnav></app-similac-bottomnav>
