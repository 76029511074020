<section class="ensureNavMenu">
  <div class="card">
    <ul>
      <li *ngIf="productInfo.is_isprint == true">
        <a
          href="javascript:;"
          routerLinkActive="active"
          routerLink="/ensure-ingredients"
        >
          <img
            class="sourceImg"
            src="../../../assets/images/sourceiconensuresmall.png"
            *ngIf="!showMilkSource"
            alt="home"
          />
          <img
            class="sourceImg"
            src="../../../assets/images/soucriconensureactive.png"
            *ngIf="showMilkSource"
            alt="home"
          />

          <span
            >{{ "ENSURE_NAV.LINK_DESC1_LINE1" | translate }}<br />
            {{ "ENSURE_NAV.LINK_DESC1_LINE2" | translate }}</span
          >
        </a>
      </li>
      <li>
        <a href="javascript:;" routerLink="/ensure-manufacturing">
          <img
            class="manufacturingImg"
            src="../../../assets/images/manufacturinciconensuresmall.png"
            alt=""
            *ngIf="!showManufacturing"
          />
          <img
            class="manufacturingImg"
            src="../../../assets/images/manufactureensureactive.png"
            *ngIf="showManufacturing"
            alt=""
          />

          <span>{{ "ENSURE_NAV.LINK_DESC2" | translate }}</span>
        </a>
      </li>
      <li>
        <a href="javascript:;" routerLink="/ensure-qualitycontrol">
          <img
            class="qualityControlImg"
            src="../../../assets/images/qualityiconensuresmall.png"
            *ngIf="!showQualityControl"
            alt=""
          />
          <img
            class="qualityControlImg"
            src="../../../assets/images/qualityensureactive.png"
            *ngIf="showQualityControl"
            alt=""
          />

          <span
            >{{ "ENSURE_NAV.LINK_DESC3_LINE1" | translate }}<br />{{
              "ENSURE_NAV.LINK_DESC3_LINE2" | translate
            }}</span
          >
        </a>
      </li>
      <li *ngIf="productInfo.is_isprint == true">
        <a href="javascript:;" routerLink="/ensure-delivery">
          <img
            class="deliveryImg"
            src="../../../assets/images/ensuredelivericonsmall.png"
            *ngIf="!showDelivery"
            alt="trace"
          />
          <img
            class="deliveryImg"
            src="../../../assets/images/clearancective.png"
            *ngIf="showDelivery"
            alt="trace"
          />

          <span
            >{{ "ENSURE_NAV.LINK_DESC4_LINE1" | translate }}<br />
            {{ "ENSURE_NAV.LINK_DESC4_LINE2" | translate }}</span
          >
        </a>
      </li>
      <li>
        <a href="javascript:;" routerLink="/ensure-hmb">
          <img
            class="vitaminImg"
            src="../../../assets/images/protieniconensuresmall.png"
            *ngIf="!showHmb"
            alt=""
          />
          <img
            class="vitaminImg"
            src="../../../assets/images/2flactive.png"
            *ngIf="showHmb"
            alt=""
          />

          <span
            >{{ "ENSURE_NAV.LINK_DESC5_LINE1" | translate }}<br />{{
              "ENSURE_NAV.LINK_DESC5_LINE2" | translate
            }}</span
          >
        </a>
      </li>
      <li>
        <a href="javascript:;" routerLink="/ensure-sharecare">
          <img
            class="sharCareImg"
            src="../../../assets/images/sharecareiconensuresmall.png"
            *ngIf="!showShareCare"
            alt=""
          />
          <img
            class="sharCareImg"
            src="../../../assets/images/sharecaresctive.png"
            *ngIf="showShareCare"
            alt=""
          />

          <span
            >{{ "ENSURE_NAV.LINK_DESC6_LINE1" | translate }}<br />{{
              "ENSURE_NAV.LINK_DESC6_LINE2" | translate
            }}</span
          >
        </a>
      </li>
    </ul>
  </div>
</section>
