<app-header-primary></app-header-primary>
<section class="blueBg _h100vh positon-relative">
  <div class="codeBottom text-center">
    <p>MAL.2021.19812.CORP.1 (v1.0)</p>
  </div>
  <div class="whiteBg mt100 h420px">
    <div class="row h-100 align-items-center textBlockOut">
      <div class="col-12 text-center">
        <h3>SOMETHING NEW & EXCITING</h3>
        <p class="boldContent mt30">COMING YOUR WAY SOON!</p>
        <p class="boldContentSmall mt30 contactInfo">
          WE’RE CURRENTLY UNDERGOING AN UPGRADE TO SERVE YOU BETTER. PLEASE GET
          IN TOUCH WITH OUR ABBOTT NUTRITION CARELINE AT
          <a href="tel:1800-88-6233"> 1800-88-6233</a> FOR ASSISTANCE.
        </p>
      </div>
    </div>
  </div>
  <div class="-mx-20">
    <app-footer></app-footer>
  </div>
</section>
