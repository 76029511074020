<app-header homeLogo="../../../assets/images/homeicon.svg" link="/traceability-ensure"></app-header>
<section class="ensureGlobalContainer">
    <div class="bannerBlockTop">
        <img class="img-fluid w-100" src="../../../../assets/images/ensuresharecarewhitebannerimagenew.png">
        <img class="titleImage shareCareImg" src="../../../../assets/images/ensureabbotshrecaretextimage.png">
    </div>
    <div class="ensureInnerBlock bgDarkBlue pb30">
        <div class="card secondaryCard px-0 mt40">
            <div class="topDateBlock bgDarkBlue singleDateBlock ml25 mr25">
                <!-- <p class="text-center mb-0">{{ 'ENSURE_SHARE_CARE.EXPIRY_DATE' | translate }} {{expiryDate}}</p> -->
            </div>
            <div class="px30 mt-4">
                <p class="contentMediumSmall">{{ 'ENSURE_SHARE_CARE.DESC1' | translate }}</p>
                <p class="contentMediumSmall">{{ 'ENSURE_SHARE_CARE.DESC2' | translate }}</p>
            </div>
            <div class="shareCareMiddleBlock">
                <div class="topContentBlock text-center mt-3">
                    <p>{{ 'ENSURE_SHARE_CARE.DESC3' | translate }}</p>
                </div>
                <div class="row mt-4">
                    <div class="col-3 text-center pr-0">
                        <img class="heartImg" src="../../../../assets/images/heartblueoutline.png">
                    </div>
                    <div class="col-9">
                        <p class="content">{{ 'ENSURE_SHARE_CARE.DESC4' | translate }}</p>
                        <p class="content">{{ 'ENSURE_SHARE_CARE.DESC5' | translate }}</p>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-3 text-center pr-0">
                        <img class="mobileImg" src="../../../../assets/images/mobileblueoutline.png">
                    </div>
                    <div class="col-9">
                        <p class="content">{{ 'ENSURE_SHARE_CARE.DESC6' | translate }}</p>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-3 text-center pr-0">
                        <img class="userImg" src="../../../../assets/images/userwithheadphoneoutline.png">
                    </div>
                    <div class="col-9">
                        <p class="content">{{ 'ENSURE_SHARE_CARE.DESC7' | translate }}</p>
                    </div>
                </div>
            </div>
            <div class="row mx-0 mt-3 mb-3">
                <div class="col-4">
                    <img class="rewardImg" src="../../../../assets/images/rewardcircleimg.png">
                </div>
                <div class="col-8 pr-0 mt-2">
                    <p class="mb-0 memberContent">{{ 'ENSURE_SHARE_CARE.MEMBER' | translate }}</p>
                    <p class="mb-0 fabulousContent">{{ 'ENSURE_SHARE_CARE.FABULOUS' | translate }}</p>
                    <button class="joinUsBtn mt-3" routerLink="/login">{{ 'ENSURE_SHARE_CARE.JOIN_US' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</section>
<app-ensure-bottomnav></app-ensure-bottomnav>
