import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { environment as env } from '../../../environments/environment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-redeem-reward-details',
  templateUrl: './redeem-reward-details.component.html',
  styleUrls: ['./redeem-reward-details.component.scss'],
})
export class RedeemRewardDetailsComponent implements OnInit {
  valueCounter: number = 1;
  rewardData: any;
  userPoints;
  pointsExcedeedError: boolean = false;
  pointsExcedeedErrorVal: boolean = false;
  finalCartValue: any;
  pointsExceeded: boolean = false;
  firstReward: boolean = false;
  @ViewChild('confirmMdl', { static: true }) confirmMdl: ModalDirective;
  addressFound: boolean = false;
  showRewardCount: boolean = true;
  constructor(
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService, 
    private bsService: BugsnagService
  ) {
    this.rewardData = this.route.snapshot.params;
    console.log(this.rewardData, 'this.rewardData');
    if (
      this.rewardData?.id == env.REWARD_ID1 ||
      this.rewardData?.id == env.REWARD_ID2
    )
      this.showRewardCount = false;

    console.log('rewardcount:', this.showRewardCount);
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    this.getCustomer();
  }
  onClickBack() {
    this.router.navigate(['/redeem-rewards']);
  }

  onClickOk() {
    let data = {
      data: 'reward',
    };
    this.router.navigate(['/profile', data]);
  }

  isEmpty(value: any) {
    return value == '' || value == null || value == undefined ? false : true;
  }

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    this.spinner.show();
    let getcustresp

    this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
      this.spinner.hide();
      getcustresp = res;
      if (res['status'].code == 200) {
        // Block the user
        let block = res['customers']['customer'][0].custom_fields.field;
        let extended_fields =
          res['customers']['customer'][0].extended_fields.field;
        let blockUser = block.filter((filt) => {
          return filt.name == 'block_count';
        });
        extended_fields.forEach((element) => {
          if (element.name == 'zip' && this.isEmpty(element.value)) {
            console.log(element.name);
            this.addressFound = true;
          }
          if (element.name == 'area' && this.isEmpty(element.value)) {
            console.log(element.name);
            this.addressFound = true;
          }
          if (element.name == 'city' && this.isEmpty(element.value)) {
            console.log(element.name);
            this.addressFound = true;
          }
          if (element.name == 'state' && this.isEmpty(element.value)) {
            console.log(element.name);
            this.addressFound = true;
          }
          if (element.name == 'sub_area' && this.isEmpty(element.value)) {
            this.addressFound = true;
          }
        });
        console.log(this.addressFound, 'address');
        console.log(blockUser, 'blockUser===');
        if (blockUser[0]?.value == '1') {
          this.spinner.hide();
          sessionStorage.clear();
          this.router.navigate(['/block-notice']);
        }
        sessionStorage.setItem(
          'abbottUserId',
          res['customers']['customer'][0].user_id
        );
        this.userPoints = res['customers']['customer'][0]?.loyalty_points
          ? res['customers']['customer'][0]?.loyalty_points
          : '0';
        let userPoints = this.userPoints;
        let rewardPoints = parseInt(this.rewardData?.intouchPoints);
        if (rewardPoints > userPoints) {
          this.pointsExcedeedError = true;
        } else {
          this.pointsExcedeedError = false;
        }
      }
    },err=>{
      this.bsService.notifyError(err);
      this.spinner.hide()
      this.apiService.showErrorMsg('Something went wrong, please try again')
      console.log(err, '=== get customer error =======')
      console.log(mobile, '=== get customer mobile =======');
      console.log(err?.error?.message, '=== get customer error message =======');
      console.log(err?.error?.code, '=== get customer error code =======');
    });

  }

  countIncrement() {
    this.valueCounter = this.valueCounter + 1;
    console.log(this.valueCounter, 'this.valueCounter increment');
    let finalCountValue =
      this.valueCounter * parseInt(this.rewardData?.intouchPoints);
    this.finalCartValue = finalCountValue;
    console.log(this.finalCartValue, 'finalCountValue ++ ');
    let userPoints = this.userPoints;
    let rewardPoints = finalCountValue;
    if (rewardPoints > userPoints) {
      this.pointsExcedeedErrorVal = true;
    } else {
      this.pointsExcedeedErrorVal = false;
    }
  }
  countDecrement() {
    this.valueCounter = this.valueCounter - 1;
    console.log(this.valueCounter, 'this.valueCounter decrement');
    let finalCountValue =
      this.valueCounter * parseInt(this.rewardData?.intouchPoints);
    this.finalCartValue = finalCountValue;
    console.log(this.finalCartValue, 'finalCountValue -- ');
    let userPoints = this.userPoints;
    let rewardPoints = finalCountValue;
    if (rewardPoints > userPoints) {
      this.pointsExcedeedErrorVal = true;
    } else {
      this.pointsExcedeedErrorVal = false;
    }
  }
  closeScannedModal() {
    this.confirmMdl.hide();
  }
  redeemVoucher() {
    this.confirmMdl.show();
  }

  reedem(data: any) {
    this.spinner.show();
    let time = new Date();
    let todayDate = moment(new Date()).format('YYYY-MM-DD HH:MM:SS');
    let getMobile = sessionStorage.getItem('mobileNo');
    let getAuthKey = sessionStorage.getItem('authToken');
    // let formData = {
    //   "transactionNumber": data?.name + ' ' + time.getTime(),
    //   "quantity": this.valueCounter,
    //   "rewardId":data.id,
    //   "redemptionTime":todayDate,
    //   "notes":data.name
    // }
    let formData = {
      notes: data?.tier,
      rewards: [
        {
          quantity: this.valueCounter,
          rewardId: data?.id,
        },
      ],
      redemptionTime: todayDate,
      transactionNumber: data?.id + '_' + time.getTime(),
    };

    console.log(formData, 'formData');
    let rewardissue;
    this.apiService
      .marvalRewardIssue(formData, getMobile, getAuthKey)
      .pipe(first())
      .subscribe(
        (res: any) => {
          rewardissue = res;
          this.spinner.hide();
          this.confirmMdl.hide();
          if (res['status'].code == 200) {
            console.log(res, 'marvalRewardIssue');
            this.getCustomer();
            this.router.navigate(['/redemption-success']);
          } else if (res['status'].code != 200) {
            console.log(res['status'].code, ' status code');
            console.log(res['status'].message, ' status message');
            if (res['status'].code == 8004) {
              this.pointsExceeded = true;
              console.log(this.pointsExceeded, ' this.pointsExceeded ');
            }
            if (res['rewards'][0]['status'].code == 8004) {
              this.firstReward = true;
              console.log(this.firstReward, ' this.firstReward ');
            }
          }
        },
        (err) => {
        
          this.bsService.notifyError(err, formData);
            this.spinner.hide()
            this.apiService.showErrorMsg('Something went wrong, please try again')
            console.log(err, '=== get customer error =======')
          });
       
  }
}
