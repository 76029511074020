<section class="footer-section">
  <div class="footer-container row m-0">
    <div class="col-4">
      <a routerLink="/aboutus" class="about-link">ABOUT ASCW</a>
    </div>
    <div class="col-8 copyright-text">
      <!-- <p>© Copyright 2021 Abbott Laboratories Sdn Bhd (163560-X) MY.PND.18.08.018</p> -->
      <p>© Copyright 2022 Abbott Laboratories Sdn Bhd (163560-X)</p>
    </div>
  </div>
</section>
