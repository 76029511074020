<section class="pb70">
  <div
    class="exposeContainer_bg"
    [class.expose_bg]="howToCollectCarePoints || learnMore"
  >
    <div class="header-logos pt-2">
      <div class="abbottLogo col-4 mt10">
        <img src="../../../assets/images/Logo.png" height="40" width="40" />
      </div>
      <div class="shareAndCareLogo col-4 mt10">
        <div class="text-center">
          <img
            src="../../../assets/images/shareAndCareWorld.png"
            height="40"
            width="40"
          />
        </div>
      </div>
    </div>
    <div
      class="titleAndIconsContainer"
      [class.newContainer]="howToCollectCarePoints || learnMore"
    >
      <div class="text-center mt-4 mb-2 exposeTitle">
        <!-- <img src="../../../assets/images/exposeTitle.png" /> -->
        <div class="centerText">GET REWARDS & AUTHENTICATE PRODUCT</div>
      </div>
      <div class="row icons">
        <div class="col-6" (click)="onClickLearnMore()">
          <div class="text-center">
            <img src="../../../assets/images/authenticProduct.png" />
            <p class="blueBtn">
              LEARN MORE ABOUT
              <br />OUR PRODUCT
            </p>
          </div>
        </div>
        <div class="col-6" (click)="onClickCollectCarepoints()">
          <div class="text-center">
            <img src="../../../assets/images/coins.png" />
            <p class="blueBtn">
              COLLECT
              <br />
              CAREPOINTS
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="px-3 mt20" *ngIf="howToCollectCarePoints">
    <div class="carepoints_bg">
      <div class="text-center howToGetPoints">
        <img src="../../../assets/images/howTogetcarepoints.png" />
        <!-- <h2 class="text-white">HOW TO GET CAREPOINTS</h2> -->
        <img src="../../../assets/images/exposeGift.png" class="exposeGift" />
      </div>
      <div class="todoList">
        <ul>
          <li>
            <img class="heart" src="../../../assets/images/Group 47.png" /><span
              >Login / Register to Abbott Share & Care World account</span
            >
          </li>
          <li>
            <img class="scan" src="../../../assets/images/Group 37.png" /><span
              >After login, select “Scan for Loyalty Points”</span
            >
          </li>
          <li>
            <img class="mob" src="../../../assets/images/Group 44.png" /><span
              >Point the camera on the QR Code to capture CarePoints</span
            >
          </li>
          <li>
            <img class="paper" src="../../../assets/images/Group 45.png" /><span
              >Keep your product receipt to upload if necessary
            </span>
          </li>
        </ul>
        <div class="text-center login-signup-btn">
          <p class="blueBtn" [routerLink]="['/login']">LOG IN/SIGN UP NOW</p>
        </div>
      </div>
    </div>
  </div>
  <div class="productInfoContainer px-3 mt-2" *ngIf="learnMore">
    <div class="productInfo_bg pb-3">
      <div class="d-flex justify-content-between">
        <div>
          <p class="prodTitle text-left mt-3">PRODUCT INFORMATION</p>
          <p class="subTitle">{{ productName }}</p>
          <ul>
            <li>Batch Number: {{ batchNumber }}</li>
            <li *ngIf="isIsprint == true">Milk Source: {{ source_of_milk }}</li>
            <li>Manufactured in {{ manufactorIn }}</li>
            <li>Manufacturing Date: {{ manufacturingDate }}</li>
          </ul>
          <ul>
            <li>Expiry Date: {{ expiryDate }}</li>
            <li *ngIf="isIsprint == true">
              Custom Clearance:{{ customClearanceDate }}
            </li>
            <li *ngIf="isIsprint == true">
              Arrived in Malaysia: {{ arrivalDate }}
            </li>
          </ul>
        </div>
        <div class="productImg mt-3">
          <div class="d-flex justify-content-center">
            <!-- <img src="../../../assets/images/Rectangle9.png" /> -->
            <img
              *ngIf="isIsprint == false"
              class="prodImg text-right"
              [src]="prodImage"
              alt="Product image"
            />
            <img
              *ngIf="isIsprint == true"
              class="prodImg w-100 text-right"
              [src]="productImage"
              alt="ensure"
            />
          </div>
          <div class="d-flex justify-content-center mt-2">
            <button class="moreInfo text-center">
              <a [href]="brandLink">MORE INFO</a>
            </button>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="blue-Btn" [routerLink]="['/login']">REDEEM POINTS NOW</div>
      </div>
    </div>
  </div>
</section>

<!-- <app-version></app-version> -->

<app-footer></app-footer>
