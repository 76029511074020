import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { Location } from '@angular/common';
import { environment as env } from '../../../environments/environment';
import { first } from 'rxjs/operators';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { v4 as uuidv4 } from 'uuid';
import { BugsnagService } from 'src/app/services/bugsnag.service';
declare var ZXing: any;

import * as ScanditSDK from "scandit-sdk";
import { BarcodePicker, ScanResult, configure } from "scandit-sdk";
@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss'],
})
export class QrScannerComponent implements OnInit {
  public scannerReady = false;
  public showButton = false;
  public showDescription = true;
  public result = "";

  @ViewChild("barcodePicker") barcodePickerElement: ElementRef<HTMLDivElement & { barcodePicker: BarcodePicker }>;
  // @ViewChild("scanSettings") scanSettings: ElementRef<HTMLDivElement & { scanSettings: ScanSettings }>;
  @ViewChild('popupModal', { static: true }) popupModal: ModalDirective;


  public onReady(): void {
    console.log("onReady")
    console.log("this.scannerReady", this.scannerReady)
    console.log("this.showButton", this.showButton)
    // this.scannerReady = true;
    // this.showButton = true;
    this.barcodePickerElement.nativeElement.barcodePicker.setVisible(true).resumeScanning();


  }
  onScanError(error) {
    console.log("error=>", error);
    this.bsService.notifyError(error)
    this.apiService.showToaster("Sorry! Something Went Wrong")
  }

  public onScan(scanResult: { detail: ScanResult }): void {
    const calculatedString = scanResult.detail.barcodes.reduce((result, barcode) => {
      console.log("scanResult =>", scanResult);

      return ` ${barcode.data}`;
    }, "");

    this.result = calculatedString;
    if (this.result) {
      this.initiateScan(this.result)
    }

  }
  showSuccessMessage: boolean = false;
  showProcessingMessage: boolean = false;
  showInstructionMessage: boolean = true;
  latitude: string;
  longitude: string;
  confidence: string;
  showScanner: boolean = true;
  loader: boolean = true;
  scanCode: any;
  scannedCode: string;
  user: any;
  userPoints: any;
  customerName: string;
  suspiciousCount = 0;
  weight: any;
  iSprintData: any;
  isWeightEmpty: boolean;
  prodImage: any;
  categoryCode: any;
  sku: string;
  productImage: any;
  isprintPoints: any;
  @ViewChild('alreadyScanned', { static: true }) alreadyScanned: ModalDirective;
  @ViewChild('camerapermission', { static: true }) camerapermission: ModalDirective;
  @ViewChild('exceedLimitModal', { static: true })
  exceedLimitModal: ModalDirective;
  productType: any;
  productName: any;
  weightInUnits: any;
  weightFinal: any;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private location: Location,
    private bsService: BugsnagService
  ) {
    configure(env.LICENSEKEY, {
      engineLocation: "https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/",
    });
    this.getPosition();
  }

  ngOnInit(): void {
    this.getCustomer();
  }

  getMobileOS() {
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
      return "android"
    }
    else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
      return "ios"
    }
    return "other"
  }


  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let capcust;
    this.apiService.getCapCustomer(mobile, token).subscribe(
      (res) => {
        capcust = res;
        // Block the user
        // let block = res['customers']['customer'][0].custom_fields.field;
        // let blockUser = block.filter((filt) => {
        //   return filt.name == 'block_count';
        // });
        // console.log(blockUser, 'blockUser===');
        // if (blockUser[0]?.value == '1') {
        //   this.spinner.hide();
        //   sessionStorage.clear();
        //   this.router.navigate(['/block-notice']);
        // }
        console.log(res, 'res---');
        console.log(res['customers']['customer'][0].user_id, 'used id---');
        sessionStorage.setItem(
          'abbottUserId',
          res['customers']['customer'][0].user_id
        );
        this.user = res['customers']['customer'][0];
        this.userPoints = res['customers']['customer'][0]?.loyalty_points
          ? res['customers']['customer'][0]?.loyalty_points
          : '0';
        console.log(this.userPoints, 'this.userPoints');
        let lifetimepoints = res['customers']['customer'][0].lifetime_purchases;
        console.log(this.userPoints, 'this.userPoints');
        console.log(lifetimepoints, 'lifetimepoints');
        this.customerName = this.user.firstname + ' ' + this.user.lastname;
        console.log(this.customerName);
        this.user.custom_fields.field.forEach((element) => {
          if (element.name === 'suspicious_count') {
            this.suspiciousCount = parseInt(element.value);
          }
        });
      },
      (err) => {

        this.bsService.notifyError(err);
        this.spinner.hide()
        this.apiService.showErrorMsg('Something went wrong, please try again')
        console.log(err, '=== get customer error =======')
        console.log(mobile, '=== get customer mobile =======');
        console.log(err?.error?.message, '=== get customer error message =======');
        console.log(err?.error?.code, '=== get customer error code =======');
      }
    );
  }

  getPosition() {
    let posresp;
    this.apiService.getPosition().then((pos) => {
      posresp = pos
      let lat = pos.lat;
      let lang = pos.lng;
      let confidence = pos.accuracy;
      this.latitude = JSON.stringify(lat);
      this.longitude = JSON.stringify(lang);
      this.confidence = JSON.stringify(confidence);
      this.onClickScanner();
    }, err => {


      this.bsService.notifyError(err);
      this.spinner.hide()
      this.apiService.showErrorMsg('Something went wrong, please try again')
      console.log(err, '=== get customer error =======')
    });
  }

  getTraceabilityContent() {
    console.log('this is inside get traceabilty content function');
  }

  onClickBack() {
    this.location.back();
  }
  isEmpty(weight) {
    if (weight == '' || weight == undefined || weight == null) {
      return false;
    } else {
      return true;
    }
  }

  closeRegisteredModal() {
    this.alreadyScanned.hide();
    this.exceedLimitModal.hide();
    this.router.navigate(['/carepoints']);
  }

  closeCameraMdl() {
    this.camerapermission.hide();
  }

  initiateScan(result) {
    const barcodePickerElements = document.getElementById("barcodePicker");
    // barcodePickerElements.addEventListener("scan", function (event) {
    //   const scanResult = event.detail;
    //   alert(
    //     scanResult.barcodes.reduce(function (string, barcode) {
    //       return string + ScanditSDK.Barcode.Symbology.toHumanizedName(barcode.symbology) + ": " + barcode.data + "\n";
    //     }, "")
    //   );
    // });
    let selectedDeviceId;
    // const codeReader = new ZXing.BrowserMultiFormatReader();
    // console.log('ZXing code reader initialized');
    // codeReader
    //   .listVideoInputDevices()
    //   .then((videoInputDevices) => {
    // console.log(videoInputDevices, 'videoInputDevices')
    // const sourceSelect = document.getElementById('sourceSelect');
    // selectedDeviceId = videoInputDevices[0].deviceId;
    // codeReader.decodeFromVideoDevice(undefined, 'video', (result, err) => {
    // console.log(err, 'err ====')
    if (result) {
      console.log(result);

      // let data = result.text;
      let data = result;
      console.log(data, 'scanned data===');
      let array = data.split('=');
      console.log(array, 'array');
      let scannedCode = array[1];
      this.scanCode = scannedCode;
      this.barcodePickerElement.nativeElement.barcodePicker.setVisible(false).resumeScanning();
      // this.barcodePickerElement.nativeElement.barcodePicker.;
      //  this.barcodePickerElement.nativeElement.barcodePicker.destroy(true)
      console.log(scannedCode, ' scannedCode');
      sessionStorage.setItem('scannedCode', scannedCode);
      let scanCode = sessionStorage.getItem('scannedCode');
      console.log(scanCode, 'scanCode first');
      // this.spinner.show();
      this.showInstructionMessage = false;
      this.showProcessingMessage = true;
      let getprodinfo;
      this.apiService
        .productinfo(
          scannedCode,
          this.latitude,
          this.longitude,
          this.confidence
        )
        .subscribe((res: any) => {
          getprodinfo = res;
          console.log(res, 'res isprint');
          this.showProcessingMessage = false;

          if (res.status == 'success') {
            let productInfo = res.result;
            console.log(productInfo);
            sessionStorage.setItem(
              'iSprintData',
              JSON.stringify(res.result)
            );
            this.iSprintData = res.result;

            if (this.iSprintData.is_isprint) {
              this.productName = JSON.parse(
                this.iSprintData.product_name
              );
              this.productName = this.productName.en_US;
            } else {
              this.productName = this.iSprintData.brand;
            }
            this.productName = this.productName.toLowerCase();

            if (this.productName.startsWith('ensure')) {
              this.productType = 'ensure';
            } else if (this.productName.startsWith('pediasure')) {
              this.productType = 'pediasure';
            } else if (this.productName.startsWith('similac gold')) {
              this.productType = 'similac gold';
            } else if (this.productName.startsWith('similac mom')) {
              this.productType = 'similac mom';
            } else if (
              this.productName.startsWith('similac intelli-pro')
            ) {
              this.productType = 'similac intelli-pro';
            } else if (this.productName.startsWith('similac gain')) {
              this.productType = 'similac gold';
            } else if (this.productName.startsWith('glucerna')) {
              this.productType = 'glucerna';
            }
            this.categoryCode = res.result.category_code;
            let sku = sessionStorage.getItem('sku');
            this.sku = sku;
            this.productImage = this.iSprintData.product_image;
            console.log(this.iSprintData);
            this.showSuccessMessage = true;
            this.weight = this.iSprintData.size;
            // this.weight = '850 G';

            this.isWeightEmpty = this.isEmpty(this.weight);
            if (this.isWeightEmpty) {
              this.weight = this.weight.split(' ');
              this.weight = this.weight[0];
              this.weight = parseInt(this.weight) / 1000;
            } else {
              this.weight = 0;
            }
            console.log('weight: ', this.weight);
            this.weight = JSON.stringify(this.weight);
            sessionStorage.setItem('productWeight', this.weight);
            // this.spinner.show();
            console.log(this.iSprintData.sku, 'is the skuuuuuuuuu');
            let gapsprodimg;
            this.apiService
              .getGpasProductImage(this.iSprintData.sku)
              .subscribe(
                (res: any) => {
                  gapsprodimg = res;
                  this.spinner.hide();
                  this.prodImage = res.data[0]?.productimage;
                  console.log(this.prodImage, 'getGpasProductImage');
                },
                (err) => {

                  this.bsService.notifyError(err);
                  this.spinner.hide()
                  this.apiService.showErrorMsg('Something went wrong, please try again')
                  console.log(err, '=== get customer error =======')
                }
              );

            let cat_code = res.result.category_code;

            if (res.result.uaid_info_type == 1) {
              if (
                cat_code.startsWith('Ensure') ||
                cat_code.startsWith('ENSURE') ||
                cat_code.startsWith('ensure')
              ) {
                this.router.navigate(['/traceability-ensure']);
              } else if (
                cat_code.startsWith('Similac') ||
                cat_code.startsWith('SIMILAC') ||
                cat_code.startsWith('similac')
              ) {
                this.router.navigate(['/traceability-similac']);
              } else if (
                cat_code.startsWith('PediaSure') ||
                cat_code.startsWith('Pediasure') ||
                cat_code.startsWith('pediasure') ||
                cat_code.startsWith('PEDIASURE')
              ) {
                this.router.navigate(['/traceability-pediasure']);
              }
            } else {
              // this.postGpassEarnPoints(scannedCode);
              this.onClickCancelModalone(scannedCode);
            }
          } else {
            this.apiService.showErrorMsg('Something went wrong, please try again')
          }
        }, err => {

          this.bsService.notifyError(err);
          this.spinner.hide()
          this.apiService.showErrorMsg(err?.error?.message)
          console.log(err, '=== get customer error =======')
          this.router.navigate(['/carepoints'])
        });
    }

    // if (err && !(err instanceof ZXing.NotFoundException)) {
    //   console.error(err);
    //   document.getElementById('result').textContent = err;
    // }
    // },err=>{
    //   console.log('bugsnag error')
    //   this.bsService.notifyError(err);
    // });
    console.log(
      `Started continous decode from camera with id ${selectedDeviceId}`

    );
    // if(selectedDeviceId == undefined){
    //   this.camerapermission.show();
    // }
    // })
    // .catch((err) => {
    //   console.error(err, "=========== camera Error ===============");
    //   this.bsService.notifyError(err);
    //     this.spinner.hide()
    //     this.apiService.showErrorMsg('Something went wrong, please try again')
    //     console.log(err, '=== get customer error =======')
    //     });
  }

  onClickScanner() {
    console.log("onClickScanner");

    this.showScanner = true;
    //this.showData = false;
    const deviceOS = this.getMobileOS();
    if (deviceOS == 'android') {
      navigator.permissions.query({ name: "camera" }).then(res => {
        console.log(res, 'res 1')
        if (res.state == "granted" || res.state == "prompt") {
          console.log(res, 'res 2')

          // this.initiateScan();

        } else if (res.state == "denied") {
          console.log('denied')
          this.camerapermission.show();
        } else {
          this.camerapermission.show();
          console.log('else part')
        }
      }, err => {
        this.camerapermission.show();
        console.log(err, 'error')
      })
    } else {
      // this.initiateScan();
    }

  }




  parseFunction(value) {
    return JSON.parse(value);
  }

  // postGpassEarnPoints(scannedCode) {
  //   let mobile = sessionStorage.getItem('mobileNo');
  //   let scanCode = this.scanCode;
  //   let token = sessionStorage.getItem('authToken');
  //   if (this.iSprintData.is_isprint) {
  //     let parsedPrimaryValue = this.parseFunction(
  //       this.iSprintData.product_name
  //     ).en_US;
  //     let prdname = parsedPrimaryValue;
  //     let formData = {
  //       scan_code: scannedCode.trim(),
  //       mobile: mobile,
  //       cap_device_id: env.DEVICE_ID,
  //       customer_name: this.customerName,
  //       suspicious_count: this.suspiciousCount,
  //       product_name: prdname,
  //       product_image:
  //         this.iSprintData?.product_image == undefined ||
  //         this.iSprintData?.product_image == null
  //           ? 'null'
  //           : this.iSprintData?.product_image,
  //       confidence: this.confidence,
  //       child_count: '1',
  //       weight:
  //         this.weight == undefined || this.weight == null ? '0' : this.weight,
  //       //"weight": this.weight,
  //       custom_fields: [
  //         {
  //           name: 'translat',
  //           value: this.latitude,
  //         },
  //         {
  //           name: 'translong',
  //           value: this.longitude,
  //         },
  //         {
  //           name: 'productname',
  //           value: prdname ? prdname : 'Pediasure',
  //         },
  //         {
  //           name: 'categorycode',
  //           value: this.iSprintData.category_code
  //             ? this.iSprintData.category_code
  //             : 'Pediasure',
  //         },
  //       ],
  //     };
  //     console.log(formData, 'form data');
  //     this.apiService
  //       .postGpassEarnPoints(formData, token)
  //       .pipe(first())
  //       .subscribe(
  //         (res: any) => {
  //           if (res['status'] == 'success') {
  //             console.log(res);
  //           }
  //         },
  //         (err) => {
  //           console.log(err);
  //           // //alert(err.error['message'])
  //           // if (err.status == 420) {
  //           // } else {
  //           //   alert(err.error.message);
  //           //   // this.router.navigate(['/profile']);
  //           // }
  //         }
  //       );
  //   } else {
  //     let formData = {
  //       scan_code: this.scanCode,
  //       mobile: mobile,
  //       customer_name: this.customerName,
  //       suspicious_count: this.suspiciousCount,
  //       product_name:
  //         this.iSprintData.brand +
  //         ' ' +
  //         this.iSprintData.flavor +
  //         ' ' +
  //         this.iSprintData.size,
  //       product_image:
  //         this.prodImage == undefined || this.prodImage == null
  //           ? 'null'
  //           : this.prodImage,
  //       latitude: this.latitude,
  //       longitude: this.longitude,
  //       confidence: this.confidence,
  //       //   "child_count": this.kidsLength,
  //       weight:
  //         this.weight == undefined || this.weight == null ? '0' : this.weight,
  //       child_count: '1',
  //       custom_fields: [
  //         {
  //           name: 'productname',
  //           value:
  //             this.iSprintData.brand +
  //             ' ' +
  //             this.iSprintData.flavor +
  //             ' ' +
  //             this.iSprintData.size,
  //         },
  //         {
  //           name: 'sfproductcode',
  //           value: this.iSprintData.sku ? this.iSprintData.sku : 'Null',
  //         },
  //       ],
  //       //"weight": this.weight,
  //     };
  //     console.log(formData, 'gaps form data');
  //     // this.spinner.show();
  //     this.apiService
  //       .postGpassEarnPoints(formData, token)
  //       .pipe(first())
  //       .subscribe(
  //         (res: any) => {
  //           // this.spinner.hide();
  //           console.log(res);
  //           if (res['status'] == 'success') {
  //             // this.router.navigate(['scan/success']);
  //             console.log('success');
  //           }
  //           // else if (res['code'] == 400) {
  //           //   alert(res?.message);
  //           // } else if (res['code'] == 420) {
  //           //   alert(res?.message);
  //           // }
  //         },
  //         (err) => {
  //           // this.spinner.hide();
  //           console.log(err);
  //           // if (err?.error?.code == 400) {
  //           //   alert(err?.error?.message);
  //           // } else if (err?.error?.code == 420) {
  //           //   alert(err?.error?.message);
  //           // } else {
  //           //   console.log(err.error.message);
  //           // }
  //         }
  //       );
  //   }
  // }

  getWeightLimit(weight) {
    for (let i = 0; i < weight.length; i++) {
      let flag: Boolean = isNaN(weight[i])
      if (!flag) {
        weight = weight.split(weight[i - 1])
      }
    }
    return weight;

  }

  onClickCancelModalone(scannedCode) {
    // this.congratsModalStepOne.hide();
    // this.processingModal.show();
    let mobileNo = sessionStorage.getItem('mobileNo');
    let tokenAuth = sessionStorage.getItem('authToken');
    let getcustresp;
    this.apiService.getCapCustomer(mobileNo, tokenAuth).subscribe(
      (res: any) => {
        if (res['status'].code == 200) {
          getcustresp = res;
          console.log(res, 'res---');
          console.log(res['customers']['customer'][0].user_id, 'used id---');
          sessionStorage.setItem(
            'abbottUserId',
            res['customers']['customer'][0].user_id
          );
          this.user = res['customers']['customer'][0];
          this.userPoints = res['customers']['customer'][0]?.loyalty_points
            ? res['customers']['customer'][0]?.loyalty_points
            : '0';
          console.log(this.userPoints, 'this.userPoints');
          let lifetimepoints = res['customers']['customer'][0].lifetime_purchases;
          console.log(this.userPoints, 'this.userPoints');
          console.log(lifetimepoints, 'lifetimepoints');
          this.customerName = this.user.firstname + ' ' + this.user.lastname;
          console.log(this.customerName);
          this.user.custom_fields.field.forEach((element) => {
            if (element.name === 'suspicious_count') {
              this.suspiciousCount = parseInt(element.value);
            }
          });

          // Isprint and Gpass codes
          this.iSprintData = JSON.parse(sessionStorage.getItem('iSprintData'));

          console.log(this.iSprintData.sku, 'isprint sku');

          //doubt -----
          // env.sfproductList.filter((data) => {
          //   if (data.sku == this.iSprintData.sku) {
          //     this.sfCode = data.sfcode;
          //     console.log(data, 'data===');
          //   }
          // });

          if (this.iSprintData.is_isprint) {
            this.productName = JSON.parse(this.iSprintData.product_name);
            this.productName = this.productName?.en_US;

            this.weight = this.iSprintData?.size;
            this.isWeightEmpty = this.isEmpty(this.weight);
            if (this.isWeightEmpty) {
              this.weight = this.weight.split(' ');
              this.weight = this.weight[0];
              this.weight = parseInt(this.weight) / 1000;
            } else {
              this.weight = 0;
            }
          } else {
            this.productName = this.iSprintData.brand;
            this.weight = this.iSprintData?.size;
            this.isWeightEmpty = this.isEmpty(this.weight);
            if (this.isWeightEmpty) {
              this.weight = this.weight.split(' ');
              this.weight = this.weight[0];
              this.weight = parseInt(this.weight) / 1000;
            } else {
              this.weight = 0;
            }
          }

          this.productName = this.productName.toLowerCase();

          if (this.productName.startsWith('ensure')) {
            this.productType = 'ensure';
          } else if (this.productName.startsWith('pediasure')) {
            this.productType = 'pediasure';
          } else if (this.productName.startsWith('similac gold')) {
            this.productType = 'similac gold';
          } else if (this.productName.startsWith('similac mom')) {
            this.productType = 'similac mom';
          } else if (this.productName.startsWith('similac intelli-pro')) {
            this.productType = 'similac intelli-pro';
          } else if (this.productName.startsWith('similac gain')) {
            this.productType = 'similac gold';
          } else if (this.productName.startsWith('glucerna')) {
            this.productType = 'glucerna';
          }

          console.log('weight: ', this.weight);

          this.weight = JSON.stringify(this.weight);
          sessionStorage.setItem('productWeight', this.weight);

          console.log(this.iSprintData?.is_isprint);

          let mobile = sessionStorage.getItem('mobileNo');
          let scanCode = sessionStorage.getItem('scanCode');

          let token = sessionStorage.getItem('authToken');
          console.log(this.iSprintData?.is_isprint);
          if (this.iSprintData?.is_isprint) {
            // console.log('image: ', this.image);
            let parsedPrimaryValue = this.parseFunction(
              this.iSprintData?.product_name
            );
            let prdname = parsedPrimaryValue;

            if (typeof parsedPrimaryValue == 'object')
              prdname = parsedPrimaryValue.en_US;
            else prdname = parsedPrimaryValue;
            let formData = {
              scan_code: scannedCode,
              mobile: mobile,
              cap_device_id: env.DEVICE_ID,
              customer_name: this.customerName,
              suspicious_count: this.suspiciousCount,
              product_name: prdname,
              product_image: this.iSprintData?.product_image,
              confidence: this.confidence,
              product_type: this.productType,
              child_count: '1',
              weight:
                this.weight == undefined || this.weight == null ? '0' : this.weight,
              //"weight": this.weight,
              custom_fields: [
                {
                  name: 'TransLat',
                  value: this.latitude,
                },
                {
                  name: 'TransLong',
                  value: this.longitude,
                },
                {
                  name: 'productName',
                  value: prdname,
                },
                {
                  name: 'category_code_trans',
                  value: this.categoryCode ? this.categoryCode : '',
                },
                {
                  name: 'sfproductcode',
                  value: this.sku,
                },
                {
                  name: 'productimage',
                  value: this.productImage,
                },
              ],
            };
            this.spinner.show();
            console.log(formData, 'form data');
            let isprintresp;

            this.apiService
              .postGpassEarnPoints(formData, token)
              .pipe(first())
              .subscribe(
                (res: any) => {
                  isprintresp = res;
                  this.spinner.hide();
                  // this.processingModal.show();
                  if (res['status'] == 'success') {
                    this.spinner.hide();
                    // this.processingModal.hide();
                    // this.congratsModalStepTwo.show()
                    // this.disabled = true;
                    console.log(res, 'resp on isprint earns points....');
                    this.isprintPoints = res?.result?.points;
                    this.router.navigate(['/carepoints']);

                    // this.successScanModal.show();

                    //Get Points API
                    // let mobile = sessionStorage.getItem('mobileNo');
                    // let token = sessionStorage.getItem('authToken');
                    // this.spinner.show();
                    // this.apiService
                    //   .getPoints(mobile, token)
                    //   .pipe(first())
                    //   .subscribe((res: any) => {
                    //     this.spinner.hide();
                    //     console.log(
                    //       res?.customer?.transactions?.transaction,
                    //       'points list'
                    //     );
                    //     this.pointsEarned =
                    //       res?.customer?.transactions?.transaction[0]?.points?.issued;
                    //    this.alreadyScanned.show();
                    //     this.router.navigate(['/home'])
                    //   });
                  } else {
                    console.log('subscribe else part in isprint');
                    // add transcation fail show the pop-up
                    this.alreadyScanned.show();
                    this.spinner.hide();
                  }
                },
                (err) => {
                  this.spinner.hide();
                  console.log(err);
                  this.alreadyScanned.show();

                  if (err.status === 420) {
                    this.exceedLimitModal.show();
                  }
                  //else {
                  //   this.alreadyScanned.show();
                  //   this.router.navigate(['/profile']);
                  // }
                  // this.disable = true


                  this.bsService.notifyError(err);
                  this.spinner.hide()
                  this.apiService.showErrorMsg('Something went wrong, please try again')
                  console.log(err, '=== get customer error =======')
                }
              );
          } else {
            this.spinner.show();

            //useful in future----------
            let gapsprodimg;
            this.apiService
              .getGpasProductImage(this.iSprintData?.sku)
              .subscribe((res: any) => {
                gapsprodimg = res;
                this.spinner.hide();
                console.log(res.data, 'getGpasProductImage');
                //console.log(this.latitude, this.longitude, this.confidence);
                this.prodImage = res.data[0]?.productimage;
                console.log(this.prodImage, 'this.prodImage ');

                let formData = {
                  scan_code: scannedCode,
                  mobile: mobile,
                  customer_name: this.customerName,
                  suspicious_count: this.suspiciousCount,
                  product_name:
                    this.iSprintData?.brand +
                    ' ' +
                    this.iSprintData?.flavor +
                    ' ' +
                    this.iSprintData?.size,
                  product_image: this.prodImage ? this.prodImage : 'null',
                  latitude: this.latitude,
                  longitude: this.longitude,
                  confidence: this.confidence,
                  product_type: this.productType,

                  child_count: '1',
                  weight:
                    this.weight == undefined || this.weight == null
                      ? '0'
                      : this.weight,
                  //"weight": this.weight,
                  custom_fields: [
                    {
                      name: 'productname',
                      value:
                        this.iSprintData?.brand +
                        ' ' +
                        this.iSprintData?.flavor +
                        ' ' +
                        this.iSprintData?.size,
                    },
                    {
                      name: 'categorycode',
                      value:
                        this.iSprintData?.brand +
                        ' ' +
                        this.iSprintData?.flavor +
                        ' ' +
                        this.iSprintData?.size,
                    },
                    {
                      name: 'sfproductcode',
                      value: this.sku,
                    },
                  ],
                };
                console.log(formData, 'gpas form data');
                this.spinner.show();
                let country = this.iSprintData?.country.toLowerCase().trim() || this.iSprintData?.country_of_origin.toLowerCase().trim()
                if (country == 'malaysia' || country == "shared") {
                let gpasresp;
                this.apiService
                  .postGpassEarnPoints(formData, token)
                  .pipe(first())
                  .subscribe(
                    (res: any) => {
                      gpasresp = res;
                      this.spinner.hide();
                      console.log('res: ', res);
                      // this.processingModal.show();

                      if (res['status'] == 'success') {
                        this.spinner.hide();
                        // this.processingModal.hide();
                        // this.congratsModalStepTwo.show();
                        // this.disabled = true;
                        // this.disable = true;
                        //this.gpasPoints =

                        // this.apiService.getSkuPoints(this.gpasSku).subscribe((res:any)=>{
                        //   console.log(res?.data[0]?.points, 'gpaspoints');
                        //   this.gpasPoints = res?.data[0]?.points;
                        // })
                        console.log(res, 'resp on GPAS earns points....');
                        this.router.navigate(['/carepoints']);
                        // this.gpasSuccessMdl.show();

                        //console.log(res);
                        //Get Points API
                        // let mobile = sessionStorage.getItem('mobileNo');
                        // let token = sessionStorage.getItem('authToken');
                        // this.spinner.show();
                        // this.apiService
                        //   .getPoints(mobile, token)
                        //   .pipe(first())
                        //   .subscribe((res: any) => {
                        //     this.spinner.hide();
                        //     console.log(
                        //       res?.customer?.transactions?.transaction,
                        //       'points list'
                        //     );
                        //     this.pointsEarned =
                        //       res?.customer?.transactions?.transaction[0]?.points?.issued;
                        //    // this.onClickSuccessScanModal();
                        //    this.router.navigate(['/home'])
                        //   });
                      } else {
                        // add transcation fail show the pop-up
                        this.alreadyScanned.show();
                        this.spinner.hide();
                      }
                    },
                    (err) => {
                      //alert(err.error.message);
                      // this.processingModal.hide();
                      this.spinner.hide();
                      this.alreadyScanned.show();
                      if (err.status == 420) {
                        this.exceedLimitModal.show();
                      }
                      //else {
                      //   alert(err.error.message);
                      //   this.router.navigate(['/profile']);
                      // }


                      this.bsService.notifyError(err, formData);
                      this.spinner.hide()
                      this.apiService.showErrorMsg('Something went wrong, please try again')
                      console.log(err, '=== get customer error =======')
                      console.log(mobileNo, '=== get customer mobile =======');
                      console.log(err?.error?.message, '=== get customer error message =======');
                      console.log(err?.error?.code, '=== get customer error code =======');
                    }
                  );
                }else{
                  // show popup  invalid qr code
                  this.spinner.hide()
                  this.popupModal.show()
               }
                // },
                //   (err) => {
                //     this.spinner.hide();
                //   }
                // );
              }, err => {
                this.bsService.notifyError(err);
                this.spinner.hide()
                this.apiService.showErrorMsg('Something went wrong, please try again')
                console.log(err, '=== get customer error =======')
                console.log(mobileNo, '=== get customer mobile =======');
                console.log(err?.error?.message, '=== get customer error message =======');
                console.log(err?.error?.code, '=== get customer error code =======');
              });
          }

        } else console.log("error in get customer")
      }, err => {
        this.bsService.notifyError(err);
      })


  }

  onClickOK() {
    this.popupModal.hide()
  }
}



