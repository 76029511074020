import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-similac-nucleotides',
  templateUrl: './similac-nucleotides.component.html',
  styleUrls: ['./similac-nucleotides.component.scss']
})
export class SimilacNucleotidesComponent implements OnInit {
  hideInChinese: boolean = false;
  hideInBahasa: boolean = false; 
  constructor(private apiService: ApiService) {
    this.apiService.chineseLang.subscribe(res=>{
      this.hideInChinese = res;
     })

     this.apiService.bahasaLang.subscribe(res=>{
      this.hideInBahasa = res;
     })
   }
   ValueSelected:any;
   selectedVal:any;
  ngOnInit(): void {
    window.scrollTo({top:0});
    this.apiService.trackLang.subscribe(res=>{
      this.ValueSelected = res;
      if(this.ValueSelected != ''){
        this.selectedVal = this.ValueSelected;
      }
     // console.log(this.ValueSelected ,this.selectedVal )
     
    })
  }

}
