<!-- <app-header title="Log in"></app-header> -->
<app-header-primary></app-header-primary>

<section class="blueBg _h100vh">
  <!-- <div class="scanContainer">
    <p class="scanHello">
      HELLO, <br />{{ user?.firstname }} {{ user?.lastname }}
    </p>
    <p class="content mb-4">
      TOTAL CAREPOINTS BALANCE: {{ user?.loyalty_points }}
    </p>
    <img
      src="../../../assets/images/Group 307.png"
      width="100"
      height="100"
      (click)="onClickToScan()"
      class="mb-5"
    />
    <p class="promo">PROMOTION</p>
    <div class="promo-bg">
      <div class="promo-content text-center">
        <p class="font-darkBlue fs24">DISCOUNT</p>
        <p class="mb-0 font-darkBlue fs12">ONLY</p>
        <div class="font-red fs24 rm100 text-center">RM 100</div>
      </div>
    </div>
  </div> -->
  <!-- <div
  bsModal
  #scanQrcodeGuideModal="bs-modal"
  class="modal fade customModalContainer px30"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm mx-auto">
    <div class="modal-content">
      <div class="modal-body pt-3">
        <div class="qrScanGuideModal">
          <div class="text-center px15">
            <span class="title fs16 mb-0">Where To Find The QR Code</span>
            <span class="mb-0 text1">
              *Only applicable for participating products</span
            >
            <img
              class="br10 mt-3 w-100p"
              src="../../../assets/images/Group 122.png"
            />
            <p class="textPureBlack mt-2 mb-0">1. Scan QR code on the box</p>
            <span class="title mt-2">OR</span>
            <img
              class="br10 mt-3 w-100p"
              src="../../../assets/images/Group 118.png"
            />
            <p class="textPureBlack mt-2">2. Scan QR code under the tin</p>
          </div>
          <div class="buttonOk">
            <button class="btnOk text-center" [routerLink]="['/qr-scanner']">
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
</section>
<app-version></app-version>

<app-footer></app-footer>
