import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import { HomeComponent } from './pages/home/home.component';
import { ProductInfoComponent } from './pages/product-info/product-info.component';
import { TraceabilityEnsureComponent } from './pages/ensure/traceability-ensure/traceability-ensure.component';
import { TraceabilityPediasureComponent } from './pages/pediasure/traceability-pediasure/traceability-pediasure.component';
import { TraceabilitySimlilacComponent } from './pages/similac/traceability-simlilac/traceability-simlilac.component';
import { SourceOfIngredientsComponent } from './pages/ensure/source-of-ingredients/source-of-ingredients.component';
import { DeliveryComponent } from './pages/ensure/delivery/delivery.component';
import { ManufacturingComponent } from './pages/ensure/manufacturing/manufacturing.component';
import { QualitycontrolComponent } from './pages/ensure/qualitycontrol/qualitycontrol.component';
import { EnsuresharecareComponent } from './pages/ensure/ensuresharecare/ensuresharecare.component';
import { EnsurehmbtripleproteinComponent } from './pages/ensure/ensurehmbtripleprotein/ensurehmbtripleprotein.component';
import { WhyensureComponent } from './pages/ensure/whyensure/whyensure.component';
import { PediasureSharecareComponent } from './pages/pediasure/pediasure-sharecare/pediasure-sharecare.component';
import { PediasureDeliveryComponent } from './pages/pediasure/pediasure-delivery/pediasure-delivery.component';
import { PediasureQualitycontrolComponent } from './pages/pediasure/pediasure-qualitycontrol/pediasure-qualitycontrol.component';
import { PediasureManufacturingComponent } from './pages/pediasure/pediasure-manufacturing/pediasure-manufacturing.component';
import { PediasureNaturalvitaminComponent } from './pages/pediasure/pediasure-naturalvitamin/pediasure-naturalvitamin.component';
import { SimilacManufacturingComponent } from './pages/similac/similac-manufacturing/similac-manufacturing.component';
import { SimilacMilksourceComponent } from './pages/similac/similac-milksource/similac-milksource.component';
import { SimilacQualitycontrolComponent } from './pages/similac/similac-qualitycontrol/similac-qualitycontrol.component';
import { SimilacDeliveryComponent } from './pages/similac/similac-delivery/similac-delivery.component';
import { SimilacSharecareComponent } from './pages/similac/similac-sharecare/similac-sharecare.component';
import { SimilacWhychooseComponent } from './pages/similac/similac-whychoose/similac-whychoose.component';
import { SimilacNucleotidesComponent } from './pages/similac/similac-nucleotides/similac-nucleotides.component';
import { ProductsComponent } from './pages/products/products.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { RegisterComponent } from './pages/register/register.component';
import { CarePointsComponent } from './pages/care-points/care-points.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { RedeemRewardsComponent } from './pages/redeem-rewards/redeem-rewards.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { RedeemRewardDetailsComponent } from './pages/redeem-reward-details/redeem-reward-details.component';
import { EnsureDetailsComponent } from './pages/ensure-details/ensure-details.component';
import { SomethingNewComponent } from './pages/something-new/something-new.component';
import { SimilacDetailsComponent } from './pages/similac-details/similac-details.component';
import { PediasureDetailsComponent } from './pages/pediasure-details/pediasure-details.component';
import { UpdateMobileComponent } from './pages/update-mobile/update-mobile.component';
import { RedemptionSuccessComponent } from './pages/redemption-success/redemption-success.component';
import { RedemptionHistoryComponent } from './pages/redemption-history/redemption-history.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReferFriendComponent } from './pages/refer-friend/refer-friend.component';
import { ScanComponent } from './pages/scan/scan.component';
import { QrScannerComponent } from './pages/qr-scanner/qr-scanner.component';
import { PointsStatusComponent } from './pages/points-status/points-status.component';
import { ValidateReceiptComponent } from './pages/validate-receipt/validate-receipt.component';
import { CameraComponent } from './pages/camera/camera.component';
import { ExposeComponent } from './pages/expose/expose.component';
import { BlockUserComponent } from './pages/block-user/block-user.component';


const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'landing-page',
    component: LandingComponent,
  },
  {
    path: 'product-info',
    component: ProductInfoComponent,
  },
  {
    path: 'product-info/:time',
    component: ProductInfoComponent,
  },
  {
    path: 'traceability-ensure',
    component: TraceabilityEnsureComponent,
  },
  {
    path: 'traceability-ensure/:time',
    component: TraceabilityEnsureComponent,
  },
  {
    path: 'traceability-similac',
    component: TraceabilitySimlilacComponent,
  },
  {
    path: 'traceability-similac/:time',
    component: TraceabilitySimlilacComponent,
  },
  {
    path: 'traceability-pediasure',
    component: TraceabilityPediasureComponent,
  },
  {
    path: 'traceability-pediasure/:time',
    component: TraceabilityPediasureComponent,
  },
  {
    path: 'ensure-ingredients',
    component: SourceOfIngredientsComponent,
  },
  {
    path: 'ensure-delivery',
    component: DeliveryComponent,
  },
  {
    path: 'ensure-manufacturing',
    component: ManufacturingComponent,
  },
  {
    path: 'ensure-qualitycontrol',
    component: QualitycontrolComponent,
  },
  {
    path: 'ensure-sharecare',
    component: EnsuresharecareComponent,
  },
  {
    path: 'ensure-hmb',
    component: EnsurehmbtripleproteinComponent,
  },
  {
    path: 'why-ensure',
    component: WhyensureComponent,
  },
  {
    path: 'pediasure-sharecare',
    component: PediasureSharecareComponent,
  },
  {
    path: 'pediasure-delivery',
    component: PediasureDeliveryComponent,
  },
  {
    path: 'pediasure-qualitycontrol',
    component: PediasureQualitycontrolComponent,
  },
  {
    path: 'pediasure-manufacturing',
    component: PediasureManufacturingComponent,
  },
  {
    path: 'pediasure-naturalvitamin',
    component: PediasureNaturalvitaminComponent,
  },
  {
    path: 'similac-manufacturing',
    component: SimilacManufacturingComponent,
  },
  {
    path: 'similac-milksource',
    component: SimilacMilksourceComponent,
  },
  {
    path: 'similac-qualitycontrol',
    component: SimilacQualitycontrolComponent,
  },
  {
    path: 'similac-delivery',
    component: SimilacDeliveryComponent,
  },
  {
    path: 'similac-sharecare',
    component: SimilacSharecareComponent,
  },
  {
    path: 'similac-whychoose',
    component: SimilacWhychooseComponent,
  },
  {
    path: 'similac-nucleotides',
    component: SimilacNucleotidesComponent,
  },
  {
    path: 'products',
    component: ProductsComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login/:id',
    component: LoginComponent,
  },
  {
    path: 'login/:id/:time',
    component: LoginComponent,
  },
  {
    path: 'login/:time',
    component: LoginComponent,
  },
  {
    path: 'aboutus',
    component: AboutusComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'carepoints',
    component: CarePointsComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'redeem-rewards',
    component: RedeemRewardsComponent,
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
  },
  {
    path: 'change-password/:id',
    component: ChangePasswordComponent,
  },
  {
    path: 'redeem-reward-details',
    component: RedeemRewardDetailsComponent,
  },
  {
    path: 'redeem-reward-details/:id',
    component: RedeemRewardDetailsComponent,
  },
  {
    path: 'ensure-details',
    component: EnsureDetailsComponent,
  },
  {
    path: 'something-new',
    component: SomethingNewComponent,
  },
  {
    path: 'similac-details',
    component: SimilacDetailsComponent,
  },
  {
    path: 'pediasure-details',
    component: PediasureDetailsComponent,
  },
  {
    path: 'update-mobile',
    component: UpdateMobileComponent,
  },
  {
    path: 'redemption-success',
    component: RedemptionSuccessComponent,
  },
  {
    path: 'redemption-history',
    component: RedemptionHistoryComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
  },
  {
    path: 'refer-friend',
    component: ReferFriendComponent,
  },
  {
    path: 'scan',
    component: ScanComponent,
  },
  {
    path: 'qr-scanner',
    component: QrScannerComponent,
  },
  {
    path: 'points-status',
    component: PointsStatusComponent,
  },
  {
    path: 'validate-receipt',
    component: ValidateReceiptComponent,
  },
  {
    path: 'camera',
    component: CameraComponent,
  },

  {
    path: 'expose',
    component: ExposeComponent,
  },
  {
    path: 'block-notice',
    component: BlockUserComponent,
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
