<!-- <app-header title="Log in"></app-header> -->
<app-header-primary></app-header-primary>

<section class="blueBg _h100vh">
  <div class="btn-left backBtn" (click)="onClickBack()">
    <img src="../../../assets/images/Group 310.png" class="backIcon" />
    <p>Back</p>
  </div>
  <div class="upload-receipt-container">
    <p class="title">PURCHASE VALIDATION</p>

    <img src="../../../assets/images/Group 309.png" width="200" height="200" />
    <p class="content">
      To enable processing of points you need to upload a picture of your
      receipt for validation
    </p>

    <button class="uploadBtn" (click)="handleUploadReceiptBtn()">
      UPLOAD RECEIPT<img src="../../../assets/images/Group 312.svg" />
    </button>
  </div>
  <div
    bsModal
    #popupTandC="bs-modal"
    class="modal fade customModalContainer px30"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-child-name"
  >
    <div class="modal-dialog modal-sm mx-auto">
      <div class="modal-content">
        <div class="modal-body pt-3">
          <div class="qrScanGuideModal">
            <div class="text-center">
              <span class="title"
                >Terms and Conditions for uploading receipt</span
              >
              <ol class="content">
                <li class="mt20">
                  Photo has to be a computerisedreceipt and all following
                  information must be visible: Shop name, date and time of
                  purchase, name and amount of products purchased as well as
                  product proce
                </li>
                <li class="mt20">
                  We reserve the right to check every receipt and disqualify the
                  submitted receipt for if it's found to be invalid.
                </li>
              </ol>
            </div>
            <div class="buttonOk">
              <button class="btnOk text-center" (click)="onclickUploadReceipt()">
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-version></app-version>

<app-footer></app-footer>
