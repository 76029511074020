import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent implements OnInit {
  hideInChinese: boolean = false;
  hideInBahasa: boolean = false;
  ValueSelected: any;
  selectedVal: any = 'en_US';
  constructor(public translate: TranslateService, private apiService: ApiService) { 

    translate.addLangs(['en_US', 'bh', 'ch']);
    // translate.setDefaultLang('en_US');
 
    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en_US|bh|ch/) ? browserLang : 'en_US');

    this.apiService.trackLang.subscribe(res=>{
      this.ValueSelected = res;
      if(this.ValueSelected != ''){
        this.selectedVal = this.ValueSelected;
      }
     
    })

    
 

  }

  ngOnInit(): void {
  



  }

  selectedLanguage(lang_val){
    if(lang_val == 'en_US'){
      this.hideInChinese = false;
      this.hideInBahasa = false;
      this.apiService.languageValue(this.hideInChinese);
      this.apiService.BahasaValue(this.hideInBahasa);
      this.apiService.trackValue(lang_val);
      this.translate.use(lang_val);

    }
    if(lang_val == 'bh'){
      this.hideInChinese = false;
      this.hideInBahasa = true;
      this.apiService.languageValue(this.hideInChinese)
      this.apiService.BahasaValue(this.hideInBahasa);
      this.apiService.trackValue(lang_val);
      this.translate.use(lang_val);
    }
    if(lang_val == 'ch'){
      this.hideInChinese = true;
      this.hideInBahasa = false;
      this.apiService.languageValue(this.hideInChinese)
      this.apiService.BahasaValue(this.hideInBahasa);
      this.apiService.trackValue(lang_val);
      this.translate.use(lang_val);
    }
  }

}
