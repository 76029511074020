<app-header-primary></app-header-primary>
<div class="blueBg">
  <div class="whiteBg">
    <div class="formOut">
      <h3>my profile</h3>
      <h6>personal information</h6>
      <form [formGroup]="profileForm" (ngSubmit)="onProfileFormSubmit()">
        <div class="form-group">
          <label>first name</label>
          <input
            type="text"
            class="form-control"
            formControlName="firstName"
            [ngClass]="{ 'is-invalid': submitted && fVal.firstName.errors }"
            (keypress)="onlyCharacters($event)"
          />
          <div
            *ngIf="submitted && fVal.firstName.errors"
            class="invalid-feedback"
          >
            <div *ngIf="fVal.firstName.errors.required">Enter First Name</div>
          </div>
        </div>
        <div class="form-group">
          <label>last name</label>
          <input
            type="text"
            class="form-control"
            formControlName="lastName"
            [ngClass]="{ 'is-invalid': submitted && fVal.lastName.errors }"
            (keypress)="onlyCharacters($event)"
          />
          <div
            *ngIf="submitted && fVal.lastName.errors"
            class="invalid-feedback"
          >
            <div *ngIf="fVal.lastName.errors.required">Enter Last Name</div>
          </div>
        </div>
        <div class="form-group">
          <label>gender</label>
          <div class="customSelect">
            <select
              class="form-control"
              formControlName="gender"
              [ngClass]="{ 'is-invalid': submitted && fVal.gender.errors }"
            >
              <option value="" selected>Select Gender</option>
              <option>Male</option>
              <option>Female</option>
            </select>
            <div
              *ngIf="submitted && fVal.gender.errors"
              class="invalid-feedback"
            >
              <div *ngIf="fVal.gender.errors.required">Select Gender</div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>email address</label>
          <input
            type="email"
            class="form-control"
            formControlName="email"
            [ngClass]="{ 'is-invalid': submitted && fVal.email.errors }"
          />
          <div *ngIf="submitted && fVal.email.errors" class="invalid-feedback">
            <div *ngIf="fVal.email.errors.required">Enter Email</div>
            <div *ngIf="fVal.email.errors.email || fVal.email.errors.pattern">
              Enter a valid Email
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>date of birth</label>
          <div class="row">
            <div class="col-12">
              <div>
                <input
                  type="text"
                  placeholder="Select date"
                  class="form-control"
                  bsDatepicker
                  formControlName="DOB"
                  [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
                  [ngClass]="{ 'is-invalid': submitted && fVal.DOB.errors }"
                  [maxDate]="parentMaxDate"
                />
                <div
                  *ngIf="submitted && fVal.DOB.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="fVal.DOB.errors.required">DOB is required</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>favourite brand</label>
          <div class="customSelect">
            <select
              class="form-control"
              formControlName="favouriteBrand"
              [ngClass]="{
                'is-invalid': submitted && fVal.favouriteBrand.errors
              }"
            >
              <option value="" selected>Select brand</option>
              <!-- <option>Pediasure</option>
                                <option>Ensure</option> -->
              <option>Pediasure</option>
              <option>Ensure</option>
              <option>Glucerna</option>
              <option>Similac mom</option>
              <option>Similac gain plus gold</option>
              <option>Similac gain kid gold</option>
              <option>Isomil plus</option>
              <option>PEDIASURE 10+</option>
              <option>SIMILAC INTELLI-PRO</option>
            </select>
            <div
              *ngIf="submitted && fVal.favouriteBrand.errors"
              class="invalid-feedback"
            >
              <div *ngIf="fVal.favouriteBrand.errors.required">
                Select Favourite Brand
              </div>
            </div>
          </div>
        </div>
        <a href="javascript:;" class="link" routerLink="/change-password"
          >Change my password</a
        >

        <h6 class="mt-3 mb-3">address information</h6>

        <div class="form-group">
          <label>address line 1</label>
          <textarea
            class="form-control"
            formControlName="addressLine1"
            [ngClass]="{ 'is-invalid': submitted && fVal.addressLine1.errors }"
          >
          </textarea>
          <div
            *ngIf="submitted && fVal.addressLine1.errors"
            class="invalid-feedback"
          >
            <div *ngIf="fVal.addressLine1.errors.required">
              Enter Address Line1
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>address line 2</label>
          <textarea
            class="form-control"
            formControlName="addressLine2"
            [ngClass]="{ 'is-invalid': submitted && fVal.addressLine2.errors }"
          >
          </textarea>
          <div
            *ngIf="submitted && fVal.addressLine2.errors"
            class="invalid-feedback"
          >
            <div *ngIf="fVal.addressLine2.errors.required">
              Enter Address Line2
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>city</label>
          <div class="customSelect">
            <select
              class="form-control"
              formControlName="city"
              [ngClass]="{ 'is-invalid': submitted && fVal.city.errors }"
            >
              <option value="" selected>Select city</option>
              <option *ngFor="let city of cityList" [value]="city.city">
                {{ city?.city }}
              </option>
            </select>
            <div *ngIf="submitted && fVal.city.errors" class="invalid-feedback">
              <div *ngIf="fVal.city.errors.required">Select City</div>
            </div>
          </div>
          <!-- <ng-select
            [items]="cityList"
            bindLabel="city"
            bindValue="city"
            formControlName="city"
            [ngClass]="{ 'is-invalid': submitted && fVal.city.errors }"
          >
          </ng-select> -->
        </div>
        <div class="form-group">
          <label>state/province</label>
          <div class="customSelect">
            <select
              class="form-control"
              formControlName="stateProvince"
              [ngClass]="{
                'is-invalid': submitted && fVal.stateProvince.errors
              }"
            >
              <option value="" selected>Select state/province</option>
              <option *ngFor="let state of stateList" [value]="state.state">
                {{ state?.state }}
              </option>
            </select>
            <div
              *ngIf="submitted && fVal.stateProvince.errors"
              class="invalid-feedback"
            >
              <div *ngIf="fVal.stateProvince.errors.required">
                Select State/Province
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>postal code</label>
          <input
            type="text"
            class="form-control"
            formControlName="postalCode"
            [ngClass]="{ 'is-invalid': submitted && fVal.postalCode.errors }"
            (keypress)="onlyPostalNumbers($event)"
            maxlength="5"
            minlength="5"
          />
          <div
            *ngIf="submitted && fVal.postalCode.errors"
            class="invalid-feedback"
          >
            <div *ngIf="fVal.postalCode.errors.required">Enter Postal Code</div>
            <div *ngIf="submitted && fVal.postalCode.errors.minlength">
              Postal Code should be 5 digits
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>country</label>
          <div>
            <input
              class="form-control"
              formControlName="country"
              [ngClass]="{ 'is-invalid': submitted && fVal.country.errors }"
              readonly
            />
            <!-- <option value="malaysia" selected>malaysia</option>
                                <option>Singapore</option>
                                <option>Thailand</option> -->

            <div
              *ngIf="submitted && fVal.country.errors"
              class="invalid-feedback"
            >
              <div *ngIf="fVal.country.errors.required">Select Country</div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>mobile</label>
          <div class="row">
            <div class="col-3 pr-1">
              <input
                type="text"
                class="form-control"
                placeholder="+60"
                readonly
              />
            </div>
            <div class="col-9 pl-0 pr pr-0">
              <input
                type="text"
                class="form-control"
                formControlName="mobile"
                [ngClass]="{ 'is-invalid': submitted && fVal.mobile.errors }"
                (change)="newMobile($event)"
                maxLength="10"
                [readonly]="changeMobNo"
                (keypress)="onlyNumbers($event)"
              />
              <div
                *ngIf="submitted && fVal.mobile.errors"
                class="invalid-feedback"
              >
                <div *ngIf="fVal.mobile.errors.required">
                  Enter Mobile Number
                </div>
                <div
                  *ngIf="
                    fVal.mobile.errors.pattern || fVal.mobile.errors.pattern
                  "
                >
                  Incorrect Mobile Number
                </div>
                <div *ngIf="fVal.mobile.errors.minlength || mobLength < 9">
                  Mobile number must be atleast 9 digits
                </div>
                <div *ngIf="fVal.mobile.errors.maxlength || mobLength > 10">
                  Mobile number must be atmost 10 digits
                </div>
              </div>
              <div *ngIf="mobLength < 9" class="invalidText">
                Mobile number must be atleast 9 digits
              </div>
              <!-- <div *ngIf="mobLength > 10" class="invalidText">
                                Mobile number must be atmost 10 digits 
                            </div>  -->
              <!-- <img
                src="../../../assets/images/pencil.png"
                class="editMobIco"
                alt="edit"
                (click)="editMob()"
              /> -->
            </div>
            <!-- <p class="contentDarkBlue mt-2 pl-3">
              Please enter full handphone number beginning with 1. Eg: Handphone
              number 012-3899000. Key in 123899000.
            </p> -->
          </div>
          <!-- <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="blueBtn"
                [disabled]="changeMobNo"
                (click)="setNewMobileNo()"
              >
                Change Mobile Number
              </button>
            </div>
          </div> -->
        </div>
        <!-- <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="subscription">
                    <label class="form-check-label primaryForeColor">I give consent to Abbott my details for the purpose of this program. I would like to receive communication from Abbott by mail / email / SMS regarding Abbott products and services, or for research purpose.</label>
                </div> -->
        <div class="form-group">
          <label>child's name</label>
          <input
            type="text"
            class="form-control"
            formControlName="childsName"
            [ngClass]="{ 'is-invalid': submitted && fVal.childsName.errors }"
          />
          <div
            *ngIf="submitted && fVal.childsName.errors"
            class="invalid-feedback"
          >
            <div *ngIf="fVal.childsName.errors.required">
              Enter Child's Name
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>date of birth</label>
          <div class="row">
            <div class="col-12">
              <input
                type="text"
                placeholder="Select date"
                class="form-control"
                bsDatepicker
                formControlName="childDob"
                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
                [ngClass]="{ 'is-invalid': submitted && fVal.childDob.errors }"
                [maxDate]="childMaxDate"
                [minDate]="childMinDate"
              />
              <div
                *ngIf="submitted && fVal.childDob.errors"
                class="invalid-feedback"
              >
                <div *ngIf="fVal.childDob.errors.required">
                  child DOB is required
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>how many serving per day</label>
          <div class="customSelect">
            <select
              class="form-control"
              formControlName="serving"
              [ngClass]="{ 'is-invalid': submitted && fVal.serving.errors }"
            >
              <option value="" selected>Select how many serving per day</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <div
              *ngIf="submitted && fVal.serving.errors"
              class="invalid-feedback"
            >
              <div *ngIf="fVal.serving.errors.required">
                Select Serving Per Day
              </div>
            </div>
          </div>
        </div>
        <div class="text-center">
          <button
            type="submit"
            class="blueBtn"
            [ngClass]="{ isValidSubmit: profileForm.valid }"
          >
            Save
          </button>
        </div>
        <div class="text-center mt-4">
          <button type="submit" class="blueBtn" (click)="onClickLogout()">
            logout
          </button>
        </div>
      </form>
    </div>
  </div>
  <app-version></app-version>
  <div class="-mx-20">
    <app-footer></app-footer>
  </div>
</div>
<app-bottom-nav></app-bottom-nav>

<div
  bsModal
  #profileUpdated="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeProfileModal()"
        >
          x
        </button>
        <div *ngIf="!readonly">
          <p class="text-center">Profile updated successfully</p>

          <div class="text-center">
            <button
              type="submit"
              class="blueBtn text-white mt-3"
              (click)="closeProfileModal()"
            >
              ok
            </button>
          </div>
        </div>
        <div *ngIf="readonly">
          <p class="text-center mt-4">
            Thanks for updating your details. Please re-select your redemption
            item again to proceed.
          </p>

          <div class="text-center">
            <button
              type="submit"
              class="blueBtn text-white mt-3"
              (click)="gotoReward()"
            >
              Select Item
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
