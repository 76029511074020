import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-secondary-copy',
  templateUrl: './header-secondary-copy.component.html',
  styleUrls: ['./header-secondary-copy.component.scss']
})
export class HeaderSecondaryCopyComponent implements OnInit {
  @Input() homeLogo : string;
  @Input() link: string;
  constructor() { }

  ngOnInit(): void {
  }

}
