import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-similac-bottomnav',
  templateUrl: './similac-bottomnav.component.html',
  styleUrls: ['./similac-bottomnav.component.scss']
})
export class SimilacBottomnavComponent implements OnInit {
  activePath: any;
  showMilkSource =  false;
  showManufacturing = false;
  showQualityControl = false;
  showDelivery = false;
  showNucleotides = false;
  showShareCare = false;
  hideInChinese: boolean = false;
  constructor(private route: ActivatedRoute, private apiService: ApiService) { 
    this.activePath = this.route.snapshot.routeConfig.path;
    console.log('rouetInfo', this.activePath);

    this.apiService.chineseLang.subscribe(res=>{
      this.hideInChinese = res;
     })


  }

  ngOnInit(): void {
    if(this.activePath === 'similac-milksource') {
      this.showMilkSource = true;
    };
    if(this.activePath === 'similac-manufacturing') {
      this.showManufacturing = true;
    };
    if(this.activePath === 'similac-qualitycontrol') {
      this.showQualityControl= true;
    };
    if(this.activePath === 'similac-delivery') {
      this.showDelivery = true;
    };
    if(this.activePath === 'similac-nucleotides') {
      this.showNucleotides = true;
    };
    if(this.activePath === 'similac-sharecare') {
      this.showShareCare = true;
    };
  }

}
