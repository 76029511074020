<app-header homeLogo="../../../assets/images/homeicon.svg" link="/traceability-ensure"></app-header>
<section class="ensureGlobalContainer">
    <div class="bannerBlockTop">
        <img class="img-fluid" src="../../../../assets/images/ensuredarkbluebannerwithproduct.png">
        <img class="titleImage manufactureImg" src="../../../../assets/images/ensurequalitycontrol.png">
    </div>
    <div class="ensureInnerBlock bgDarkBlue pb30">
        <div class="card secondaryCard pt50 mt40">
            <div class="topDateBlock singleDateBlock">
                <p class="text-center mb-0">{{ 'ENSURE_QUALITYCONTROL.EXPIRY_DATE' | translate }} {{expiryDate}}</p>
            </div>
            <p class="mt-3 contentMediumSmall">{{ 'ENSURE_QUALITYCONTROL.DESC_MANUFACTURING1' | translate }}</p>
            <img class="w-100" src="../../../../assets/images/labqualitytestimage.png">
            <p class="mt-3 contentMediumSmall">{{ 'ENSURE_QUALITYCONTROL.DESC_MANUFACTURING2' | translate }}</p>
            <div class="text-center takeEnsureContentBlock">
                <p class="mb-0">{{ 'ENSURE_QUALITYCONTROL.TAKE_ENSURE' | translate }}</p>
                <p class="mb-0">{{ 'ENSURE_QUALITYCONTROL.SCIENTIFICALLY' | translate }}</p>
                <p class="mb-0"> {{ 'ENSURE_QUALITYCONTROL.STRENGTH' | translate }} <sup>{{ 'ENSURE_QUALITYCONTROL.STAR' | translate }}</sup>.</p>
            </div>
            <img class="img-fluid -mb45 w-100 h-100" src="../../../../assets/images/whuyensuregoldimagewithshadow.png" routerLink="/why-ensure">
        </div>
        <div class="bottomContentSmall mt50">
            <p class="mb-0">{{ 'SOURCE_OF_INGREDIENTS.REFERENCES' | translate }}</p>
            <p class="mb-0">{{ 'SOURCE_OF_INGREDIENTS.DESC' | translate }}</p>
            <p class="mb-0">{{ 'SOURCE_OF_INGREDIENTS.DESC1' | translate }}</p>
            <p class="">{{ 'SOURCE_OF_INGREDIENTS.EXERCISE_CONTENT' | translate }}</p>
        </div>
    </div>
    <app-footer></app-footer>
</section>
<app-ensure-bottomnav></app-ensure-bottomnav>
