import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {
  isOpen = true;
  isFirstDisabled = true;
  terms: any;
  faq: any;
  faqTitle: any;
  faqDes: any;
  faqcontent: any;
  showFaqCloseIcon =  false;
  showTermsCloseIcon = false;
  showVoucherCloseIcon = false;
  showHouseCloseIcon = false;
  showChildCloseIcon = false;
  showHealthCloseIcon = false;
  showProductsCloseIcon = false;
  showEligibleCloseIcon = false;
  constructor(
    private apiService: ApiService, 
    private spinner: NgxSpinnerService, 
    private bsService: BugsnagService
    ) {
  }
  OnChange(event, id) {
    console.log('event', event, id);
    if (event === true && id === 'faq') {
      this.showFaqCloseIcon = true;
    } else {
      this.showFaqCloseIcon = false;
    }
    if (event === true && id === 'terms') {
      this.showTermsCloseIcon = true;
    } else {
      this.showTermsCloseIcon = false;
    }
    if (event === true && id === 'voucher') {
      this.showVoucherCloseIcon = true;
    } else {
      this.showVoucherCloseIcon = false;
    }
    if (event === true && id === 'child') {
      this.showChildCloseIcon = true;
    } else {
      this.showChildCloseIcon = false;
    }
    if (event === true && id === 'household') {
      this.showHouseCloseIcon = true;
    } else {
      this.showHouseCloseIcon = false;
    }
    if (event === true && id === 'health') {
      this.showHealthCloseIcon = true;
    } else {
      this.showHealthCloseIcon = false;
    }
    if (event === true && id === 'abbotproducts') {
      this.showProductsCloseIcon = true;
    } else {
      this.showProductsCloseIcon = false;
    }
    if (event === true && id === 'eligible') {
      this.showEligibleCloseIcon = true;
    } else {
      this.showEligibleCloseIcon = false;
    }
  }

  ngOnInit(): void {
    
    window.scrollTo({top:0});
    this.spinner.show();
    this.apiService.getTerms().subscribe((res: any) => {
      this.spinner.hide();
      res.data.forEach(item =>{
        if (item.key == 'terms_and_conditions') {
         this.terms = item.contentvalue;
        }
      })
    }, err => {
      this.bsService.notifyError(err);
    })
    this.apiService.getFaq().subscribe((res: any) => {
      this.spinner.hide();
      console.log('faq', res);
      this.faqcontent  = res.data;
    }, err => {
      this.bsService.notifyError(err);
    })

  }

}
