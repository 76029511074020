import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { first } from 'rxjs/operators';
import { environment as env } from '../../../environments/environment';
import { v4 as uuidv4 } from 'uuid';
declare var $: any;
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-care-points',
  templateUrl: './care-points.component.html',
  styleUrls: ['./care-points.component.scss'],
})
export class CarePointsComponent {
  userPoints: any;
  user: any;
  pointsList: any;
  findDay: any;
  rewardList: any;
  nearestListReward: any;
  lessPointvoucher: any;
  lastTranasction: any;
  measurementData: any;
  productList = [];
  hideCont: boolean = false;
  hideCont1: boolean = false;
  noData: any;
  webquloPointData: any;
  promotionalPointsList: any;
  finalFilteredobj: any;
  referralList: any;
  findGrothdairyflag: any;
  totalLength: any;
  rejectReason: any;
  @ViewChild(' scanQrcodeGuideModal', { static: true })
  scanQrcodeGuideModal: ModalDirective;
  lastTransactionDate: any = '';
  @ViewChild('reasonModal', { static: true }) reasonModal: ModalDirective;
  @ViewChild('reUploadImageModal', { static: true })
  reUploadImageModal: ModalDirective;
  @ViewChild('loginPopup', { static: true }) loginPopup: ModalDirective;

  selectedId: any;
  constructor(
    private apiService: ApiService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private bsService: BugsnagService
  ) {
    window.scrollTo({ top: 0 });
    let reload = this.route.snapshot.params.data;
    if (reload == 'login') {
      // location.reload();
    }
  }

  ngInit(): void {
    
    console.log("env: ",env.ENV)
    console.log("key: ",env.LICENSEKEY)
    this.getCustomer();
  }

  ngAfterViewInit(): void {
    this.loginPopup.show();
    this.getCustomer();
    this.getAllPoints();
    this.getRewardList();
    this.getWebquloMeasurement();
    this.getWebquloPointDetail();
  }

  onClickToScan() {
    // this.onClickHereToScan = true;
    this.scanQrcodeGuideModal.show();
  }

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    this.spinner.show();
    let getcustresp
    this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
      getcustresp = res;
      this.spinner.hide();
      if (res['status'].code == 200) {
        // Block the user
        let block = res['customers']['customer'][0].custom_fields.field;
        let blockUser = block.filter((filt) => {
          return filt.name == 'block_count';
        });
        console.log(blockUser, 'blockUser===');
        if (blockUser[0]?.value == '1') {
          this.spinner.hide();
          sessionStorage.clear();
          this.router.navigate(['/block-notice']);
        }
        let findgroth = res['customers']['customer'][0].custom_fields?.field;
        console.log(findgroth, 'custom fields');
        findgroth.filter((dataa) => {
          //console.log(dataa?.name == 'growthdiaryflag', 'growthdiaryflag===')
          if (dataa?.name == 'growthdiaryflag')
            this.findGrothdairyflag = dataa?.name == 'growthdiaryflag';
          console.log(this.findGrothdairyflag, 'growthdiaryflag===');
        });
        //  this.findGrothdairyflag =  findgroth.filter(dataa => console.log(dataa[0]?.name == 'growthdiaryflag'))
        //  console.log(this.findGrothdairyflag, 'this.findGrothdairyflag---')
        console.log(res['customers']['customer'][0].user_id, 'used id---');
        sessionStorage.setItem(
          'abbottUserId',
          res['customers']['customer'][0].user_id
        );
        this.user = res['customers']['customer'][0];
        this.userPoints = res['customers']['customer'][0]?.loyalty_points
          ? res['customers']['customer'][0]?.loyalty_points
          : '0';

        let promotionalPoints =
          res['customers']['customer'][0].promotion_points?.customer?.item;
        this.promotionalPointsList = promotionalPoints;

        console.log(promotionalPoints?.length, 'promotionalPoints ==');
        console.log('Referral result', res);
        //console.log("Promotional type", promotionalPoints)
        let referPointsArray = [];
        promotionalPoints.forEach((item) => {
          var referString = 'REFERRAL_CAMPAIGN';
          //var dobSrting = "Profile_DOB"
          var tempString1 = item.promotion_name.split('_');
          var tempString2 = tempString1.splice(0, 2);
          var referOutput = tempString2.join('_');
          //console.log("c",referString3)
          if (referOutput == referString) {
            let pointObject = {
              points: item.points,
              date: item.issued_on,
            };
            referPointsArray.push(pointObject);
          }
        });
        //console.log("referPointsArray", referPointsArray);
        this.referralList = referPointsArray;
        console.log(this.referralList, 'this.referralList');
      }
    },err=>{
      
      this.bsService.notifyError(err);
      console.log(mobile, '=== get customer mobile =======');
      console.log(err?.error?.message, '=== get customer error message =======');
      console.log(err?.error?.code, '=== get customer error code =======');
      this.spinner.hide()
      this.apiService.showErrorMsg('Something went wrong, please try again')
      console.log(err, '=== get customer error =======')
    });
  }
  // getAllPoints(){
  //   // let startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
  //   // let endOfMonth = moment().clone().endOf('month').add(1, 'day').format('YYYY-MM-DD');
  //   let mobile = sessionStorage.getItem('mobileNo')
  //   let token = sessionStorage.getItem('authToken')
  //   this.spinner.show();
  //   this.apiService.getPointsList(mobile,token).pipe(first()).subscribe((res:any) => {
  //     this.spinner.hide();
  //     this.pointsList = res?.customer?.transactions?.transaction;
  //     let pointslist = res?.customer?.transactions?.transaction;
  //    // pointslist.filter(resp => resp?.custom_fields?.field[1]?.value == 'PediaSure OptiHEIGHT Chocolate');
  //     let name =  pointslist.filter(resp => resp?.custom_fields?.field[1]?.value.startsWith('PediaSure'));
  //     console.log(name, 'name');
  //       let latest = name.reduce(function (r, a) {
  //         return r.billing_time > a.billing_time ? r : a;
  //     });
  //      console.log(latest, 'datefilt ==')
  //      let latestDate = moment(latest.billing_time).format('YYYY-MM-DD');
  //      console.log(latestDate, 'latestDate')

  //      let final = name.filter(day => day?.billing_time.startsWith(latestDate))
  //     console.log(final, 'final filter')

  //     this.finalFilteredobj = final;

  //     // let datefilt = new Date(Math.max(...name.map(e => new Date(e.billing_time))));
  //     // console.log(datefilt, 'datefilt ==')

  //     let billingTime = moment(this.pointsList[0]?.billing_time).format('DD/MM/YYYY');
  //     let today = new Date();
  //     let todayDate =  moment(today).add(2.5, 'hours').format('DD/MM/YYYY');
  //     let yesterday1 = moment(this.pointsList[0]?.billing_time).add(2.5, 'hours').format('DD/MM/YYYY');
  //     let yesterday2 =  moment(today).add(2.5, 'hours').subtract(1, 'day').format('DD/MM/YYYY');
  //     if(billingTime == todayDate){
  //       this.findDay = 'TODAY'
  //     }else if(yesterday1 == yesterday2){
  //       this.findDay = 'YESTERDAY'
  //     }else{
  //       this.findDay = 'LAST TRANSACTION DONE BY'
  //     }
  //     console.log("Customer result" ,res);
  //     console.log(moment(this.pointsList[0]?.billing_time).add(2.5, 'hours').format('hh:mm, DD-MM-YY'), 'pointsList[0]?.billing_time')
  //     this.lastTranasction = moment(this.pointsList[0]?.billing_time).add(2.5, 'hours').format('hh:mm, DD-MM-YY');
  //     console.log( this.lastTranasction, ' this.lastTranasction')

  //   })
  // }

  getRewardList() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    this.spinner.show();
    let getrewards;
    this.apiService
      .getMarvalRewardDetails(mobile, token)
      .pipe(first())
      .subscribe((reward: any) => {
        getrewards = reward;
        this.spinner.hide();
        this.rewardList = reward.rewardList;
        setTimeout(() => {
          let newarr = this.rewardList.filter((res) => {
            return res.intouchPoints < this.userPoints;
          });
          let fin = newarr.map((res) => {
            return res.intouchPoints;
          });
          console.log(fin, 'fin');
          let largest = fin.sort((a, b) => a - b).reverse()[0];
          let nearestReward = newarr.filter((res) => {
            return res.intouchPoints == largest;
          });
          this.nearestListReward = nearestReward[0];
          console.log(this.nearestListReward, 'this.nearestListReward');
        }, 1500);

        // Less points logic
        let noPoints = this.rewardList.map((nopoint) => {
          return nopoint.intouchPoints;
        });
        let minVal = Math.min.apply(Math, noPoints);
        let minvoucher = this.rewardList.filter((minVoucher) => {
          return minVoucher.intouchPoints == minVal;
        });
        let sortVoucher = minvoucher.sort(
          (a, b) => parseFloat(a.name) - parseFloat(b.name)
        );
        this.lessPointvoucher = sortVoucher[0];
        console.log(this.lessPointvoucher, 'lessPointvoucher');
      },err=>{
      

        this.bsService.notifyError(err);
        this.spinner.hide()
        this.apiService.showErrorMsg('Something went wrong, please try again')
        console.log(err, '=== get customer error =======')
      });
  }

  gotoHistory() {
    let access_token;
    let abbott_user_id = sessionStorage.getItem('abbottUserId');
    let getMobile = sessionStorage.getItem('mobileNo');
    let getOTPToken = sessionStorage.getItem('authToken');
    let getOTPhKey = sessionStorage.getItem('authKey');

    let auth_key = env.webQlo_api_key;
    let auth_secret = env.webQlo_api_secret;

    this.spinner.show();
    let redirect;
    this.apiService.redirectUrlApi(getMobile).subscribe((res: any) => {
      console.log(res, 'buzz bee token gen');
      redirect = res;
      access_token = res.result.access_token;

      (<HTMLInputElement>document.getElementById('access_token')).value =
        access_token;
      (<HTMLInputElement>document.getElementById('auth_key')).value = auth_key;
      (<HTMLInputElement>document.getElementById('auth_secret')).value =
        auth_secret;
      (<HTMLInputElement>document.getElementById('mobile')).value = getMobile;
      (<HTMLInputElement>document.getElementById('otp_token')).value =
        getOTPToken;
      (<HTMLInputElement>document.getElementById('otp_key')).value = getOTPhKey;
      (<HTMLInputElement>document.getElementById('Pediasure_Obj')).value =
        JSON.stringify(this.finalFilteredobj);
      (<HTMLInputElement>document.getElementById('target_page')).value =
        '/dashboard';
      $('#formSubmit').submit();
      this.spinner.hide();
      console.log(
        'Sending request to ' +
        $('#formSubmit').attr('action') +
        ' with data: ' +
        $('#formSubmit').serialize()
      );
    },err=>{
     

      this.bsService.notifyError(err);
      this.spinner.hide()
      this.apiService.showErrorMsg('Something went wrong, please try again')
      console.log(err, '=== get customer error =======')
    });
  }

  getWebquloMeasurement() {
    this.spinner.show();
    let abbott_user_id = sessionStorage.getItem('abbottUserId');
    let getMobile = sessionStorage.getItem('mobileNo');
    let formData = {
      'user_id': abbott_user_id,
    };
    let getmeasure;
    this.apiService.getWebqloMeasurement(formData).subscribe((res: any) => {
      getmeasure = res;
      console.log(res, 'webQulo data');
      this.spinner.hide();
      if (res.result == 'User not registered in growth diary.') {
        this.noData = 'User not registered in growth diary.';
      } else if (res.result == 'Invalid user ID.') {
        this.noData = 'Invalid user ID.';
      } else if (res.result == '') {
        this.noData = 'No Measuredata';
      }
      this.measurementData = res.result;
      console.log(this.measurementData[0]?.name, 'length');

      console.log(this.measurementData?.length, 'this.measurementData');
      if (this.measurementData.length == 0) {
        this.hideCont1 = true;
        console.log(this.hideCont1, 'this.hideCont');
      }
    },err=>{
     
      this.bsService.notifyError(err, formData);
      this.spinner.hide()
      this.apiService.showErrorMsg('Something went wrong, please try again')
      console.log(err, '=== get customer error =======')
    });
  }

  getWebquloPointDetail() {
    this.spinner.show();
    let abbott_user_id = sessionStorage.getItem('abbottUserId');
    let getMobile = sessionStorage.getItem('mobileNo');
    let formData = {
      'user_id': abbott_user_id,
    };
    let getpont;
    this.apiService.getWebqloPointdetail(formData).subscribe((res: any) => {
      getpont = res;
      console.log(res, 'webQulo Point data ====');
      this.spinner.hide();
      this.webquloPointData = res.result;
      if (this.webquloPointData.length == 0) {
        this.hideCont = true;
        console.log(this.hideCont, 'this.hideCont');
      }
    },err=>{
      
      this.bsService.notifyError(err, formData);
      this.spinner.hide()
      this.apiService.showErrorMsg('Something went wrong, please try again')
      console.log(err, '=== get customer error =======')
    });
  }

  getAllPoints() {
    console.log(this.productList, 'productssssss');

    let mobile = sessionStorage.getItem('mobileNo');
    this.spinner.show();
    let getallpoints;
    this.apiService
      .getProductsList(mobile)
      .pipe(first())
      .subscribe((res) => {
        console.log(res, 'result');
        getallpoints = res;
        this.spinner.hide();
        this.pointsList = res['result'];
        this.totalLength = this.pointsList.length;

        this.pointsList.forEach((product) => {
          let productItem = {
            id: product?.id,
            name: product.product_name,
            image: product.product_image,
            status: product.receipt_status,
            productCode: product.id,
            submissionDate: moment(product.submission_date).format(),
            points: product.points,
            reason: product.reason,
            color: '',
          };

          if (productItem.status == 'approved') {
            productItem.status = 'approved';
            productItem.color = '#30C878';
            if (productItem.points == null) {
              productItem.points = '';
            } else {
              productItem.points = '+' + productItem.points + ' ' + 'pts';
            }
          } else if (productItem.status == 'pending') {
            productItem.status = 'pending';
            productItem.points = '';
            productItem.color = '#8b8f91';
          } else if (productItem.status == 'rejected') {
            productItem.status = 'rejected';
            productItem.points = '';

            productItem.color = '#fe2121';
          } else if (productItem.status == 'awaiting') {
            productItem.status = 'awaiting';
            productItem.color = '#EFBE35';
            productItem.points = '';
          }

          this.productList.push(productItem);
          console.log(this.productList);
        });
        console.log(this.productList);
        let productListLength = this.productList?.length;
        if (productListLength > 0) {
          this.lastTransactionDate =
            this.productList[0].submissionDate;
          console.log(moment(this.lastTransactionDate).format('DD-MM-YYYY'));
        } else {
          this.lastTransactionDate = '';
        }
      }),
      (err) => {
       
        this.bsService.notifyError(err);
        this.spinner.hide()
        this.apiService.showErrorMsg('Something went wrong, please try again')
        console.log(err, '=== get customer error =======')
      };
  }
  prductCode: any;
  onclickUploadReceipt(productCode) {
    this.prductCode = productCode;
    console.log(this.prductCode, 'this.prductCode ');
    let data = {
      data: this.prductCode,
    };
    this.router.navigate(['/validate-receipt', data]);
  }
  onclickReUploadImageReceipt() {
    // this.prductCode = productCode;
    // console.log(this.prductCode, 'this.prductCode ');
    let data = {
      data: this.selectedId,
    };
    sessionStorage.setItem('reUpload', 'Uploaded')

    this.router.navigate(['/validate-receipt', data]);
  }
  openReUploadImageModal(id) {
    this.reUploadImageModal.show();
    console.log(id);
    this.selectedId = id;
  }
  closeReasonModal() {
    this.reasonModal.hide();
  }
  closeReUploadImageModal() {
    this.reUploadImageModal.hide();
  }
  onClickViewDetails(reason) {
    this.rejectReason = reason;
    console.log(this.rejectReason);
    this.reasonModal.show();
  }
  closeloginPopup(){
    this.loginPopup.hide();

  }

}
