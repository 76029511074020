<section class="similacNavMenu">
    <div class="card">
        <ul>
            <li>
                <a href="javascript:;" routerLinkActive="active" routerLink="/similac-milksource">
                    <img class="sourceImg" src="../../../assets/images/sourceiconensuresmall.png"   alt="home" *ngIf="!showMilkSource" />
                    <img class="sourceImg" src="../../../assets/images/soucriconensureactive.png"   alt="home" *ngIf="showMilkSource"  />

                    <span *ngIf="!hideInChinese">{{ 'SIMILAC_NAV.LINK_DESC1_LINE1' | translate }}<br> {{ 'SIMILAC_NAV.LINK_DESC1_LINE2' | translate }}</span>
                    <span *ngIf="hideInChinese">{{ 'SIMILAC_NAV.LINK_DESC1_LINE1' | translate }}</span>
                </a>
            </li>
            <li >
                <a href="javascript:;" routerLink="/similac-manufacturing">
                    <img class="manufacturingImg" src="../../../assets/images/manufacturinciconensuresmall.png" *ngIf="!showManufacturing" alt="" />
                    <img class="manufacturingImg" src="../../../assets/images/manufactureensureactive.png" *ngIf="showManufacturing" alt="" />

                    <span>{{ 'SIMILAC_NAV.LINK_DESC2' | translate }}</span>
                </a>
            </li>
            <li>
                <a href="javascript:;" routerLink="/similac-qualitycontrol" >
                    <img class="qualityControlImg " src="../../../assets/images/qualityiconensuresmall.png" *ngIf="!showQualityControl" alt="" />
                    <img class="qualityControlImg " src="../../../assets/images/qualityensureactive.png" *ngIf="showQualityControl" alt="" />

                    <span *ngIf="!hideInChinese">{{ 'SIMILAC_NAV.LINK_DESC3_LINE1' | translate }}<br>{{ 'SIMILAC_NAV.LINK_DESC3_LINE2' | translate }}</span>
                    <span *ngIf="hideInChinese">{{ 'SIMILAC_NAV.LINK_DESC3_LINE1' | translate }}</span>
                </a>
            </li>
            <li>
                <a href="javascript:;" routerLink="/similac-delivery">
                    <img class="deliveryImg" src="../../../assets/images/ensuredelivericonsmall.png" alt="trace"  *ngIf="!showDelivery"  />
                    <img class="deliveryImg" src="../../../assets/images/clearancective.png" *ngIf="showDelivery" alt="trace" />

                    <span *ngIf="!hideInChinese">{{ 'SIMILAC_NAV.LINK_DESC4_LINE1' | translate }}<br> {{ 'SIMILAC_NAV.LINK_DESC4_LINE2' | translate }}</span>
                    <span *ngIf="hideInChinese">{{ 'SIMILAC_NAV.LINK_DESC4_LINE1' | translate }}</span>
                </a>
            </li>
            <li >
                <a href="javascript:;" routerLink="/similac-nucleotides">
                    <img class="vitaminImg" src="../../../assets/images/protieniconensuresmall.png" *ngIf="!showNucleotides" alt="" />
                    <img class="vitaminImg" src="../../../assets/images/2flactive.png" *ngIf="showNucleotides"  alt="" />

                    <span *ngIf="!hideInChinese">{{ 'SIMILAC_NAV.LINK_DESC5_LINE1' | translate }}<br>{{ 'SIMILAC_NAV.LINK_DESC5_LINE2' | translate }}</span>
                    <span *ngIf="hideInChinese">{{ 'SIMILAC_NAV.LINK_DESC5_LINE1' | translate }}</span>
                </a>
            </li>
            <li >
                <a href="javascript:;" routerLink="/similac-sharecare">
                    <img class="sharCareImg" src="../../../assets/images/sharecareiconensuresmall.png" *ngIf="!showShareCare"  alt="" />
                    <img class="sharCareImg" src="../../../assets/images/sharecaresctive.png" *ngIf="showShareCare"  alt="" />

                    <span *ngIf="!hideInChinese">{{ 'SIMILAC_NAV.LINK_DESC6_LINE1' | translate }}<br>{{ 'SIMILAC_NAV.LINK_DESC6_LINE2' | translate }}</span>
                    <span *ngIf="hideInChinese">{{ 'SIMILAC_NAV.LINK_DESC6_LINE1' | translate }}</span>
                </a>
            </li>
        </ul>
    </div>
</section>