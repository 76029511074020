import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMyDpOptions } from 'mydatepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { environment as env } from '../../../environments/environment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  profileForm: FormGroup;
  submitted = false;
  myDate = new Date();
  cityList: any;
  stateList: any;
  user: any;
  registerForm: any;
  newNumber: any;
  public myDatePickerOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'mm.dd.yyyy',
  };
  changeMobNo: boolean = true;
  curr_date = new Date();
  childMinDate: any;
  childMaxDate: any;
  parentMinDate: any;
  parentMaxDate: any;
  mobLength: any;
  refCode:any;;
  readonly: boolean = false
  @ViewChild('profileUpdated', { static: true }) profileUpdated: ModalDirective;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private bsService: BugsnagService
  ) {

    this.refCode = this.route.snapshot.params.data;
    console.log(this.refCode, 'refcode-----')
    if(this.refCode){
      this.readonly = true;
    }else{
      this.readonly = false;
    }
    console.log( this.readonly, ' this.readonly')

    this.cityList = [
      {
        city: 'Air Itam',
      },
      {
        city: 'Air Keroh',
      },
      {
        city: 'Air Kuning Selatan',
      },
      {
        city: 'Air Molek',
      },
      {
        city: 'Air Tawar',
      },
      {
        city: 'AJIL',
      },
      {
        city: 'Alai',
      },
      {
        city: 'Almuktaffi Billah Shah',
      },
      {
        city: 'Alor Gajah',
      },
      {
        city: 'Alor Janggus',
      },
      {
        city: 'Alor Setar',
      },
      {
        city: 'Alor Star',
      },
      {
        city: 'Ampang',
      },
      {
        city: 'Ampang Jaya',
      },
      {
        city: 'Anak Bukit',
      },
      {
        city: 'Arau',
      },
      {
        city: 'Asahan',
      },
      {
        city: 'Asajaya',
      },
      {
        city: 'Asap',
      },
      {
        city: 'Ater Tawar',
      },
      {
        city: 'Ayer Baloi',
      },
      {
        city: 'Ayer Hitam',
      },
      {
        city: 'Ayer Itam',
      },
      {
        city: 'Ayer Keloh',
      },
      {
        city: 'Ayer Keroh',
      },
      {
        city: 'Ayer Lanas',
      },
      {
        city: 'Ayer Molek',
      },
      {
        city: 'Ayer Tawar',
      },
      {
        city: 'Ayer Tawar 2',
      },
      {
        city: 'Bachang',
      },
      {
        city: 'Bachok',
      },
      {
        city: 'Bagan Ajam',
      },
      {
        city: 'Bagan Datoh',
      },
      {
        city: 'Bagan Lalang',
      },
      {
        city: 'Bagan Serai',
      },
      {
        city: 'Bahau',
      },
      {
        city: 'Bakri',
      },
      {
        city: 'Balakong',
      },
      {
        city: 'Balik',
      },
      {
        city: 'Balik Pulau',
      },
      {
        city: 'Balik Pulau Penang',
      },
      {
        city: 'Baling',
      },
      {
        city: 'Balingian',
      },
      {
        city: 'Balok',
      },
      {
        city: 'Balok Kuantan',
      },
      {
        city: 'Bandar Amanjaya',
      },
      {
        city: 'Bandar Baharu',
      },
      {
        city: 'Bandar Baru Ampang',
      },
      {
        city: 'Bandar Baru Ayer Itam',
      },
      {
        city: 'Bandar Baru Bangi',
      },
      {
        city: 'Bandar baru jeli',
      },
      {
        city: 'Bandar Baru Nilai',
      },
      {
        city: 'Bandar Baru Pokok Sena',
      },
      {
        city: 'Bandar Baru Salak Tinggi',
      },
      {
        city: 'Bandar baru seberang perak',
      },
      {
        city: 'Bandar Baru Selayang',
      },
      {
        city: 'Bandar Baru Sri Jempol',
      },
      {
        city: 'BANDAR BARU SRI PETALING',
      },
      {
        city: 'Bandar Bayan Baru',
      },
      {
        city: 'Bandar Bera',
      },
      {
        city: 'Bandar Bharu',
      },
      {
        city: 'Bandar Botanik',
      },
      {
        city: 'Bandar Bukit Beruntung',
      },
      {
        city: 'Bandar Bukit Tinggi',
      },
      {
        city: 'Bandar Cheng',
      },
      {
        city: 'Bandar Damansara Perdana',
      },
      {
        city: 'Bandar Damansara Perdana',
      },
      {
        city: 'Bandar Dungun',
      },
      {
        city: 'Bandar Enstek',
      },
      {
        city: 'Bandar Enstek',
      },
      {
        city: 'Bandar Hilir',
      },
      {
        city: 'Bandar Jengka',
      },
      {
        city: 'Bandar Kinrara',
      },
      {
        city: 'Bandar kota marudu',
      },
      {
        city: 'Bandar Labuan',
      },
      {
        city: 'Bandar Langkawi',
      },
      {
        city: 'Bandar Mahkota',
      },
      {
        city: 'Bandar Mahkota Cheras',
      },
      {
        city: 'Bandar Melaka',
      },
      {
        city: 'Bandar Menjalara',
      },
      {
        city: 'Bandar Menjalara',
      },
      {
        city: 'Bandar Meru Raya',
      },
      {
        city: 'Bandar Muadzam Shah',
      },
      {
        city: 'Bandar Muallim',
      },
      {
        city: 'Bandar Nusajaya',
      },
      {
        city: 'Bandar Penawar',
      },
      {
        city: 'Bandar Permaisuri',
      },
      {
        city: 'Bandar Pontian',
      },
      {
        city: 'Bandar Puncak Alam',
      },
      {
        city: 'Bandar Puncak Alam Selangor',
      },
      {
        city: 'Bandar Pusat Jengka',
      },
      {
        city: 'Bandar Puteri',
      },
      {
        city: 'Bandar Putra',
      },
      {
        city: 'Bandar Putra Kulai',
      },
      {
        city: 'Bandar Putra Permai',
      },
      {
        city: 'Bandar Saujana Putra',
      },
      {
        city: 'Bandar Selesa Jaya',
      },
      {
        city: 'Bandar Seri Alam',
      },
      {
        city: 'Bandar Seri Iskandar',
      },
      {
        city: 'Bandar Seri Jempol',
      },
      {
        city: 'Bandar Seri Putra',
      },
      {
        city: 'Bandar Seri Sendayan',
      },
      {
        city: 'Bandar Sri Damansara',
      },
      {
        city: 'Bandar Sri Permaisuri',
      },
      {
        city: 'Bandar Sri Senayan',
      },
      {
        city: 'Bandar Sri Sendayan',
      },
      {
        city: 'Bandar Sungai Buaya',
      },
      {
        city: 'Bandar Sunway',
      },
      {
        city: 'Bandar Sunway',
      },
      {
        city: 'Bandar Tanah Merah',
      },
      {
        city: 'Bandar Tasik Kesuma',
      },
      {
        city: 'Bandar Tasik Selatan',
      },
      {
        city: 'bandar tenggara',
      },
      {
        city: 'Bandar Triang',
      },
      {
        city: 'Bandar Tun Abdul Razak',
      },
      {
        city: 'Bandar Tun Razak',
      },
      {
        city: 'Bandar Utama',
      },
      {
        city: 'Bandarputra',
      },
      {
        city: 'Bangi',
      },
      {
        city: 'Bangsar',
      },
      {
        city: 'Bangsar South City',
      },
      {
        city: 'Bangsar South City',
      },
      {
        city: 'Banting',
      },
      {
        city: 'Baram',
      },
      {
        city: 'Barat Daya',
      },
      {
        city: 'Batang Berjuntai',
      },
      {
        city: 'Batang Kali',
      },
      {
        city: 'Batang Tiga',
      },
      {
        city: 'Batangkali',
      },
      {
        city: 'Bati Gajah',
      },
      {
        city: 'Batu 9 Cheras',
      },
      {
        city: 'Batu 9 Cheras',
      },
      {
        city: 'Batu Arang',
      },
      {
        city: 'Batu Berendam',
      },
      {
        city: 'Batu Bernam',
      },
      {
        city: 'Batu Caves',
      },
      {
        city: 'Batu Feringghi',
      },
      {
        city: 'Batu Ferringhi',
      },
      {
        city: 'Batu Gajah',
      },
      {
        city: 'Batu hampar',
      },
      {
        city: 'Batu Kawa',
      },
      {
        city: 'Batu Kawan',
      },
      {
        city: 'Batu Kikir',
      },
      {
        city: 'Batu Kurau',
      },
      {
        city: 'Batu Lancang',
      },
      {
        city: 'Batu Lanchang',
      },
      {
        city: 'Batu Maung',
      },
      {
        city: 'Batu Pahat',
      },
      {
        city: 'Bau',
      },
      {
        city: 'Bayan Baru',
      },
      {
        city: 'Bayan Lepas',
      },
      {
        city: 'Bdr Baharu',
      },
      {
        city: 'Bdr Baru Ayer Itam',
      },
      {
        city: 'Bdr Baru Bangi',
      },
      {
        city: 'Bdr Baru Lembah Selatan',
      },
      {
        city: 'Bdr Bayan Baru',
      },
      {
        city: 'Bdr Behrang',
      },
      {
        city: 'Bdr Botanic',
      },
      {
        city: 'Bdr Kinrara',
      },
      {
        city: 'Bdr Permaisuri',
      },
      {
        city: 'Bdr Puchong Jaya',
      },
      {
        city: 'Bdr Puncak Alam',
      },
      {
        city: 'Bdr Seri Alam',
      },
      {
        city: 'Bdr Seri Jempol',
      },
      {
        city: 'Bdr Sri Damansara',
      },
      {
        city: 'Bdr Sultan Sulaiman',
      },
      {
        city: 'Bdr Tasek Mutiara',
      },
      {
        city: 'Bdr Tun Abdul Razak',
      },
      {
        city: 'Bdr Tun Razak',
      },
      {
        city: 'Beaufort',
      },
      {
        city: 'Beaufort',
      },
      {
        city: 'Bedoh',
      },
      {
        city: 'Bedong',
      },
      {
        city: 'Behrang Stesen',
      },
      {
        city: 'Bekenu',
      },
      {
        city: 'Bekok',
      },
      {
        city: 'Belaga',
      },
      {
        city: 'Belawai',
      },
      {
        city: 'Beluran',
      },
      {
        city: 'Bemban',
      },
      {
        city: 'Benta',
      },
      {
        city: 'Bentong',
      },
      {
        city: 'Benut',
      },
      {
        city: 'Bera',
      },
      {
        city: 'Beranang',
      },
      {
        city: 'Berlimau',
      },
      {
        city: 'Bertam',
      },
      {
        city: 'Bertam Malim',
      },
      {
        city: 'Bertam ulu',
      },
      {
        city: 'Beruas',
      },
      {
        city: 'Beserah Kuantan',
      },
      {
        city: 'Beseri',
      },
      {
        city: 'Bestari Jaya',
      },
      {
        city: 'Besut',
      },
      {
        city: 'Betong',
      },
      {
        city: 'Bidong',
      },
      {
        city: 'Bidor',
      },
      {
        city: 'Bintangor',
      },
      {
        city: 'Bintong',
      },
      {
        city: 'Bintulu',
      },
      {
        city: 'Bistari Jaya',
      },
      {
        city: 'Bkt Katil',
      },
      {
        city: 'Bkt Mertajam',
      },
      {
        city: 'Bkt Rambai',
      },
      {
        city: 'Bongawan',
      },
      {
        city: 'Bota',
      },
      {
        city: 'Bota Kanan',
      },
      {
        city: 'Brickfields',
      },
      {
        city: 'Brinchang',
      },
      {
        city: 'Bruas',
      },
      {
        city: 'Bt. Caves',
      },
      {
        city: 'Bt. Gajah',
      },
      {
        city: 'Bt Gajah',
      },
      {
        city: 'Bt. Maung',
      },
      {
        city: 'Bt. Pahat',
      },
      {
        city: 'Bt Pahat',
      },
      {
        city: 'Btg Kali',
      },
      {
        city: 'Bukit Baru',
      },
      {
        city: 'Bukit Belutong',
      },
      {
        city: 'Bukit Beruang',
      },
      {
        city: 'Bukit Beruntung',
      },
      {
        city: 'Bukit Besi',
      },
      {
        city: 'Bukit Damansara',
      },
      {
        city: 'Bukit Damansara',
      },
      {
        city: 'Bukit Gambir',
      },
      {
        city: 'Bukit Gedung',
      },
      {
        city: 'Bukit Gelugor',
      },
      {
        city: 'Bukit Jalil',
      },
      {
        city: 'Bukit Jambul',
      },
      {
        city: 'Bukit Jambul',
      },
      {
        city: 'Bukit Kapar',
      },
      {
        city: 'Bukit Katil',
      },
      {
        city: 'Bukit kayu hitam',
      },
      {
        city: 'Bukit Mertajam',
      },
      {
        city: 'Bukit Mertajam Spt',
      },
      {
        city: 'Bukit Minyak',
      },
      {
        city: 'Bukit Payong',
      },
      {
        city: 'Bukit Rambai',
      },
      {
        city: 'Bukit Rotan',
      },
      {
        city: 'Bukit Siput',
      },
      {
        city: 'Bukit Tambun',
      },
      {
        city: 'Buloh Kasap',
      },
      {
        city: 'Buntong',
      },
      {
        city: 'Butterworth',
      },
      {
        city: 'Caluk',
      },
      {
        city: 'Cameron Highlands',
      },
      {
        city: 'Chaah',
      },
      {
        city: 'Chalok',
      },
      {
        city: 'Chang Loon',
      },
      {
        city: 'Changiun',
      },
      {
        city: 'Changkat Jering',
      },
      {
        city: 'Changkat Keruing',
      },
      {
        city: 'Changloon',
      },
      {
        city: 'Changlun',
      },
      {
        city: 'Chemoh',
      },
      {
        city: 'Chemor',
      },
      {
        city: 'Chenderiang',
      },
      {
        city: 'Chenderong Balai',
      },
      {
        city: 'Cheng',
      },
      {
        city: 'Chenor',
      },
      {
        city: 'Cherang Ruku',
      },
      {
        city: 'Cheras',
      },
      {
        city: 'Cheras Baru',
      },
      {
        city: 'Cheras Selangor',
      },
      {
        city: 'Chini',
      },
      {
        city: 'Chukai',
      },
      {
        city: 'Chuping',
      },
      {
        city: 'Cukai',
      },
      {
        city: 'Cyberjaya',
      },
      {
        city: 'Dabong',
      },
      {
        city: 'Dalat',
      },
      {
        city: 'Damak',
      },
      {
        city: 'Damansara',
      },
      {
        city: 'Damansara Damai',
      },
      {
        city: 'Damansara Heights',
      },
      {
        city: 'Damasara Heights',
      },
      {
        city: 'Daro',
      },
      {
        city: 'Debak',
      },
      {
        city: 'Dengkil',
      },
      {
        city: 'DESA PARK CITY',
      },
      {
        city: 'Desa Petaling',
      },
      {
        city: 'Desa Tun Abdul Razak',
      },
      {
        city: 'Dungun',
      },
      {
        city: 'Durian Tunggal',
      },
      {
        city: 'Endau',
      },
      {
        city: 'Enggor',
      },
      {
        city: 'Engkilili',
      },
      {
        city: 'Entilibon Asal',
      },
      {
        city: 'Fasa Bachok',
      },
      {
        city: 'Gambang',
      },
      {
        city: 'Gelang Patah',
      },
      {
        city: 'Gelugor',
      },
      {
        city: 'Gemas',
      },
      {
        city: 'Gemencheh',
      },
      {
        city: 'Geniang',
      },
      {
        city: 'Genting Highland',
      },
      {
        city: 'George Town',
      },
      {
        city: 'Georgetown',
      },
      {
        city: 'Gerik',
      },
      {
        city: 'Gombak',
      },
      {
        city: 'Gopeng',
      },
      {
        city: 'Greenlane',
      },
      {
        city: 'Greenlane Height',
      },
      {
        city: 'Gua Musang',
      },
      {
        city: 'Guar Chempedak',
      },
      {
        city: 'Guchil Bayam',
      },
      {
        city: 'Gurun',
      },
      {
        city: 'Hulu Bernam',
      },
      {
        city: 'Hulu Kelang',
      },
      {
        city: 'Hulu Kinta',
      },
      {
        city: 'Hulu Langat',
      },
      {
        city: 'Hulu Selangor',
      },
      {
        city: 'Hulu Terengganu',
      },
      {
        city: 'Huluyam Bharu',
      },
      {
        city: 'Hutan Melintang',
      },
      {
        city: 'Ijok',
      },
      {
        city: 'Inanam',
      },
      {
        city: 'Indapura kulai',
      },
      {
        city: 'Intan',
      },
      {
        city: 'Ipoh',
      },
      {
        city: 'Ipoh Garden',
      },
      {
        city: 'Ipoh Garden South',
      },
      {
        city: 'Iskandar Puteri',
      },
      {
        city: 'Island Park',
      },
      {
        city: 'Jahal',
      },
      {
        city: 'Jasin',
      },
      {
        city: 'Jawi',
      },
      {
        city: 'Jb',
      },
      {
        city: 'Jelapang',
      },
      {
        city: 'Jelebu',
      },
      {
        city: 'Jeli',
      },
      {
        city: 'Jelutong',
      },
      {
        city: 'Jemaluang',
      },
      {
        city: 'Jementah',
      },
      {
        city: 'Jempol',
      },
      {
        city: 'Jengka',
      },
      {
        city: 'Jeniang',
      },
      {
        city: 'Jenjarom',
      },
      {
        city: 'Jeram',
      },
      {
        city: 'Jerantut',
      },
      {
        city: 'Jerteh',
      },
      {
        city: 'Jertih',
      },
      {
        city: 'Jinjang',
      },
      {
        city: 'Jinjang Utara',
      },
      {
        city: 'Jitra',
      },
      {
        city: 'Jo',
      },
      {
        city: 'Johol',
      },
      {
        city: 'Johor',
      },
      {
        city: 'Johor Bahru',
      },
      {
        city: 'Johor Bharu',
      },
      {
        city: 'Johor Jaya',
      },
      {
        city: 'Johor Nusa Jaya',
      },
      {
        city: 'Juasseh',
      },
      {
        city: 'Julau',
      },
      {
        city: 'Juru',
      },
      {
        city: 'Kabong',
      },
      {
        city: 'Kahang',
      },
      {
        city: 'Kajang',
      },
      {
        city: 'Kaki Bukit',
      },
      {
        city: 'Kalumpang',
      },
      {
        city: 'Kampar',
      },
      {
        city: 'Kampong Gajah',
      },
      {
        city: 'Kampong Raja',
      },
      {
        city: 'Kampung',
      },
      {
        city: 'Kampung Alai',
      },
      {
        city: 'Kampung Baru',
      },
      {
        city: 'Kampung Fikri',
      },
      {
        city: 'Kampung Gajah',
      },
      {
        city: 'Kampung Kembang Ipoh',
      },
      {
        city: 'Kampung Kepaya',
      },
      {
        city: 'Kampung Kepayang',
      },
      {
        city: 'Kampung Kerinchi',
      },
      {
        city: 'Kampung Malim Nawar',
      },
      {
        city: 'Kampung Raja',
      },
      {
        city: 'Kamunting',
      },
      {
        city: 'Kangar',
      },
      {
        city: 'Kangkar Pulai',
      },
      {
        city: 'Kanowit',
      },
      {
        city: 'Kapar',
      },
      {
        city: 'Kapar Klang',
      },
      {
        city: 'Kapit',
      },
      {
        city: 'Karak',
      },
      {
        city: 'Karangan',
      },
      {
        city: 'Kedah',
      },
      {
        city: 'Kelana Jaya',
      },
      {
        city: 'Kemaman',
      },
      {
        city: 'Kemasik',
      },
      {
        city: 'Kemayan',
      },
      {
        city: 'Kempas',
      },
      {
        city: 'Kemunting',
      },
      {
        city: 'Keningau',
      },
      {
        city: 'Kepala Batas',
      },
      {
        city: 'Kepong',
      },
      {
        city: 'Keramat',
      },
      {
        city: 'Keratong',
      },
      {
        city: 'Kerian',
      },
      {
        city: 'Kerling',
      },
      {
        city: 'Kerteh',
      },
      {
        city: 'Kerteh 2',
      },
      {
        city: 'Ketengah Jaya',
      },
      {
        city: 'Ketereh',
      },
      {
        city: 'Kg. Baru',
      },
      {
        city: 'Kg Gajah',
      },
      {
        city: 'Kg Kepayang',
      },
      {
        city: 'Kijal',
      },
      {
        city: 'Kinabatangan',
      },
      {
        city: 'Kinarut',
      },
      {
        city: 'KINRARA',
      },
      {
        city: 'Kiulu',
      },
      {
        city: 'Kl',
      },
      {
        city: 'Kl Central',
      },
      {
        city: 'Klang',
      },
      {
        city: 'Klang Lama',
      },
      {
        city: 'Klang Lama',
      },
      {
        city: 'Klebang Besar',
      },
      {
        city: 'Kluang',
      },
      {
        city: 'Klubong',
      },
      {
        city: 'Kodiang',
      },
      {
        city: 'Kok Lanas',
      },
      {
        city: 'Kota',
      },
      {
        city: 'Kota Bahru',
      },
      {
        city: 'Kota Belud',
      },
      {
        city: 'Kota Bharu',
      },
      {
        city: 'Kota Damansara',
      },
      {
        city: 'Kota K',
      },
      {
        city: 'Kota Kamuning',
      },
      {
        city: 'Kota Kinabalu',
      },
      {
        city: 'Kota Kinabatangan',
      },
      {
        city: 'Kota Kuala Muda',
      },
      {
        city: 'Kota Marudu',
      },
      {
        city: 'Kota Marudu South',
      },
      {
        city: 'Kota Masai',
      },
      {
        city: 'Kota Samarahan',
      },
      {
        city: 'Kota Samarindah',
      },
      {
        city: 'Kota Sarang Semut',
      },
      {
        city: 'Kota setar',
      },
      {
        city: 'Kota Tinggi',
      },
      {
        city: 'Krubong',
      },
      {
        city: 'Kuah',
      },
      {
        city: 'Kuala Terengganu',
      },
      {
        city: 'Kuala Balah',
      },
      {
        city: 'Kuala Berang',
      },
      {
        city: 'Kuala Besut',
      },
      {
        city: 'Kuala Dungun',
      },
      {
        city: 'KUALA IBAI',
      },
      {
        city: 'Kuala Kangar',
      },
      {
        city: 'Kuala Kangsar',
      },
      {
        city: 'Kuala Kedah',
      },
      {
        city: 'Kuala Ketil',
      },
      {
        city: 'Kuala Klawang',
      },
      {
        city: 'Kuala Krai',
      },
      {
        city: 'Kuala Krau',
      },
      {
        city: 'Kuala Kubu',
      },
      {
        city: 'Kuala Kubu Baru',
      },
      {
        city: 'Kuala kubu bharu',
      },
      {
        city: 'Kuala Kurau',
      },
      {
        city: 'Kuala Langat',
      },
      {
        city: 'Kuala Langat Selangor',
      },
      {
        city: 'Kuala Lipis',
      },
      {
        city: 'Kuala Lumpur',
      },
      {
        city: 'Kuala Nerang',
      },
      {
        city: 'Kuala Nerus',
      },
      {
        city: 'Kuala Penyu',
      },
      {
        city: 'Kuala Perlis',
      },
      {
        city: 'Kuala Pilah',
      },
      {
        city: 'Kuala Rompin',
      },
      {
        city: 'Kuala Selangor',
      },
      {
        city: 'Kuala Sepetang',
      },
      {
        city: 'Kuala sg baru',
      },
      {
        city: 'Kuala Sg. Baru',
      },
      {
        city: 'Kuala Sungai Baru',
      },
      {
        city: 'Kuala Terengganu',
      },
      {
        city: 'Kualau Lumpur',
      },
      {
        city: 'Kuang',
      },
      {
        city: 'Kuantan',
      },
      {
        city: 'Kuantan Gambang',
      },
      {
        city: 'Kubang',
      },
      {
        city: 'Kubang Kerian',
      },
      {
        city: 'Kubang Pasu',
      },
      {
        city: 'Kubang Semang',
      },
      {
        city: 'Kuchai Lama',
      },
      {
        city: 'Kuching',
      },
      {
        city: 'Kuching Sarawak',
      },
      {
        city: 'Kuching Serian',
      },
      {
        city: 'Kuchng',
      },
      {
        city: 'Kudat',
      },
      {
        city: 'Kukup',
      },
      {
        city: 'Kukup Pontian',
      },
      {
        city: 'Kulai',
      },
      {
        city: 'Kulai Jaya',
      },
      {
        city: 'Kulaijaya',
      },
      {
        city: 'Kulim',
      },
      {
        city: 'Kunak',
      },
      {
        city: 'Kundang',
      },
      {
        city: 'Kupang',
      },
      {
        city: 'Kupang Baling',
      },
      {
        city: 'Labis',
      },
      {
        city: 'Labu',
      },
      {
        city: 'Labuan',
      },
      {
        city: 'Lahad',
      },
      {
        city: 'Lahad Datu',
      },
      {
        city: 'Lahat',
      },
      {
        city: 'Lahat Datu',
      },
      {
        city: 'Lanchang',
      },
      {
        city: 'Langgar',
      },
      {
        city: 'Langkap',
      },
      {
        city: 'Langkawi',
      },
      {
        city: 'Larkin',
      },
      {
        city: 'Lawas',
      },
      {
        city: 'Layang Layang',
      },
      {
        city: 'Layang-Layang',
      },
      {
        city: 'Lebuh Ampang',
      },
      {
        city: 'Ledang',
      },
      {
        city: 'Lembah Pantai',
      },
      {
        city: 'Lenggeng',
      },
      {
        city: 'Lenggong',
      },
      {
        city: 'Limbang',
      },
      {
        city: 'Linggi',
      },
      {
        city: 'Lobak',
      },
      {
        city: 'Lubok China',
      },
      {
        city: 'Lubuk redan',
      },
      {
        city: 'Lukut',
      },
      {
        city: 'Lumut',
      },
      {
        city: 'Lunas',
      },
      {
        city: 'Lundu',
      },
      {
        city: 'Lurah Bilut',
      },
      {
        city: 'Lutong',
      },
      {
        city: 'Luyang',
      },
      {
        city: 'Machang',
      },
      {
        city: 'Majedee',
      },
      {
        city: 'Malaka',
      },
      {
        city: 'Malaysia',
      },
      {
        city: 'Maleka',
      },
      {
        city: 'Malim',
      },
      {
        city: 'Malim Jaya',
      },
      {
        city: 'Malim Mawar',
      },
      {
        city: 'Malim Nawar',
      },
      {
        city: 'Maling Mawar',
      },
      {
        city: 'Manir',
      },
      {
        city: 'Manjung',
      },
      {
        city: 'Manong',
      },
      {
        city: 'Mantin',
      },
      {
        city: 'Maran',
      },
      {
        city: 'Marang',
      },
      {
        city: 'Marudi',
      },
      {
        city: 'Masai',
      },
      {
        city: 'Masai Johor Bahru',
      },
      {
        city: 'Masjid Tanah',
      },
      {
        city: 'Mata Air',
      },
      {
        city: 'Matang',
      },
      {
        city: 'Matu',
      },
      {
        city: 'Medamit',
      },
      {
        city: 'Megat Dewa',
      },
      {
        city: 'Melaka',
      },
      {
        city: 'Melaka',
      },
      {
        city: 'Melaka Baru',
      },
      {
        city: 'Melaka City',
      },
      {
        city: 'Melaka Raya',
      },
      {
        city: 'Melaka Tengah',
      },
      {
        city: 'Melor',
      },
      {
        city: 'Membakut',
      },
      {
        city: 'Mendakab',
      },
      {
        city: 'Mendakap',
      },
      {
        city: 'Menggatal',
      },
      {
        city: 'Menglembu',
      },
      {
        city: 'Mentakab',
      },
      {
        city: 'Menumbok',
      },
      {
        city: 'Merang',
      },
      {
        city: 'Merbok',
      },
      {
        city: 'Mercang',
      },
      {
        city: 'Merinding',
      },
      {
        city: 'Merlimau',
      },
      {
        city: 'Mersing',
      },
      {
        city: 'Meru',
      },
      {
        city: 'Minden',
      },
      {
        city: 'Miri',
      },
      {
        city: 'Mont Kiara',
      },
      {
        city: 'Mount austin',
      },
      {
        city: 'Muadzam Shah',
      },
      {
        city: 'Muar',
      },
      {
        city: 'Muar Ledang',
      },
      {
        city: 'Mukah',
      },
      {
        city: 'Mulu',
      },
      {
        city: 'Nabawan',
      },
      {
        city: 'Naka',
      },
      {
        city: 'Negeri Sembilan',
      },
      {
        city: 'Niah',
      },
      {
        city: 'Niah Miri',
      },
      {
        city: 'Nibong',
      },
      {
        city: 'Nibong Tebal',
      },
      {
        city: 'Nilai',
      },
      {
        city: 'Nusa Bayu',
      },
      {
        city: 'Nusa Jaya',
      },
      {
        city: 'Nusajaya',
      },
      {
        city: 'Nyalas',
      },
      {
        city: 'P. Langkawi',
      },
      {
        city: 'Padang Besar',
      },
      {
        city: 'Padang Rengas',
      },
      {
        city: 'Padang Serai',
      },
      {
        city: 'Padawan',
      },
      {
        city: 'Pagoh',
      },
      {
        city: 'Paka',
      },
      {
        city: 'Paloh',
      },
      {
        city: 'Palong',
      },
      {
        city: 'Pancaran Hutan',
      },
      {
        city: 'Pandamaran',
      },
      {
        city: 'Pandan Indah',
      },
      {
        city: 'Pandan Perdana',
      },
      {
        city: 'Pandanmas',
      },
      {
        city: 'Pangka',
      },
      {
        city: 'Pangkor',
      },
      {
        city: 'Pantai Dalam',
      },
      {
        city: 'Pantai Remis',
      },
      {
        city: 'Papar',
      },
      {
        city: 'Parit',
      },
      {
        city: 'Parit Buntar',
      },
      {
        city: 'Parit Jawa',
      },
      {
        city: 'Parit Raja',
      },
      {
        city: 'Parit Sulong',
      },
      {
        city: 'Pasar Selangau',
      },
      {
        city: 'Pasir Emas',
      },
      {
        city: 'Pasir Gudang',
      },
      {
        city: 'Pasir Kelantan',
      },
      {
        city: 'Pasir Mas',
      },
      {
        city: 'Pasir Panjang',
      },
      {
        city: 'Pasir Permai',
      },
      {
        city: 'Pasir Puteh',
      },
      {
        city: 'Pasir Putih',
      },
      {
        city: 'Patsok',
      },
      {
        city: 'Paya Rumput',
      },
      {
        city: 'Paya Terubong',
      },
      {
        city: 'Pdg Serai',
      },
      {
        city: 'Pedas',
      },
      {
        city: 'Pejkan Nanas',
      },
      {
        city: 'Pekan',
      },
      {
        city: 'Pekan Nanas',
      },
      {
        city: 'Pekan Nenas',
      },
      {
        city: 'Pekan salak',
      },
      {
        city: 'Pelabuhan Klang',
      },
      {
        city: 'Penaga',
      },
      {
        city: 'Penaga Kepala Batas',
      },
      {
        city: 'Penaga Seberang Perai',
      },
      {
        city: 'Penampang',
      },
      {
        city: 'Penang',
      },
      {
        city: 'Pendang',
      },
      {
        city: 'Pengerang',
      },
      {
        city: 'Pengkalan Batu',
      },
      {
        city: 'Pengkalan Chepa',
      },
      {
        city: 'Pengkalan Hulu',
      },
      {
        city: 'Pengkalan Ipoh',
      },
      {
        city: 'Perai',
      },
      {
        city: 'Perak',
      },
      {
        city: 'Perak Tengah',
      },
      {
        city: 'Peringat',
      },
      {
        city: 'Peringgit',
      },
      {
        city: 'Perling',
      },
      {
        city: 'Permaisuri',
      },
      {
        city: 'Permas',
      },
      {
        city: 'Permataing Tinggi',
      },
      {
        city: 'Permatang Pauh',
      },
      {
        city: 'Petaling Jaya',
      },
      {
        city: 'Petaling Utama',
      },
      {
        city: 'Petrajaya',
      },
      {
        city: 'Pinang',
      },
      {
        city: 'Pitas',
      },
      {
        city: 'Pokok',
      },
      {
        city: 'Pokok Sena',
      },
      {
        city: 'Pontian',
      },
      {
        city: 'Port Dickson',
      },
      {
        city: 'Port Klang',
      },
      {
        city: 'Pp',
      },
      {
        city: 'Prai',
      },
      {
        city: 'Puchong',
      },
      {
        city: 'Puchong Jaya',
      },
      {
        city: 'Puchong Selangor',
      },
      {
        city: 'Pulai',
      },
      {
        city: 'Pulai Chondong',
      },
      {
        city: 'Pulai Jaya',
      },
      {
        city: 'Pulau Carey',
      },
      {
        city: 'Pulau Gadong',
      },
      {
        city: 'Pulau Indah',
      },
      {
        city: 'Pulau Ketam',
      },
      {
        city: 'Pulau Langkawi',
      },
      {
        city: 'Pulau Pangkor',
      },
      {
        city: 'Pulau Pinang',
      },
      {
        city: 'Pulau Tikus',
      },
      {
        city: 'Puncak Alam',
      },
      {
        city: 'PUSA',
      },
      {
        city: 'Pusing',
      },
      {
        city: 'Putatan',
      },
      {
        city: 'Puteri Iskandar',
      },
      {
        city: 'Putrajaya',
      },
      {
        city: 'Rambai',
      },
      {
        city: 'Ranau',
      },
      {
        city: 'Rantau',
      },
      {
        city: 'Rantau Panjang',
      },
      {
        city: 'Rasa',
      },
      {
        city: 'Raub',
      },
      {
        city: 'Rawang',
      },
      {
        city: 'Relau',
      },
      {
        city: 'Rembau',
      },
      {
        city: 'Rengam',
      },
      {
        city: 'Renggam',
      },
      {
        city: 'Rengit',
      },
      {
        city: 'Rengit Batu Pahat',
      },
      {
        city: 'Rinching',
      },
      {
        city: 'Ringlet',
      },
      {
        city: 'Roban',
      },
      {
        city: 'Rompin',
      },
      {
        city: 'Sabah',
      },
      {
        city: 'Sabak Bernam',
      },
      {
        city: 'Sadong Jaya',
      },
      {
        city: 'Salak Utara',
      },
      {
        city: 'Samarahan',
      },
      {
        city: 'Sandakan',
      },
      {
        city: 'Sanglong',
      },
      {
        city: 'Santubong',
      },
      {
        city: 'Saratok',
      },
      {
        city: 'Sarikei',
      },
      {
        city: 'Saujana Rawang',
      },
      {
        city: 'Sauk',
      },
      {
        city: 'Seberang Jaya',
      },
      {
        city: 'Seberang Perai',
      },
      {
        city: 'Seberang Perai Tengah',
      },
      {
        city: 'Seberang Perai Utara',
      },
      {
        city: 'Seberang Perak',
      },
      {
        city: 'Seberang Prai',
      },
      {
        city: 'Seberang Prai Selatan',
      },
      {
        city: 'Seberang Prai Utara',
      },
      {
        city: 'Sebuyau',
      },
      {
        city: 'Sega',
      },
      {
        city: 'Segamat',
      },
      {
        city: 'Segambut',
      },
      {
        city: 'Sekinchan',
      },
      {
        city: 'Selama',
      },
      {
        city: 'Selandar',
      },
      {
        city: 'Selangau Sibu',
      },
      {
        city: 'Selangor',
      },
      {
        city: 'Selayang',
      },
      {
        city: 'Selayang baru',
      },
      {
        city: 'Selekoh',
      },
      {
        city: 'Selising',
      },
      {
        city: 'Semabok',
      },
      {
        city: 'Semanggol',
      },
      {
        city: 'Semenyih',
      },
      {
        city: 'Semerah',
      },
      {
        city: 'Semerah Batu Pahat',
      },
      {
        city: 'Semporna',
      },
      {
        city: 'Senai',
      },
      {
        city: 'Senawang',
      },
      {
        city: 'Senggarang',
      },
      {
        city: 'Sentul',
      },
      {
        city: 'Sentul Pasar',
      },
      {
        city: 'Sepang',
      },
      {
        city: 'Sepanggar',
      },
      {
        city: 'Seperang Selatan',
      },
      {
        city: 'Seraki',
      },
      {
        city: 'Serdang',
      },
      {
        city: 'Seremban',
      },
      {
        city: 'Seremban 2',
      },
      {
        city: 'Seremban Dua',
      },
      {
        city: 'Seremban Jaya',
      },
      {
        city: 'Seremban Negeri',
      },
      {
        city: 'Serendah',
      },
      {
        city: 'Seri Botani',
      },
      {
        city: 'Seri Gading',
      },
      {
        city: 'Seri Iskandar',
      },
      {
        city: 'Seri Kembangan',
      },
      {
        city: 'Seri Manjong',
      },
      {
        city: 'Seri Manjung',
      },
      {
        city: 'Seri Menanti',
      },
      {
        city: 'Serian',
      },
      {
        city: 'Serikei',
      },
      {
        city: 'Serkat',
      },
      {
        city: 'Setapak',
      },
      {
        city: 'Setapak Jaya',
      },
      {
        city: 'Setia Alam',
      },
      {
        city: 'Setiawan',
      },
      {
        city: 'Setiawangsa',
      },
      {
        city: 'Setiu',
      },
      {
        city: 'Sg Ampat',
      },
      {
        city: 'Sg Ara',
      },
      {
        city: 'Sg Asap',
      },
      {
        city: 'Sg Bakap',
      },
      {
        city: 'Sg Bakap Sps',
      },
      {
        city: 'Sg Besar',
      },
      {
        city: 'Sg Besi',
      },
      {
        city: 'Sg Buloh',
      },
      {
        city: 'Sg Gadut',
      },
      {
        city: 'Sg Jawi',
      },
      {
        city: 'Sg Lalang Bedong',
      },
      {
        city: 'Sg Lalang Nedong',
      },
      {
        city: 'Sg Nibong',
      },
      {
        city: 'Sg Patani',
      },
      {
        city: 'Sg Pelek',
      },
      {
        city: 'Sg Petani',
      },
      {
        city: 'Sg Rambai',
      },
      {
        city: 'Sg Siput',
      },
      {
        city: 'Sg Siput (U)',
      },
      {
        city: 'Sg Yan',
      },
      {
        city: 'Shah Alam',
      },
      {
        city: 'Sibu',
      },
      {
        city: 'Siburan',
      },
      {
        city: 'Sibuti',
      },
      {
        city: 'Sijangkang',
      },
      {
        city: 'Sik',
      },
      {
        city: 'Sikamat',
      },
      {
        city: 'Simpang',
      },
      {
        city: 'Simpang Ampat',
      },
      {
        city: 'Simpang Ampat Semanggol',
      },
      {
        city: 'Simpang Durian',
      },
      {
        city: 'Simpang Empat',
      },
      {
        city: 'Simpang Pertang',
      },
      {
        city: 'Simpang Pulai',
      },
      {
        city: 'Simpang Rengam',
      },
      {
        city: 'Simpang Renggam',
      },
      {
        city: 'Simpang Taiping',
      },
      {
        city: 'Simpat Ampat',
      },
      {
        city: 'Simunjan',
      },
      {
        city: 'Sintok',
      },
      {
        city: 'Sipitang',
      },
      {
        city: 'Siputeh',
      },
      {
        city: 'Sirusa',
      },
      {
        city: 'Sitiawan',
      },
      {
        city: 'Skudai',
      },
      {
        city: 'Slim River',
      },
      {
        city: 'Song',
      },
      {
        city: 'Spg. Ampat',
      },
      {
        city: 'Spg Ampat',
      },
      {
        city: 'Spg Durian',
      },
      {
        city: 'Spg Empat',
      },
      {
        city: 'Sri Aman',
      },
      {
        city: 'Sri Aman Engkilili',
      },
      {
        city: 'Sri Kembangan',
      },
      {
        city: 'Sri Manjung',
      },
      {
        city: 'Sri Petaling',
      },
      {
        city: 'Stampin',
      },
      {
        city: 'Stiawan',
      },
      {
        city: 'Suaran',
      },
      {
        city: 'Subang',
      },
      {
        city: 'Subang Jaya',
      },
      {
        city: 'Sugai Buloh',
      },
      {
        city: 'Sukau',
      },
      {
        city: 'Sukudai',
      },
      {
        city: 'Sungai Ara',
      },
      {
        city: 'Sungai Bakap',
      },
      {
        city: 'Sungai Besar',
      },
      {
        city: 'Sungai Besi',
      },
      {
        city: 'Sungai Buloh',
      },
      {
        city: 'Sungai Dua',
      },
      {
        city: 'Sungai Dua Butterworth',
      },
      {
        city: 'Sungai Gadut',
      },
      {
        city: 'Sungai Jawi',
      },
      {
        city: 'Sungai Koyan',
      },
      {
        city: 'Sungai Lang',
      },
      {
        city: 'Sungai Mati',
      },
      {
        city: 'Sungai Mati Ledang',
      },
      {
        city: 'Sungai Nibong',
      },
      {
        city: 'Sungai Pe',
      },
      {
        city: 'Sungai Pelek',
      },
      {
        city: 'Sungai Petani',
      },
      {
        city: 'Sungai Pinang',
      },
      {
        city: 'Sungai Pinang',
      },
      {
        city: 'Sungai Rambai',
      },
      {
        city: 'Sungai Siput',
      },
      {
        city: 'Sungai Siput (U)',
      },
      {
        city: 'Sungai Siput Utara',
      },
      {
        city: 'Sungai Udang',
      },
      {
        city: 'Sungkai',
      },
      {
        city: 'Taiping',
      },
      {
        city: 'Talek Bang Tumpat',
      },
      {
        city: 'Tamah Merah',
      },
      {
        city: 'Taman Air Bemban',
      },
      {
        city: 'Taman Bertam Indah',
      },
      {
        city: 'Tambun',
      },
      {
        city: 'Tambunan',
      },
      {
        city: 'Tamparuli',
      },
      {
        city: 'Tamparuri',
      },
      {
        city: 'Tampin',
      },
      {
        city: 'Tampoi',
      },
      {
        city: 'Tanah Merah',
      },
      {
        city: 'Tanah Rata',
      },
      {
        city: 'Tangkak',
      },
      {
        city: 'Tangkak Ledang',
      },
      {
        city: 'Tanjong Bungah',
      },
      {
        city: 'Tanjong Karang',
      },
      {
        city: 'Tanjong Keling',
      },
      {
        city: 'Tanjong Kling',
      },
      {
        city: 'Tanjong Malim',
      },
      {
        city: 'Tanjong Piandang',
      },
      {
        city: 'Tanjong Rambutan',
      },
      {
        city: 'Tanjong Sepat',
      },
      {
        city: 'Tanjong Tualang',
      },
      {
        city: 'Tanjung Bungah',
      },
      {
        city: 'TANJUNG IPOH',
      },
      {
        city: 'Tanjung Karang',
      },
      {
        city: 'Tanjung Kling',
      },
      {
        city: 'Tanjung Malim',
      },
      {
        city: 'Tanjung Minyak',
      },
      {
        city: 'Tanjung Piandang',
      },
      {
        city: 'Tanjung Rambutan',
      },
      {
        city: 'Tanjung Sepat',
      },
      {
        city: 'Tanjung Tokong',
      },
      {
        city: 'Tanjung Tualang',
      },
      {
        city: 'Tanjung Tuaran',
      },
      {
        city: 'Tapah',
      },
      {
        city: 'Tapah Road',
      },
      {
        city: 'Tapar',
      },
      {
        city: 'Tasek Gelugor',
      },
      {
        city: 'Tasik Emas',
      },
      {
        city: 'Tasik Gelugur',
      },
      {
        city: 'Tasik Mas',
      },
      {
        city: 'Tawau',
      },
      {
        city: 'Tebakang',
      },
      {
        city: 'Tebong',
      },
      {
        city: 'Tebrau',
      },
      {
        city: 'Teku',
      },
      {
        city: 'Telipok',
      },
      {
        city: 'Telok Mas',
      },
      {
        city: 'Telok Panglima',
      },
      {
        city: 'Telok Panglima Garang',
      },
      {
        city: 'Teluk Bahang',
      },
      {
        city: 'Teluk Intan',
      },
      {
        city: 'Teluk Kumbar',
      },
      {
        city: 'Teluk Panglima Garang',
      },
      {
        city: 'Telupid',
      },
      {
        city: 'Telur Mas',
      },
      {
        city: 'Temangan',
      },
      {
        city: 'Temerloh',
      },
      {
        city: 'Temoh',
      },
      {
        city: 'Tengkera',
      },
      {
        city: 'Tenom',
      },
      {
        city: 'Terbrau Johor Bahru',
      },
      {
        city: 'Teriang',
      },
      {
        city: 'Termeloh',
      },
      {
        city: 'Terong',
      },
      {
        city: 'Tg Bungah',
      },
      {
        city: 'Tg Karang',
      },
      {
        city: 'Tg. Malim',
      },
      {
        city: 'Tg Malim',
      },
      {
        city: 'Tg Manis',
      },
      {
        city: 'Tg Piandang',
      },
      {
        city: 'Tg Tokong',
      },
      {
        city: 'Tg Tualang',
      },
      {
        city: 'Tg.Malim',
      },
      {
        city: 'Tikam Batu',
      },
      {
        city: 'Tikam Batu Sungai Petani',
      },
      {
        city: 'Tldm Lumut',
      },
      {
        city: 'Triang',
      },
      {
        city: 'Trolak',
      },
      {
        city: 'Trong',
      },
      {
        city: 'Tronoh',
      },
      {
        city: 'Tuaran',
      },
      {
        city: 'Tumpat',
      },
      {
        city: 'Tumpat',
      },
      {
        city: 'Ujong Pasir',
      },
      {
        city: 'Ulu Kelang',
      },
      {
        city: 'Ulu Kinta',
      },
      {
        city: 'Ulu Klang',
      },
      {
        city: 'Ulu Tiram',
      },
      {
        city: 'Ulu Tiran',
      },
      {
        city: 'Ulu Yam Bharu',
      },
      {
        city: 'Uru Tiram',
      },
      {
        city: 'Wakaf Baru',
      },
      {
        city: 'Wakaf Bharu',
      },
      {
        city: 'Wakas Bahru',
      },
      {
        city: 'Wanga Maju',
      },
      {
        city: 'Wangsa Maju',
      },
      {
        city: 'Wilayah Labuan',
      },
      {
        city: 'Wilayah Persekutuan Kuala Lumpur',
      },
      {
        city: 'Wp Labuan',
      },
      {
        city: 'Yan',
      },
      {
        city: 'Yan Besar',
      },
      {
        city: 'Yong Peng',
      },
    ];

    this.stateList = [
      {
        state: 'Kedah',
      },
      {
        state: 'Pulau Pinang',
      },
      {
        state: 'Perak',
      },
      {
        state: 'Perlis',
      },
      {
        state: 'Selangor',
      },
      {
        state: 'Negeri Sembilan',
      },
      {
        state: 'Melaka',
      },
      {
        state: 'Johor',
      },
      {
        state: 'Pahang',
      },
      {
        state: 'Terengganu',
      },
      {
        state: 'Kelantan',
      },
      {
        state: 'Sabah',
      },
      {
        state: 'Sarawak',
      },
      {
        state: 'Wilayah Persekutuan Kuala Lumpur',
      },
      {
        state: 'Wilayah Persekutuan Putrajaya',
      },
      {
        state: 'Wilayah Persekutuan Labuan',
      },
    ];
  }

  latestMobNo: any = null;
  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    this.profileForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      gender: ['', Validators.required],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
          ),
        ],
      ],
      DOB: ['', Validators.required],
      favouriteBrand: ['', Validators.required],
      addressLine1: ['', Validators.required],
      addressLine2: ['', Validators.required],
      city: ['', Validators.required],
      stateProvince: ['', Validators.required],
      //   Validators.pattern(/\b\d{5}\b/g)
      postalCode: [
        '',
        [Validators.required, Validators.minLength(5), Validators.minLength(5)],
      ],
      country: ['MALAYSIA', Validators.required],
      mobile: [
        '',
        [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(10),
        ],
      ],
      childsName: [''],
      serving: [''],
      childDob: [''],
      //   subscription: [false]
    });
    this.getCustomer();
    this.latestMobNo = sessionStorage.getItem('latestMobNo');
    this.childMinDate = moment(this.curr_date).subtract(10, 'years');
    this.childMinDate = this.childMinDate['_d'];

    this.childMaxDate = this.curr_date;

    this.parentMaxDate = moment(this.curr_date).subtract(18, 'years');
    this.parentMaxDate = this.parentMaxDate['_d'];
  }

  get fVal() {
    return this.profileForm.controls;
  }

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    this.spinner.show();
    this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
      this.spinner.hide();
      console.log(res, 'res---');
      if (res['status'].code == 200) {
        // Block the user
        let block = res['customers']['customer'][0].custom_fields.field;
        let blockUser = block.filter((filt) => {
          return filt.name == 'block_count';
        });
        console.log(blockUser, 'blockUser===');
        if (blockUser[0]?.value == '1') {
          sessionStorage.clear();
          this.router.navigate(['/block-notice']);
        }
        console.log(res['customers']['customer'][0].user_id, 'used id---');
        sessionStorage.setItem(
          'abbottUserId',
          res['customers']['customer'][0].user_id
        );
        this.user = res['customers']['customer'][0];
        let customFields = this.user.custom_fields['field'];
        let extendedfieds = this.user.extended_fields['field'];
        console.log(this.user, 'this.user');
        this.PatchUserData(this.user, customFields, extendedfieds);
      }
    }, err => {
      this.spinner.hide();
      this.bsService.notifyError(err);
      console.log(mobile, '=== get customer mobile =======');
      console.log(err?.error?.message, '=== get customer error message =======');
      console.log(err?.error?.code, '=== get customer error code =======');
    });
  }

  PatchUserData(user, customFields, extendedfieds) {
    let mobile = sessionStorage.getItem('mobileNo');
    console.log(customFields, 'customFields');
    console.log(extendedfieds, 'extendedfieds');
    let trimMobile = (user?.mobile).substring(2);
    this.profileForm.patchValue({
      firstName: user?.firstname,
      lastName: user?.lastname,
      email: user?.email,
      mobile: trimMobile,
      // mobile: user?.mobile,
      // mobile: this.latestMobNo != null ? this.latestMobNo : user?.mobile,
    });
    customFields.filter((ele) => {
      if (ele.name == 'gender' && ele.value != '') {
        this.profileForm.patchValue({ gender: ele.value });
      }
      if (ele.name == 'dob' && ele.value != '') {
        this.profileForm.patchValue({
          DOB: moment(ele.value, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        });
      }
      if (ele.name == 'gender' && ele.value != '') {
        this.profileForm.patchValue({ gender: ele.value });
      }
      // if(ele.name == 'favourite_brand' && ele.value != ""){
      //     this.profileForm.patchValue({favouriteBrand: ele.value})
      // }
      // if(ele.name == 'address_1' && ele.value != ""){
      //     this.profileForm.patchValue({addressLine1: ele.value})
      // }
      // if(ele.name == 'address_2' && ele.value != ""){
      //     this.profileForm.patchValue({addressLine2: ele.value})
      // }
      if (ele.name == 'city' && ele.value != '') {
        this.profileForm.patchValue({ city: ele.value });
      }
      if (ele.name == 'province' && ele.value != '') {
        this.profileForm.patchValue({ stateProvince: ele.value });
      }
      // if(ele.name == 'postal_code' && ele.value != ""){
      //     this.profileForm.patchValue({postalCode: ele.value})
      // }
      if (ele.name == 'country' && ele.value != '') {
        this.profileForm.patchValue({ country: ele.value });
      }
      if (ele.name == 'child_name_1' && ele.value != '') {
        this.profileForm.patchValue({ childsName: ele.value });
      }
      if (ele.name == 'serves_per_day' && ele.value != '') {
        this.profileForm.patchValue({ serving: ele.value });
      }
      if (ele.name == 'child_dob_one' && ele.value != '') {
        this.profileForm.patchValue({
          childDob: moment(ele.value, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        });
      }
    });

    extendedfieds.filter((ele) => {
      if (ele.name == 'zip' && ele.value != '') {
        this.profileForm.patchValue({ postalCode: ele.value });
      }
      if (ele.name == 'city' && ele.value != '') {
        this.profileForm.patchValue({ city: ele.value });
      }
      if (ele.name == 'child1_dob' && ele.value != '') {
        this.profileForm.patchValue({
          childDob: moment(ele.value, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        });
      }
      if (ele.name == 'preferred_brand' && ele.value != '') {
        console.log(ele.name, ele.value, 'preferred_brand');
        this.profileForm.patchValue({ favouriteBrand: ele.value });
      }
      if (ele.name == 'state' && ele.value != '') {
        this.profileForm.patchValue({ stateProvince: ele.value });
      }
      if (ele.name == 'dob' && ele.value != '') {
        this.profileForm.patchValue({
          DOB: moment(ele.value, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        });
      }
      if (ele.name == 'gender' && ele.value != '') {
        this.profileForm.patchValue({ gender: ele.value });
      }
      if (ele.name == 'area' && ele.value != '') {
        this.profileForm.patchValue({ addressLine1: ele.value });
      }
      if (ele.name == 'sub_area' && ele.value != '') {
        this.profileForm.patchValue({ addressLine2: ele.value });
      }
    });
  }

  onProfileFormSubmit() {
    this.submitted = true;
    if (this.profileForm.invalid) {
      console.log(this.profileForm.controls, 'inside invalid');
      console.log(this.profileForm.value, 'value---');
      return;
    } else {
      console.log('inside valid--');
      let userEditData = this.profileForm.value;
      console.log(userEditData, 'userEditData--');
      let filledFormValues = this.getuserForm(userEditData);
      let getMobile = sessionStorage.getItem('mobileNo');
      let token = sessionStorage.getItem('authToken');
      this.spinner.show();
     

      this.apiService
        .updateCapCustomer(filledFormValues, getMobile, token)
        .pipe(first())
        .subscribe(
          (res) => {
           
            this.spinner.hide();
            if (res['status'].code == 200) {
              if (userEditData?.email != null) {
                let updateEmail = {
                  root: {
                    customer: [
                      {
                        identifier: 'email',
                        old_value: this.user?.email,
                        new_value: userEditData?.email,
                      },
                    ],
                  },
                };
                this.spinner.show();
                this.apiService
                  .updateCapCustomerIdentity(updateEmail, getMobile, token)
                  .pipe(first())
                  .subscribe(
                    (res) => {
                      console.log(res, '=== updated email ===');
                      this.spinner.hide();
                    },
                    (err) => {
                      this.bsService.notifyError(err);
                      console.log(err, 'err===');
                    }
                  );
              }

              this.showProfileModal();
              console.log('update successfully--');
            } else {
              console.log(res['status'].message);
              //this.toastr.info(res['status'].message)
            }
          },
          (err) => {
            this.spinner.hide();
            this.bsService.notifyError(err);
            console.log(err, '=== update customer error =======');
          }
        );
    }
  }

  getuserForm(userData) {
    return (this.registerForm = {
      root: {
        customer: [
          {
            mobile: userData.mobile,
            // "mobile": mobile,
            email: userData.email,
            firstname: userData.firstName,
            lastname: userData.lastName,
            custom_fields: {
              field: [
                // {
                //   "name": "gender",
                //   "value": userData.gender
                // },
                // {
                //   "name": "dob",
                //   "value": userData?.DOB ? moment(userData.DOB, "DD-MM-YYYY").format("YYYY-MM-DD") : ''
                // },
                // {
                //   "name": "favourite_brand",
                //   "value": userData.favouriteBrand
                // },
                // {
                //   "name": "address_1",
                //   "value": userData.addressLine1
                // },
                // {
                //   "name": "address_2",
                //   "value": userData.addressLine2
                // },
                // {
                //   "name": "city",
                //   "value": userData.city
                // },
                // {
                //   "name": "province",
                //   "value": userData.stateProvince
                // },
                // {
                //   "name": "postal_code",
                //   "value": userData.postalCode
                // },
                {
                  name: 'country',
                  value: userData.country,
                },
                {
                  name: 'child_name_1',
                  value: userData.childsName,
                },
                // {
                //   "name": "child_dob_one",
                //   "value": userData?.childDob ? moment(userData.childDob, "DD-MM-YYYY").format("YYYY-MM-DD") : ''
                // },
                {
                  name: 'serves_per_day',
                  value: userData.serving,
                },
              ],
            },
            extended_fields: {
              field: [
                {
                  name: 'gender',
                  value: userData.gender,
                },
                {
                  name: 'dob',
                  value: userData?.DOB
                    ? moment(userData.DOB, 'DD-MM-YYYY').format('YYYY-MM-DD')
                    : '',
                },
                {
                  name: 'zip',
                  value: userData.postalCode,
                },
                {
                  name: 'state',
                  value: userData.stateProvince,
                },
                {
                  name: 'preferred_brand',
                  value: userData.favouriteBrand,
                },
                {
                  name: 'city',
                  value: userData.city,
                },
                {
                  name: 'child1_dob',
                  value: userData?.childDob
                    ? moment(userData.childDob, 'DD-MM-YYYY').format(
                        'YYYY-MM-DD'
                      )
                    : '',
                },
                {
                  name: 'area',
                  value: userData.addressLine1,
                },
                {
                  name: 'sub_area',
                  value: userData.addressLine2,
                },
              ],
            },
          },
        ],
      },
    });
  }

  setNewMobileNo() {
    // let mobile = sessionStorage.getItem('mobileNo')
    //   console.log(this.newNumber, 'this.newNumber')
    //   this.newNumber == undefined ? sessionStorage.setItem('NewMobileNo', mobile):
    //     sessionStorage.setItem('NewMobileNo',  this.newNumber)
    console.log(this.profileForm.value.mobile, 'test mobile');
    this.mobLength = this.profileForm.value.mobile.length;
    if (this.mobLength < 9 || this.mobLength > 10) {
    } else {
      let data = {
        mobNum: this.profileForm.value.mobile,
      };
      this.router.navigate(['/update-mobile', data]);
    }
  }

  newMobile(ev) {
    this.newNumber = ev.target.value;
    console.log(ev.target.value, 'val--');
  }
  editMob() {
    this.changeMobNo = false;
  }

  onlyCharacters(ev) {
    let onlynum = /^[a-zA-Z .]*$/;

    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }

  onlyNumbers(ev) {
    let onlynum = /^[0-9]*$/;

    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
    this.mobLength = ev.target.value.length + 1;
  }

  onlyPostalNumbers(ev) {
    let onlynum = /^[0-9]*$/;

    if (!onlynum.test(ev.key)) {
      ev.preventDefault();
    }
  }

  closeProfileModal() {
    this.profileUpdated.hide();
  }

  showProfileModal() {
    this.profileUpdated.show();
  }

  onClickLogout() {
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }
  gotoReward(){
    this.router.navigate(['/redeem-rewards']);
  }
}
