<app-header></app-header>
<section class="pediaSureGlobalContainer">
    <section class="pediaSureTraceabilityContainer">
        <div class="pediaSureInnerContainer positon-relative">
            <!-- This commented for usage -->
            <div class="topBannerContent">
                <p>Rest assured that this product
                is a genuine Abbott product</p>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-5">
                        <img class="leftCurvyLine" src="../../../assets/images/leftcurvyline.png">
                    </div>
                    <div class="col-7 text-left mt-3 pl40">
                        <!-- <img class="img-fluid mb-4" [src]="productImage"> -->
                        <img
                            *ngIf="isIsprint == false"
                            class="prodImg"
                            [src]="prodImage"
                            alt="ensure"
                            />
                            <img
                            *ngIf="isIsprint == true"
                            class="prodImg w-100"
                            [src]="productImage"
                            alt="ensure"
                            />
                        <p class="mb-0 titleMain">{{ productName }}</p>
                        <p class="descSmallMedium">{{ 'TRACEABILITY_PEDIASURE.BATCH_NUMBER' | translate }} {{batchNumber}}</p>
                    </div>
                </div>
                <div class="row mt40">
                    <div class="col-8 text-left">
                        <figure class="">
                            <img class="manufacturingImg" routerLink="/pediasure-manufacturing" src="../../../assets/images/manufacturingwhite.png" />
                        </figure>
                        <figure>
                            <img class="titleImages" src="../../../assets/images/manufacturinggold.png" />
                        </figure>
                        <p class="mb-0 descSmallBold">{{ 'TRACEABILITY_PEDIASURE.BATCH_NUMBER' | translate }} <span class="descSmallMedium">{{batchNumber}}</span></p>
                        <p class="mb-0 descSmallBold">
                            {{ 'TRACEABILITY_PEDIASURE.MANUFACTURED' | translate }} {{manufactorIn}}
                        </p>
                        <p class="mb-0 descSmallBold">
                            {{ 'TRACEABILITY_PEDIASURE.MANUFACTURED_DATE' | translate }} <span class="descSmallMedium">{{manufacturingDate}}</span>
                        </p>
                    </div>
                    <div class="col-4 text-left mt-2">
                        <img class="rightCurvyLine" src="../../../assets/images/rightcurvyline.png">
                    </div>
                </div>
                <div class="row mt50">
                    <div class="col-4">
                        <img class="leftCurvyLineOne" src="../../../assets/images/leftcurvyline.png">
                    </div>
                    <div class="col-8 text-center pl60">
                        <figure class="text-center">
                            <img class="qualityControlImg" routerLink="/pediasure-qualitycontrol" src="../../../assets/images/qualitycontrolwhite.png" />
                        </figure>
                        <figure class="">
                            <img class="titleImages" src="../../../assets/images/qualitycontrolgold.png" />
                        </figure>
                        <p class="descSmallBold"> {{ 'TRACEABILITY_PEDIASURE.EXPIRY_DATE' | translate }} <span class="descSmallMedium">{{expiryDate}}</span> </p>
                    </div>
                </div>
                <div class="row mt40">
                    <div class="col-8 text-left">
                        <figure class="">
                            <img class="manufacturingImg" routerLink="/pediasure-delivery" src="../../../assets/images/deliverywhite.png" />
                        </figure>
                        <figure>
                            <img class="titleImages" src="../../../assets/images/delivergold.png" />
                        </figure>
                        <p class="mb-0 descSmallBold"> {{ 'TRACEABILITY_PEDIASURE.CUSTOM_CLEARANCE' | translate }} <span class="descSmallMedium">{{customClearanceDate}}</span>
                        </p>
                        <p class="mb-0 descSmallBold"> {{ 'TRACEABILITY_PEDIASURE.ARRIVED' | translate }}  <span class="descSmallMedium">{{arrivalDate}} </span></p>
                    </div>
                </div>
                <div class="getFreeBlock text-center pt30 mt60 pb30 mx10">
                    <p class="smallContentTop">{{ 'TRACEABILITY_PEDIASURE.SMALL_CODE' | translate }}</p>
                    <p class="mb-0 titleBottom">{{ 'TRACEABILITY_PEDIASURE.GET_FREE' | translate }}</p>
                    <p class="mb-0 titleBottom">{{ 'TRACEABILITY_PEDIASURE.SAMPLE_TODAY' | translate }}</p>
                    <p class="mt-3 smallContent">{{ 'TRACEABILITY_PEDIASURE.TASTE_IT' | translate }}</p>
                    <button class="btn getNowBtn text-uppercase"><a href="https://pediasure.com.my/"> {{ 'TRACEABILITY_PEDIASURE.GET_IT_NOW' | translate }}</a></button>
                </div>
            </div>
        </div>
    
        <app-footer></app-footer>
    </section>
</section>
<app-pediasure-bottomnav></app-pediasure-bottomnav>
