import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pediasure-delivery',
  templateUrl: './pediasure-delivery.component.html',
  styleUrls: ['./pediasure-delivery.component.scss'],
})
export class PediasureDeliveryComponent implements OnInit {
  productInfo: any;
  productImage: any;
  content: any;
  productCode: any;
  productName: any;
  productName1: any;
  productName2: any;
  batchNumber: any;
  sourceofIn: any;
  manufacturingDate: any;
  expiryDate: any;
  arrivalDate: any;
  manufactorIn: any;
  customClearanceDate: any;

  constructor() {
    this.productInfo = JSON.parse(sessionStorage.getItem('iSprintData'));
    console.log(this.productInfo, 'productInfo');
  }

  parseFunction(value) {
    return JSON.parse(value);
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    this.productImage = this.productInfo.product_image;
    this.getTraceabilityContent();
  }

  getTraceabilityContent() {
    //TODO: populate custom claearance date and arrival date only
    this.customClearanceDate = this.productInfo.custom_clearance_date.value;
    this.arrivalDate = this.productInfo.arrival_date.value;
  }
}
