<app-header-primary></app-header-primary>
<div class="blueBg">
  <div class="text-left">
    <p class="-mt20" (click)="onClickBack()">
      <i class="mdi mdi-arrow-left backIcon"></i> Go back
    </p>
  </div>
  <div class="redeemRewardDetailsOut">
    <div class="headerImg">
      <img
        src="../../../assets/images/redeem_rewards.png"
        alt="redeem rewards"
      />
    </div>
    <div class="row">
      <div class="col col-4">
        <div class="productImg">
          <img
            *ngIf="rewardData?.imageUrl"
            [src]="rewardData?.imageUrl"
            alt="product card"
          />
        </div>
      </div>
      <div class="col col-8">
        <div class="redeemRewardDetails">
          <h4 class="text-uppercase">
            {{ rewardData?.name }}
            <span>{{ rewardData?.intouchPoints }} CAREPOINTS</span>
          </h4>
          <p>{{ rewardData?.description }}</p>
          <div class="range" *ngIf="showRewardCount">
            <button
              type="button"
              (click)="countDecrement()"
              [disabled]="valueCounter <= 1 || pointsExcedeedError"
            >
              -
            </button>
            <input
              type="number"
              [(ngModel)]="valueCounter"
              value="1"
              disabled
            />
            <button
              type="button"
              href="javascript:;"
              (click)="countIncrement()"
              [disabled]="pointsExcedeedError"
            >
              +
            </button>
          </div>
          <div class="range" *ngIf="!showRewardCount">
            <input
              type="number"
              [(ngModel)]="valueCounter"
              value="1"
              disabled
            />
          </div>
          <button
            class="blueBtn"
            [disabled]="pointsExcedeedError || pointsExcedeedErrorVal"
            (click)="redeemVoucher()"
          >
            redeem
          </button>
          <span
            class="error"
            *ngIf="pointsExcedeedError || pointsExcedeedErrorVal"
            >Sorry, looks like you don’t have enough points for
            redemption.</span
          >

          <div
            bsModal
            #confirmMdl="bs-modal"
            class="modal fade"
            [config]="{ backdrop: 'static' }"
            tabindex="-1"
            role="dialog"
            aria-labelledby="dialog-child-name"
          >
            <div class="modal-dialog modal-sm">
              <div class="modal-content" *ngIf="addressFound">
                <div class="modal-body">
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    (click)="closeScannedModal()"
                  >
                    x
                  </button>
                  <p class="text-center mt-5 mb-3">
                    Please confirm that you want to make this redemption. Please
                    note that once confirm, CarePoints will be deducted from
                    your account to make this redemption and are not reversible.
                    Strictly no change allowed on item redeemed after
                    confirmation.
                  </p>
                  <p class="text-center">
                    Please update delivery address on your Profile before
                    confirming your redemption.
                  </p>

                  <div class="row">
                    <div class="col-6 text-right">
                      <button
                        type="submit"
                        class="btn btn-default mt-3"
                        (click)="closeScannedModal()"
                        style="border: 1px solid"
                      >
                        Cancel
                      </button>
                    </div>
                    <div class="col-6">
                      <button
                        type="submit"
                        class="btn btn-primary text-white mt-3"
                        (click)="reedem(rewardData)"
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-content" *ngIf="!addressFound">
                <div class="modal-body">
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    (click)="closeScannedModal()"
                  >
                    x
                  </button>
                  <p class="text-center mt-5 mb-3">
                    Oops, we noticed that you didn’t provide a mailing address
                    for us to send you your item. Please click “Update Address”
                    to proceed.
                  </p>
                  <div class="text-center">
                    <button
                      type="submit"
                      class="btn btn-primary text-white mt-3 r"
                      (click)="onClickOk()"
                    >
                      Update Address
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="redeemRewardDetails mt-0">
      <!-- <span class="error m-0" *ngIf="pointsExceeded"
        >Sorry, you have exceed the redemption limit of the year (1200 points
        per year).</span
      > -->
      <span class="error m-0" *ngIf="pointsExceeded"
        >Sorry, either you have exceed the annual redemption limit of 1,200
        points or you have not made a transaction (product scan).<br />Please
        make a transaction before making your redemption.</span
      >
      <span class="error m-0" *ngIf="firstReward"
        >Sorry, you are only allowed to redeem only 1 unit for this item.</span
      >
    </div>
  </div>
  <app-version></app-version>
  <div class="-mx-20">
    <app-footer></app-footer>
  </div>
</div>
<app-bottom-nav></app-bottom-nav>
