<app-header-secondary homeLogo="../../../assets/images/homeicon.svg" link="/traceability-similac"></app-header-secondary>
<section class="similacGlobalContainer">
    <div class="topBannerBlock"></div>
    <div class="similacInnerBlock pb50">
        <div class="card">
            <img class="img-fluid topImg" *ngIf="ValueSelected == '' || ValueSelected == 'undefined' || ValueSelected == 'en_US'" src="../../../../assets/images/similacnucleotidesnew.png">
            <img class="img-fluid topImg" *ngIf="ValueSelected == 'bh' " src="../../../../assets/images/bm/header_nucleo.png">
            <img class="img-fluid topImg" *ngIf="ValueSelected == 'ch' " src="../../../../assets/images/cn/header_nucleo.png">
            <div class="px15">
                <p class="contentMediumSmall">{{ 'SIMILAC_NUCLEOTIDES.DESC1' | translate }}</p>
                <p class="contentMediumSmall">{{ 'SIMILAC_NUCLEOTIDES.DESC2' | translate }}</p>
                <p class="mb-0 contentMediumBold text-center mt-4">{{ 'SIMILAC_NUCLEOTIDES.DESC3_BEFORE_2FL' | translate
                    }} <span class="textSandal">{{ 'SIMILAC_NUCLEOTIDES.2FL' | translate }}</span>{{
                    'SIMILAC_NUCLEOTIDES.DESC3_AFTER_2FL' | translate }}</p>
                <p class="mb-0 contentMediumBold text-center" [hidden]="hideInChinese || hideInBahasa">{{ 'SIMILAC_NUCLEOTIDES.DESC4' | translate }}</p>
                <img class="img-fluid w-100 mt-3" *ngIf="ValueSelected == '' || ValueSelected == 'undefined' || ValueSelected == 'en_US'" src="../../../../assets/images/worldfirstformulborder.png">
                <img class="img-fluid w-100 mt-3" *ngIf="ValueSelected == 'bh' " style ="width:100%" src="../../../../assets/images/bm/2fl.png">
                <img class="img-fluid w-100 mt-3" *ngIf="ValueSelected == 'ch' " style ="width:100%"  src="../../../../assets/images/cn/2fl.png">
                

                <p class="contentMediumBold text-center mb-0 mt30" *ngIf="!hideInChinese">{{ 'SIMILAC_NUCLEOTIDES.DESC5_BEFORE_2FL' | translate
                    }}<span class="textSandal">{{ 'SIMILAC_NUCLEOTIDES.2FL' | translate }}</span> <span> {{
                    'SIMILAC_NUCLEOTIDES.DESC5_AFTER_2FL' | translate }} {{ 'SIMILAC_NUCLEOTIDES.DESC7' | translate }} </span>{{ 'SIMILAC_NUCLEOTIDES.DESC8' | translate }}</p>
                    
                <p class="contentMediumBold text-center mb-0 mt30" *ngIf="hideInChinese"><span class="textSandal">2’-FL </span>是什么？
                {{ 'SIMILAC_NUCLEOTIDES.DESC8' | translate }}</p>    

                <p class="mb-0 mt-2 contentMediumSmall text-center"><span class="textSandalBold">{{
                        'SIMILAC_NUCLEOTIDES.2FL' | translate }}</span> {{ 'SIMILAC_NUCLEOTIDES.DESC9' | translate }}
                </p>

                <p class="mb-0 contentMediumSmall text-center" [hidden]="hideInChinese">{{ 'SIMILAC_NUCLEOTIDES.DESC10' | translate }}</p>
                <div class="text-center mt-4 mb-4">
                    <img class="img-fluid" *ngIf="ValueSelected == '' || ValueSelected == 'undefined' || ValueSelected == 'en_US'" src="../../../../assets/images/Similac Gain-Plus-Gold-milkboy.png">
                    <img class="img-fluid" *ngIf="ValueSelected == 'bh' " src="../../../../assets/images/bm/diagram-1.png">
                    <img class="img-fluid" *ngIf="ValueSelected == 'ch' " src="../../../../assets/images/cn/diagram-1.png">
                </div>
                <p class="contentMediumSmall mb-0">{{ 'SIMILAC_NUCLEOTIDES.DESC11' | translate }}</p>
            </div>

        </div>
        <div class="bottomContentBlock text-center mt-4">
            <p class="mb-0" *ngIf="!hideInChinese">{{ 'SIMILAC_NUCLEOTIDES.DESC12' | translate }}</p>
            <p class="mb-0" *ngIf="!hideInChinese">{{ 'SIMILAC_NUCLEOTIDES.DESC13' | translate }}</p>
            <p class="mb-0 textSandal" *ngIf="!hideInChinese">{{ 'SIMILAC_NUCLEOTIDES.DESC14' | translate }}</p>

            <p class="mb-0" *ngIf="hideInChinese">选用 <span class="textSandal"> 2’-FL 和核苷酸</span> 双重系统滋养孩子的身体防御功能</p>

            <img class="mt-2 img-fluid" *ngIf="ValueSelected == '' || ValueSelected == 'undefined' || ValueSelected == 'en_US'" src="../../../../assets/images/similacgoldboycenteredimage.png" />
            <img class="img-fluid" *ngIf="ValueSelected == 'bh' " src="../../../../assets/images/bm/diagram-2.png">
            <img class="img-fluid" *ngIf="ValueSelected == 'ch' " src="../../../../assets/images/cn/diagram-2.png">
            <p class="mb-0 smallContent text-left mt-2">{{ 'SOURCE_OF_INGREDIENTS.REFERENCES' | translate }}</p>
            <p class="mb-0 smallContent text-left">1 Geohring KC, et al.J Nutr. 2016 Dec; 146(12):2559-2566. Epub 2016.</p>
            <p class="mb-0 smallContent text-left">2 Pickering LK, et al. Pediatrics. 1998; 101(2):242-249.</p>
            <div>
                <button class="buttonOutlineBlue mt-4" routerLink="/similac-whychoose">{{ 'SIMILAC_NUCLEOTIDES.DESC15' |
                    translate }}</button>
            </div>
        </div>
    </div>
    <a href="https://loyalty.abbottmalaysia.com.my/">
        <img class="img-fluid w-100" *ngIf="ValueSelected == '' || ValueSelected == 'undefined' || ValueSelected == 'en_US'" src="../../../../assets/images/beaparty.png">
        <img class="img-fluid w-100" *ngIf="ValueSelected == 'bh' " src="../../../../assets/images/bm/ascw.png">
        <img class="img-fluid w-100" *ngIf="ValueSelected == 'ch' " src="../../../../assets/images/cn/ascw.png">
   
    </a>
    <app-footer></app-footer>
</section>
<app-similac-bottomnav></app-similac-bottomnav>