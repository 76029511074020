import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { environment as env } from '../../../environments/environment';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-redemption-history',
  templateUrl: './redemption-history.component.html',
  styleUrls: ['./redemption-history.component.scss'],
})
export class RedemptionHistoryComponent implements OnInit {
  pointsList: any;
  voucherList: any;
  rewardDetail: any;
  constructor(
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService, 
    private bsService: BugsnagService
  ) {}

  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    this.getCustomer();
    this.getRewardList();
    setTimeout(() => {
      this.getRedemtionDetail();
    }, 1000);
  }
  onClickBack() {
    this.router.navigate(['/redeem-rewards']);
  }
  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    this.spinner.show();
    this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
      this.spinner.hide();
      if (res['status'].code == 200) {
        // Block the user
        let block = res['customers']['customer'][0].custom_fields.field;
        let blockUser = block.filter((filt) => {
          return filt.name == 'block_count';
        });
        console.log(blockUser, 'blockUser===');
        if (blockUser[0]?.value == '1') {
          this.spinner.hide();
          sessionStorage.clear();
          this.router.navigate(['/block-notice']);
        }
        sessionStorage.setItem(
          'abbottUserId',
          res['customers']['customer'][0].user_id
        );
      }
    }, err => {
      this.bsService.notifyError(err);
      console.log(mobile, '=== get customer mobile =======');
      console.log(err?.error?.message, '=== get customer error message =======');
      console.log(err?.error?.code, '=== get customer error code =======');
    });
  }
  getRewardList() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    this.spinner.show();
    this.apiService
      .getMarvalRewardDetails(mobile, token)
      .pipe(first())
      .subscribe((reward: any) => {
        this.spinner.hide();
        this.rewardDetail = reward.rewardList;
      }, err => {
        this.bsService.notifyError(err);
      });
  }
  pointData: any;
  getRedemtionDetail() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    this.spinner.show();
    this.apiService.getMarvalVouchers(mobile, token).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res['status'].code == 200) {
          this.pointsList = res?.response?.customer?.redemptions?.points?.point;
          this.voucherList = res?.userVouchers;
          let voucherFilter = this.voucherList.forEach((voucherList) => {
            let tierData = voucherList.rewardDetails.tier;
            let pointFilter = this.pointsList.filter((point) => {
              return point.notes === tierData;
            });
            let rewardFilter = null;
            if (this.rewardDetail != null || this.rewardDetail != undefined) {
              rewardFilter = this.rewardDetail.filter((reward) => {
                return reward.tier === tierData;
              });
            }
            let foundReward = null;
            if (rewardFilter != null && rewardFilter.length > 0) {
              foundReward = rewardFilter[0];
            }
            let tottal_points = 0;
            let quantity = 0;
            pointFilter.forEach((pointFilter) => {
              tottal_points = tottal_points + pointFilter.points_redeemed;
              if (foundReward != null) {
                quantity =
                  quantity +
                  pointFilter.points_redeemed / foundReward.intouchPoints;
              } else {
                quantity = voucherList.vouchers.length;
              }
            });
            let transDate = voucherList.vouchers[0].issued;

            voucherList['total_points'] = tottal_points;
            voucherList['quantity'] = quantity;
            //voucherList['displayDate'] = moment(transDate).add(2.5, 'hours').format('DD MMM yyyy');
            voucherList['displayDate'] = transDate;
          });

          // this.voucherList = this.voucherList.push(this.pointsList );
          // this.pointsList.forEach((element:any) => {
          //   this.pointData = element?.points_redeemed
          //   console.log( this.pointData, 'this.pointData')
          // });
        }
      },
      (err) => {
        console.log(err, 'err--');
      }
    );
  }
}
