import { Component, OnInit } from '@angular/core';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { ApiService } from 'src/app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { v4 as uuidv4 } from 'uuid';
declare var $: any;
import { BugsnagService } from 'src/app/services/bugsnag.service';
@Component({
  selector: 'app-refer-friend',
  templateUrl: './refer-friend.component.html',
  styleUrls: ['./refer-friend.component.scss'],
})
export class ReferFriendComponent implements OnInit {
  private ngNavigatorShareService: NgNavigatorShareService;
  couponCode: any;
  userPoints: any;
  useCouponCode: any;
  promotionalPointsList: any;
  constructor(
    ngNavigatorShareService: NgNavigatorShareService,
    private apiService: ApiService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute, 
    private bsService: BugsnagService
  ) {
    this.ngNavigatorShareService = ngNavigatorShareService;
  }

  ngOnInit(): void {
    $('#tooltip').tooltip();
    // this.couponCode = 'CR9F8XD'
    this.getCustomer();
    this.getReferral();
  }

  getReferral() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    this.spinner.show();
    let referresp;
    this.apiService
      .getCapCustomerReferrals(mobile, token)
      .subscribe((res: any) => {
        referresp = res;
        this.spinner.hide();
        if (res['status'].code == 200) {
          console.log(res, 'referral resp');
          this.couponCode = res?.customers?.referral_code;
          this.useCouponCode = res?.customers?.referees?.length;
        }
      },err=>{
        
        this.bsService.notifyError(err);
        this.spinner.hide()
        this.apiService.showErrorMsg('Something went wrong, please try again')
        console.log(err, '=== get customer error =======')
        console.log(mobile, '=== get customer mobile =======');
        console.log(err?.error?.message, '=== get customer error message =======');
        console.log(err?.error?.code, '=== get customer error code =======');
      });
  }
  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    this.spinner.show();
    let getcustresp;
    this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
      this.spinner.hide();
      if (res['status'].code == 200) {
        // Block the user
        let block = res['customers']['customer'][0].custom_fields.field;
        let blockUser = block.filter((filt) => {
          return filt.name == 'block_count';
        });
        console.log(blockUser, 'blockUser===');
        if (blockUser[0]?.value == '1') {
          this.spinner.hide();
          sessionStorage.clear();
          this.router.navigate(['/block-notice']);
        }

        this.userPoints = res['customers']['customer'][0]?.loyalty_points
          ? res['customers']['customer'][0]?.loyalty_points
          : '0';
        let promotionalPoints =
          res['customers']['customer'][0].promotion_points?.customer?.item;
        this.promotionalPointsList = promotionalPoints;
        console.log(this.promotionalPointsList?.length, 'promotionalPoints ==');
      }
    },err=>{
    
      this.bsService.notifyError(err);
        this.spinner.hide()
        this.apiService.showErrorMsg('Something went wrong, please try again')
        console.log(err, '=== get customer error =======')
        console.log(mobile, '=== get customer mobile =======');
        console.log(err?.error?.message, '=== get customer error message =======');
        console.log(err?.error?.code, '=== get customer error code =======');
    });
  }
  share() {
    if (!this.ngNavigatorShareService.canShare()) {
      alert(`This service/api is not supported in your Browser`);
      return;
    }

    this.ngNavigatorShareService
      .share({
        title:
          'Hey! I’m part of Abbott’s Share & Care World loyalty program and would like you to be part of it too! Lot’s of ways to earn CarePoints and use them to redeem rewards.',
        text: `Be part of Abbott Share & Care World loyalty program today and get +20 CarePoints to redeem a Glucerna or Ensure liquid product for FREE! Use my referral code: ${this.couponCode} when you sign up at:`,
        url: `https://loyalty.abbottmalaysia.com.my/register?code=${this.couponCode}`,
        //url: `https://abbott-kl-uat.sttarter.com/register?code=${this.couponCode}`,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
       
        this.bsService.notifyError(error);
        this.spinner.hide()
        this.apiService.showErrorMsg('Something went wrong, please try again')
        console.log(error, '=== get customer error =======')
      });
  }
}
