import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qualitycontrol',
  templateUrl: './qualitycontrol.component.html',
  styleUrls: ['./qualitycontrol.component.scss'],
})
export class QualitycontrolComponent implements OnInit {
  productInfo: any;
  expiryDate: any;

  constructor() {
    this.productInfo = JSON.parse(sessionStorage.getItem('iSprintData'));
    console.log(this.productInfo, 'productInfo');
  }

  parseFunction(value) {
    return JSON.parse(value);
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    this.getTraceabilityContent();
  }

  getTraceabilityContent() {
    if (this.productInfo.is_isprint == true) {
      this.expiryDate = this.productInfo.expiry_date.value;
    } else {
      this.expiryDate = this.productInfo.expiry_date;
    }
  }
}
