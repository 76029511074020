<app-header homeLogo="../../../assets/images/homeicon.svg" link="/traceability-ensure"></app-header>
<section class="ensureGlobalContainer">
    <div class="bannerBlockTop">
        <img class="img-fluid" src="../../../../assets/images/ensuredarkbluebanners.png">
        <img class="titleImage" src="../../../../assets/images/ensuresourceofingredients.png">
    </div>
    <div class="ensureInnerBlock bgDarkBlue pb30">
        <div class="card">
            <p class="contentMediumSmall"> {{ 'SOURCE_OF_INGREDIENTS.OUR_INGREDIENTS' | translate }}</p>
            <img class="mapImg" src="../../../../assets/images/map.png">
            <p class="contentMediumSmall mt-3">{{ 'SOURCE_OF_INGREDIENTS.ONLY_BEST_INGREDIENT' | translate }}</p>
            <img class="img-fluid h-100 w-100" src="../../../../assets/images/ingredientsmomimage.png">
        </div>

        <p class="strengthContent text-center">{{ 'SOURCE_OF_INGREDIENTS.IMPROVE_STRENGTH' | translate }}<sup>{{ 'SOURCE_OF_INGREDIENTS.SUB_CONTENT' | translate }}</sup></p>
        <div class="text-center">
            <img class="clinicallyImg" src="../../../../assets/images/cliinicallyproven Imgae.png" />
        </div>
        <div class="bottomContentSmall text-left">
            <p class="mb-0">{{ 'SOURCE_OF_INGREDIENTS.REFERENCES' | translate }}</p>
            <p class="mb-0">{{ 'SOURCE_OF_INGREDIENTS.DESC' | translate }}</p>
            <p class="mb-0">{{ 'SOURCE_OF_INGREDIENTS.DESC1' | translate }}</p>
            <p class="">{{ 'SOURCE_OF_INGREDIENTS.EXERCISE_CONTENT' | translate }}</p>
        </div>
    </div>
    <app-footer></app-footer>
</section>
<app-ensure-bottomnav></app-ensure-bottomnav>
