import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-something-new',
  templateUrl: './something-new.component.html',
  styleUrls: ['./something-new.component.scss']
})
export class SomethingNewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo({top:0});
  }

}
