<app-header-logged-out></app-header-logged-out>
<section class="blueBg _h100vh">
    <div class="whiteBg mt100">
        <div class="formOut">
            <h4 class="formTitle text-center text-uppercase">Forgot Password?</h4>
            <form class="mt-5" [formGroup]="forgotMobileForm" (ngSubmit)="onForgotMobileFormSubmit()"
                *ngIf="showForgotMobileForm">
                <div class="form-group">
                    <label>Mobile Number</label>
                    <div class="row">
                        <div class="col-3 pr-1">
                            <input type="text" class="countryCodeAlign form-control" formControlName="countryCode"
                                placeholder="+60" disabled />
                        </div>
                        <div class="col-9 pl-0">
                            <input type="text" class="form-control" formControlName="mobileNumber" maxLength="10"
                                [ngClass]="{ 'is-invalid': submittedMobileForm && ForgotMobileFval.mobileNumber.errors }"
                                (keypress)="onlyNumbers($event)" (keydown)="mobileInput($event)" />
                            <div *ngIf="submittedMobileForm && ForgotMobileFval.mobileNumber.errors"
                                class="invalid-feedback">
                                <div *ngIf="ForgotMobileFval.mobileNumber.errors.required">Enter Mobile Number </div>
                                <div class="text-left"
                                    *ngIf="ForgotMobileFval.mobileNumber.errors.pattern ||  ForgotMobileFval.mobileNumber.errors.pattern">
                                    Invalid Mobile Number
                                </div>
                                <div *ngIf="ForgotMobileFval.mobileNumber.errors.minlength">Mobile number must be
                                    atleast 9 digits
                                </div>
                                <div *ngIf="ForgotMobileFval.mobileNumber.errors.maxlength">Mobile number must be 10
                                    digits </div>
                            </div>
                        </div>
                        <!-- <div *ngIf="ForgotMobileFval.mobileNumber.errors.minlength">Mobile number must be atleast 10 digits </div>
                        <div *ngIf="ForgotMobileFval.mobileNumber.errors.maxlength">Mobile number must be atmost 10 digits </div> -->
                    </div>

                    <div *ngIf="submittedMobileForm && userNotFound" class="invalidText">
                        User is not registered
                    </div>
                    <div *ngIf="submittedMobileForm && nonRegisteredUser" class="invalidText">
                        User is not registered
                    </div>
                    <p class="contentDarkBlue mt-2">Please enter full handphone number beginning with 1. Eg: Handphone number 012-3899000. Key in 123899000.</p>
                </div>
                <div class="text-center mt-4">
                    <button type="submit" class="blueBtn mb-3"
                        [ngClass]="{'isValidSubmit': forgotMobileForm.valid }">Reset My
                        Password</button>
                    <button type="submit mt-4" class="blueBtn" routerLink="/login">Cancel</button>
                </div>
            </form>

            <form class="mt-5" [formGroup]="resetForm" (ngSubmit)="onSubmitResetForm()" *ngIf="showResetForm">
                <div class="form-group">
                    <label>password</label>
                    <input type="password" class="form-control" formControlName="password"
                        (keydown)="passwordInput($event)"
                        [ngClass]="{ 'is-invalid': submittedResetForm && resetFval.password.errors }" />
                    <div *ngIf=" submittedResetForm && resetFval.password.errors" class="invalid-feedback">
                        <div *ngIf="resetFval.password.errors.required">Enter password </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Confirm Password</label>
                    <input type="password" class="form-control" formControlName="confirmPassword"
                        (keydown)="passwordInput($event)"
                        [ngClass]="{ 'is-invalid': submittedResetForm && resetFval.confirmPassword.errors }" />
                    <div *ngIf="submittedResetForm && resetFval.confirmPassword.errors" class="invalid-feedback">
                        <div *ngIf="resetFval.confirmPassword.errors.required">Password is required</div>
                        <div *ngIf="resetFval.confirmPassword.errors.mustMatch">Password confirmation does not match
                        </div>
                    </div>
                    <div *ngIf="submittedResetForm && passwordError" class="invalidText">Old password and new password
                        cannot be same</div>
                </div>
                <div class="text-center mt-4">
                    <button type="submit" class="blueBtn mb-3"
                        [ngClass]="{'isValidSubmit': resetForm.valid }">submit</button>
                </div>
            </form>

            <form class="mt-5" [formGroup]="forgotOtpForm" (ngSubmit)="onSubmitForgotOtpForm()" *ngIf="showOtpForm">
                <div class="form-group text-center">
                    <!-- <label>Enter a 6 digit otp</label> -->
                    <!-- <input type="password" class="form-control" formControlName="otp" (keydown)="otpInput($event)"
                        [ngClass]="{ 'is-invalid': submittedOtpForm && forgotOtpFval.otp.errors }" maxlength="6"/>
                    <div *ngIf="submittedOtpForm && forgotOtpFval.otp.errors" class="invalid-feedback">
                        <div *ngIf="forgotOtpFval.otp.errors.required">Enter Otp</div>
                        <div class="text-left" *ngIf="forgotOtpFval.otp.errors.pattern ||  forgotOtpFval.otp.errors.pattern">
                            Invalid Otp
                        </div>
                        <div *ngIf="forgotOtpFval.otp.errors.minlength">otp should be 6 characters</div>
                        <div *ngIf="forgotOtpFval.otp.errors.maxlength">otp should be 6 characters</div>
                    </div>
                    <div *ngIf="invalidOtp" class="invalidText">
                        <div>Invalid Otp</div>
                    </div> -->
                    <p class="mb-0 contentSmall">OTP has been sent to your mobile number</p>
                    <p class="mb-0 contentSmall">Please enter it below:</p>
                    <div class="text-center mt-3" *ngIf="!showResendOtp">
                        (<span class="contentSmall" *ngIf="beginTimer">
                            Resend Otp in
                            <countdown #cd [config]="{leftTime: 60,format: 'mm:ss',notify: [ 2, 5 ]}"
                                (event)="handleEvent($event)"></countdown>
                        </span>)
                        <br>
                    </div>
                    <ng-otp-input (onInputChange)="onOtpChange($event)"
                        [config]="{length:6,placeholder:'0',allowNumbersOnly: true}"
                        [ngClass]="{'is-invalid' : submittedOtpForm && (otp == undefined || otp.length < 6) || invalidOtp }"
                        #ngOtpInput>
                    </ng-otp-input>
                    <div *ngIf="submittedOtpForm && forgotOtpFval.otp.errors" class="text-danger pl-0">
                        <div *ngIf="!activeClassButton">Please Enter OTP</div>
                    </div>
                    <div *ngIf="invalidOtp" class="text-danger pl-0">
                        <div>The entered OTP is incorrect</div>
                    </div>
                </div>

                <div class="text-center mt-4">
                    <button type="submit"
                        [ngClass]="!activeClassButton ? 'lightBlueBtn mb-3 mt-3' : 'blueBtn mb-3 mt-3'">submit</button>
                </div>

                <div class="text-center mt-4">
                    <p class="contentSmall mb-2">Didn't receive otp?</p>
                    <button *ngIf="!showResendOtp" class="lightBlueBtn" type="button">Resend otp</button>
                    <button *ngIf="showResendOtp" class="blueBtn" type="button" (click)="resendOTP()">Resend
                        otp</button>
                </div>
                <div class="text-center mt-4">
                    <button class="blueBtn text-uppercase" type="button" (click)="onClickChangeNumber()">Change
                        Number</button>
                </div>
            </form>

        </div>

    </div>
</section>