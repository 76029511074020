import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-logged-out',
  templateUrl: './header-logged-out.component.html',
  styleUrls: ['./header-logged-out.component.scss']
})
export class HeaderLoggedOutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
