<app-header-primary></app-header-primary>
<div class="cameraContainer blueBg _h100vh">
  <div class="cam-icon">
    <div class="btn-left backBtn" (click)="onClickBack()">
      <img src="../../../assets/images/Group 310.png" class="backIcon" />
      <p>Back</p>
    </div>
    <div class="icon-right">
      <img
        src="../../../assets/images/Group 318.png"
        width="30px"
        height="25px"
      />
    </div>
  </div>
  <div class="container" *ngIf="webcamImage">
    <p class="cam-title text-center">Thank you!</p>
    <p class="check-content">
      Check your uploaded receipt, ensure it's clear for us to process
    </p>
  </div>
  <div class="container" *ngIf="!webcamImage">
    <p class="cam-title text-center">Take Photo</p>
    <p class="check-content">Make sure your receipt is clear</p>
  </div>
  <div class="videoCam mt-4">
    <div class="snapshot" *ngIf="webcamImage">
      <img [src]="url" style="height: 420px" />
      <div class="confirmButtons mt-4 row">
        <div class="col-6 pr-0">
          <button class="actionBtn w-100 btn" (click)="retake()">RETAKE</button>
        </div>
        <div class="col-6">
          <button (click)="uploadImage()" class="btn w-100 btnValidate">
            SUBMIT
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="!webcamImage">
      <webcam
        [height]="400"
        [width]="500"
        [trigger]="triggerObservable"
        (imageCapture)="handleImage($event)"
        *ngIf="showWebcam"
        [allowCameraSwitch]="allowCameraSwitch"
        [switchCamera]="nextWebcamObservable"
        [imageQuality]="1"
        [mirrorImage]="never"
        (initError)="handleInitError($event)"
      >
      </webcam>
    </div>
  </div>
  <div class="camButtons row mt-4 mx-0 mb-4" *ngIf="!webcamImage">
    <div class="uploadBtn col-2">
      <input
        id="upload"
        type="file"
        name="profile"
        accept="image/*"
        (change)="fileChangeEvent($event)"
      />
      <img class="" src="../../../assets/images/Group 317.png" />
    </div>
    <div class="captureBtn col-8 text-center">
      <img
        class=""
        src="../../../assets/images/Group 316.png"
        (click)="triggerSnapshot()"
      />
    </div>
    <div class="uploadBtn col-2">
      <input
        id="upload"
        type="file"
        name="profile"
        accept="image/*"
        (change)="fileChangeEvent($event)"
      />
      <img class="" src="../../../assets/images/Path 272.png" />
    </div>
  </div>
</div>
<app-version></app-version>

<app-footer></app-footer>
