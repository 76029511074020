<app-header-primary></app-header-primary>
<div class="blueBg">
    <div class="redeemRewards">
        <div class="redemedHistoryLink">
        <a href="javascript:;" routerLink="/redemption-history">My redemption History </a><span>></span>
        </div>

        <div class="headerImg">
            <img src="../../../assets/images/redeem_rewards.png" alt="redeem rewards" />
        </div>
        <div class="redeemCard">
            <h5 class="text-uppercase">ABBOTT PRODUCTS</h5>
            <div class="row mb20">
                <div class="col-6 mb-4 text-center" *ngFor="let reward of abbottProducts">
                    <div class="productImgOut mt30">
                        <div class="points">
                            <h6>{{reward?.intouchPoints}}</h6>
                            <p class="text-uppercase">carepoints</p>
                        </div>
                        <div class="productImg">
                            <img [src]="reward?.imageUrl" alt="similac mom 900g" />
                        </div>
                    </div>
                    <h6>{{reward?.name}}</h6>
                    <a href="javascript:;" (click)="reedemReward(reward)" class="blueBtn">redeem</a>
                </div>
            </div>
        </div>
        <div class="redeemCard">
            <h5 class="text-uppercase">CHILD EDUCATION</h5>
            <div class="row mb20">
                <div class="col-6 mb-4 text-center" *ngFor="let reward of childEdu">
                    <div class="productImgOut">
                        <div class="points">
                            <h6>{{reward?.intouchPoints}}</h6>
                            <p class="text-uppercase">carepoints</p>
                        </div>
                        <div class="productImg">
                            <img [src]="reward?.imageUrl" alt="similac mom 900g" />
                        </div>
                    </div>
                    <h6>{{reward?.name}}</h6>
                    <a href="javascript:;" (click)="reedemReward(reward)" class="blueBtn">redeem</a>
                </div>
            </div>
        </div>
        <div class="redeemCard">
            <h5 class="text-uppercase">VOUCHERS & E-VOUCHERS</h5>
            <div class="row mb20">
                <div class="col-6 mb-4 text-center" *ngFor="let reward of voucherEvoucher">
                    <div class="productImgOut">
                        <div class="points">
                            <h6>{{reward?.intouchPoints}}</h6>
                            <p class="text-uppercase">carepoints</p>
                        </div>
                        <div class="productImg">
                            <img [src]="reward?.imageUrl" alt="similac mom 900g" />
                        </div>
                    </div>
                    <h6>{{reward?.name}}</h6>
                    <a href="javascript:;" (click)="reedemReward(reward)" class="blueBtn">redeem</a>
                </div>
            </div>
        </div>
        <div class="redeemCard">
            <h5 class="text-uppercase">HOUSEHOLD </h5>
            <div class="row mb20">
                <div class="col-6 mb-4 text-center" *ngFor="let reward of houseHold">
                    <div class="productImgOut">
                        <div class="points">
                            <h6>{{reward?.intouchPoints}}</h6>
                            <p class="text-uppercase">carepoints</p>
                        </div>
                        <div class="productImg">
                            <img [src]="reward?.imageUrl" alt="similac mom 900g" />
                        </div>
                    </div>
                    <h6>{{reward?.name}}</h6>
                    <a href="javascript:;" (click)="reedemReward(reward)" class="blueBtn">redeem</a>
                </div>
            </div>
        </div>
        <div class="redeemCard">
            <h5 class="text-uppercase">HEALTH & FITNESS</h5>
            <div class="row mb20">
                <div class="col-6 mb-4 text-center" *ngFor="let reward of healthandFit">
                    <div class="productImgOut">
                        <div class="points">
                            <h6>{{reward?.intouchPoints}}</h6>
                            <p class="text-uppercase">carepoints</p>
                        </div>
                        <div class="productImg">
                            <img [src]="reward?.imageUrl" alt="similac mom 900g" />
                        </div>
                    </div>
                    <h6>{{reward?.name}}</h6>
                    <a href="javascript:;" (click)="reedemReward(reward)" class="blueBtn">redeem</a>
                </div>
            </div>
        </div>
        <!-- <div class="redeemCard">
            <h5 class="text-uppercase">CHILD EDUCATION</h5>
            <div class="row mb20">
                <div class="col-6 text-center">
                    <div class="productImgOut">
                        <div class="points">
                            <h6>120</h6>
                            <p class="text-uppercase">carepoints</p>
                        </div>
                        <div class="productImg">
                            <img src="../../../assets/images/similac_mom_900g.png" alt="similac mom 900g" />
                        </div>
                    </div>
                    <h6>Similac Mom 900g</h6>
                    <a href="javascript:;" class="blueBtn">redeem</a>
                </div>
                <div class="col-6 text-center">
                    <div class="productImgOut">
                        <div class="points">
                            <h6>320</h6>
                            <p class="text-uppercase">carepoints</p>
                        </div>
                        <div class="productImg">
                            <img src="../../../assets/images/pediasure_optiheight_plain_850g.png" alt="pediasure optiheight plain 850g
                            " />
                        </div>
                    </div>
                    <h6>PediaSure® OptiHEIGHT (Plain) 850g</h6>
                    <a href="javascript:;" class="blueBtn">redeem</a>
                </div>
            </div>
            <div class="row mb20">
                <div class="col-6 text-center">
                    <div class="productImgOut">
                        <div class="points">
                            <h6>250</h6>
                            <p class="text-uppercase">carepoints</p>
                        </div>
                        <div class="productImg">
                            <img src="../../../assets/images/similac_gain_plus_gold_900g.png" alt="similac gain plus gold900g" />
                        </div>
                    </div>
                    <h6>Similac® Gain Plus Gold 900g</h6>
                    <a href="javascript:;" class="blueBtn">redeem</a>
                </div>
                <div class="col-6 text-center">
                    <div class="productImgOut">
                        <div class="points">
                            <h6>270</h6>
                            <p class="text-uppercase">carepoints</p>
                        </div>
                        <div class="productImg">
                            <img src="../../../assets/images/pediasure_optiheight_plain_850g.png" alt="pediasure optiheight plain 850g
                            " />
                        </div>
                    </div>
                    <h6>Isomil® Plus 850g</h6>
                    <a href="javascript:;" class="blueBtn">redeem</a>
                </div>
            </div>
        </div>
        <div class="redeemCard">
            <h5 class="text-uppercase">VOUCHERS & E-VOUCHERS</h5>
            <div class="row mb20">
                <div class="col-6 text-center">
                    <div class="productImgOut">
                        <div class="points">
                            <h6>120</h6>
                            <p class="text-uppercase">carepoints</p>
                        </div>
                        <div class="productImg">
                            <img src="../../../assets/images/similac_mom_900g.png" alt="similac mom 900g" />
                        </div>
                    </div>
                    <h6>Similac Mom 900g</h6>
                    <a href="javascript:;" class="blueBtn">redeem</a>
                </div>
                <div class="col-6 text-center">
                    <div class="productImgOut">
                        <div class="points">
                            <h6>320</h6>
                            <p class="text-uppercase">carepoints</p>
                        </div>
                        <div class="productImg">
                            <img src="../../../assets/images/pediasure_optiheight_plain_850g.png" alt="pediasure optiheight plain 850g
                            " />
                        </div>
                    </div>
                    <h6>PediaSure® OptiHEIGHT (Plain) 850g</h6>
                    <a href="javascript:;" class="blueBtn">redeem</a>
                </div>
            </div>
            <div class="row mb20">
                <div class="col-6 text-center">
                    <div class="productImgOut">
                        <div class="points">
                            <h6>250</h6>
                            <p class="text-uppercase">carepoints</p>
                        </div>
                        <div class="productImg">
                            <img src="../../../assets/images/similac_gain_plus_gold_900g.png" alt="similac gain plus gold900g" />
                        </div>
                    </div>
                    <h6>Similac® Gain Plus Gold 900g</h6>
                    <a href="javascript:;" class="blueBtn">redeem</a>
                </div>
                <div class="col-6 text-center">
                    <div class="productImgOut">
                        <div class="points">
                            <h6>270</h6>
                            <p class="text-uppercase">carepoints</p>
                        </div>
                        <div class="productImg">
                            <img src="../../../assets/images/pediasure_optiheight_plain_850g.png" alt="pediasure optiheight plain 850g
                            " />
                        </div>
                    </div>
                    <h6>Isomil® Plus 850g</h6>
                    <a href="javascript:;" class="blueBtn">redeem</a>
                </div>
            </div>
        </div>
        <div class="redeemCard">
            <h5 class="text-uppercase">HOUSEHOLD</h5>
            <div class="row mb20">
                <div class="col-6 text-center">
                    <div class="productImgOut">
                        <div class="points">
                            <h6>120</h6>
                            <p class="text-uppercase">carepoints</p>
                        </div>
                        <div class="productImg">
                            <img src="../../../assets/images/similac_mom_900g.png" alt="similac mom 900g" />
                        </div>
                    </div>
                    <h6>Similac Mom 900g</h6>
                    <a href="javascript:;" class="blueBtn">redeem</a>
                </div>
                <div class="col-6 text-center">
                    <div class="productImgOut">
                        <div class="points">
                            <h6>320</h6>
                            <p class="text-uppercase">carepoints</p>
                        </div>
                        <div class="productImg">
                            <img src="../../../assets/images/pediasure_optiheight_plain_850g.png" alt="pediasure optiheight plain 850g
                            " />
                        </div>
                    </div>
                    <h6>PediaSure® OptiHEIGHT (Plain) 850g</h6>
                    <a href="javascript:;" class="blueBtn">redeem</a>
                </div>
            </div>
            <div class="row mb20">
                <div class="col-6 text-center">
                    <div class="productImgOut">
                        <div class="points">
                            <h6>250</h6>
                            <p class="text-uppercase">carepoints</p>
                        </div>
                        <div class="productImg">
                            <img src="../../../assets/images/similac_gain_plus_gold_900g.png" alt="similac gain plus gold900g" />
                        </div>
                    </div>
                    <h6>Similac® Gain Plus Gold 900g</h6>
                    <a href="javascript:;" class="blueBtn">redeem</a>
                </div>
                <div class="col-6 text-center">
                    <div class="productImgOut">
                        <div class="points">
                            <h6>270</h6>
                            <p class="text-uppercase">carepoints</p>
                        </div>
                        <div class="productImg">
                            <img src="../../../assets/images/pediasure_optiheight_plain_850g.png" alt="pediasure optiheight plain 850g
                            " />
                        </div>
                    </div>
                    <h6>Isomil® Plus 850g</h6>
                    <a href="javascript:;" class="blueBtn">redeem</a>
                </div>
            </div>
        </div>
        <div class="redeemCard">
            <h5 class="text-uppercase">HEALTH & FITNESS</h5>
            <div class="row mb20">
                <div class="col-6 text-center">
                    <div class="productImgOut">
                        <div class="points">
                            <h6>120</h6>
                            <p class="text-uppercase">carepoints</p>
                        </div>
                        <div class="productImg">
                            <img src="../../../assets/images/similac_mom_900g.png" alt="similac mom 900g" />
                        </div>
                    </div>
                    <h6>Similac Mom 900g</h6>
                    <a href="javascript:;" class="blueBtn">redeem</a>
                </div>
                <div class="col-6 text-center">
                    <div class="productImgOut">
                        <div class="points">
                            <h6>320</h6>
                            <p class="text-uppercase">carepoints</p>
                        </div>
                        <div class="productImg">
                            <img src="../../../assets/images/pediasure_optiheight_plain_850g.png" alt="pediasure optiheight plain 850g
                            " />
                        </div>
                    </div>
                    <h6>PediaSure® OptiHEIGHT (Plain) 850g</h6>
                    <a href="javascript:;" class="blueBtn">redeem</a>
                </div>
            </div>
        </div> -->
    </div>
    <app-version></app-version>
    <div class="-mx-20">
        <app-footer></app-footer>
    </div>
</div>
<app-bottom-nav></app-bottom-nav>