<section class="productInfoContainer">
    <app-header-secondary-copy></app-header-secondary-copy>
    <div class="topBlockOut text-center">
        <h5 class="title mt-3">{{ productName }}</h5>
        <figure>
            <img class="rumSumImg mt-2" [src]="productImage" />
        </figure>
    </div>
    <h5 class="title mt-4 text-center">{{ 'PRODUCT_INFO.TITLE1' | translate }}</h5>
    <div class="listBlock">
        <div class="row mx-0 mt-3 align-items-center">
            <div class="col-3">
                <img class="img-fluid" src="../../../assets/images/cow.png">
            </div>
            <div class="col-8 px-0">
                <p class="listTilte">{{ 'PRODUCT_INFO.LIST_TITLE' | translate }}</p>
                <p class="listDescriptions">{{ sourceofIn }}</p>
            </div>
        </div>
        <div class="row mx-0 mt-3 align-items-center">
            <div class="col-3">
                <img class="img-fluid" src="../../../assets/images/package.png">
            </div>
            <div class="col-8 px-0">
                <p class="listTilte">{{ 'PRODUCT_INFO.LIST_TITLE1' | translate }}</p>
                <p class="listDescriptions">{{ 'PRODUCT_INFO.LIST_TILE_DESC1_LINE1' | translate }} {{batchNumber}}</p>
                <p class="listDescriptions">{{ 'PRODUCT_INFO.LIST_TILE_DESC2_LINE2' | translate }} {{manufacturingDate}}</p>
                <p class="listDescriptions">{{ 'PRODUCT_INFO.LIST_TILE_DESC3_LINE3' | translate }} {{manufactorIn}}</p>
                <p class="listDescriptions">{{ 'PRODUCT_INFO.LIST_TILE_DESC4_LINE4' | translate }} {{expiryDate}}</p>
            </div>
        </div>
        <div class="row mx-0 mt-3 align-items-center">
            <div class="col-3">
                <img class="img-fluid" src="../../../assets/images/boat.png">
            </div>
            <div class="col-8 px-0">
                <p class="listTilte">{{ 'PRODUCT_INFO.LIST_TITLE2' | translate }}</p>
                <p class="listDescriptions">{{ 'PRODUCT_INFO.LIST_TILE_DESC2_LINE1' | translate }} {{customClearanceDate}}</p>
            </div>
        </div>
        <div class="row mx-0 mt-3 align-items-center">
            <div class="col-3">
                <img class="img-fluid" src="../../../assets/images/storage.png">
            </div>
            <div class="col-8 px-0">
                <p class="listTilte">{{ 'PRODUCT_INFO.LIST_TITLE3' | translate }}</p>
                <p class="listDescriptions">{{ 'PRODUCT_INFO.LIST_TILE_DESC3_LINE1' | translate }} {{arrivalDate}}</p>
            </div>
        </div>
        <div class="row mx-0 mt-3 align-items-center">
            <div class="col-3">
                <img class="img-fluid" src="../../../assets/images/productrumsum.png">
            </div>
            <div class="col-8 px-0">
                <p class="listTilte">{{ 'PRODUCT_INFO.LIST_TITLE4' | translate }}</p>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</section>