<!-- <app-header-primary></app-header-primary> -->

<section class="blockUserContainer blueBgContainer _h100vh">
  <div class="header-logos">
    <div class="abbottLogo col-4 my-2">
      <img src="../../../assets/images/Logo.png" height="40" width="40" />
    </div>
    <div class="shareAndCareLogo col-4 my-2">
      <div class="text-right">
        <img
          src="../../../assets/images/shareAndCareWorld.png"
          height="40"
          width="40"
        />
      </div>
    </div>
  </div>
  <div class="block-content text-center">
    <!-- <p>Notice</p> -->
    <p class="px-4">
      As a form of security, your account has been locked due to suspicious
      activity. If you wish to unlock your account, please call
      <a href="tel:1800-88-6233"> 1800-88-6233</a> or email us at
      <a href="mailto:m.bluth@example.com">wecare@abbott.com</a>
    </p>
  </div>
</section>
<!-- <app-version></app-version> -->

<app-footer></app-footer>
