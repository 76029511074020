import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-expose',
  templateUrl: './expose.component.html',
  styleUrls: ['./expose.component.scss'],
})
export class ExposeComponent implements OnInit {
  productInfo: any;
  productName: any;
  productImage: any;
  batchNumber: any;
  manufactorIn: any;
  manufacturingDate: any;
  expiryDate: any;
  customClearanceDate: any;
  arrivalDate: any;
  flavor: any;
  sku: any;
  isIsprint: any;
  prodImage: any;
  source_of_milk: any;
  learnMore: boolean = false;
  howToCollectCarePoints: boolean = false;
  brandLink:any;

  constructor(
    private apiService: ApiService, 
    private bsService: BugsnagService
    ) {
    this.productInfo = JSON.parse(sessionStorage.getItem('iSprintData'));
    this.isIsprint = this.productInfo.is_isprint;
    console.log(this.productInfo, 'productInfo');
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    this.getTraceability();
    this.apiService.getGpasProductImage(this.sku).subscribe((res: any) => {
      console.log(res.data, 'getGpasProductImage');
      this.prodImage = res.data[0]?.productimage;
      console.log(this.prodImage);
    }, err => {
      this.bsService.notifyError(err, {sku: this.sku});
    });
  }
  onClickLearnMore() {
    this.learnMore = true;
    this.howToCollectCarePoints = false;
  }

  onClickCollectCarepoints() {
    this.howToCollectCarePoints = true;
    this.learnMore = false;
  }

  getTraceability() {
    this.productName = this.productInfo.product_name;
    this.productImage = this.productInfo.product_image;

    if (this.productInfo.is_isprint == true) {
      let parsedProductName = this.parseFunction(this.productInfo.product_name);
      this.productName = parsedProductName.ms_MY;
      // let parsedBatchNum = this.parseFunction(this.productInfo.batch_number);
      // this.batchNumber = parsedBatchNum.ms_MY;
      this.batchNumber = this.productInfo.batch_number.en_US;
      this.manufactorIn = this.productInfo.country_of_origin.en_US;
      this.manufacturingDate = this.productInfo.manufacture_date.value;
      this.expiryDate = this.productInfo.expiry_date.value;
      this.customClearanceDate = this.productInfo.custom_clearance_date.value;
      this.arrivalDate = this.productInfo.arrival_date.value;
      this.source_of_milk = this.productInfo.source_of_milk.ms_MY;
      if (this.productName.toLowerCase().startsWith('ensure')) {
        this.brandLink = 'https://abbottnutrition.com.my/products/ensure-gold';
      } else if (this.productName.toLowerCase().startsWith('similac')) {
        this.brandLink =
          'https://abbottnutrition.com.my/similacgainplus/aboutsimilac';
      } else if (this.productName.toLowerCase().startsWith('pediasure')) {
        this.brandLink = 'https://pediasure.com.my/';
      }  else if (this.productName.toLowerCase().startsWith('glucerna')) {
        this.brandLink = 'https://abbottnutrition.com.my/products/glucerna';
      }
      console.log(this.brandLink);
    } else {
      this.productName =
        this.productInfo.brand +
        ' ' +
        this.productInfo.flavor +
        ' ' +
        this.productInfo?.size;
      this.batchNumber = this.productInfo.batch_number;
      this.manufactorIn = this.productInfo.country;
      this.manufacturingDate = this.productInfo.manufacture_date;
      this.expiryDate = this.productInfo.expiry_date;
      this.customClearanceDate = this.productInfo.custom_clearance_date;
      this.arrivalDate = this.productInfo.arrival_date;
      this.flavor = this.productInfo.flavor;
      if (this.productInfo.brand.toLowerCase().startsWith('ensure')) {
        this.brandLink = 'https://abbottnutrition.com.my/products/ensure-gold';
      } else if (this.productInfo.brand.toLowerCase().startsWith('similac')) {
        this.brandLink =
          'https://abbottnutrition.com.my/similacgainplus/aboutsimilac';
      } else if (this.productInfo.brand.toLowerCase().startsWith('pediasure')) {
        this.brandLink = 'https://pediasure.com.my/';
      }
      console.log(this.brandLink);
    }
    this.sku = this.productInfo.sku;

    console.log(this.isIsprint);
  }
  parseFunction(value: any) {
    // throw new Error('Method not implemented.');
    return JSON.parse(value);
  }
}
