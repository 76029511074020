<app-header-primary></app-header-primary>
<div class="blueBg">
    <div class="whiteBg p0">
        <div class="ensureDetailsOut">
            <h2 class="text-uppercase text-center">STAY STRONG WITH <span>ENSURE GOLD.</span> HERE’S WHY.</h2>
            <div class="imgOut">
                <img src="../../../assets/images/edi1.png" alt="hmb" />
            </div>
            <div class="inner">
                <h6>WHAT is <span>HMB*?</span></h6>
                <p>HMB (or –hydroxy –Melthylbutyrate) is a nutrient that helps reduce muscle degeneration. HMB is found in some food but is very difficult to get such significant amounts from diet alone.</p>
                <img src="../../../assets/images/edi2.png" alt="quick facts" />
                <small class="mt20 mb20">
                    1. Berton, et al. 2015. PLoS ONE 10(11):e01417757. Doic10.1371 / journal.pone 0141757<br>
                    * HMB(_-Hydroxy _-Methylbutyrate) is needed by the body to support tissue building<br>
                    ** When taken twice daily and cosumed along with light daily exercise.<br><br>
                    by the body to support tissue building<br>
                    ** When taken twice daily and cosumed along with light daily exercise
                </small>
                <h3><span>HMB*</span> is found naturally in some foods but is difficult to achieve daily needs from diet alone2.</h3>
                <h4 class="text-center mt20">To Get 1.5G HMB/Day:</h4>
                <img src="../../../assets/images/edi3.png" alt="hmb" />
                <small class="mt10">2. Argiles HM, et. al JAMDA. 2016;17:789-796</small>
                <h3 class="mt50">Triple Protein Blend</h3>
                <p class="mb20">3 types of proteins provides sustained supply of amino acids to rebuild muscle.</p>
                <div class="text-center">
                    <img src="../../../assets/images/edi4.png"class="customImgWidth" alt="triple protein blend" />
                </div>
                <div class="mt20 mb20">
                    <img src="../../../assets/images/edi5.png" alt="amino acid" />
                </div>
                <ul class="mt20 mb20">
                    <li>Whey is considered as fast protein due to its fast metabolic rate.</li>
                    <li>Casein is considered as “slow” protein.</li>
                    <li>Soy protein is considered as “intermediate” protein</li>
                </ul>
                <p>*HMB = β-Hydroxy β-Methylbutyrate</p>
            </div>
        </div>
    </div>
    <p class="code pb-3 mt-4">MAL.2021.19812.CORP.1 (v1.0)</p>
    <div class="-mx-20">
        <app-footer></app-footer>
    </div>
</div>
<app-bottom-nav></app-bottom-nav>