import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-similac-qualitycontrol',
  templateUrl: './similac-qualitycontrol.component.html',
  styleUrls: ['./similac-qualitycontrol.component.scss'],
})
export class SimilacQualitycontrolComponent implements OnInit {
  productInfo: any;
  productImage: any;
  content: any;
  productCode: any;
  productName: any;
  productName1: any;
  productName2: any;
  batchNumber: any;
  sourceofIn: any;
  manufacturingDate: any;
  expiryDate: any;
  arrivalDate: any;
  manufactorIn: any;
  customClearanceDate: any;
  hideInChinese: boolean = false;
  constructor(
    public translate: TranslateService,
    private apiService: ApiService
  ) {
    this.productInfo = JSON.parse(sessionStorage.getItem('iSprintData'));
    console.log(this.productInfo, 'productInfo');

    this.apiService.chineseLang.subscribe((res) => {
      this.hideInChinese = res;
    });
  }

  parseFunction(value) {
    return JSON.parse(value);
  }
  ValueSelected: any;
  selectedVal: any;
  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    this.productImage = this.productInfo.product_image;
    this.getTraceabilityContent();

    //   this.content = this.productInfo.product.txt;
    //   this.apiService.trackLang.subscribe(res=>{
    //     this.ValueSelected = res;
    //     if(this.ValueSelected != ''){
    //       this.selectedVal = this.ValueSelected;
    //     }
    //     //console.log(this.ValueSelected ,this.selectedVal )

    //   })

    //   this.content.forEach((item: any) => {
    //     // product code
    //     this.productCode = this.productInfo.product.productCode;
    //     if (item.orders == 1) {
    //       if(this.parseFunction(item.value).en_US == undefined){
    //         let parsedPrimaryValue = this.parseFunction(item.value).value;
    //         this.productName = parsedPrimaryValue;
    //       }else{
    //         let parsedPrimaryValue = this.parseFunction(item.value).en_US;
    //         this.productName = parsedPrimaryValue;
    //       }

    //       let splitWords = this.productName.split(' ');
    //       let slice = splitWords.slice(2);
    //       let slice2 = splitWords[0] + ' ' + splitWords[1];
    //       let join = slice.join(' ');
    //       this.productName1 = slice2
    //       this.productName2 = join
    //     }
    //     if (item.orders == 8) {
    //       if(this.parseFunction(item.value).en_US == undefined){
    //         let parsedPrimaryValue = this.parseFunction(item.value).value;
    //         this.batchNumber = parsedPrimaryValue;
    //       }else{
    //         let parsedPrimaryValue = this.parseFunction(item.value).en_US;
    //         this.batchNumber = parsedPrimaryValue;
    //       }

    //     }
    //     if (item.orders == 2) {
    //       console.log('milk source')
    //       if( this.parseFunction(item.value).en_US == undefined){
    //         let parsedPrimaryValue = this.parseFunction(item.value).value;
    //         console.log(parsedPrimaryValue, 'parsedPrimaryValue')
    //         console.log(this.parseFunction(item.value), 'his.parseFunction(item.value)')
    //         this.sourceofIn = parsedPrimaryValue;
    //       }else{
    //         let parsedPrimaryValue = this.parseFunction(item.value).en_US;
    //         this.sourceofIn = parsedPrimaryValue;
    //         console.log(parsedPrimaryValue, 'parsedPrimaryValue')
    //         console.log(this.parseFunction(item.value), 'his.parseFunction(item.value)')
    //       }
    //     }
    //     if (item.orders == 3) {
    //       console.log('manu facturing date')
    //       if(this.parseFunction(item.value).en_US == undefined){
    //         let parsedPrimaryValue = this.parseFunction(item.value).value;
    //         this.manufacturingDate = parsedPrimaryValue;
    //         console.log(parsedPrimaryValue, 'manufac')
    //         console.log(this.parseFunction(item.value), 'manu fact 1')
    //       }else{
    //         let parsedPrimaryValue = this.parseFunction(item.value).en_US;
    //         this.manufacturingDate = parsedPrimaryValue;
    //         console.log(parsedPrimaryValue, 'manufac')
    //         console.log(this.parseFunction(item.value), 'manu fact 1')
    //       }

    //     }
    //     if (item.orders == 4) {
    //       if(this.parseFunction(item.value).en_US == undefined){
    //         let parsedPrimaryValue = this.parseFunction(item.value).value;
    //         this.manufactorIn = parsedPrimaryValue;
    //       }else{
    //         let parsedPrimaryValue = this.parseFunction(item.value).en_US;
    //         this.manufactorIn = parsedPrimaryValue;
    //       }

    //     }
    //     if (item.orders == 5) {
    //      if(this.parseFunction(item.value).en_US == undefined){
    //       let parsedPrimaryValue = this.parseFunction(item.value).value;
    //       this.expiryDate = parsedPrimaryValue;
    //      }else{
    //       let parsedPrimaryValue = this.parseFunction(item.value).en_US;
    //       this.expiryDate = parsedPrimaryValue;
    //      }
    //     }
    //     if (item.orders == 7) {
    //       if(this.parseFunction(item.value).en_US == undefined){
    //         let parsedPrimaryValue = this.parseFunction(item.value).value;
    //         this.arrivalDate = parsedPrimaryValue;
    //       }else{
    //         let parsedPrimaryValue = this.parseFunction(item.value).en_US;
    //         this.arrivalDate = parsedPrimaryValue;
    //       }

    //     }
    //     if (item.orders == 6) {
    //       if(this.parseFunction(item.value).en_US == undefined){
    //         let parsedPrimaryValue = this.parseFunction(item.value).value;
    //         this.customClearanceDate = parsedPrimaryValue;
    //       }else{
    //         let parsedPrimaryValue = this.parseFunction(item.value).en_US;
    //         this.customClearanceDate = parsedPrimaryValue;
    //       }

    //     }
    //   })
    // }

    // // selectedLanguage(lang_val){
    // //   if(lang_val == 'en_US'){
    // //     this.hideInChinese = false;
    // //     this.translate.use(lang_val)
    // //   }
    // //   if(lang_val == 'bh'){
    // //     this.hideInChinese = false;
    // //     this.translate.use(lang_val)
    // //   }
    // //   if(lang_val == 'ch'){
    // //     this.hideInChinese = true;
    // //     this.translate.use(lang_val)
    // //   }
  }
  getTraceabilityContent() {
    // this.batchNumber = this.productInfo.batch_number.en_US;

    if (this.productInfo.is_isprint == true) {
      let parsedProductName = this.parseFunction(this.productInfo.product_name);
      this.productName = parsedProductName.ms_MY;
      this.expiryDate = this.productInfo.expiry_date.value;
    } else {
      this.productName = this.productInfo.product_name;
      this.expiryDate = this.productInfo.expiry_date;
    }
  }
}
