import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pediasure-naturalvitamin',
  templateUrl: './pediasure-naturalvitamin.component.html',
  styleUrls: ['./pediasure-naturalvitamin.component.scss']
})
export class PediasureNaturalvitaminComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo({top:0})
  }

}
