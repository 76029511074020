<app-header homeLogo="../../../assets/images/homeicon.svg" link="/traceability-pediasure"></app-header>
<section class="pediaSureGlobalContainer">
    <div class="pediaSureInnerBlock">
        <div class="card px15 mt30">
            <div class="px20">
                <img class="img-fluid w-100 topImgPrimary" src="../../../../assets/images/pediasharecaregold.png">
                <p class="mt20 fontGBMediumSmall ">{{ 'PEDIA_SHARE_CARE.DESC1' | translate }}</p>
                <p class="mt-1 fontGBMediumSmall ">{{ 'PEDIA_SHARE_CARE.DESC2' | translate }}</p>
            </div>
            <div class="pediaShareMiddleBlock">
                <div class="topContentBlock text-center">
                    <p class="mb-0">{{ 'PEDIA_SHARE_CARE.DESC3' | translate }}</p>
                </div>
                <div class="row mt-4">
                    <div class="col-3 text-center pr-0">
                        <img class="heartImg" src="../../../../assets/images/heartvioletoutline.png">
                    </div>
                    <div class="col-9">
                        <p class="content">{{ 'PEDIA_SHARE_CARE.DESC4' | translate }}</p>
                        <p class="content">{{ 'PEDIA_SHARE_CARE.DESC5' | translate }}</p>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-3 text-center pr-0">
                        <img class="mobileImg" src="../../../../assets/images/mobilevioletoutline.png">
                    </div>
                    <div class="col-9">
                        <p class="content">{{ 'PEDIA_SHARE_CARE.DESC6' | translate }}</p>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-3 text-center pr-0">
                        <img class="userImg" src="../../../../assets/images/uservioletouline.png">
                    </div>
                    <div class="col-9">
                        <p class="content">{{ 'PEDIA_SHARE_CARE.DESC7' | translate }}</p>
                    </div>
                </div>
            </div>
            <div class="row mx-0 mt-3 mb-3">
                <div class="col-4 pl-0">
                    <img class="rewardImg" src="../../../../assets/images/pediarewardimage.png">
                </div>
                <div class="col-8 pr-0 mt-2">
                    <p class="mb-0 memberContent">{{ 'PEDIA_SHARE_CARE.MEMBER' | translate }}</p>
                    <p class="mb-0 fabulousContent">{{ 'PEDIA_SHARE_CARE.FABULOUS' | translate }}</p>
                    <button class="joinUsBtn mt-2" routerLink="/login">{{ 'PEDIA_SHARE_CARE.JOIN_US' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</section>
<app-pediasure-bottomnav></app-pediasure-bottomnav>
