<app-header-secondary homeLogo="../../../assets/images/homeicon.svg" link="/traceability-similac"></app-header-secondary>
<section class="similacGlobalContainer">
    <div class="topBannerBlock"></div>
    <div class="similacInnerBlock pb50">
        <div class="card">
            <img class="img-fluid topImg" *ngIf="ValueSelected == '' || ValueSelected == 'undefined' || ValueSelected == 'en_US'" src="../../../../assets/images/similacwhychoose.png">
            <img class="img-fluid topImg" *ngIf="ValueSelected == 'bh' " src="../../../../assets/images/bm/header_why.png">
            <img class="img-fluid topImg" *ngIf="ValueSelected == 'ch' " src="../../../../assets/images/cn/header_why.png">
            
            <p class="contentMediumSmall mt-2"> {{ 'SIMILAC_WHYCHOOSE.DESC1' | translate }}</p>

            <p class="contentMediumSmall">{{ 'SIMILAC_WHYCHOOSE.DESC2' | translate }}</p>
        
            <p class="contentMediumBold" >{{ 'SIMILAC_WHYCHOOSE.DESC3' | translate }} <span class="textSandal" [hidden]="hideInChinese">{{ 'SIMILAC_WHYCHOOSE.DESC3_HIGHLIGHTED' | translate }}</span></p>
            
            <img class="img-fluid w-100" *ngIf="ValueSelected == '' || ValueSelected == 'undefined' || ValueSelected == 'en_US'" src="../../../../assets/images/eyeqsystemnew.png"/>
            <img class="img-fluid w-100" *ngIf="ValueSelected == 'bh' " src="../../../../assets/images/bm/diagram-3.png">
            <img class="img-fluid w-100" *ngIf="ValueSelected == 'ch' " src="../../../../assets/images/cn/diagram-3.png">
            <p class="contentMediumSmall mt-2">{{ 'SIMILAC_WHYCHOOSE.DESC4' | translate }}</p>
        </div>
        <img class="img-fluid w-100 mt40" *ngIf="ValueSelected == 'bh' " src="../../../../assets/images/bm/table.png">
        <img class="img-fluid w-100 mt40" *ngIf="ValueSelected == 'ch' " src="../../../../assets/images/cn/table.png">
        <img class="img-fluid w-100 mt40" *ngIf="ValueSelected == '' || ValueSelected == 'undefined' || ValueSelected == 'en_US'" src="../../../../assets/images/similacgainplustable.png">

        <div class="text-center">
            <button class="buttonOutlineBlue mt-3" routerLink="/similac-nucleotides">{{ 'SIMILAC_WHYCHOOSE.LEARN_MORE' | translate }}</button>
         </div>
    </div>
    <a href="https://loyalty.abbottmalaysia.com.my/">
        <img class="img-fluid w-100" *ngIf="ValueSelected == '' || ValueSelected == 'undefined' || ValueSelected == 'en_US'" src="../../../../assets/images/beaparty.png">
        <img class="img-fluid w-100" *ngIf="ValueSelected == 'bh' " src="../../../../assets/images/bm/ascw.png">
        <img class="img-fluid w-100" *ngIf="ValueSelected == 'ch' " src="../../../../assets/images/cn/ascw.png">
   
    </a>
    <app-footer></app-footer>
</section>
<app-similac-bottomnav></app-similac-bottomnav>

