<app-header-primary></app-header-primary>
<section class="blueBg _h100vh">
  <div class="cam-icon">
    <div class="btn-left backBtn" (click)="onClickBack()">
      <img src="../../../assets/images/Group 310.png" class="backIcon" />
      <p>Back</p>
    </div>
    <div class="icon-right">
      <img
        src="../../../assets/images/Path 400.png"
        width="20x"
        height="30px"
      />
    </div>
  </div>
  <div>
    <div class="title text-center mt60">
      <span>SCAN QR CODE</span>
    </div>
    <section id="demo-content">
      <scandit-barcode-picker
        #barcodePicker
        style="border-radius: 15% !important"
        id="barcode-picker"
        scanningPaused="true"
        visible="false"
        playSoundOnScan="false"
        vibrateOnScan="true"
        scanSettings.enabledSymbologies='["QR"]'
        scanSettings.codeDuplicateFilter="-1"
        (ready)="onReady()"
        (scan)="onScan($event)"
        (scanError)="onScanError($event)"
      >
      </scandit-barcode-picker>
    </section>

    <!-- video -->

    <!-- <section id="demo-content">
      <video id="video" width="80%" height="100%" #video></video>
    </section> -->

    <!-- success scan modal -->
    <div *ngIf="showSuccessMessage" class="qr-success">
      <span>QR code scan successful</span>
    </div>

    <!-- processing and istruction messages-->
    <div class="text" *ngIf="showInstructionMessage">
      <span class="instructions">
        Point your camera to product's QR code.<br />
        Please ensure QR Code is clearly visible so it can be processed
      </span>
    </div>
    <div class="text" *ngIf="showProcessingMessage">
      <span class="instructions">Processing QR code</span>
    </div>
  </div>

  <div
    bsModal
    #alreadyScanned="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-child-name"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="closeRegisteredModal()"
          >
            x
          </button>
          <p class="text-center contactInfo">
            <strong>Oops! This code has already been scanned.</strong>
            <br />
            If you think this is an error, please call
            <a href="tel:1800-88-6233"> 1800-88-6233</a> or email us at
            <a href="mailto:m.bluth@example.com">wecare@abbott.com</a>
          </p>

          <div class="text-center">
            <button
              type="submit"
              class="blueBtn text-white mt-3"
              (click)="closeRegisteredModal()"
            >
              ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    bsModal
    #exceedLimitModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-child-name"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="closeRegisteredModal()"
          >
            x
          </button>
          <p class="text-center">
            Sorry, the number of scans seemed to have exceeded the recommended
            monthly consumption limit. <br />
            Please keep your product so that you can scan it again next month to
            collect CarePoints.
          </p>
          <p class="contactInfo">
            For more information, please contact
            <a href="tel:1800-88-6233"> 1800-88-6233</a> or email us at
            <a href="mailto:m.bluth@example.com">wecare@abbott.com</a>
          </p>

          <div class="text-center">
            <button
              type="submit"
              class="blueBtn text-white mt-3"
              (click)="closeRegisteredModal()"
            >
              ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    bsModal
    #camerapermission="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-child-name"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="closeCameraMdl()"
          >
            x
          </button>
          <!-- <p class="text-center">
          Sorry, the number of scans seemed to have exceeded the recommended
          monthly consumption limit. <br />
          Please keep your product so that you can scan it again next month to
          collect CarePoints.
        </p>
        <p class="contactInfo">
          For more information, please contact
          <a href="tel:1800-88-6233"> 1800-88-6233</a> or email us at
          <a href="mailto:m.bluth@example.com">wecare@abbott.com</a>
        </p> -->
          <p class="text-center">
            You have denied the camera permission, Please allow camera
            permission from your phone settings.
          </p>

          <div class="text-center">
            <button
              type="submit"
              class="blueBtn text-white mt-3"
              (click)="closeCameraMdl()"
            >
              ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
  bsModal
  #popupModal="bs-modal"
  class="modal fade px30"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body p-4">
        <div class="product-qty row">
          <div class="center p-10">
            QR code unable to be scanned due to non-MY product.
          </div>
          <div class="col-12 mt-3">
            <button
              type="submit"
              class="primaryBtn mt-3 fW500 Btn"
              (click)="onClickOK()"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</section>

<app-version></app-version>

<app-footer></app-footer>
