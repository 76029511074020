import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whyensure',
  templateUrl: './whyensure.component.html',
  styleUrls: ['./whyensure.component.scss']
})
export class WhyensureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo({top: 0});
  }

}
