import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-traceability-pediasure',
  templateUrl: './traceability-pediasure.component.html',
  styleUrls: ['./traceability-pediasure.component.scss'],
})
export class TraceabilityPediasureComponent implements OnInit {
  productInfo: any;
  productImage: any;
  content: any;
  productCode: any;
  productName: any;
  productName1: any;
  productName2: any;
  batchNumber: any;
  sourceofIn: any;
  manufacturingDate: any;
  expiryDate: any;
  arrivalDate: any;
  manufactorIn: any;
  customClearanceDate: any;
  prodImage: any;
  sku: any;
  isIsprint: any;
  constructor(
    private apiService: ApiService, 
    private bsService: BugsnagService
    ) {
    this.productInfo = JSON.parse(sessionStorage.getItem('iSprintData'));
    console.log(this.productInfo, 'productInfo');
  }

  parseFunction(value) {
    return JSON.parse(value);
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0 });
    this.productImage = this.productInfo.product_image;
    this.getTraceabilityContent();
    this.apiService.getGpasProductImage(this.sku).subscribe((res: any) => {
      console.log(res.data, 'getGpasProductImage');
      this.prodImage = res.data[0]?.productimage;
      console.log(this.prodImage);
    }, err => {
      this.bsService.notifyError(err, {sku: this.sku});
    });
  }
  getTraceabilityContent() {
    this.productName = this.productInfo.product_name;
    this.productImage = this.productInfo.product_image;

    if (this.productInfo.is_isprint == true) {
      let parsedProductName = this.parseFunction(this.productInfo.product_name);
      this.productName = parsedProductName.ms_MY;
      this.batchNumber = this.productInfo.batch_number.en_US;
      this.manufactorIn = this.productInfo.country_of_origin.en_US;
      this.manufacturingDate = this.productInfo.manufacture_date.value;
      this.expiryDate = this.productInfo.expiry_date.value;
      this.customClearanceDate = this.productInfo.custom_clearance_date.value;
      this.arrivalDate = this.productInfo.arrival_date.value;
    } else {
      this.productName = this.productInfo.product_name;
      this.batchNumber = this.productInfo.batch_number;
      this.manufactorIn = this.productInfo.country_of_origin;
      this.manufacturingDate = this.productInfo.manufacture_date;
      this.expiryDate = this.productInfo.expiry_date;
      this.customClearanceDate = this.productInfo.custom_clearance_date;
      this.arrivalDate = this.productInfo.arrival_date;
    }
    this.sku = this.productInfo.sku;
    this.isIsprint = this.productInfo.is_isprint;
  }
}
