import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ensure-bottomnav',
  templateUrl: './ensure-bottomnav.component.html',
  styleUrls: ['./ensure-bottomnav.component.scss'],
})
export class EnsureBottomnavComponent implements OnInit {
  activePath: any;
  showMilkSource = false;
  showManufacturing = false;
  showQualityControl = false;
  showDelivery = false;
  showHmb = false;
  showShareCare = false;
  productInfo: any;
  constructor(private route: ActivatedRoute) {
    this.activePath = this.route.snapshot.routeConfig.path;
    console.log('rouetInfo', this.activePath);
    this.productInfo = JSON.parse(sessionStorage.getItem('iSprintData'));
  }

  ngOnInit(): void {
    if (this.activePath === 'ensure-ingredients') {
      this.showMilkSource = true;
    }
    if (this.activePath === 'ensure-manufacturing') {
      this.showManufacturing = true;
    }
    if (this.activePath === 'ensure-qualitycontrol') {
      this.showQualityControl = true;
    }
    if (this.activePath === 'ensure-delivery') {
      this.showDelivery = true;
    }
    if (this.activePath === 'ensure-hmb') {
      this.showHmb = true;
    }
    if (this.activePath === 'ensure-sharecare') {
      this.showShareCare = true;
    }
  }
}
