import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-bottom-nav',
  templateUrl: './bottom-nav.component.html',
  styleUrls: ['./bottom-nav.component.scss']
})
export class BottomNavComponent implements OnInit {
  activePath: any;
  showActiveImage: boolean = false;
  showActiveCarepointsImage: boolean = false;
  showActiveRedemptionImage: boolean = false;
  showActiveProductsImage: boolean = false;
  showActiveProfileImage: boolean = false;

  constructor(private route: ActivatedRoute) { 
    this.activePath = this.route.snapshot.routeConfig.path;
    console.log('rouetInfo', this.activePath);
  }

  ngOnInit(): void {
    if (this.activePath === 'aboutus') {
      this.showActiveImage = true;
      console.log("about", )
     }
   if (this.activePath === 'carepoints') {
    this.showActiveCarepointsImage = true;
   }

   if (this.activePath === 'redeem-rewards' ||  this.activePath === 'redemption-history' || this.activePath === 'redemption-success') {
    this.showActiveRedemptionImage = true;
   }

   if (this.activePath === 'products') {
    this.showActiveProductsImage = true;
   }
   if (this.activePath === 'profile' || this.activePath === 'register' ) {
     this.showActiveProfileImage = true;
   }
  }

}
