<app-header-primary></app-header-primary>
<div class="blueBg">
    <div class="whiteBg">
        <div class="pediasureDetailsOut">
            <img src="../../../assets/images/pdi1.png" alt="arginine" />
            <h1>SEE VISIBILITY GROWTH IN <span>8 WEEKS</span></h1>
            <img src="../../../assets/images/pdi2.png" alt="visibility growth" />
            <h5 class="mt20">Arginine, triple protein complex and 37 key nutrients to help your child achieve optimal growth potential.</h5>
            <p><strong>Arginine</strong> triggers the release of Growth Factors together with Triple Protein Complex to support longer bone growth<sup>1</sup></p>
            <img src="../../../assets/images/pdi3.png" alt="growth direction" class="mt20" />
            <p>Natural Vitamin K2 promotes calcium transfer to the bone to support stronger bone growth<sup>1</sup>.</p>
            <img src="../../../assets/images/pdi4.png" alt="sufficient" class="mt20 mb20" />
        </div>
    </div>
    <p class="code pb-3 mt-4">MAL.2021.19812.CORP.1 (v1.0)</p>
    <div class="-mx-20">
        <app-footer></app-footer>
    </div>
</div>
<app-bottom-nav></app-bottom-nav>