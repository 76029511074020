<app-header-secondary homeLogo="../../../assets/images/homeicon.svg" link="/traceability-similac"></app-header-secondary>
<section class="similacGlobalContainer">
    <div class="topBannerBlock"></div>
    <div class="similacInnerBlock pb50">
        <div class="card">
            <img class="img-fluid topImg" *ngIf="ValueSelected == '' || ValueSelected == 'undefined' || ValueSelected == 'en_US'" src="../../../../assets/images/similacsharecare.png">
            <img class="img-fluid topImg" *ngIf="ValueSelected == 'bh' " src="../../../../assets/images/bm/header_ascw.png">
            <img class="img-fluid topImg" *ngIf="ValueSelected == 'ch' " src="../../../../assets/images/cn/header_ascw.png">

            <p class="mb-0 contentMediumSmall mt-2">{{ 'SIMILAC_SHARE_CARE.DESC1' | translate }}</p>
            <p class="contentMediumSmall mt20">{{ 'SIMILAC_SHARE_CARE.DESC2' | translate }}</p>
            <p class="contentMediumBold">{{ 'SIMILAC_SHARE_CARE.DESC3' | translate }}</p>
            <div class="row mt-4">
                <div class="col-3 text-center pr-0">
                    <img class="heartImg" src="../../../../assets/images/roundedheartwhiteoutline.png">
                </div>
                <div class="col-9">
                    <p class="contentMediumSmall">{{ 'SIMILAC_SHARE_CARE.DESC4' | translate }}</p>
                    <p class="contentMediumSmall">{{ 'SIMILAC_SHARE_CARE.DESC5' | translate }}</p>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-3 text-center pr-0">
                    <img class="mobileImg" src="../../../../assets/images/roundedbluemobilewhiteoutline.png">
                </div>
                <div class="col-9">
                    <p class="contentMediumSmall pt-2">{{ 'SIMILAC_SHARE_CARE.DESC6' | translate }}</p>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-3 text-center pr-0">
                    <img class="userImg" src="../../../../assets/images/roundedheadsetwhiteoutline.png">
                </div>
                <div class="col-9">
                    <p class="contentMediumSmall">{{ 'SIMILAC_SHARE_CARE.DESC7' | translate }}</p>
                </div>
            </div>
        </div>
        <div class="bottomContentBlock text-center mt-4">
            <p class="mb-0 text-uppercase">{{ 'SIMILAC_SHARE_CARE.MEMBER' | translate }} </p>
            <p class="mb-0 text-uppercase textSandal">{{ 'SIMILAC_SHARE_CARE.FABULOUS' | translate }}</p>
            <img class="mt-2" src="../../../../assets/images/usersicon.png">
            <div>
               <button class="buttonOutlineBlue mt-3 w160" routerLink="/login">{{ 'SIMILAC_SHARE_CARE.JOIN_US' | translate }}</button>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</section>
<app-similac-bottomnav></app-similac-bottomnav>
