import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { BugsnagService } from '../services/bugsnag.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  scanScode = null;
  typeCheck: number;
  loader: boolean = true;
  errMsg: any;
  redirect: any;
  productName: any;
  latitude: string;
  longitude: string;
  confidence: string;
  iSprintData: any;
  user: any;
  userPoints: any;
  customerName: string;
  suspiciousCount: number = 0;
  weight: any;
  isWeightEmpty: boolean;
  prodImage: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private bsService: BugsnagService
  ) {
    // this.scanScode = this.route.snapshot.queryParams.u;
    let time = new Date();
    let timeStamp = time.getTime();
    // if (
    //   this.scanScode === undefined ||
    //   this.scanScode === null ||
    //   this.scanScode === ''
    // ) {
    //   this.loader = false;
    //   this.router.navigate([`/login/${timeStamp}`]);
    // } else {
    //   sessionStorage.setItem('scanCode', this.scanScode);
    // }

    // console.log(this.scanScode, 'scanScode');
    // sessionStorage.setItem('scanCode', this.scanScode);
    // console.log(sessionStorage.getItem('scanCode'), 'scanScode localstorage');

    window.scrollTo({ top: 0 });
    console.log(this.route.snapshot.queryParams.c);
    if (this.route.snapshot.queryParams.c) {
      this.scanScode = this.route.snapshot.queryParams.c;
      console.log(this.scanScode, 'this.scanScode 12');
      this.getPosition();
    } else if (this.route.snapshot.queryParams.u) {
      this.scanScode = this.route.snapshot.queryParams.u;
      console.log(this.scanScode, 'this.scanScode 12');
      this.getPosition();
    }

    //this.spinner.show();
    if (
      this.scanScode === undefined ||
      this.scanScode === null ||
      this.scanScode === ''
    ) {
      this.loader = false;

      //alert('Scan Code is mandatory');
      this.router.navigate([`/login/${timeStamp}`]);
    }
    if (this.scanScode == undefined) {
      this.loader = false;
      sessionStorage.removeItem('iSprintData');
      // alert("Please scan a valid QR code.")
    }

    sessionStorage.setItem('scanCode', this.scanScode);
  }

  ngOnInit(): void {
   // this.getPosition();
    // this.getCustomer();

    if (this.loader && this.scanScode) {
      // this.getTraceabilityContent();
    } else {
      // this.router.navigate(['/about-glucerna'])
    }
  }

  getPosition() {
    this.apiService.getPosition().then((pos) => {
      let lat = pos.lat;
      let lang = pos.lng;
      let confidence = pos.accuracy;
      this.latitude = JSON.stringify(lat);
      this.longitude = JSON.stringify(lang);
      this.confidence = JSON.stringify(confidence);
      this.getProductInfo();
    });
  }

  parseFunction(value) {
    return JSON.parse(value);
  }
  // getTraceabilityContent() {
  //   this.apiService.getTraceability(this.scanScode).subscribe((res: any) => {
  //     let time = new Date();
  //     let timeStamp = time.getTime();
  //     console.log(res, 'res isprint');
  //     if (res.result.code != '0') {
  //       console.log(res.result, 'res.result');
  //       alert(res.result.message);
  //     }
  //     let product = this.parseFunction(res.result.data.product.productName);
  //     this.productName = product.en_US;

  //     sessionStorage.setItem('productName', this.productName);

  //     sessionStorage.setItem('iSprintData', JSON.stringify(res.result.data));
  //     let productValue = res.result.data;
  //     let skuData = productValue?.product?.txt;
  //     console.log(skuData, 'skuData--');
  //     skuData.filter((items) => {
  //       // if (items.orders == 7) {
  //       //   let sf_product_code = this.parseFunction(items.value);
  //       //   let sfProductCode = sf_product_code.value;
  //       //   sessionStorage.setItem('sku', sfProductCode);
  //       // }
  //     });

  //     let sfProductCode = productValue?.verify?.productNum;
  //     sessionStorage.setItem('sku', sfProductCode);
  //     console.log(res.result.code, 'res.result.code 1');
  //     if (res.result.code == '0') {
  //       console.log(res.result.code, 'res.result.code 2');
  //       if (
  //         res.result.data.uaidInfo.type == 1 ||
  //         res.result.data.uaidInfo.type == 7
  //       ) {
  //         console.log(
  //           res.result.data.uaidInfo.type,
  //           'res.result.data.uaidInfo.type'
  //         );
  //         if (
  //           res?.result?.data?.product?.categoryCode ==
  //             'Ensure Gold Wheat HMB' ||
  //           res?.result?.data?.product?.categoryCode ==
  //             'Ensure Gold Vanilla HMB'
  //         ) {
  //           this.router.navigate([`/traceability-ensure/${timeStamp}`]);
  //           console.log('Ensure');
  //         } else if (
  //           res?.result?.data?.product?.categoryCode ==
  //             'PediaSure OptiHEIGHT Plain' ||
  //           res?.result?.data?.product?.categoryCode ==
  //             'PediaSure OptiHEIGHT Chocolate' ||
  //           res?.result?.data?.product?.categoryCode ==
  //             'PediaSure OptiHEIGHT Vanilla'
  //         ) {
  //           this.router.navigate([`/traceability-pediasure/${timeStamp}`]);
  //           console.log('Pediasure');
  //         } else if (
  //           res?.result?.data?.product?.categoryCode ==
  //             "Similac Gain Plus GOLD (2'-FL)" ||
  //           res?.result?.data?.product?.categoryCode ==
  //             "Similac Gain Kid GOLD (2'-FL)"
  //         ) {
  //           console.log('Similac');
  //           this.router.navigate([`/traceability-similac/${timeStamp}`]);
  //         } else if (
  //           res?.result?.data?.product?.categoryCode == 'Similac 1 (2’-FL)' ||
  //           res?.result?.data?.product?.categoryCode == 'Similac 2 (2’-FL)'
  //         ) {
  //           console.log('Similac-1 and 2');
  //           this.router.navigate([`/product-info/${timeStamp}`]);
  //         } else {
  //           console.log('else part');
  //         }

  //         //this.router.navigate(['/product-info'])
  //         // this.router.navigate(['/about-glucerna',data])
  //       } else if (res.result.data.uaidInfo.type == 2) {
  //         console.log(
  //           res.result.data.uaidInfo.type,
  //           'res.result.data.uaidInfo.type'
  //         );
  //         this.router.navigate([`/login/11/${timeStamp}`]);
  //         //  this.router.navigate(['/templanding'])
  //       }
  //     } else {
  //       console.log(res.result.code, 'res.result.code 3');
  //       alert('Enter valid qr code');
  //     }
  //   });
  // }

  isEmpty(weight) {
    if (weight == '' || weight == undefined || weight == null) {
      return false;
    } else {
      return true;
    }
  }

  getProductInfo() {
    this.apiService
      .productinfo(
        this.scanScode,
        this.latitude,
        this.longitude,
        this.confidence
      )
      .subscribe((res: any) => {
        console.log(res, 'res isprint');
        // this.spinner.hide();

        if (res.status === 'success') {
          this.iSprintData = res.result;
          sessionStorage.setItem('iSprintData', JSON.stringify(res.result));

          console.log(res.result.category_code);

          let cat_code = res.result.category_code;
          console.log(res.result.uaid_info_type);
          console.log(res.result.identifier?.en_US);

          // if( res.result.uaid_info_type == '' || (res.result.uaid_info_type == 2 && res.result.identifier.en_US == 'Single')){
          //   this.router.navigate(['/expose']);
          // }
          if( (res.result.uaid_info_type == 2 && res.result.identifier?.en_US == 'Single') || res.result.uaid_info_type == ''){
            this.router.navigate(['/expose']);
          }
         else if (
            res.result.uaid_info_type == 1 ||
           
            res.result.uaid_info_type == undefined
          ) {
            if (
              cat_code.startsWith('Ensure') ||
              cat_code.startsWith('ENSURE') ||
              cat_code.startsWith('ensure')
            ) {
              this.router.navigate(['/traceability-ensure']);
            } else if (
              cat_code.startsWith('Similac') ||
              cat_code.startsWith('SIMILAC') ||
              cat_code.startsWith('similac')
            ) {
              this.router.navigate(['/traceability-similac']);
            } else if (
              cat_code.startsWith('PediaSure') ||
              cat_code.startsWith('Pediasure') ||
              cat_code.startsWith('pediasure') ||
              cat_code.startsWith('PEDIASURE')
            ) {
              this.router.navigate(['/traceability-pediasure']);
            }
          } else {
            this.router.navigate(['/login']);
          }
        } else {
          this.loader = false;
          this.apiService.showErrorMsg('Something went wrong, please try again')
        }
      },err=>{     
        this.bsService.notifyError(err, {
          scanScode: this.scanScode,
          latitude: this.latitude,
          longitude: this.longitude,
          confidence: this.confidence
        });
            setTimeout(() => {
              this.router.navigate(['/login']);
            }, 5000);
           this.loader = false;
          this.apiService.showErrorMsg(err?.error?.message)
          console.log(err, '=== get customer error =======')
      });
  }
}
