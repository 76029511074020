<app-header-primary></app-header-primary>
    <section class="refer-friend-container">
        <div class="banner">
            <img class="max-width-100 w-100" src="../../../assets/images/refer-friend-banner.png" alt="Banner" />
        </div>
        <div class="myRewardsOut my-4 px-2">
            <div class="myRewards row mx-0">
                <div class="col-7 rewardsDashboard text-center">
                    <p class="rewardLabel">
                        My Rewards Dashboard
                    </p>
                    <p class="dashboard-points">
                        <span class="points">{{userPoints}}</span> CAREPOINTS
                    </p>
                </div>
                <div class="col-5 rewardsmyCode pl-0 pt-1 text-center">
                    <p class="use-code-label">USED MY CODE</p>
                    <p class="use-code">{{promotionalPointsList?.length}}</p>
                </div>
            </div>
        </div>
        <div class="myRewardsOut my-4 px-2">
            <div class="myRewards row mx-0">
                <div class="col-7 rewardsDashboard text-center">
                    <p class="rewardLabel">
                        My Referrer Code
                    </p>
                    <p class="dashboard-points">
                        <span class="points">{{couponCode}}</span><i class="mdi mdi-content-copy" id="tooltip" data-toggle="tooltip" title="Copied" ngxClipboard [cbContent]="couponCode"></i></p>
                </div>
                <div class="col-5 rewardsmyCode pl-0 pt-1 text-center d-flex align-items-center">
                    <a href="javascript:;" class="btn referBtn" (click)="share()">REFER NOW</a>
                </div>
            </div>
        </div>
        <div class="my-4 referBanner">
            <img class="max-width-100 w-100" src="../../../assets/images/refer.png" alt="Banner" />
            <a href="javascript:;" class="refer-now-btn" (click)="share()">Refer Now</a>
        </div>
        <app-version></app-version>
    </section>

<app-footer></app-footer>