import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-similac-details',
  templateUrl: './similac-details.component.html',
  styleUrls: ['./similac-details.component.scss']
})
export class SimilacDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo({top:0})
  }

}
