<app-header-primary></app-header-primary>

<section class="blueBg _h100vh points-statusContainer">
  <div class="btn-left backBtn">
    <img src="../../../assets/images/Group 310.png" class="backIcon" />
    <p (click)="onClickBack()">Back</p>
  </div>
  <ul class="nav nav-pills three-tabs mt20" id="pills-tab">
    <li
      class="col-3 active whiteBtn-tab"
      [class.blueBtn-tab]="tabId === 'allStatus'"
    >
      <a
        (click)="onClickPill('allStatus')"
        class="check3 active"
        data-toggle="pill"
        data-target="#menu1"
      >
        All Status</a
      >
    </li>
    <li
      class="col-3 whiteBtn-tab"
      [class.blueBtn-tab]="tabId === 'friendReferral'"
    >
      <a
        data-toggle="pill"
        (click)="onClickPill('friendReferral')"
        class="check4"
        data-target="#menu2"
        >Friend Referral</a
      >
    </li>
    <li
      *ngIf="findGrothdairyflag === true"
      class="col-3 whiteBtn-tab"
      [class.blueBtn-tab]="tabId === 'growthDairy'"
    >
      <a
        data-toggle="pill"
        (click)="onClickPill('growthDairy')"
        class="check3"
        data-target="#menu3"
        >Growth Diary</a
      >
    </li>
  </ul>

  <section>
    <!-- 1st list item -->
    <div class="tab-content">
      <div id="menu1" class="tab-pane fade show active">
        <div class="carePoints">
          <div class="updatesOut" *ngIf="productList?.length > 0">
            <!-- <h6>{{findDay}}</h6> -->
            <!-- <span>{{lastTranasction}}</span> -->
            <!-- <span>11:34, 24/04/21</span> -->
            <h6>Last Transaction</h6>
            <span>{{ lastTransactionDate | date: "dd-MM-yyyy" }}</span>
          </div>
        </div>
        <div *ngFor="let product of productList">
          <div class="points-statusList">
            <div class="image">
              <img *ngIf="product.image != 'null'" [src]="product.image" />
              <img
                *ngIf="product.image == 'null'"
                src="../../../assets/images/abbott.svg"
              />
            </div>
            <div class="col-6 product-name">
              <p class="mb-0">{{ product.name }}</p>
              <p
                *ngIf="product.status == 'rejected' && product.reason"
                class="mt-1 viewDetails"
              >
                <a (click)="onClickViewDetails(product.reason)">View Details</a>
              </p>
            </div>
            <div class="col-4 status pr-0 text-right">
              <span
                style="
                  background: #fff;
                  color: #004c6c;
                  padding: 6px 16px;
                  border-radius: 15px;
                "
                *ngIf="product.status == 'pending'"
                [class.pending]="status === 'pending'"
                >Pending</span
              >
              <a
                *ngIf="product.status == 'pending'"
                class="validateLink"
                (click)="onclickUploadReceipt(product?.productCode)"
                >Click to validate</a
              >
              <span
                style="
                  background: #c92e26;
                  color: #fff;
                  padding: 6px 16px;
                  border-radius: 15px;
                "
                *ngIf="product.status == 'rejected'"
                [class.rejected]="status === 'rejected'"
                >Rejected</span
              >
              <a
                *ngIf="product.status == 'rejected'"
                class="validateLink"
                (click)="openReUploadImageModal(product?.productCode)"
                >Re Upload Receipt</a
              >
              <span
                style="
                  background: #66a443;
                  color: #fff;
                  padding: 6px 16px;
                  border-radius: 15px;
                "
                *ngIf="product.status == 'approved'"
                [class.confirm]="status === 'approved'"
                >Confirm</span
              ><strong>{{ product.points }}</strong>
              <span
                style="
                  background: orange;
                  color: #fff;
                  padding: 6px 16px;
                  border-radius: 15px;
                "
                *ngIf="product.status == 'awaiting'"
                [class.confirm]="status === 'awaiting'"
                >Awaiting</span
              >
            </div>
          </div>
        </div>
        <div class="no-scanPoints row" *ngIf="productList?.length == 0">
          <h5 class="no-transc-available col-12 pl-2 pr-0 mt-2">
            No Transactions are Available
          </h5>
        </div>
        <!-- for testing in app camera component only
        <a [routerLink]="['/validate-receipt']"
          >Validate(click for testing further screens)</a
        > -->
      </div>
      <div id="menu2" class="tab-pane fade">
        <div *ngIf="referralList?.length > 0">
          <!-- <h4 class="mt-3">Friend Referral Points</h4> -->
          <div class="measurmentContainer my-4">
            <div class="measureLabel row mx-0">
              <!-- <div class="col-4 pr-0 pl-0">
                      <p class="label">PROMOTION <br /> NAME</p>
                  </div> -->
              <div class="col-6 pr-0 pl-0">
                <p class="label">POINTS</p>
              </div>
              <div class="col-6 pr-0">
                <p class="label">ISSUED DATE</p>
              </div>
            </div>
            <div
              class="measureUnits row mx-0 mt-3"
              *ngFor="let promotion of referralList"
            >
              <!-- <div class="col-4 pr-0 pl-0">
                      <p class="label text-uppercase"> {{promotion?.promotion_name}} </p>
                  </div> -->
              <div class="col-6 pl-0 pr-0">
                <p class="label">{{ promotion?.points }} points</p>
              </div>
              <div class="col-6 pr-0">
                <p class="label">{{ promotion?.date | date: "dd-MMM yy" }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="no-scanPoints row mt-4" *ngIf="referralList?.length == 0">
          <h5 class="no-transc-available col-12 pl-2 pr-0">
            No Friend Referral are Available
          </h5>
        </div>
      </div>

      <div id="menu3" class="tab-pane fade">
        <div *ngIf="findGrothdairyflag == true">
          <div
            [hidden]="
              webquloPointData == 'Invalid user ID.' ||
              webquloPointData == 'User not registered in growth diary.' ||
              hideCont
            "
          >
            <div class="measurmentContainer my-4">
              <div class="measureLabel row mx-0">
                <div class="col-4 pr-0 pl-0">
                  <p class="label">DATE</p>
                </div>
                <div class="col-3 pr-0 pl-0">
                  <p class="label">POINTS</p>
                </div>
                <div class="col-5 pr-0">
                  <p class="label">DESCRIPTION</p>
                </div>
              </div>
              <div
                class="measureUnits row mx-0 mt-3"
                *ngFor="let wqdata of webquloPointData"
              >
                <div class="col-4 pr-0 pl-0">
                  <p class="label text-uppercase">
                    {{ wqdata?.point_date | date: "dd-MMM yy" }}
                  </p>
                </div>
                <div class="col-3 pl-0 pr-0">
                  <p class="label">{{ wqdata?.point }} points</p>
                </div>
                <div class="col-5 pr-0">
                  <p class="label">{{ wqdata?.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
<div
  bsModal
  #reasonModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeReasonModal()"
        >
          x
        </button>
        <p class="text-center contactInfo">
          {{ rejectReason }}
        </p>
        <hr />
        <p class="text-center contactInfo">
          If you think this is an error, please call
          <a href="tel:1800-88-6233"> 1800-88-6233</a> or email us at
          <a href="mailto:m.bluth@example.com">wecare@abbott.com</a>
        </p>

        <div class="text-center">
          <button
            type="submit"
            class="blueBtn text-white mt-3"
            (click)="closeReasonModal()"
          >
            ok
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #reUploadImageModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="closeReUploadImageModal()"
        >
          x
        </button>

        <p class="text-center contactInfo">
          By proceeding, you have opted to re-upload a valid receipt for
          validation and will be subjected to the same
          <a routerLink="/">terms & conditions</a>
        </p>

        <div class="text-center">
          <button
            type="submit"
            class="blueBtn text-white mt-3"
            (click)="onclickReUploadImageReceipt()"
          >
            RE-UPLOAD RECEIPT
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-version></app-version>

<app-footer></app-footer>
