import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-validate-receipt',
  templateUrl: './validate-receipt.component.html',
  styleUrls: ['./validate-receipt.component.scss'],
})
export class ValidateReceiptComponent implements OnInit {
  @ViewChild('popupTandC', { static: true })
  popupTandC: ModalDirective;

  productCode:any;
  prductCode:any;
  
  constructor(private location: Location,
    private router: Router,
    private route: ActivatedRoute,) {
    this.productCode = this.route.snapshot.params.data;
    console.log(this.productCode, 'productCode====');
  }

  ngOnInit(): void {}
  handleUploadReceiptBtn() {
    this.popupTandC.show();
  }

  onClickBack() {
    this.location.back();
  }

  onclickUploadReceipt() {
    this.prductCode = this.productCode;
    console.log(this.productCode, 'this.productCode ')
    let data = {
      data: this.productCode
    }
    
    this.router.navigate(['/camera', data]);
  }
}
