import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pediasure-bottomnav',
  templateUrl: './pediasure-bottomnav.component.html',
  styleUrls: ['./pediasure-bottomnav.component.scss']
})
export class PediasureBottomnavComponent implements OnInit {
  activePath: any;
  showManufacturing = false;
  showQualityControl = false;
  showDelivery = false;
  showVitamin = false;
  showShareCare = false;
  constructor(private route: ActivatedRoute) {
    this.activePath = this.route.snapshot.routeConfig.path;
    console.log('rouetInfo', this.activePath);
   }

  ngOnInit(): void {
    if(this.activePath === 'pediasure-manufacturing') {
      this.showManufacturing = true;
    };
    if(this.activePath === 'pediasure-qualitycontrol') {
      this.showQualityControl = true;
    };
    if(this.activePath === 'pediasure-delivery') {
      this.showDelivery = true;
    };
    if(this.activePath === 'pediasure-naturalvitamin') {
      this.showVitamin = true;
    };
    if(this.activePath === 'pediasure-sharecare') {
      this.showShareCare = true;
    };
  
  }

}
