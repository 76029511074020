import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-similac-milksource',
  templateUrl: './similac-milksource.component.html',
  styleUrls: ['./similac-milksource.component.scss']
})

export class SimilacMilksourceComponent implements OnInit {
  hideInChinese: boolean = false;
  constructor(private apiService: ApiService,public translate: TranslateService) { 
    this.apiService.chineseLang.subscribe(res=>{
      this.hideInChinese = res;
     })
  }
  ValueSelected:any;
  selectedVal:any;

  ngOnInit(): void {
    window.scrollTo({top:0});
   
    this.apiService.trackLang.subscribe(res=>{
      this.ValueSelected = res;
      if(this.ValueSelected != ''){
        this.selectedVal = this.ValueSelected;
      }
     // console.log(this.ValueSelected ,this.selectedVal )
     
    })
  }

}
