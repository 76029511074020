import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-header-secondary',
  templateUrl: './header-secondary.component.html',
  styleUrls: ['./header-secondary.component.scss']
})
export class HeaderSecondaryComponent implements OnInit {
  @Input() homeLogo : string;
  @Input() link: string;
  constructor(public translate: TranslateService, private apiService: ApiService) {  
   }

  ngOnInit(): void {
  }

}
